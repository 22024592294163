import { useState, useEffect } from "react";
import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";
import { onlyIntInput} from "../utils/basics";

function BucketTool() {
  const { 
    bucketContinguous,
    setBucketContinguous,
    bucketMode,
    setBucketMode,
    ditherRatio,
    setDitherRatio,
    ditherOffsetX,
    setDitherOffsetX,
    ditherOffsetY,
    setDitherOffsetY,
  } = useMainContext();

  const [displayOffsetX, setDisplayOffsetX] = useState(ditherOffsetX);
  const [displayOffsetY, setDisplayOffsetY] = useState(ditherOffsetY);

  const handleBucketContinguous = (checked) => {
    setBucketContinguous(checked);
  };

  const handleModeChange = (e) => {
      setBucketMode(e.target.value);
  };

  const handleOffsetXChange = (e) => {
    if(e.target.value > 3){
      setDitherOffsetX(3);
      setDisplayOffsetX(3);
    } else if(e.target.value < 0){
      setDitherOffsetX(0);
      setDisplayOffsetX('');
    } else {
      setDitherOffsetX(parseInt(e.target.value));
      setDisplayOffsetX(e.target.value);
    }
  };

  const handleOffsetYChange = (e) => {
    if(e.target.value > 3){
      setDitherOffsetY(3);
      setDisplayOffsetY(3);
    } else if(e.target.value < 0){
      setDitherOffsetY(0);
      setDisplayOffsetY('');
    } else {
      setDitherOffsetY(parseInt(e.target.value));
      setDisplayOffsetY(e.target.value);
    }
  };

  const handleDitherRatio = (e) => {
    setDitherRatio(e.target.value);
  };

  return (
    <>
      <h4>Bucket Tool</h4>
      <label className="tool-label">
        Continguous:
        <input
          className="tool-checkbox"
          type="checkbox"
          onChange={(e) => handleBucketContinguous(e.target.checked)}
          checked={bucketContinguous}
        ></input>
      </label>
      
      <label className="tool-label">
        Mode: <select
            className="tool-select"
            onChange={(event) => {handleModeChange(event)}}
            value={bucketMode}
        >
            <option value="normal">Normal</option>
            <option value="dither">Dither</option>
        </select>
      </label>

      {bucketMode === "dither" && <>
        <label className="tool-label">Dither Ratio: </label>
        <input
          className="tool-range"
          type="range"
          onChange={handleDitherRatio}
          value={ditherRatio}
          min="0"
          max="16"
        ></input>
        <label className="tool-label">Offset X: <input
          className="tool-integer"
          type="number"
          value={ditherOffsetX}
          onChange={handleOffsetXChange}
          onKeyDown={onlyIntInput}
          min={0}
          max={3}
        ></input></label>
        <label className="tool-label">Offset Y: <input
          className="tool-integer"
          type="number"
          value={ditherOffsetY}
          onChange={handleOffsetYChange}
          onKeyDown={onlyIntInput}
          min={0}
          max={3}
        ></input></label>
      </>}
    </>
  );
}

export default BucketTool;

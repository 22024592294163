import { useState, useEffect } from "react";
import "../css/Tools.css";
import { useMainContext } from "../contexts/MainContext";
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { metaKey} from "../constants";
import alphaImage from "../assets/images/alpha.png";

function Tools() {
  // Get the state from MainContext
  const {
    setSwatchModal,
    selectedTool,
    setSelectedTool,
    palette,
    selectedSwatch,
    setSelectedSwatch,
    secondarySwatch,
    setSecondarySwatch,
    setUndoNow,
    setRedoNow,
    invertZoom,
    setTransformNow,
    altButton,
    setAltButton,
    shiftButton,
    setShiftButton,
    metaButton,
    setMetaButton,
  } = useMainContext();

  // Function to handle tool selection
  const handleToolClick = (tool) => {
    setSelectedTool(tool);
  };

  // Apply a 'selected' class if the tool is selected
  const toolButtonClass = (tool) => {
    if(selectedTool === "zoom"){
      if(tool === "zoom-in"){
        return invertZoom ? "tool-button" : "tool-button selected";
      } else if(tool === "zoom-out"){
        return invertZoom ? "tool-button selected" : "tool-button";
      }
    }
    return selectedTool === tool ? "tool-button selected" : "tool-button";
  };

  const swapColors = () => {
    setSecondarySwatch(selectedSwatch);
    setSelectedSwatch(secondarySwatch);
  }

  const tooltip = children => (
    <Tooltip className=".tooltip">{children}</Tooltip>
  );

  const swatchStyle = (swatch) => {
    if(parseInt(swatch) === 32){
      return { backgroundImage: `url(${alphaImage})` }
    } else {
      return { backgroundColor: palette[swatch] }
    }
  }

  return (
    <div className="tools">
      <OverlayTrigger placement="right" overlay={tooltip("M")}>
        <button
          className={toolButtonClass("move")}
          onClick={() => handleToolClick("move")}
        >
          <span className="icon-move"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("P")}>
        <button
          className={toolButtonClass("pencil")}
          onClick={() => handleToolClick("pencil")}
        >
          <span className="icon-pencil6"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("E")}>
        <button
          className={toolButtonClass("erase")}
          onClick={() => handleToolClick("erase")}
        >
          <span className="icon-eraser"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("L")}>
        <button
          className={toolButtonClass("line")}
          onClick={() => handleToolClick("line")}
        >
          <span className="icon-line"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("B")}>
        <button
          className={toolButtonClass("bucket")}
          onClick={() => handleToolClick("bucket")}
        >
          <span className="icon-bucket"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("G")}>
        <button
          className={toolButtonClass("gradient")}
          onClick={() => handleToolClick("gradient")}
        >
          <span className="icon-gradient"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("R")}>
        <button
          className={toolButtonClass("rectangle")}
          onClick={() => handleToolClick("rectangle")}
        >
          <span className="icon-dash-square"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("Shift-E")}>
        <button
          className={toolButtonClass("ellipse")}
          onClick={() => handleToolClick("ellipse")}
        >
          <span className="icon-dash-circle"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("W")}>
        <button
          className={toolButtonClass("wand")}
          onClick={() => handleToolClick("wand")}
        >
          <span className="icon-magic-wand"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("C")}>
        <button
          className={toolButtonClass("crop")}
          onClick={() => handleToolClick("crop")}
        >
          <span className="icon-enlarge"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("T")}>
        <button
          className={toolButtonClass("transform")}
          onClick={() => setTransformNow((prevValue) => prevValue + 1)}
        >
          <span className="icon-transform"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("I")}>
        <button
          className={toolButtonClass("eyedropper")}
          onClick={() => handleToolClick("eyedropper")}
        >
          <span className="icon-eyedropper"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("H")}>
        <button
          className={toolButtonClass("hand")}
          onClick={() => handleToolClick("hand")}
        >
          <span className="icon-hand-paper-o"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip("Z")}>
        <button
          className={toolButtonClass("zoom")}
          onClick={() => handleToolClick("zoom")}
        >
          {!invertZoom ? <span className="icon-zoom-in"></span> : <span className="icon-zoom-out"></span>}
        </button>
      </OverlayTrigger>
      
      <div className="selected-colors-wrapper" onClick={() => setSwatchModal(true)}>
        <div className="selected-colors">
          <div
            className="secondary-color"
            style={swatchStyle(secondarySwatch)}
          ></div>
          <div
            className="selected-color"
            style={swatchStyle(selectedSwatch)}
          ></div>
        </div>
      </div>

      <OverlayTrigger placement="right" overlay={tooltip("X")}>
        <button
          className="color-swap"
          onClick={swapColors}
        >
          <span className="icon-swap"></span>
        </button>
      </OverlayTrigger>

      <div className="modify-wrapper">
        {shiftButton ?
          <button className="modify-button selected" onClick={() => {setShiftButton(false)}}>Shift</button>
          :
          <button className="modify-button" onClick={() => {setShiftButton(true)}}>Shift</button>
        }
        {altButton ?
          <button className="modify-button selected" onClick={() => {setAltButton(false)}}>Alt</button>
          :
          <button className="modify-button" onClick={() => {setAltButton(true)}}>Alt</button>
        }
        {metaButton ?
          <button className="modify-button selected" onClick={() => {setMetaButton(false)}}>{metaKey}</button>
          :
          <button className="modify-button" onClick={() => {setMetaButton(true)}}>{metaKey}</button>
        }
      </div>

      <OverlayTrigger placement="right" overlay={tooltip(metaKey + "+Z")}>
        <button
          className="undo-redo-button"
          onClick={() => {setUndoNow((prevValue) => prevValue + 1)}}
        >
          <span className="icon-undo"></span>
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={tooltip(metaKey + "+Shift+Z")}>
        <button
          className="undo-redo-button"
          onClick={() => {setRedoNow((prevValue) => prevValue + 1)}}
        >
          <span className="icon-redo"></span>
        </button>
      </OverlayTrigger>
    </div>
  );
}

export default Tools;

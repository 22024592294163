import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useCallback
} from "react";
import "../css/Viewport.css";
import { maxLayerSize, maxCursorSize, maxResolution, cropProximity} from "../constants";
import chroma, { hex } from "chroma-js";
import { wait } from "../utils/basics";
import { 
  distance, 
  summate, 
  findPathsInCanvas, 
  floodFill, 
  drawRectangle, 
  drawEllipse,  
  calculateSquareCorner, 
  extendLineToBounds, 
  closestAngle, 
  closestAngleCoord,
  deriveVanishingPoints,
  deriveAngleFromCursor,
  deriveAngleFromCursor2,
  calculateRotatedPoint,
  getEndPoint,
  getRatioToEllipseEdge,
  getPointAtRatioFromEllipseEdge,
  pixelLine,
} from "../utils/drawing";
import { useMainContext } from "../contexts/MainContext";
import rotateTopLeft from "../assets/cursors/rotateTopLeft.svg";
import rotateTopRight from "../assets/cursors/rotateTopRight.svg";
import rotateBottomRight from "../assets/cursors/rotateBottomRight.svg";
import rotateBottomLeft from "../assets/cursors/rotateBottomLeft.svg";

const Viewport = forwardRef((props, ref) => {
  const { scale, setScale, translate, setTranslate, layout} = props;

  const {
    palette,
    modalIsOpen,
    selectedTool,
    setSelectedTool,
    previousTool,
    setPreviousTool,
    selectedSwatch,
    setSelectedSwatch,
    secondarySwatch,
    layers,
    resolution,
    offset,
    crop, 
    setCrop,
    transform,
    setTransform,
    transformStartCanvas,
    setTransformStartCanvas,
    transformStartSelection,
    setTransformStartSelection,
    transformCanvas,
    setTransformCanvas,
    transformSelection,
    setTransformSelection,
    transformStart,
    setTransformStart,
    transformVersion,
    transformNow,
    applyTransformNow,
    resetTransformNow,
    selectedViewport,
    setSelectedViewport,
    updateLayer,
    applyTransform,
    moveLayers,
    selectedLayer,
    setSelectedLayer,
    selectedLayers,
    setSelectedLayers,
    magnetLayer,
    setMagnetLayer,
    version,
    stencil,
    dither,
    brushDiameter,
    setBrushDiameter,
    brushPixelPerfect,
    brushMode,
    eraseDiameter,
    erasePixelPerfect,
    setEraseDiameter,
    ditherRatio,
    ditherOffsetX,
    ditherOffsetY,
    eraseMode,
    eraseDitherRatio,
    eraseDitherPressureMode,
    invertEraseDither,
    ditherPressureMode,
    autoSelect,
    dropperCurrent,
    dropperReplace,
    brushPressure,
    erasePressure,
    wandContinguous,
    bucketContinguous,
    bucketMode,
    brushPressureFactor,
    erasePressureFactor,
    rectangleAngle,
    ellipseAngle,
    altKey,
    shiftKey,
    shiftButton,
    altButton,
    metaButton,
    isSpaceDown,
    updatePalette,
    selection,
    hasSelection,
    setHasSelection,
    applyCrop,
    setViewportCenter,
    invertZoom,
    contextMenu,
    setContextMenu,
    details,
    setDetails,
    showBorder,
    setShowBorder,
    pixelGrid,
    setPixelGrid,
    loading,
    setLoading,
    updateGuide,
    lineWidth,
  } = useMainContext();

  const [cursor, setCursor] = useState("default");
  const [canvasWidth, setCanvasWidth] = useState(null);
  const [canvasHeight, setCanvasHeight] = useState(null);
  const [svgWidth, setSvgWidth] = useState(null);
  const [svgHeight, setSvgHeight] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [canvases, setCanvases] = useState([]);
  const [updateSelection, setUpdateSelection] = useState(0);
  const [selectionEdges, setSelectionEdges] = useState(null);
  const [animationFrameId, setAnimationFrameId] = useState(null);
  const [selectionFrameId, setSelectionFrameId] = useState(null);
  const [translateFrameId, setTranslateFrameId] = useState(null);
  const [svgViewBox, setSvgViewBox] = useState(`0 0 ${resolution.width} ${resolution.height}`);
  const [cursorEdges, setCursorEdges] = useState(null);
  const [cursorPath, setCursorPath] = useState(null);
  const [iconPath, setIconPath] = useState(null);
  const [whitePathsComp, setWhitePathsComp] = useState(null);
  const [blackPathsComp, setBlackPathsComp] = useState(null);
  const [toolEdges, setToolEdges] = useState(null);
  const [toolPath, setToolPath] = useState(null);
  const [movePath, setMovePath] = useState(null);
  const [cropPath, setCropPath] = useState(null);
  const [transformPath, setTransformPath] = useState(null);
  const [guidesPath, setGuidesPath] = useState(null);
  const [move, setMove] = useState({x: 0, y: 0});
  const [nearCrop, setNearCrop] = useState(0);
  const [nearTransform, setNearTransform] = useState(0);
  const [deltaTranslate, setDeltaTranslate] = useState(null);
  const [shiftM, setShiftM] = useState(false);
  const [altM, setAltM] = useState(false);
  const [shiftRelease, setShiftRelease] = useState(true);
  const [altRelease, setAltRelease] = useState(true);
  const [gradientPoints, setGradientPoints] = useState(null);
  const [guidePoints, setGuidePoints] = useState(null);
  const [nearLine, setNearLine] = useState(-1);
  const [nearPerspective, setNearPerspective] = useState(-1);
  const [nearGrid, setNearGrid] = useState(-1);
  const [nearEllipse, setNearEllipse] = useState(-1);
  const [cursorStartPoint, setCursorStartPoint] = useState(null);
  const [line, setLine] = useState(null);

  const wrapper = useRef(null);
  const bg = useRef(null);
  const cursorLayer = useRef(null);
  const selectionLayer = useRef(null);
  const combinationLayer = useRef(null);
  const toolLayer = useRef(null);
  const transformLayer = useRef(null);
  const dragOffset = useRef(null);
  const lastPosition = useRef(null);
  const lastCoordinate = useRef(null);
  const startDraw = useRef(null);
  const startSnap = useRef(null);
  const lastDraw = useRef(null);
  const isDrawing = useRef(false);
  const lastPoint = useRef(null);
  const selectionStart = useRef(null);
  const initialMove = useRef(null);
  const initialCrop = useRef(crop);
  const transformRef = useRef(transform);
  const rotationStart = useRef(null);
  const previousRotation = useRef(null);
  const previousLayer = useRef(selectedLayer);
  const perviousVersion = useRef(version);
  const perviousTransformVersion = useRef(transformVersion);
  const previousViewport = useRef(null);
  const activeTouches = useRef([]);
  const touchTimer = useRef(null);
  const initialTouch = useRef(null);
  const moveAngles = useRef(null);
  const moveArray = useRef(null);
  const ellipseDistance = useRef(null);

  //pixel perfect
  const lastPixel = useRef(null);
  const lastDrawnPixel = useRef(null);
  const waitingPixel = useRef(null);

  useEffect(() => { //initialize canavses
    cursorLayer.current = document.createElement('canvas');
    cursorLayer.current.width = brushDiameter;
    cursorLayer.current.height = brushDiameter;
    const cursorCtx = cursorLayer.current.getContext("2d");
    cursorCtx.imageSmoothingEnabled = false;
    cursorCtx.drawImage(
      stencil.current,
      summate(brushDiameter) - brushDiameter,
      maxCursorSize * selectedSwatch,
      brushDiameter,
      brushDiameter,
      0,
      0,
      brushDiameter,
      brushDiameter
    );

    selectionLayer.current = document.createElement('canvas');
    selectionLayer.current.width = resolution.width;
    selectionLayer.current.height = resolution.height;

    combinationLayer.current = document.createElement('canvas');
    combinationLayer.current.width = resolution.width;
    combinationLayer.current.height = resolution.height;

    toolLayer.current = document.createElement('canvas');
    toolLayer.current.width = resolution.width;
    toolLayer.current.height = resolution.height;
  }, []);

  useEffect(() => { //update canvases on crop, layer update, or selection 
    if (
      layers.length > 0 &&
      canvases.length > 0 &&
      layers.length === canvases.length
    ) {
      for (const index in canvases) {
        if(layers[index].type !== "Canvas") continue;
        //get context of selected local canvas and offscreen canvas
        canvases[index].current.width = resolution.width;
        canvases[index].current.height = resolution.height;
        const ctx = canvases[index].current.getContext("2d");
        ctx.imageSmoothingEnabled = false;
        ctx.globalCompositeOperation = "source-over";

        //draw image from off screen canvas onto local canvas
        ctx.drawImage(
          layers[index].ref.current, 
          -layers[index].x + offset.x,
          -layers[index].y + offset.y,
          resolution.width,
          resolution.height,
          0,
          0,
          resolution.width,
          resolution.height
        );
      }
    }

    selectionLayer.current.width = resolution.width;
    selectionLayer.current.height = resolution.height;
    const selectionCtx = selectionLayer.current.getContext("2d");
    selectionCtx.imageSmoothingEnabled = false;
    selectionCtx.globalCompositeOperation = "source-over";
    selectionCtx.drawImage(selection.current,0,0);

    const paths = findPathsInCanvas(selectionLayer.current);
    setSelectionEdges(paths);
    if(paths.length === 0){
      setHasSelection(false);
    } else {
      setHasSelection(true);
    }
  }, [layers, selectedLayer, canvases, resolution, offset, scale, selection,  version]);

  useEffect(() => {
    // Update canvases only when layers are added or removed
    setCanvases((prevCanvases) => {
      // Create a copy of the previous canvases array
      const updatedCanvases = [...prevCanvases];
  
      // Iterate through each layer
      layers.forEach((_, index) => {
        // Only create a new ref if it doesn't exist
        if (!updatedCanvases[index]) {
          updatedCanvases[index] = React.createRef();
        }
      });
  
      // Trim any excess refs if layers have been removed
      if (updatedCanvases.length > layers.length) {
        updatedCanvases.length = layers.length;
      }
  
      return updatedCanvases;
    });
  }, [layers]);
  

  useEffect(() => { //update cursor path
    const diameter = selectedTool === "pencil" ? brushDiameter : eraseDiameter;

    cursorLayer.current = document.createElement('canvas');
    cursorLayer.current.width = diameter;
    cursorLayer.current.height = diameter;
    const cursorCtx = cursorLayer.current.getContext("2d");
    cursorCtx.imageSmoothingEnabled = false;

    cursorCtx.drawImage(
      stencil.current,
      summate(diameter) - diameter,
      maxCursorSize * selectedSwatch,
      diameter,
      diameter,
      0,
      0,
      diameter,
      diameter
    );
      
    setCursorEdges(findPathsInCanvas(cursorLayer.current));
  }, [brushDiameter, eraseDiameter, selectedTool, selectedSwatch]);

  const handleWheel = useCallback((e) => {
    e.preventDefault();

    if(bg.current && wrapper.current){
      //set scale factor
      const scaleFactor = e.deltaY > 0 ? 0.9 : 1.1111111;
      const newScale = scale * scaleFactor;
  
      //calculate cursor coordinate relative to center of canvas
      const layerWidth = bg.current.clientWidth * scale;
      const layerHeight = bg.current.clientHeight * scale;
      const offsetX = ((translate.x + 50) / 100) * layerWidth;
      const offsetY = ((translate.y + 50) / 100) * layerHeight;
      const left = (wrapper.current.clientWidth - layerWidth) / 2 + offsetX;
      const top = (wrapper.current.clientHeight - layerHeight) / 2 + offsetY;
  
      
      //get center
      const centerX = (wrapper.current.clientWidth / 2 - left) / layerWidth;
      const centerY = (wrapper.current.clientHeight / 2 - top) / layerHeight;
  
      //get coordinate relative to center of viewport
      const x = (e.clientX - wrapper.current.getBoundingClientRect().left - left) / layerWidth - centerX;
      const y = (e.clientY - wrapper.current.getBoundingClientRect().top - top) / layerHeight - centerY;
  
  
      //get new cursor after scaling before adjustment
      const nX = x / scaleFactor;
      const nY = y / scaleFactor;
  
      const tX = (nX - x) * 100;
      const tY = (nY - y) * 100;
  
      //apply new translate and scale
      setTranslate({x: translate.x + tX, y: translate.y + tY});
      setScale(newScale);
    }

  }, [scale, translate,resolution]);

  const handleTouchUp = (e) => {
    if(e.touches.length === 0) activeTouches.current = [];
  };
  
  const handlePointerUp = useCallback((e) => {
    if (e.pointerType === 'touch') activeTouches.current = activeTouches.current.filter(touch => touch.id !== e.pointerId);

    if(activeTouches.current.length > 1){
      initialTouch.current = {
        scale,
        translate,
        dist: distance(activeTouches.current[0], activeTouches.current[1]),
        x: activeTouches.current[0].x + (activeTouches.current[1].x - activeTouches.current[0].x)/2,
        y: activeTouches.current[0].y + (activeTouches.current[1].y - activeTouches.current[0].y)/2,
      };
    } else initialTouch.current = null;

    if (isDrawing.current) {
      isDrawing.current = false;
      lastDraw.current = null;
      startDraw.current = null;
      startSnap.current = null;
      updateLayer(selectedLayer, canvases[selectedLayer], selectionLayer);
    } 

    if(line){      
      const ctx = canvases[selectedLayer].current.getContext("2d");
      ctx.globalCompositeOperation = "source-over";
      ctx.fillStyle = palette[selectedSwatch];
        
      if(hasSelection){
        combinationLayer.current.width = resolution.width;
        combinationLayer.current.height = resolution.height;
        const combinationCtx = combinationLayer.current.getContext("2d");
        combinationCtx.globalCompositeOperation = "source-over";
        combinationCtx.imageSmoothingEnabled = false;
        combinationCtx.fillStyle = palette[selectedSwatch];
        
        combinationCtx.drawImage(transformLayer.current, 0, 0);

        combinationCtx.globalCompositeOperation = "destination-in";
        combinationCtx.drawImage(selectionLayer.current,0,0);

        ctx.drawImage(combinationLayer.current,0,0);
      } else {
        ctx.drawImage(transformLayer.current, 0, 0);
      }

      updateLayer(selectedLayer, canvases[selectedLayer], selectionLayer);

      setLine(null);
      setDetails(null);
      
      setTimeout(() =>{
        const transformCtx = transformLayer.current.getContext("2d");
        transformCtx.clearRect(0,0,resolution.width,resolution.height);
      }, 100);
    }
    
    if(isMoving && move){
      moveLayers(move.x, move.y);
      setMove({x: 0, y: 0});
      setDetails(null);
      moveAngles.current = null;
    }
    setIsMoving(false);

    initialMove.current = null;
    initialCrop.current = crop;
    transformRef.current = transform;
    previousRotation.current = null;
    moveAngles.current = null;
    ellipseDistance.current = null;

    if(cursorStartPoint){
      setDetails(null);
      setCursorStartPoint(null);
    }

    setIsDragging(false);
    dragOffset.current = null;
    
    if(selectionStart.current){
      const selectionCtx = selectionLayer.current.getContext("2d");
      selectionCtx.imageSmoothingEnabled = false;
      if(shiftM && hasSelection){
        if(altM){
          selectionCtx.globalCompositeOperation = "source-in";
        } else {
          selectionCtx.globalCompositeOperation = "source-over";
        }
      } else if(altM && hasSelection){
        selectionCtx.globalCompositeOperation = "destination-out";
      } else {
        selectionCtx.clearRect(0,0,resolution.width,resolution.height);
        selectionCtx.globalCompositeOperation = "source-over";
      }
      selectionCtx.drawImage(toolLayer.current, 0, 0);

      setUpdateSelection((prevValue) => prevValue + 1);

      updateLayer(selectedLayer, canvases[selectedLayer], selectionLayer);
      setDetails(null);
      setAltRelease(true);
      setShiftRelease(true);
      setShiftM(false);
      setAltM(false);
    }
    toolLayer.current.width = resolution.width;
    toolLayer.current.height = resolution.height;
    selectionStart.current = null;
    setToolEdges(null);

    if(selectedTool === "gradient" && gradientPoints){
      setLoading(true);
      const rgb1 = selectedSwatch !== 32 ? chroma(palette[selectedSwatch]).rgb() : null;
      const rgb2 = secondarySwatch !== 32 ? chroma(palette[secondarySwatch]).rgb() : null;

      let oX = (ditherOffsetX - offset.x) % 4;
      let oY = (ditherOffsetY - offset.y) % 4;

      const difX = gradientPoints.x2 - gradientPoints.x;
      const difY = gradientPoints.y2 - gradientPoints.y;
      const radians = Math.atan2(difY, difX);
      const length = Math.sqrt(Math.pow(difX, 2) + Math.pow(difY, 2));

      const ctx = canvases[selectedLayer].current.getContext("2d");
      ctx.imageSmoothingEnabled = false;

      combinationLayer.current.width = resolution.width;
      combinationLayer.current.height = resolution.height;
      const combinationCtx = combinationLayer.current.getContext("2d");
      combinationCtx.globalCompositeOperation = "source-over";
      combinationCtx.imageSmoothingEnabled = false;

      const ditherCanvas = document.createElement("canvas");
      ditherCanvas.width = resolution.width;
      ditherCanvas.height = resolution.height;
      const ditherCtx = ditherCanvas.getContext("2d");
      ditherCtx.imageSmoothingEnabled = false;  
      
      const gradientLayer = document.createElement("canvas");
      gradientLayer.width = resolution.width;
      gradientLayer.height = resolution.height;
      const gradientCtx = gradientLayer.getContext("2d");
      gradientCtx.imageSmoothingEnabled = false;
      
      const diagonal = Math.sqrt(resolution.width * resolution.width + resolution.height * resolution.height);

      if(selectedSwatch !== 32){
        combinationCtx.save();  
        combinationCtx.translate(gradientPoints.x, gradientPoints.y);
        combinationCtx.rotate(radians);
        combinationCtx.fillRect(0, -diagonal, -diagonal, diagonal * 2);
        combinationCtx.restore();
        
        const imageData = combinationCtx.getImageData(0,0,resolution.width,resolution.height);

        for (let i = 0; i < imageData.data.length; i+=4) {
          if ( imageData.data[i + 3] > 0) {
            imageData.data[i] = rgb1[0];
            imageData.data[i + 1] = rgb1[1];
            imageData.data[i + 2] = rgb1[2];
            imageData.data[i + 3] = 255;
          }
        }
        combinationCtx.putImageData(imageData, 0, 0);
        gradientCtx.drawImage(combinationLayer.current, 0, 0);
        combinationCtx.clearRect(0,0,resolution.width, resolution.height);
        
        setDetails(null);
      }

      for(let i = 1; i < 16; i++){
        ditherCtx.clearRect(0,0,resolution.width,resolution.height);
        for(let j = -4; j < resolution.width; j += 68){
          for(let k = -4; k < resolution.height; k += 68){
            ditherCtx.drawImage(
              dither.current,
              i * 68,
              0,
              68,
              68,
              j + oX,
              k + oY,
              68,
              68
            );
          }
        }

        if(selectedSwatch !== 32){
          combinationCtx.save();  
          combinationCtx.globalCompositeOperation = "source-over";
          combinationCtx.translate(gradientPoints.x, gradientPoints.y);
          combinationCtx.rotate(radians);
          combinationCtx.fillRect((i-1) * length/15, -diagonal, length/15, diagonal * 2);
          combinationCtx.restore();

          const imageData = combinationCtx.getImageData(0,0,resolution.width,resolution.height);

          for (let j = 0; j < imageData.data.length; j+=4) {
            if ( imageData.data[j + 3] > 0) {
              imageData.data[j] = rgb1[0];
              imageData.data[j + 1] = rgb1[1];
              imageData.data[j + 2] = rgb1[2];
              imageData.data[j + 3] = 255;
            }
          }
          combinationCtx.putImageData(imageData, 0, 0); 

          combinationCtx.globalCompositeOperation = "destination-out";
          combinationCtx.drawImage(ditherCanvas, 0, 0);
          gradientCtx.drawImage(combinationLayer.current, 0, 0);
          combinationCtx.clearRect(0,0,resolution.width, resolution.height);
        }

        
        if(secondarySwatch !== 32){
          combinationCtx.save();  
          combinationCtx.globalCompositeOperation = "source-over";
          combinationCtx.translate(gradientPoints.x, gradientPoints.y);
          combinationCtx.rotate(radians);
          combinationCtx.fillRect((i-1) * length/15, -diagonal, length/15, diagonal * 2);
          combinationCtx.restore();

          const imageData = combinationCtx.getImageData(0,0,resolution.width,resolution.height);

          for (let j = 0; j < imageData.data.length; j+=4) {
            if ( imageData.data[j + 3] > 0) {;
              imageData.data[j] = rgb2[0];
              imageData.data[j + 1] = rgb2[1];
              imageData.data[j + 2] = rgb2[2];
              imageData.data[j + 3] = 255;
            }
          }
          combinationCtx.putImageData(imageData, 0, 0); 

          combinationCtx.globalCompositeOperation = "destination-in";
          combinationCtx.drawImage(ditherCanvas, 0, 0);
          gradientCtx.drawImage(combinationLayer.current, 0, 0);
          combinationCtx.clearRect(0,0,resolution.width, resolution.height);
        }
      }


      if(secondarySwatch !== 32){
        combinationCtx.save();  
        combinationCtx.globalCompositeOperation = "source-over";
        combinationCtx.translate(gradientPoints.x, gradientPoints.y);
        combinationCtx.rotate(radians);
        combinationCtx.fillRect(length, -diagonal, diagonal, diagonal * 2);
        combinationCtx.restore();

        const imageData = combinationCtx.getImageData(0,0,resolution.width,resolution.height);

        for (let i = 0; i < imageData.data.length; i+=4) {
          if ( imageData.data[i + 3] > 0) {
            imageData.data[i] = rgb2[0];
            imageData.data[i + 1] = rgb2[1];
            imageData.data[i + 2] = rgb2[2];
            imageData.data[i + 3] = 255;
          }
        }
        combinationCtx.putImageData(imageData, 0, 0);
        gradientCtx.drawImage(combinationLayer.current, 0, 0);
        combinationCtx.clearRect(0,0,resolution.width, resolution.height);
      }
            
      if(hasSelection){
        gradientCtx.globalCompositeOperation = "destination-in";
        gradientCtx.drawImage(selectionLayer.current,0,0);
      }

      ctx.globalCompositeOperation = "source-over";
      ctx.drawImage(gradientLayer,0,0);
      
      updateLayer(selectedLayer, canvases[selectedLayer], selectionLayer);
    }

    if(guidePoints){
      setDetails(null);
      setGuidePoints(null);
      switch(layers[selectedLayer].type){
        case "Line":
          updateGuide(selectedLayer, {
            startPoint: { x: guidePoints.x, y: guidePoints.y },
            endPoint: { x: guidePoints.x2, y: guidePoints.y2 },
            color: layers[selectedLayer].color,
            extend: layers[selectedLayer].extend,
          });
          break;
        case "OnePoint":
          updateGuide(selectedLayer, {
            point: { x: guidePoints.x, y: guidePoints.y },
            color: layers[selectedLayer].color,
          });
          break;
        case "TwoPoint":
          updateGuide(selectedLayer, {
            center: {x: guidePoints.centerX, y: guidePoints.centerY},
            focalLength: layers[selectedLayer].focalLength,
            angle: guidePoints.angle,
            tilt: layers[selectedLayer].tilt,
            color: layers[selectedLayer].color,
          });
          break;
        case "ThreePoint":
          updateGuide(selectedLayer, {
            center: {x: guidePoints.centerX, y: guidePoints.centerY},
            focalLength: layers[selectedLayer].focalLength,
            angle: guidePoints.angle,
            angle2: guidePoints.angle2,
            tilt: layers[selectedLayer].tilt,
            color: layers[selectedLayer].color,
          });
          break;
        case "Grid":
          updateGuide(selectedLayer, {
            corner: {x: guidePoints.cornerX, y: guidePoints.cornerY},
            width: guidePoints.width,
            height: guidePoints.height,
            rows: layers[selectedLayer].rows,
            columns: layers[selectedLayer].columns,
            angle: guidePoints.angle,
            color: layers[selectedLayer].color,
          });
          break;
        case "Isometric":
          updateGuide(selectedLayer, {
            corner: {x: guidePoints.cornerX, y: guidePoints.cornerY},
            width: guidePoints.width,
            height: guidePoints.height,
            rows: layers[selectedLayer].rows,
            columns: layers[selectedLayer].columns,
            angle: guidePoints.angle,
            angle2: guidePoints.angle2,
            color: layers[selectedLayer].color,
          });
          break;
        case "Ellipse":
          updateGuide(selectedLayer, {
            center: {x: guidePoints.centerX, y: guidePoints.centerY},
            width: guidePoints.width,
            height: guidePoints.height,
            angle: guidePoints.angle,
            color: layers[selectedLayer].color,
          });
          break;
      }
    }
    setGradientPoints(null);

    setNearLine(-1);
    setNearPerspective(-1);
  }, [
    selectedLayer,  
    canvases, 
    selectionLayer, 
    isMoving, 
    move, 
    crop, 
    transform, 
    shiftM, 
    altM, 
    layers,
    resolution, 
    offset, 
    version, 
    selection,
    scale,
    translate,
    gradientPoints,
    palette,
    selectedSwatch,
    secondarySwatch,
    ditherOffsetX,
    ditherOffsetY,
    eraseDitherPressureMode,
    invertEraseDither,
    ditherPressureMode,
    hasSelection,
    guidePoints,
    nearLine,
    cursorStartPoint,
    line,
  ]);
  
  const handlePointerOut = useCallback(async() => {
    await wait(100);
    setCursorPath(null);
    setIconPath(null);
  }, [resolution, selectedLayer,move,canvases]);

  const handleDocumentOut = useCallback(() => {
    setDeltaTranslate(null);
  }, []);

  const handlePointerDown  = useCallback((e) => {
    if(e.target.dataset.id){
      setContextMenu(null);
      setSelectedLayers([parseInt(e.target.dataset.id)]);
      setSelectedLayer(parseInt(e.target.dataset.id))
      if(selectedTool === "guide"){
        setSelectedTool(previousTool);
      }
      return;
    }
    if(contextMenu) setContextMenu(null);
    
    const delayedPointerDown = (e) => {
      touchTimer.current = null;
      if(bg.current && wrapper.current){
        let canvasScale = canvasHeight === "auto" ? bg.current.clientWidth / resolution.width :  bg.current.clientHeight / resolution.height;

        const layerWidth = bg.current.clientWidth * scale;
        const layerHeight = bg.current.clientHeight * scale;
  
        const offsetX = ((translate.x + 50) / 100) * layerWidth;
        const offsetY = ((translate.y + 50) / 100) * layerHeight;
  
        //calculate boundaries
        const left = (wrapper.current.clientWidth - layerWidth) / 2 + offsetX;
        const top =
          (wrapper.current.clientHeight - layerHeight) / 2 + offsetY;

        const preX = e.clientX - wrapper.current.getBoundingClientRect().left;
        const preY = e.clientY - wrapper.current.getBoundingClientRect().top;
  
        //convert offset to canvas position
        let x = (preX - left)  / layerWidth* resolution.width;
        let y = (preY - top) / layerHeight * resolution.height;

        lastCoordinate.current = {x, y};
        startDraw.current = {x, y};

        if(e.button){
          if(e.button === 2){
            const layersUnder = [];
            for (const index in canvases) {
              if(layers[index].type === "Canvas"){

                const ctx = canvases[index].current.getContext("2d", { willReadFrequently: true });
                ctx.imageSmoothingEnabled = false;
                const imageData = ctx.getImageData(Math.floor(x), Math.floor(y), 1, 1);
                if (imageData.data[3] !== 0) {
                  layersUnder.push({name: layers[index].name, index})
                }
              }
            }
            if(layersUnder && layersUnder.length > 0){
              return setContextMenu({layers: layersUnder.reverse(), x: preX, y: preY});
            } else {
              return setContextMenu(null);
            }
          } else if(e.button !== 0){
            return
          }
        }
  
        // Adjust brush diameter based on pressure
        let pressure = e.pointerType === "pen" ? e.pressure : 1;
        //get pressure factor from tool
        const pressureFactor = selectedTool === "erase" ? erasePressureFactor : brushPressureFactor;
        if(pressureFactor){
          // Ensure the pressure is between 0 and 1
          pressure = Math.max(0, Math.min(pressure, 1));
          // Apply a logarithmic curve
          // Adjust the base of the logarithm to change the curvature
          // Adding 1 to avoid log(0) which is undefined
          pressure = Math.log1p(pressure * pressureFactor) / Math.log1p(pressureFactor);
          // Ensure the output is between 0 and 1
          pressure = Math.max(0, Math.min(pressure, 1));
        }
        if(
          (selectedTool === "pencil" && ditherPressureMode === "ratio") ||
          (selectedTool === "erase" && eraseDitherPressureMode === "ratio")
        ) pressure = Math.pow(pressure, 2.25);

        if (isSpaceDown || selectedTool === "hand") {
          setIsDragging(true);
        } else if (selectedTool === "zoom") {
          //set scale factor
          let scaleFactor;
          if(invertZoom){
            scaleFactor = altKey ? 1.25 : 0.8;
          } else {
            scaleFactor = altKey ? 0.8 : 1.25;
          }
          const newScale = scale * scaleFactor;
      
          //calculate cursor coordinate relative to center of canvas
          const layerWidth = bg.current.clientWidth * scale;
          const layerHeight = bg.current.clientHeight * scale;
          const offsetX = ((translate.x + 50) / 100) * layerWidth;
          const offsetY = ((translate.y + 50) / 100) * layerHeight;
          const left = (wrapper.current.clientWidth - layerWidth) / 2 + offsetX;
          const top = (wrapper.current.clientHeight - layerHeight) / 2 + offsetY;
      
          
          //get center
          const centerX = (wrapper.current.clientWidth / 2 - left) / layerWidth;
          const centerY = (wrapper.current.clientHeight / 2 - top) / layerHeight;
      
          //get coordinate relative to center of viewport
          const x = (e.clientX - wrapper.current.getBoundingClientRect().left - left) / layerWidth - centerX;
          const y = (e.clientY - wrapper.current.getBoundingClientRect().top - top) / layerHeight - centerY;
      
      
          //get new cursor after scaling before adjustment
          const nX = x / scaleFactor;
          const nY = y / scaleFactor;
      
          const tX = (nX - x) * 100;
          const tY = (nY - y) * 100;
      
          //apply new translate and scale
          setTranslate({x: translate.x + tX, y: translate.y + tY});
          setScale(newScale);
        } else if (selectedTool === "move") {
          if (autoSelect) {
            for (let i = canvases.length - 1; i >= 0; i--) {
              if(layers[i].type !== "Canvas") continue;
              const ctx = canvases[i].current.getContext("2d", { willReadFrequently: true });
              ctx.imageSmoothingEnabled = false;
              const imageData = ctx.getImageData(x, y, 1, 1);
              if (imageData.data[3] !== 0) {
                setSelectedLayer(i);
                setSelectedLayers([i]);

                setIsMoving(true);

                initialMove.current = { x: x, y: y };
                break;
              }
            }
          } else {
            setIsMoving(true);

            initialMove.current = { x: x, y: y };
          }
        } else if (selectedTool === "eyedropper") {
          if (dropperCurrent) {
            if(layers[selectedLayer].type !== "Canvas") return;
            const ctx = canvases[selectedLayer].current.getContext("2d", { willReadFrequently: true });
            ctx.imageSmoothingEnabled = false;
            const imageData = ctx.getImageData(Math.floor(x), Math.floor(y), 1, 1);
            if (imageData.data[3] !== 0) {
              for (const index in palette) {
                if(palette[index] === '') continue;
                const rgbColor = chroma(palette[index]).rgb();
                if (
                  imageData.data[0] === rgbColor[0] &&
                  imageData.data[1] === rgbColor[1] &&
                  imageData.data[2] === rgbColor[2]
                ) {
                  if(!dropperReplace){
                    setSelectedSwatch(parseInt(index));
                  } else {
                    updatePalette(selectedSwatch, palette[index]);
                  }
                }
              }
            }
          } else {
            for (let i = layers.length - 1; i >= 0; i--) {
              if(!layers[i].visible || layers[i].type !== "Canvas") continue;
              const ctx = canvases[i].current.getContext("2d", { willReadFrequently: true });
              ctx.imageSmoothingEnabled = false;
              const imageData = ctx.getImageData(Math.floor(x), Math.floor(y), 1, 1);
              if (imageData.data[3] !== 0) {
                for (const index in palette) {
                  if(palette[index] === '') continue;
                  const rgbColor = chroma(palette[index]).rgb();
                  if (
                    imageData.data[0] === rgbColor[0] &&
                    imageData.data[1] === rgbColor[1] &&
                    imageData.data[2] === rgbColor[2]
                  ) {
                    if(!dropperReplace){
                      setSelectedSwatch(parseInt(index));
                    } else {
                      updatePalette(selectedSwatch, palette[index]);
                    }
                  }
                }
                break;
              }
            }
          }
        } else if (selectedTool === "crop"){
          const width = crop.right - crop.left;
          const height = crop.bottom - crop.top;
          const scaledCropProximity = Math.min(width,height) / 10;

          initialMove.current = { x: x, y: y };

          if(
            x > crop.left - scaledCropProximity && 
            x < crop.right + scaledCropProximity &&
            y > crop.top - scaledCropProximity &&
            y < crop.bottom + scaledCropProximity
          ){
            const nearLeft = Math.abs(x - crop.left) <= scaledCropProximity;
            const nearTop = Math.abs(y - crop.top) <= scaledCropProximity;
            const nearRight = Math.abs(x - crop.right) <= scaledCropProximity;
            const nearBottom = Math.abs(y - crop.bottom) <= scaledCropProximity;

            if((nearLeft && nearRight) || (nearTop && nearBottom)){
              setNearCrop(0);
            } else if(nearLeft){
              if(nearTop){
                setNearCrop(1);
              } else if(nearBottom){
                setNearCrop(7);
              } else {
                setNearCrop(8);
              }
            } else if(nearRight){
              if(nearTop){
                setNearCrop(3);
              } else if(nearBottom){
                setNearCrop(5);
              } else {
                setNearCrop(4);
              }
            } else if(nearTop){
              setNearCrop(2);
            } else if(nearBottom){
              setNearCrop(6);
            } else {
              setNearCrop(0);
            }
          } else {
            const cropLeft = x < crop.left;
            const cropRight = x > crop.right;
            const cropTop = y < crop.top;
            const cropBottom = y > crop.bottom;

            if(cropLeft){
              if(cropTop){
                setNearCrop(1);
              } else if(cropBottom){
                setNearCrop(7);
              } else {
                setNearCrop(8);
              }
            } else if(cropRight){
              if(cropTop){
                setNearCrop(3);
              } else if(cropBottom){
                setNearCrop(5);
              } else {
                setNearCrop(4);
              }
            } else if(cropTop){
              setNearCrop(2);
            } else if(cropBottom) {
              setNearCrop(6);
            } else{
              setNearCrop(0);
            }
          }
        } else if(selectedTool === "rectangle" || selectedTool === "ellipse"){
          selectionStart.current = { x, y };

          if(shiftKey || shiftButton){
            setShiftM(true);
          } else {
            setShiftM(false);
          }

          if(altKey || altButton){
            setAltM(true);
          } else {
            setAltM(false);
          }
          
          if(hasSelection){
            setShiftRelease(false);
            setAltRelease(false);
          }
        } else if(layers[selectedLayer].type !== "Canvas"){
          if(selectedTool === "guide"){
            const guideProximity = 10 / canvasScale / scale;
            const layer = layers[selectedLayer];
            switch(layer.type){
              case "Line":
                if(!layers[selectedLayer].startPoint){
                  setGuidePoints({x: x, y: y, x2: x, y2: y});
                } else {
                  
                  const midPointX = layers[selectedLayer].startPoint.x + (layers[selectedLayer].endPoint.x - layers[selectedLayer].startPoint.x)/2;
                  const midPointY = layers[selectedLayer].startPoint.y + (layers[selectedLayer].endPoint.y - layers[selectedLayer].startPoint.y)/2;

                  if(
                    x > layers[selectedLayer].startPoint.x - guideProximity &&
                    x < layers[selectedLayer].startPoint.x + guideProximity &&
                    y > layers[selectedLayer].startPoint.y - guideProximity  &&
                    y < layers[selectedLayer].startPoint.y + guideProximity
                  ){
                    setGuidePoints({x: x, y: y, x2: layers[selectedLayer].endPoint.x, y2: layers[selectedLayer].endPoint.y});
                    setNearLine(0);
                  } else if(
                    x > layers[selectedLayer].endPoint.x - guideProximity &&
                    x < layers[selectedLayer].endPoint.x + guideProximity &&
                    y > layers[selectedLayer].endPoint.y - guideProximity  &&
                    y < layers[selectedLayer].endPoint.y + guideProximity
                  ){
                    setGuidePoints({x: layers[selectedLayer].startPoint.x, y: layers[selectedLayer].startPoint.y, x2: x, y2: y});
                    setNearLine(1);
                  } else if (
                    x > midPointX - guideProximity &&
                    x < midPointX + guideProximity &&
                    y > midPointY - guideProximity  &&
                    y < midPointY + guideProximity
                  ){
                    setGuidePoints({x: layers[selectedLayer].startPoint.x, y: layers[selectedLayer].startPoint.y, x2: layers[selectedLayer].endPoint.x, y2: layers[selectedLayer].endPoint.y});
                    setNearLine(2);
                  } else {
                    setNearLine(-1);
                  }
                }
                break;
              case "OnePoint":
                if(
                  x > layers[selectedLayer].point.x - guideProximity &&
                  x < layers[selectedLayer].point.x + guideProximity &&
                  y > layers[selectedLayer].point.y - guideProximity  &&
                  y < layers[selectedLayer].point.y + guideProximity
                ){
                  setGuidePoints({x: layers[selectedLayer].point.x, y: layers[selectedLayer].point.y});
                  setNearPerspective(0);
                } else {
                  setNearPerspective(-1);
                }
                break;
              case "TwoPoint":
                const {vp1, vp2} = deriveVanishingPoints(layer.center, layer.focalLength, layer.tilt, layer.angle);

                if(
                  x > vp1.x - guideProximity &&
                  x < vp1.x + guideProximity &&
                  y > vp1.y - guideProximity  &&
                  y < vp1.y + guideProximity
                ){
                  setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle: layer.angle});
                  setNearPerspective(1);
                } else if(
                  x > vp2.x - guideProximity &&
                  x < vp2.x + guideProximity &&
                  y > vp2.y - guideProximity  &&
                  y < vp2.y + guideProximity
                ){
                  setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle: layer.angle});
                  setNearPerspective(2);
                } else if(
                  x > layer.center.x - guideProximity &&
                  x < layer.center.x + guideProximity &&
                  y > layer.center.y - guideProximity  &&
                  y < layer.center.y + guideProximity
                ){
                  setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle: layer.angle});
                  setNearPerspective(0);
                } else {
                  setNearPerspective(-1);
                }
                break;
              case "ThreePoint":
                if(layer.center){
                  const verticalVP = deriveVanishingPoints(layer.center, layer.focalLength, layer.tilt + 90, layer.angle2);
                  const vp3 = verticalVP.vp1;
                  const vp4 = verticalVP.vp2;
                  const { vp1, vp2 } = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layer.focalLength, layer.tilt, layer.angle);

                  if(
                    x > vp1.x - guideProximity &&
                    x < vp1.x + guideProximity &&
                    y > vp1.y - guideProximity  &&
                    y < vp1.y + guideProximity
                  ){
                    setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle: layer.angle, angle2: layer.angle2});
                    setNearPerspective(1);
                  } else if(
                    x > vp2.x - guideProximity &&
                    x < vp2.x + guideProximity &&
                    y > vp2.y - guideProximity  &&
                    y < vp2.y + guideProximity
                  ){
                    setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle: layer.angle, angle2: layer.angle2});
                    setNearPerspective(2);
                  } else if(
                    x > layer.center.x - guideProximity &&
                    x < layer.center.x + guideProximity &&
                    y > layer.center.y - guideProximity  &&
                    y < layer.center.y + guideProximity
                  ){
                    setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle: layer.angle, angle2: layer.angle2});
                    setNearPerspective(0);
                  } else if(
                    x > vp3.x - guideProximity &&
                    x < vp3.x + guideProximity &&
                    y > vp3.y - guideProximity  &&
                    y < vp3.y + guideProximity
                  ){
                    setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle: layer.angle, angle2: layer.angle2});
                    setNearPerspective(3);
                  }  else if(
                    x > vp4.x - guideProximity &&
                    x < vp4.x + guideProximity &&
                    y > vp4.y - guideProximity  &&
                    y < vp4.y + guideProximity
                  ){
                    setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle: layer.angle, angle2: layer.angle2});
                    setNearPerspective(4);
                  } else {
                    setNearPerspective(-1);
                  }
                }
                break;
              case "Grid":
                if(layer.corner){
                  const { x: widthX, y: widthY } = calculateRotatedPoint(
                    layer.corner.x + layer.width,
                    layer.corner.y,
                    -layer.angle,
                    layer.corner.x, // centerX should be defined as in the previous response
                    layer.corner.y  // centerY should be defined as in the previous response
                  );

                  const { x: heightX, y: heightY } = calculateRotatedPoint(
                    layer.corner.x,
                    layer.corner.y + layer.height,
                    -layer.angle,
                    layer.corner.x, // centerX should be defined as in the previous response
                    layer.corner.y  // centerY should be defined as in the previous response
                  );

                  if(
                    x > widthX - guideProximity &&
                    x < widthX + guideProximity &&
                    y > widthY - guideProximity  &&
                    y < widthY + guideProximity
                  ){
                    setGuidePoints({cornerX: layer.corner.x, cornerY: layer.corner.y, width: layer.width, height: layer.height, angle: layer.angle});
                    setNearGrid(1);
                  } else if(
                    x > heightX - guideProximity &&
                    x < heightX + guideProximity &&
                    y > heightY - guideProximity  &&
                    y < heightY + guideProximity
                  ){
                    setGuidePoints({cornerX: layer.corner.x, cornerY: layer.corner.y, width: layer.width, height: layer.height, angle: layer.angle});
                    setNearGrid(2);
                  } else if(
                    x > layer.corner.x - guideProximity &&
                    x < layer.corner.x + guideProximity &&
                    y > layer.corner.y - guideProximity  &&
                    y < layer.corner.y + guideProximity
                  ){
                    setGuidePoints({cornerX: layer.corner.x, cornerY: layer.corner.y, width: layer.width, height: layer.height, angle: layer.angle});
                    setNearGrid(0);
                  } else {
                    setNearGrid(-1);
                  }
                }
                break;
              case "Isometric":
                if(layer.corner){

                  //get left and right points
                  const left = getEndPoint(layer.corner.x, layer.corner.y, layer.angle2, layer.height);
                  const right = getEndPoint(layer.corner.x, layer.corner.y, layer.angle, layer.width);

                  if(
                    x > left.x - guideProximity &&
                    x < left.x + guideProximity &&
                    y > left.y - guideProximity  &&
                    y < left.y + guideProximity
                  ){
                    setGuidePoints({cornerX: layer.corner.x, cornerY: layer.corner.y, width: layer.width, height: layer.height, angle: layer.angle, angle2: layer.angle2});
                    setNearGrid(1);
                  } else if(
                    x > right.x - guideProximity &&
                    x < right.x + guideProximity &&
                    y > right.y - guideProximity  &&
                    y < right.y + guideProximity
                  ){
                    setGuidePoints({cornerX: layer.corner.x, cornerY: layer.corner.y, width: layer.width, height: layer.height, angle: layer.angle, angle2: layer.angle2});
                    setNearGrid(2);
                  } else if(
                    x > layer.corner.x - guideProximity &&
                    x < layer.corner.x + guideProximity &&
                    y > layer.corner.y - guideProximity  &&
                    y < layer.corner.y + guideProximity
                  ){
                    setGuidePoints({cornerX: layer.corner.x, cornerY: layer.corner.y, width: layer.width, height: layer.height, angle: layer.angle, angle2: layer.angle2});
                    setNearGrid(0);
                  } else {
                    setNearGrid(-1);
                  }
                }
                break;
              case "Ellipse":
                if(layer.center){
                  const { x: widthX, y: widthY } = calculateRotatedPoint(
                    layer.center.x + layer.width/2,
                    layer.center.y,
                    -layer.angle,
                    layer.center.x, // centerX should be defined as in the previous response
                    layer.center.y  // centerY should be defined as in the previous response
                  );

                  const { x: heightX, y: heightY } = calculateRotatedPoint(
                    layer.center.x,
                    layer.center.y - layer.height/2,
                    -layer.angle,
                    layer.center.x, // centerX should be defined as in the previous response
                    layer.center.y  // centerY should be defined as in the previous response
                  );

                  if(
                    x > widthX - guideProximity &&
                    x < widthX + guideProximity &&
                    y > widthY - guideProximity  &&
                    y < widthY + guideProximity
                  ){
                    setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, width: layer.width, height: layer.height, angle: layer.angle});
                    setNearEllipse(1);
                  } else if(
                    x > heightX - guideProximity &&
                    x < heightX + guideProximity &&
                    y > heightY - guideProximity  &&
                    y < heightY + guideProximity
                  ){
                    setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, width: layer.width, height: layer.height, angle: layer.angle});
                    setNearEllipse(2);
                  } else if(
                    x > layer.center.x - guideProximity &&
                    x < layer.center.x + guideProximity &&
                    y > layer.center.y - guideProximity  &&
                    y < layer.center.y + guideProximity
                  ){
                    setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, width: layer.width, height: layer.height, angle: layer.angle});
                    setNearEllipse(0);
                  } else {
                    setNearEllipse(-1);
                  }
                }
                break;
            }
          }
        } else if (
          !isSpaceDown &&
          !modalIsOpen &&
          layers[selectedLayer].visible
        ) {
          if(selectedTool === "pencil" || selectedTool === "erase"){   
            if(e.ctrlKey || e.metaKey || metaButton){
              if(selectedTool === "pencil"){
                setBrushDiameter(1);
              } else {
                setEraseDiameter(1);
              }
              setDetails("1 px");

              let d = ""
              d += `M ${Math.floor(x)},${Math.floor(y)} `;
              d += `L ${Math.ceil(x)},${Math.floor(y)} `;
              d += `L ${Math.ceil(x)},${Math.ceil(y)} `;
              d += `L ${Math.floor(x)},${Math.ceil(y)} Z`;

              const blackPaths = ( <path key={`black-0`} d={d} fill="none" stroke="black" strokeWidth={3/window.devicePixelRatio} vectorEffect="non-scaling-stroke" /> );
              const whitePaths = ( <path key={`white-0`} d={d} fill="none" stroke="white" strokeWidth={1.5/window.devicePixelRatio} vectorEffect="non-scaling-stroke" /> );
              setCursorPath([blackPaths,whitePaths]);

              return setCursorStartPoint({x: Math.round(x), y: Math.round(y)});
            }           

            const ditherLayer = document.createElement("canvas");
            ditherLayer.width = resolution.width;
            ditherLayer.height = resolution.height;
            const ditherCtx = ditherLayer.getContext("2d");
            ditherCtx.imageSmoothingEnabled = false;
  
            let oX = (ditherOffsetX - offset.x) % 4;
            let oY = (ditherOffsetY - offset.y) % 4;

            let diameter;
            if(selectedTool === "pencil"){
              if(brushMode === "normal" || (brushMode === "dither" && ditherPressureMode === "diameter")){
                diameter = brushPressure
                  ? Math.round(brushDiameter * pressure) || 1
                  : brushDiameter;
              } else {
                diameter = brushDiameter;
              }
            } else {
              if(eraseMode === "normal" || (eraseMode === "dither" && eraseDitherPressureMode === "diameter")){
                diameter = eraseDiameter
                  ? Math.round(eraseDiameter * pressure) || 1
                  : eraseDiameter;
              } else {
                diameter = eraseDiameter;
              }
            }
  
            if((altKey || altButton) && lastPoint.current){
              if(shiftKey || shiftButton){
                let snapAngles = [0,90,180,270];
                if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                  const getTarget = (point) => {
                    return diameter % 2 === 0 ? {x: point.x - 0.5, y: point.y - 0.5} : {x: Math.floor(point.x) + 0.5, y: Math.floor(point.y) + 0.5};
                  }; 
                  let vp1, vp2, vp3, vp4;
                  switch(layers[magnetLayer].type){
                    case "Line":
                      const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                      const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                      const angleInRadians = Math.atan2(deltaY, deltaX);
                      let angleInDegrees = angleInRadians * (180 / Math.PI);
                      angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
  
                      snapAngles = [angleInDegrees];
                      for(let i = 0; i < 3; i++){
                        angleInDegrees += 90;
                        snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                      }
                      break;
                    case "OnePoint":
                      const target2 = getTarget(layers[magnetLayer].point);
                      const deltaX2 = lastPoint.current.x - target2.x;
                      const deltaY2 = lastPoint.current.y - target2.y;
                      const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                      let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                      angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;
  
                      snapAngles = [angleInDegrees2];
                      break;
                    case "ThreePoint":
                      const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                      vp3 = vanishingPoints2.vp1;
                      vp4 = vanishingPoints2.vp2;
                      const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                      vp1 = vanishingPoints3.vp1;
                      vp2 = vanishingPoints3.vp2;
                      const target5 = getTarget(vp1);
                      const target6 = getTarget(vp2);
                      const target8 = getTarget(vp4);
  
                      const deltaX5 = lastPoint.current.x - target5.x;
                      const deltaY5 = lastPoint.current.y - target5.y;
                      const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                      let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                      angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                      const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;
  
                      const deltaX6 = lastPoint.current.x - target6.x;
                      const deltaY6 = lastPoint.current.y - target6.y;
                      const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                      let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                      angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                      const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;
  
                      const deltaX8 = lastPoint.current.x - target8.x;
                      const deltaY8 = lastPoint.current.y - target8.y;
                      const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                      let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                      angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                      const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;
  
                      const horizontalAngle = layers[magnetLayer].tilt;
                      const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;
  
                      snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                      break;
                    case "Grid":
                      snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                      break;
                    case "Isometric":
                      snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                      break;
                  }
                }
                  
                const deltaX = x - lastPoint.current.x;
                const deltaY = y - lastPoint.current.y;
                const angleInRadians = Math.atan2(deltaY, deltaX);
                let angleInDegrees = angleInRadians * (180 / Math.PI);
                angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
                const snapAngle = closestAngle(angleInDegrees, snapAngles);
                const snapAngleRadians = snapAngle * (Math.PI / 180);
                const length = deltaX * Math.cos(snapAngleRadians) + deltaY * Math.sin(snapAngleRadians);
                x = lastPoint.current.x + length * Math.cos(snapAngleRadians);
                y = lastPoint.current.y + length * Math.sin(snapAngleRadians);
              }

              //get distance since last draw;
              const dist = distance({ x: x, y: y }, lastPoint.current);
              
              // Calculate the number of iterations needed with optimized spacing
              const iterations = Math.ceil(dist);
  
              //get the change of each itteration
              const delta = {
                x: (x - lastPoint.current.x) / iterations || 0,
                y: (y - lastPoint.current.y) / iterations || 0
              };
              
              let ratio;
              if(selectedTool === "erase"){
                ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
              } else {
                ratio = ditherRatio;
              }

              if((selectedTool === "pencil" && brushDiameter === 1 && brushPixelPerfect) || (selectedTool === "erase" && eraseDiameter === 1 && erasePixelPerfect)){
                const ctx = canvases[selectedLayer].current.getContext("2d");
                ctx.imageSmoothingEnabled = false;
                ctx.fillStyle = palette[selectedSwatch];

                //set operation for current canvas based on tool settings
                if(selectedTool === "erase"){
                  ctx.globalCompositeOperation = "destination-out";
                } else {
                  ctx.globalCompositeOperation = "source-over";
                }

                const mouseX = Math.floor(x);
                const mouseY = Math.floor(y);

                if((selectedTool === "pencil" && brushMode === "select") || (selectedTool === "erase" && eraseMode === "select")){
                  const selectionCtx = selectionLayer.current.getContext("2d");
                  selectionCtx.imageSmoothingEnabled = false;
  
                  //set operation for selection based on tool settings
                  selectionCtx.globalCompositeOperation = selectedTool === "erase" ? "destination-out" : "source-over";

                  pixelLine(selectionCtx, Math.floor(lastPoint.current.x), Math.floor(lastPoint.current.y), mouseX, mouseY);

                  setUpdateSelection((prevValue) => prevValue + 1);
                  
                } else if(!hasSelection){  
                  if((selectedTool === "pencil" && brushMode === "dither") || (selectedTool ==="erase" && eraseMode === "dither")){
                    const tempCanvas = document.createElement("canvas");
                    tempCanvas.width = resolution.width;
                    tempCanvas.height = resolution.height;
                    const tempCtx = tempCanvas.getContext("2d");
                    tempCtx.imageSmoothingEnabled = false;

                    if(selectedTool !== "erase" && selectedSwatch === 32 && secondarySwatch === 32) return;

                    ditherCtx.clearRect(0,0,resolution.width,resolution.height);

                    ditherCtx.globalCompositeOperation = "source-over";
                    tempCtx.fillStyle = palette[secondarySwatch];

                    pixelLine(tempCtx, Math.floor(lastPoint.current.x), Math.floor(lastPoint.current.y), mouseX, mouseY);

                    ditherCtx.drawImage(tempCanvas,0,0);
                    
                    let ratio;
                    if(e.pointerType === "pen"){
                      if(selectedTool === "pencil"){
                        if(ditherPressureMode === "ratio"){
                          ratio = Math.round(16 * pressure);
                        } else {
                          ratio = ditherRatio;
                        }
                      } else {
                        if(eraseDitherPressureMode === "ratio"){
                          ratio = Math.round(16 * pressure);
                          ratio = invertEraseDither ? ratio : 16 - ratio;
                        } else {
                          ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                        }
                      }
                    } else if(selectedTool === "erase"){
                      ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                    } else {
                      ratio = ditherRatio;
                    }

                    ditherCtx.globalCompositeOperation = "destination-out";
                    for(let j = -4; j < resolution.width; j += 68){
                      for(let k = -4; k < resolution.height; k += 68){
                        ditherCtx.drawImage(
                          dither.current,
                          ratio * 68,
                          0,
                          68,
                          68,
                          j + oX,
                          k + oY,
                          68,
                          68
                        );
                      }
                    }
                    if(secondarySwatch !== 32 || selectedTool === "erase"){
                      ctx.drawImage(ditherLayer, 0, 0);
                    }

                    if(selectedSwatch !== 32 && selectedTool !== "erase"){
                      ditherCtx.globalCompositeOperation = "source-out";
                      tempCtx.fillStyle = palette[selectedSwatch];   
                      pixelLine(tempCtx, Math.floor(lastPoint.current.x), Math.floor(lastPoint.current.y), mouseX, mouseY);
                      ditherCtx.drawImage(tempCanvas,0,0);
                      ctx.drawImage(ditherLayer, 0, 0);
                    }
                  } else if(selectedSwatch !== 32 || selectedTool === "erase"){           
                    pixelLine(ctx, Math.floor(lastPoint.current.x), Math.floor(lastPoint.current.y), mouseX, mouseY);
                  }
                } else {
                  const tempCanvas = document.createElement("canvas");
                  tempCanvas.width = resolution.width;
                  tempCanvas.height = resolution.height;
                  const tempCtx = tempCanvas.getContext("2d");
                  tempCtx.imageSmoothingEnabled = false;

                  combinationLayer.current.width = resolution.width;
                  combinationLayer.current.height = resolution.height;
                  const combinationCtx = combinationLayer.current.getContext("2d");
                  combinationCtx.imageSmoothingEnabled = false;
                  combinationCtx.globalCompositeOperation = "source-over";
                  combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                  combinationCtx.drawImage(selectionLayer.current,0,0);
                  combinationCtx.globalCompositeOperation = "source-in";
                  combinationCtx.fillStyle = palette[selectedSwatch];

                  if((selectedTool === "pencil" && brushMode === "dither") || (selectedTool === "erase" && eraseMode === "dither")){ 
                    if(selectedTool !== "erase" && selectedSwatch === 32 && secondarySwatch === 32) return;

                    ditherCtx.clearRect(0,0,resolution.width,resolution.height);

                    ditherCtx.globalCompositeOperation = "source-over";
                    tempCtx.fillStyle = palette[secondarySwatch];
                    pixelLine(tempCtx, Math.floor(lastPoint.current.x), Math.floor(lastPoint.current.y), mouseX, mouseY);
                    ditherCtx.drawImage(tempCanvas,0,0);
                    
                    let ratio;
                    if(e.pointerType === "pen"){
                      if(selectedTool === "pencil"){
                        if(ditherPressureMode === "ratio"){
                          ratio = Math.round(16 * pressure);
                        } else {
                          ratio = ditherRatio;
                        }
                      } else {
                        if(eraseDitherPressureMode === "ratio"){
                          ratio = Math.round(16 * pressure);
                          ratio = invertEraseDither ? ratio : 16 - ratio;
                        } else {
                          ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                        }
                      }
                    } else if(selectedTool === "erase"){
                      ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                    } else {
                      ratio = ditherRatio;
                    }

                    ditherCtx.globalCompositeOperation = "destination-out";
                    for(let j = -4; j < resolution.width; j += 68){
                      for(let k = -4; k < resolution.height; k += 68){
                        ditherCtx.drawImage(
                          dither.current,
                          ratio * 68,
                          0,
                          68,
                          68,
                          j + oX,
                          k + oY,
                          68,
                          68
                        );
                      }
                    }
                    if(secondarySwatch !== 32 || selectedTool === "erase"){
                      combinationCtx.drawImage(ditherLayer,0,0);
                      ctx.drawImage(combinationLayer.current, 0, 0);
                    }

                    if(selectedSwatch !== 32 && selectedTool !== "erase"){
                      combinationCtx.globalCompositeOperation = "source-over";
                      combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                      combinationCtx.drawImage(selectionLayer.current,0,0);
                      combinationCtx.globalCompositeOperation = "source-in";

                      ditherCtx.globalCompositeOperation = "source-out";
                      tempCtx.fillStyle = palette[selectedSwatch];   
                      pixelLine(tempCtx, Math.floor(lastPoint.current.x), Math.floor(lastPoint.current.y), mouseX, mouseY);
                      ditherCtx.drawImage(tempCanvas,0,0);
                      combinationCtx.drawImage(ditherLayer,0,0);
                      ctx.drawImage(combinationLayer.current, 0, 0);
                    }
                  } else if(selectedSwatch !== 32 || selectedTool === "erase"){       
                    pixelLine(tempCtx, Math.floor(lastPoint.current.x), Math.floor(lastPoint.current.y), mouseX, mouseY);
                  }

                  combinationCtx.drawImage(tempCanvas,0,0);
                  ctx.drawImage(combinationLayer.current, 0, 0);
                }
                lastPoint.current = {x, y, pressure};
                return;
              }
              
              if((selectedTool === "pencil" && brushMode === "select") || (selectedTool === "erase" && eraseMode === "select")){
                const selectionCtx = selectionLayer.current.getContext("2d");
                selectionCtx.imageSmoothingEnabled = false;
  
                //set operation for selection based on tool settings
                selectionCtx.globalCompositeOperation = selectedTool === "erase" ? "destination-out" : "source-over";
  
                //draw line
                for (let i = 1; i <= iterations; i++) {
                  selectionCtx.drawImage(
                    stencil.current,
                    summate(diameter) - diameter,
                    maxCursorSize * selectedSwatch,
                    diameter,
                    diameter,
                    Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                    Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                    diameter,
                    diameter
                  );
                }
  
                setUpdateSelection((prevValue) => prevValue + 1);
              } else {
                const ctx = canvases[selectedLayer].current.getContext("2d");
                ctx.imageSmoothingEnabled = false;
  
                //set operation for current canvas based on tool settings
                if(selectedTool === "erase"){
                  ctx.globalCompositeOperation = "destination-out";
                } else {
                  ctx.globalCompositeOperation = "source-over";
                }

                if(!hasSelection){
                  //draw line
                  for (let i = 1; i <= iterations; i++) {
                    if((selectedTool === "pencil" && brushMode === "dither") || (selectedTool ==="erase" && eraseMode === "dither")){
                      if(selectedTool !== "erase" && selectedSwatch === 32 && secondarySwatch === 32) return;
                      
                      ditherCtx.clearRect(0,0,resolution.width,resolution.height);

                      ditherCtx.globalCompositeOperation = "source-over"
                      ditherCtx.drawImage(
                        stencil.current,
                        summate(diameter) - diameter,
                        maxCursorSize * secondarySwatch,
                        diameter,
                        diameter,
                        Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                        Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                        diameter,
                        diameter
                      );

                      ditherCtx.globalCompositeOperation = "destination-out";
                      for(let j = -4; j < resolution.width; j += 68){
                        for(let k = -4; k < resolution.height; k += 68){
                          ditherCtx.drawImage(
                            dither.current,
                            ratio * 68,
                            0,
                            68,
                            68,
                            j + oX,
                            k + oY,
                            68,
                            68
                          );
                        }
                      }
                      if(secondarySwatch !== 32 || selectedTool === "erase"){
                        ctx.drawImage(ditherLayer, 0, 0);
                      }

                      if(selectedSwatch !== 32 && selectedTool !== "erase"){
                        ditherCtx.globalCompositeOperation = "source-out";
                        ditherCtx.drawImage(
                          stencil.current,
                          summate(diameter) - diameter,
                          maxCursorSize * selectedSwatch,
                          diameter,
                          diameter,
                          Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                          Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                          diameter,
                          diameter
                        );
                        ctx.drawImage(ditherLayer, 0, 0);
                      }
                    } else if(selectedSwatch !== 32 || selectedTool === "erase"){                        
                      ctx.drawImage(
                        stencil.current,
                        summate(diameter) - diameter,
                        maxCursorSize * selectedSwatch,
                        diameter,
                        diameter,
                        Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                        Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                        diameter,
                        diameter
                      );
                    }
                  }
                } else {  
                  combinationLayer.current.width = resolution.width;
                  combinationLayer.current.height = resolution.height;
                  const combinationCtx = combinationLayer.current.getContext("2d");
                  combinationCtx.imageSmoothingEnabled = false;

                  //draw line
                  for (let i = 1; i <= iterations; i++) {               
                    if((selectedTool === "pencil" && brushMode === "dither") || (selectedTool ==="erase" && eraseMode === "dither")){ 
                      if(selectedTool !== "erase" && selectedSwatch === 32 && secondarySwatch === 32) return;

                      combinationCtx.globalCompositeOperation = "source-over";
                      combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                      combinationCtx.drawImage(selectionLayer.current,0,0);
                      combinationCtx.globalCompositeOperation = "source-in";

                      ditherCtx.clearRect(0,0,resolution.width,resolution.height);

                      ditherCtx.globalCompositeOperation = "source-over"
                      ditherCtx.drawImage(
                        stencil.current,
                        summate(diameter) - diameter,
                        maxCursorSize * secondarySwatch,
                        diameter,
                        diameter,
                        Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                        Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                        diameter,
                        diameter
                      );

                      ditherCtx.globalCompositeOperation = "destination-out";
                      for(let j = -4; j < resolution.width; j += 68){
                        for(let k = -4; k < resolution.height; k += 68){
                          ditherCtx.drawImage(
                            dither.current,
                            ratio * 68,
                            0,
                            68,
                            68,
                            j + oX,
                            k + oY,
                            68,
                            68
                          );
                        }
                      }
                      combinationCtx.drawImage(ditherLayer, 0, 0);
                      if(secondarySwatch !== 32 || selectedTool === "erase"){
                        ctx.drawImage(
                          combinationLayer.current,
                          Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                          Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                          diameter,
                          diameter,
                          Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                          Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                          diameter,
                          diameter
                        );
                      }

                      if(selectedSwatch !== 32 && selectedTool !== "erase"){
                        combinationCtx.globalCompositeOperation = "source-over";
                        combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                        combinationCtx.drawImage(selectionLayer.current,0,0);
                        combinationCtx.globalCompositeOperation = "source-in";

                        ditherCtx.globalCompositeOperation = "source-out";
                        ditherCtx.drawImage(
                          stencil.current,
                          summate(diameter) - diameter,
                          maxCursorSize * selectedSwatch,
                          diameter,
                          diameter,
                          Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                          Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                          diameter,
                          diameter
                        );
                        combinationCtx.drawImage(ditherLayer, 0, 0);

                        ctx.drawImage(
                          combinationLayer.current,
                          Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                          Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                          diameter,
                          diameter,
                          Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                          Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                          diameter,
                          diameter
                        );
                      }
                    } else if(selectedSwatch !== 32 || selectedTool === "erase"){
                      combinationCtx.globalCompositeOperation = "source-over";
                      combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                      combinationCtx.drawImage(selectionLayer.current,0,0);
                      combinationCtx.globalCompositeOperation = "source-in";
                        
                      combinationCtx.drawImage(
                        stencil.current,
                        summate(diameter) - diameter,
                        maxCursorSize * selectedSwatch,
                        diameter,
                        diameter,
                        Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                        Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                        diameter,
                        diameter
                      );
      
                      ctx.drawImage(
                        combinationLayer.current,
                        Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                        Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                        diameter,
                        diameter,
                        Math.ceil( lastPoint.current.x + delta.x * i ) - Math.round(diameter / 2),
                        Math.ceil( lastPoint.current.y + delta.y * i ) - Math.round(diameter / 2),
                        diameter,
                        diameter
                      );
                    }
                  }
                }
              }
            } else if((selectedTool === "pencil" && brushMode === "select") || (selectedTool === "erase" && eraseMode === "select")){
              //if pencil or erase select then draw to selection layer instead
              const selectionCtx = selectionLayer.current.getContext("2d");
              selectionCtx.imageSmoothingEnabled = false;
              //set operation for selection ctx based on tool settings
              selectionCtx.globalCompositeOperation = selectedTool === "erase" ? "destination-out" : "source-over";

              selectionCtx.drawImage(
                stencil.current,
                summate(diameter) - diameter,
                maxCursorSize * selectedSwatch,
                diameter,
                diameter,
                Math.ceil(x) - Math.round(diameter / 2),
                Math.ceil(y) - Math.round(diameter / 2),
                diameter,
                diameter
              );

              setUpdateSelection((prevValue) => prevValue + 1);
            } else {
              let ratio;
              if(e.pointerType === "pen"){
                if(selectedTool === "pencil"){
                  if(ditherPressureMode === "ratio"){
                    ratio = Math.round(16 * pressure);
                  } else {
                    ratio = ditherRatio;
                  }
                } else {
                  if(eraseDitherPressureMode === "ratio"){
                    ratio = Math.round(16 * pressure);
                    ratio = invertEraseDither ? ratio : 16 - ratio;
                  } else {
                    ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                  }
                }
              } else if(selectedTool === "erase"){
                ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
              } else {
                ratio = ditherRatio;
              }

              //get current canvas ctx
              const ctx = canvases[selectedLayer].current.getContext("2d");
              ctx.imageSmoothingEnabled = false;

              //set operation for current canvas based on tool settings
              if(selectedTool === "erase"){
                ctx.globalCompositeOperation = "destination-out";
              } else {
                ctx.globalCompositeOperation = "source-over";
              }

              if(!hasSelection){
                if((selectedTool === "pencil" && brushMode === "dither") || (selectedTool ==="erase" && eraseMode === "dither")){
                  if(selectedTool !== "erase" && selectedSwatch === 32 && secondarySwatch === 32) return;
                  
                  ditherCtx.clearRect(0,0,resolution.width,resolution.height);
  
                  ditherCtx.globalCompositeOperation = "source-over"
                  ditherCtx.drawImage(
                    stencil.current,
                    summate(diameter) - diameter,
                    maxCursorSize * secondarySwatch,
                    diameter,
                    diameter,
                    Math.ceil(x) - Math.round(diameter / 2),
                    Math.ceil(y) - Math.round(diameter / 2),
                    diameter,
                    diameter
                  );
  
                  ditherCtx.globalCompositeOperation = "destination-out";
                  for(let j = -4; j < resolution.width; j += 68){
                    for(let k = -4; k < resolution.height; k += 68){
                      ditherCtx.drawImage(
                        dither.current,
                        ratio * 68,
                        0,
                        68,
                        68,
                        j + oX,
                        k + oY,
                        68,
                        68
                      );
                    }
                  }
                  if(secondarySwatch !== 32 || selectedTool === "erase"){
                    ctx.drawImage(ditherLayer, 0, 0);
                  }
  
                  if(selectedSwatch !== 32 && selectedTool !== "erase"){
                    ditherCtx.globalCompositeOperation = "source-out";
                    ditherCtx.drawImage(
                      stencil.current,
                      summate(diameter) - diameter,
                      maxCursorSize * selectedSwatch,
                      diameter,
                      diameter,
                      Math.ceil(x) - Math.round(diameter / 2),
                      Math.ceil(y) - Math.round(diameter / 2),
                      diameter,
                      diameter
                    );
                    ctx.drawImage(ditherLayer, 0, 0);
                  }

                } else if(selectedSwatch !== 32 || selectedTool === "erase"){                        
                  ctx.drawImage(
                    stencil.current,
                    summate(diameter) - diameter,
                    maxCursorSize * selectedSwatch,
                    diameter,
                    diameter,
                    Math.ceil(x) - Math.round(diameter / 2),
                    Math.ceil(y) - Math.round(diameter / 2),
                    diameter,
                    diameter
                  );
                }
              } else {
                combinationLayer.current.width = resolution.width;
                combinationLayer.current.height = resolution.height;
                const combinationCtx = combinationLayer.current.getContext("2d");
                combinationCtx.imageSmoothingEnabled = false;
         
                if((selectedTool === "pencil" && brushMode === "dither") || (selectedTool ==="erase" && eraseMode === "dither")){ 
                  if(selectedTool !== "erase" && selectedSwatch === 32 && secondarySwatch === 32) return;

                  combinationCtx.globalCompositeOperation = "source-over";
                  combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                  combinationCtx.drawImage(selectionLayer.current,0,0);
                  combinationCtx.globalCompositeOperation = "source-in";

                  ditherCtx.clearRect(0,0,resolution.width,resolution.height);

                  ditherCtx.globalCompositeOperation = "source-over"
                  ditherCtx.drawImage(
                    stencil.current,
                    summate(diameter) - diameter,
                    maxCursorSize * secondarySwatch,
                    diameter,
                    diameter,
                    Math.ceil(x) - Math.round(diameter / 2),
                    Math.ceil(y) - Math.round(diameter / 2),
                    diameter,
                    diameter
                  );

                  ditherCtx.globalCompositeOperation = "destination-out";
                  for(let j = -4; j < resolution.width; j += 68){
                    for(let k = -4; k < resolution.height; k += 68){
                      ditherCtx.drawImage(
                        dither.current,
                        ratio * 68,
                        0,
                        68,
                        68,
                        j + oX,
                        k + oY,
                        68,
                        68
                      );
                    }
                  }
                  combinationCtx.drawImage(ditherLayer, 0, 0);
                  if(secondarySwatch !== 32 || selectedTool === "erase"){
                    ctx.drawImage(
                      combinationLayer.current,
                      Math.ceil(x) - Math.round(diameter / 2),
                      Math.ceil(y) - Math.round(diameter / 2),
                      diameter,
                      diameter,
                      Math.ceil(x) - Math.round(diameter / 2),
                      Math.ceil(y) - Math.round(diameter / 2),
                      diameter,
                      diameter
                    );
                  }

                  if(selectedSwatch !== 32 && selectedTool !== "erase"){
                    combinationCtx.globalCompositeOperation = "source-over";
                    combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                    combinationCtx.drawImage(selectionLayer.current,0,0);
                    combinationCtx.globalCompositeOperation = "source-in";

                    ditherCtx.globalCompositeOperation = "source-out";
                    ditherCtx.drawImage(
                      stencil.current,
                      summate(diameter) - diameter,
                      maxCursorSize * selectedSwatch,
                      diameter,
                      diameter,
                      Math.ceil(x) - Math.round(diameter / 2),
                      Math.ceil(y) - Math.round(diameter / 2),
                      diameter,
                      diameter
                    );
                    combinationCtx.drawImage(ditherLayer, 0, 0);

                    ctx.drawImage(
                      combinationLayer.current,
                      Math.ceil(x) - Math.round(diameter / 2),
                      Math.ceil(y) - Math.round(diameter / 2),
                      diameter,
                      diameter,
                      Math.ceil(x) - Math.round(diameter / 2),
                      Math.ceil(y) - Math.round(diameter / 2),
                      diameter,
                      diameter
                    );
                  }
                } else if(selectedSwatch !== 32 || selectedTool === "erase"){
                  combinationCtx.globalCompositeOperation = "source-over";
                  combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                  combinationCtx.drawImage(selectionLayer.current,0,0);
                  combinationCtx.globalCompositeOperation = "source-in";
                    
                  combinationCtx.drawImage(
                    stencil.current,
                    summate(diameter) - diameter,
                    maxCursorSize * selectedSwatch,
                    diameter,
                    diameter,
                    Math.ceil(x) - Math.round(diameter / 2),
                    Math.ceil(y) - Math.round(diameter / 2),
                    diameter,
                    diameter
                  );
  
                  ctx.drawImage(
                    combinationLayer.current,
                    Math.ceil(x) - Math.round(diameter / 2),
                    Math.ceil(y) - Math.round(diameter / 2),
                    diameter,
                    diameter,
                    Math.ceil(x) - Math.round(diameter / 2),
                    Math.ceil(y) - Math.round(diameter / 2),
                    diameter,
                    diameter
                  );
                }
              }
            }
            lastPoint.current = {x, y, pressure};
          } else if( selectedTool === "line" && selectedSwatch !== 32){
            setLine({startX: x, startY: y, endX: x, endY: y});
          } else if(selectedTool === "wand"){
            if(x < 0 || y < 0 || x > resolution.width - 1 || y > resolution.height- 1) return;
  
            const tempCanvas = document.createElement("canvas");
            tempCanvas.width = resolution.width;
            tempCanvas.height = resolution.height;
            const tempCtx = tempCanvas.getContext("2d");
            tempCtx.imageSmoothingEnabled = false;
            const tempImageData = tempCtx.getImageData(0,0,resolution.width,resolution.height);
  
            const selectionCtx = selectionLayer.current.getContext("2d", {willReadFrequently: true});
            selectionCtx.imageSmoothingEnabled = false;
            const selectionImageData = selectionCtx.getImageData(0,0,resolution.width,resolution.height);
  
            const ctx = canvases[selectedLayer].current.getContext("2d", {willReadFrequently: true});
            const imageData = ctx.getImageData(0,0,resolution.width,resolution.height);
  
            const fX = Math.floor(x);
            const fY = Math.floor(y);
            const index = (fY * resolution.width + fX) * 4;
            const startColor = {
              r: imageData.data[index],
              g: imageData.data[index + 1],
              b: imageData.data[index + 2],
              a: imageData.data[index + 3]
            };
  
            if(wandContinguous){
              const indexes = floodFill(imageData, fX, fY, resolution.width, resolution.height);
              if(shiftKey || shiftButton){
                if((altKey || altButton) && hasSelection){
                  for(const i of indexes){
                    tempImageData.data[i + 3] = 255;
                  }
                  tempCtx.putImageData(tempImageData, 0, 0);
                  selectionCtx.globalCompositeOperation = "source-in";
                  selectionCtx.drawImage(tempCanvas, 0, 0);
                  selectionCtx.globalCompositeOperation = "source-over";
                } else {
                  for(const i of indexes){
                    selectionImageData.data[i + 3] = 255;
                  }
                  selectionCtx.putImageData(selectionImageData, 0, 0);
                }
              } else if(altKey || altButton){
                for(const i of indexes){
                  selectionImageData.data[i + 3] = 0;
                }
                selectionCtx.putImageData(selectionImageData, 0, 0);
              } else {
                for(const i of indexes){
                  tempImageData.data[i + 3] = 255;
                }
                selectionCtx.putImageData(tempImageData, 0, 0);
              }
            } else {
              if(shiftKey || shiftButton){
                if((altKey || altButton) && hasSelection){
                  for(let i = 0; i < imageData.data.length; i += 4){
                    if(startColor.a < 255){
                      if(imageData.data[i + 3] < 255){
                        tempImageData.data[i + 3] = 255;
                      }
                    } else {
                      if(
                        imageData.data[i] === startColor.r && 
                        imageData.data[i + 1] === startColor.g &&
                        imageData.data[i + 2] === startColor.b &&
                        imageData.data[i + 3] === 255
                      ) {
                        tempImageData.data[i + 3] = 255;
                      }
                    }
                  }
                  tempCtx.putImageData(tempImageData, 0, 0);
                  selectionCtx.globalCompositeOperation = "source-in";
                  selectionCtx.drawImage(tempCanvas, 0, 0);
                  selectionCtx.globalCompositeOperation = "source-over";
                } else {
                  for(let i = 0; i < imageData.data.length; i += 4){
                    if(startColor.a < 255){
                      if(imageData.data[i + 3] < 255){
                        selectionImageData.data[i + 3] = 255;
                      }
                    } else {
                      if(
                        imageData.data[i] === startColor.r && 
                        imageData.data[i + 1] === startColor.g &&
                        imageData.data[i + 2] === startColor.b &&
                        imageData.data[i + 3] === 255
                      ) {
                        selectionImageData.data[i + 3] = 255;
                      }
                    }
                  }
                  selectionCtx.putImageData(selectionImageData, 0, 0);
                }
              } else if(altKey || altButton){
                for(let i = 0; i < imageData.data.length; i += 4){
                  if(startColor.a < 255){
                    if(imageData.data[i + 3] < 255){
                      selectionImageData.data[i + 3] = 0;
                    }
                  } else {
                    if(
                      imageData.data[i] === startColor.r && 
                      imageData.data[i + 1] === startColor.g &&
                      imageData.data[i + 2] === startColor.b &&
                      imageData.data[i + 3] === 255
                    ) {
                      selectionImageData.data[i + 3] = 0;
                    }
                  }
                }
                selectionCtx.putImageData(selectionImageData, 0, 0);
              } else {
                for(let i = 0; i < imageData.data.length; i += 4){
                  if(startColor.a < 255){
                    if(imageData.data[i + 3] < 255){
                      tempImageData.data[i + 3] = 255;
                    }
                  } else {
                    if(
                      imageData.data[i] === startColor.r && 
                      imageData.data[i + 1] === startColor.g &&
                      imageData.data[i + 2] === startColor.b &&
                      imageData.data[i + 3] === 255
                    ) {
                      tempImageData.data[i + 3] = 255;
                    }
                  }
                }
                selectionCtx.putImageData(tempImageData, 0, 0);
              }
            }
            updateLayer(selectedLayer, canvases[selectedLayer], selectionLayer);
            setUpdateSelection(prevValue => prevValue + 1);
          } else if(selectedTool === "bucket"){
            if(bucketMode !== "dither" && selectedSwatch === 32) return;
            
            const ctx = canvases[selectedLayer].current.getContext("2d", {willReadFrequently: true});
            ctx.imageSmoothingEnabled = false;
  
            const canvasCtx = canvases[selectedLayer].current.getContext("2d", {willReadFrequently: true});
  
            let prevData = canvasCtx.getImageData(0,0,resolution.width,resolution.height);
  
            const fX = Math.floor(x);
            const fY = Math.floor(y);
            const index = (fY * resolution.width + fX) * 4;
  
            const startColor = {
              r: prevData.data[index],
              g: prevData.data[index + 1],
              b: prevData.data[index + 2],
              a: prevData.data[index + 3]
            };     

            const rgbColor = selectedSwatch === 32 ? chroma("#000").rgb() : chroma(palette[selectedSwatch]).rgb();
                
            let imageData = ctx.createImageData(resolution.width, resolution.height);
            if(bucketContinguous){
              const indexes = floodFill(prevData, fX, fY, resolution.width, resolution.height);
              for(const i of indexes){
                imageData.data[i] = rgbColor[0];
                imageData.data[i + 1] = rgbColor[1];
                imageData.data[i + 2] = rgbColor[2];
                imageData.data[i + 3] = 255;
              }
            } else {
              for(let i = 0; i < imageData.data.length; i += 4){
                if(startColor.a < 255){
                  if(prevData.data[i + 3] < 255) {
                    imageData.data[i] = rgbColor[0];
                    imageData.data[i + 1] = rgbColor[1];
                    imageData.data[i + 2] = rgbColor[2];
                    imageData.data[i + 3] = 255;
                  } else {
                    imageData.data[i + 3] = 0;
                  }
                } else if(
                  prevData.data[i] === startColor.r && 
                  prevData.data[i + 1] === startColor.g &&
                  prevData.data[i + 2] === startColor.b &&
                  prevData.data[i + 3] > 0
                ) {
                  imageData.data[i] = rgbColor[0];
                  imageData.data[i + 1] = rgbColor[1];
                  imageData.data[i + 2] = rgbColor[2];
                  imageData.data[i + 3] = 255;
                }
              }
            }

            combinationLayer.current.width = resolution.width;
            combinationLayer.current.height = resolution.height;
            const combinationCtx = combinationLayer.current.getContext("2d");
            combinationCtx.imageSmoothingEnabled = false;

            if(bucketMode === "dither"){
              if(selectedSwatch === 32 && secondarySwatch === 32) return;

              const ditherLayer = document.createElement("canvas");
              ditherLayer.width = resolution.width;
              ditherLayer.height = resolution.height;
              const ditherCtx = ditherLayer.getContext("2d");
              ditherCtx.imageSmoothingEnabled = false;

              let oX = (ditherOffsetX - offset.x) % 4;
              let oY = (ditherOffsetY - offset.y) % 4;

              if(secondarySwatch !== 32){
                ditherCtx.putImageData(imageData, 0, 0);
                ditherCtx.globalCompositeOperation = "destination-out";
                for(let j = -4; j < resolution.width; j += 68){
                  for(let k = -4; k < resolution.height; k += 68){
                    ditherCtx.drawImage(
                      dither.current,
                      ditherRatio * 68,
                      0,
                      68,
                      68,
                      j + oX,
                      k + oY,
                      68,
                      68
                    );
                  }
                }
                combinationCtx.drawImage(ditherLayer, 0, 0);
                combinationCtx.globalCompositeOperation = "source-in";
                combinationCtx.fillStyle = palette[secondarySwatch];
                combinationCtx.fillRect(0, 0, resolution.width,resolution.height);
                combinationCtx.globalCompositeOperation = "source-over";
              }

              if(selectedSwatch !== 32){
                ditherCtx.putImageData(imageData, 0, 0);
                ditherCtx.globalCompositeOperation = "destination-in";
                for(let j = -4; j < resolution.width; j += 68){
                  for(let k = -4; k < resolution.height; k += 68){
                    ditherCtx.drawImage(
                      dither.current,
                      ditherRatio * 68,
                      0,
                      68,
                      68,
                      j + oX,
                      k + oY,
                      68,
                      68
                    );
                  }
                }
                combinationCtx.drawImage(ditherLayer, 0, 0);
              }
            } else {  
              combinationCtx.putImageData(imageData, 0, 0);
            }
            
            if(hasSelection){
              combinationCtx.globalCompositeOperation = "destination-in";
              combinationCtx.drawImage(selectionLayer.current,0,0);
            }

            ctx.drawImage(combinationLayer.current, 0, 0);
            updateLayer(selectedLayer, canvases[selectedLayer], selectionLayer);
          } else if (selectedTool === "transform" && transform && selectedViewport === layout){
            initialMove.current = { x, y };
            rotationStart.current = { x, y };
  
            const startPosition = { x: rotationStart.current.x - transform.pivotX, y: rotationStart.current.y - transform.pivotY};
            const startAngle = Math.atan2(startPosition.y, startPosition.x);
            const startAngleDegrees = (startAngle * 180) / Math.PI;
            previousRotation.current =  (startAngleDegrees + 360) % 360;

            const radius = 4 / canvasScale / scale;
            
            const width = transform.right - transform.left;
            const height = transform.bottom - transform.top;
            const scaledTransformProximity = Math.min(width,height) / 10;

            if(
              (Math.abs(transform.right - transform.left) > radius * 10 &&
              Math.abs(transform.bottom - transform.top) > radius * 10) &&
              (x > transform.pivotX - scaledTransformProximity/2 &&
              x < transform.pivotX + scaledTransformProximity/2 &&
              y > transform.pivotY - scaledTransformProximity/2  &&
              y < transform.pivotY + scaledTransformProximity/2 )
            ){
              setNearTransform(-1);
            } else if(
              x > transform.left - scaledTransformProximity && 
              x < transform.right + scaledTransformProximity &&
              y > transform.top - scaledTransformProximity &&
              y < transform.bottom + scaledTransformProximity
            ){
              const nearLeft = Math.abs(x - transform.left) <= scaledTransformProximity;
              const nearTop = Math.abs(y - transform.top) <= scaledTransformProximity;
              const nearRight = Math.abs(x - transform.right) <= scaledTransformProximity;
              const nearBottom = Math.abs(y - transform.bottom) <= scaledTransformProximity;

              if((nearLeft && nearRight) || (nearTop && nearBottom)){
                setNearTransform(0);
              } else if(nearLeft){
                if(nearTop){
                  setNearTransform(1);
                } else if(nearBottom){
                  setNearTransform(7);
                } else {
                  setNearTransform(8);
                }
              } else if(nearRight){
                if(nearTop){
                  setNearTransform(3);
                } else if(nearBottom){
                  setNearTransform(5);
                } else {
                  setNearTransform(4);
                }
              } else if(nearTop){
                setNearTransform(2);
              } else if(nearBottom){
                setNearTransform(6);
              } else{
                setNearTransform(0);
              } 
            } else{
              const rotateLeft = x < transform.pivotX;
              const rotateTop = y < transform.pivotY;

              if(rotateLeft && rotateTop){
                setNearTransform(9);
              } else if(rotateTop){
                setNearTransform(10);
              } else if(rotateLeft){
                setNearTransform(12);
              } else {
                setNearTransform(11);
              }
            }
          } else if (selectedTool === "gradient"){
            setGradientPoints({x: x, y: y, x2: x, y2: y});
          }
        }
      }
    }

    setSelectedViewport(layout);

    const oX = ((translate.x + 50) / 100) * resolution.width * scale;
    const oY = ((translate.y + 50) / 100) * resolution.height * scale;
    const centerX = resolution.width / 2 - oX;
    const centerY = resolution.height / 2 - oY;
    setViewportCenter({x: centerX, y: centerY});

    
    if(bg.current && wrapper.current && (selectedTool === "pencil" || selectedTool === "erase")){
      const layerWidth = bg.current.clientWidth * scale;
      const layerHeight = bg.current.clientHeight * scale;

      const offsetX = ((translate.x + 50) / 100) * layerWidth;
      const offsetY = ((translate.y + 50) / 100) * layerHeight;

      //calculate boundaries
      const left = (wrapper.current.clientWidth - layerWidth) / 2 + offsetX;
      const top =
        (wrapper.current.clientHeight - layerHeight) / 2 + offsetY;

      const preX = e.clientX - wrapper.current.getBoundingClientRect().left;
      const preY = e.clientY - wrapper.current.getBoundingClientRect().top;

      //convert offset to canvas position
      const x = (preX - left)  / layerWidth* resolution.width;
      const y = (preY - top) / layerHeight * resolution.height;

      // Adjust brush diameter based on pressure
      let pressure = e.pointerType === "pen" ? e.pressure : 1;
      //get pressure factor from tool
      const pressureFactor = selectedTool === "erase" ? erasePressureFactor : brushPressureFactor;
      if(pressureFactor){
        // Ensure the pressure is between 0 and 1
        pressure = Math.max(0, Math.min(pressure, 1));
        // Apply a logarithmic curve
        // Adjust the base of the logarithm to change the curvature
        // Adding 1 to avoid log(0) which is undefined
        pressure = Math.log1p(pressure * pressureFactor) / Math.log1p(pressureFactor);
        // Ensure the output is between 0 and 1
        pressure = Math.max(0, Math.min(pressure, 1));
      }

      lastDraw.current = {x, y, pressure};

      //pixel perfect refs;
      lastPixel.current = {x: Math.floor(x), y: Math.floor(y)};
      lastDrawnPixel.current = lastPixel.current;
      waitingPixel.current = lastPixel.current;

      isDrawing.current = true;
    }

    if (e.pointerType === 'touch') {
      activeTouches.current.push({
        id: e.pointerId,
        x: e.clientX,
        y: e.clientY,
      });

      if(activeTouches.current.length === 1){
        touchTimer.current = setTimeout(delayedPointerDown, 200, e);
      } else if(touchTimer.current){
        initialTouch.current = {
          scale,
          translate,
          dist: distance(activeTouches.current[0], activeTouches.current[1]),
          x: activeTouches.current[0].x + (activeTouches.current[1].x - activeTouches.current[0].x)/2,
          y: activeTouches.current[0].y + (activeTouches.current[1].y - activeTouches.current[0].y)/2,
        };
        clearTimeout(touchTimer.current);
        touchTimer.current = null;
      }
    } else delayedPointerDown(e);
  }, [
    isSpaceDown,
    shiftKey,
    altKey,
    altButton,
    metaButton,
    shiftButton,
    selectedTool,
    previousTool,
    scale,
    translate,
    resolution,
    offset,
    modalIsOpen,
    layers,
    brushDiameter,
    eraseDiameter,
    brushMode,
    eraseDiameter,
    ditherRatio,
    ditherOffsetX,
    ditherOffsetY,
    eraseMode,
    eraseDitherRatio,
    selectedLayer,
    selectedLayers,
    canvases,
    selectionLayer,
    eraseDitherPressureMode,
    invertEraseDither,
    ditherPressureMode,
    canvases,
    hasSelection,
    selectedSwatch,
    secondarySwatch,
    dropperCurrent,
    dropperReplace,
    wandContinguous,
    bucketContinguous,
    bucketMode,
    invertZoom,
    palette,
    selection,
    crop,
    transform,
    version,
    contextMenu,
    canvasWidth,
    lineWidth,
    brushPixelPerfect,
    erasePixelPerfect,
  ]);

  const handlePointerMove = useCallback((e) => {
    const handleMovement = () => {
      const pressureSetting = selectedTool === "pencil" ? brushPressure : erasePressure;
      const pressureFactor = selectedTool === "erase" ? erasePressureFactor : brushPressureFactor;
      let diameter = selectedTool === "erase" ? eraseDiameter : brushDiameter;
      let pressureChange = true;

      // Adjust brush diameter based on pressure
      let pressure = e.pointerType === "pen" ? e.pressure : 1;
      if(pressureFactor){
        // Ensure the pressure is between 0 and 1
        pressure = Math.max(0, Math.min(pressure, 1));
        // Apply a logarithmic curve
        // Adjust the base of the logarithm to change the curvature
        // Adding 1 to avoid log(0) which is undefined
        pressure = Math.log1p(pressure * pressureFactor) / Math.log1p(pressureFactor);
        // Ensure the output is between 0 and 1
        pressure = Math.max(0, Math.min(pressure, 1));
      }
      if(
        (selectedTool === "pencil" && ditherPressureMode === "ratio") ||
        (selectedTool === "erase" && eraseDitherPressureMode === "ratio")
      ) pressure = Math.pow(pressure, 2.25);
      
      const layerWidth = bg.current.clientWidth * scale;
      const layerHeight = bg.current.clientHeight * scale;

      const offsetX = ((translate.x + 50) / 100) * layerWidth;
      const offsetY = ((translate.y + 50) / 100) * layerHeight;

      //calculate boundaries
      const left = (wrapper.current.clientWidth - layerWidth) / 2 + offsetX;
      const top = (wrapper.current.clientHeight - layerHeight) / 2 + offsetY;

      //convert offset to canvas position
      let x = ((e.clientX - wrapper.current.getBoundingClientRect().left - left) / layerWidth) * resolution.width;
      let y = ((e.clientY - wrapper.current.getBoundingClientRect().top - top) / layerHeight) * resolution.height;

      const startX = Math.ceil(x) - Math.round(cursorLayer.current.width / 2);
      const startY = Math.ceil(y) - Math.round(cursorLayer.current.height / 2);

      let outsideX = 0;
      let outsideY = 0;
      
      if( e.clientX < wrapper.current.getBoundingClientRect().left){
        outsideX = 1;
      } else if(e.clientX > wrapper.current.getBoundingClientRect().right){
        outsideX = -1;
      }
        
      if(e.clientY < wrapper.current.getBoundingClientRect().top){
        outsideY = 1;
      } else if(e.clientY > wrapper.current.getBoundingClientRect().bottom ){
        outsideY = -1;
      }

      setCursorPath(null);
      setIconPath(null);
      let canvasScale = canvasHeight === 'auto' ? bg.current.clientWidth / resolution.width :  bg.current.clientHeight / resolution.height;
      
      if(e.pointerType === 'touch' && initialTouch.current){
        // Find the index of the touch object with the matching pointerId
        const touchIndex = activeTouches.current.findIndex(touch => touch.id === e.pointerId);
        if (touchIndex !== -1) {
          // If the touch object is found, update its x and y coordinates
          activeTouches.current[touchIndex].x = e.clientX;
          activeTouches.current[touchIndex].y = e.clientY;
        }

        if(e.pointerId === activeTouches.current[0].id){
          const dist = distance(activeTouches.current[0], activeTouches.current[1]);
          const newScale = initialTouch.current.scale * dist / initialTouch.current.dist;

          setScale(newScale);
          
          const centerX = activeTouches.current[0].x + (activeTouches.current[1].x - activeTouches.current[0].x)/2;
          const centerY = activeTouches.current[0].y + (activeTouches.current[1].y - activeTouches.current[0].y)/2;

          const difX = centerX - initialTouch.current.x;
          const difY = centerY - initialTouch.current.y;

          const nxPercent = (difX / bg.current.clientWidth) * 100 / newScale;
          const nyPercent = (difY / bg.current.clientHeight) * 100 / newScale;

          setTranslate({
            x: initialTouch.current.translate.x + nxPercent,
            y: initialTouch.current.translate.y + nyPercent,
          });
        }
      } else if(!touchTimer.current) {
        if(activeTouches.current.length > 1 && e.pointerId !== activeTouches.current[0].id) return;
        if (isDragging) {
          const dxPixel = e.clientX - lastPosition.current.x;
          const dyPixel = e.clientY - lastPosition.current.y;
          const dxPercent = (dxPixel / bg.current.clientWidth) * 100 / scale
          const dyPercent = (dyPixel / bg.current.clientHeight) * 100 / scale

          setTranslate({
              x: translate.x + dxPercent,
              y: translate.y + dyPercent
          });
        } else {
          if (
            !isSpaceDown &&
            !modalIsOpen &&
            selectionStart.current &&
            (selectedTool === "rectangle" || selectedTool === "ellipse")
          ){
            let x1 = selectionStart.current.x;
            let y1 = selectionStart.current.y;
            let x2 = x;
            let y2 = y;
            const angle = selectedTool === "ellipse" ? -ellipseAngle : -rectangleAngle;
            
            if (shiftRelease && (shiftKey || shiftButton)) {;
              const oppositeCorner = calculateSquareCorner(Math.floor(x1),Math.floor(y1), Math.ceil(x2), Math.ceil(y2), angle);
              x2 = oppositeCorner.x2;
              y2 = oppositeCorner.y2;
            }

            if (altRelease && (altKey || altButton)) {
              // Center the shape at the starting point
              const dx = x1 - x2;
              const dy = y1 - y2;
              x1 = x1 + dx;
              y1 = y1 + dy;
            }

            if(Math.floor(x1) < Math.ceil(x2)){
              x1 = Math.floor(x1);
              x2 = Math.ceil(x2);
            } else {
              x1 = Math.ceil(x1);
              x2 = Math.floor(x2);
            }

            if(Math.floor(y1) < Math.ceil(y2)){
              y1 = Math.floor(y1);
              y2 = Math.ceil(y2);
            } else {
              y1 = Math.ceil(y1);
              y2 = Math.floor(y2);
            }
            
            const ctx = toolLayer.current.getContext("2d");
            ctx.imageSmoothingEnabled = false;
            ctx.clearRect(0, 0, resolution.width, resolution.height);
            
            let sides;
            if (selectedTool === "ellipse") {
              sides = drawEllipse(ctx, x1, y1, x2, y2, angle);
            } else {
              sides = drawRectangle(ctx, x1, y1, x2, y2, angle);
            }
            
            const imageData = ctx.getImageData(
              0,
              0,
              resolution.width,
              resolution.height
            );
            for (let i = 0; i < imageData.data.length; i+=4) {
              if ( imageData.data[i + 3] <= 128) {
                imageData.data[i + 3] = 0;
              } else {
                imageData.data[i + 3] = 255;
              }
            }
            ctx.putImageData(imageData, 0, 0);

            setToolEdges(findPathsInCanvas(toolLayer.current));
            setDetails(`${Math.round(sides.width)}px x ${Math.round(sides.height)}px`);
          } else if(!isSpaceDown &&
            !modalIsOpen &&
            layers[selectedLayer].visible
          ) {
            if (selectedTool === "move" && isMoving) {
              if (initialMove.current) {
                if (outsideX || outsideY) {
                  setDeltaTranslate({
                    x: outsideX,
                    y: outsideY
                  });
                } else {
                  setDeltaTranslate(null);
                }

                let delta;
                let snapAngles = [0,90,180,270];
                
                if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                  let vp1, vp2, vp3, vp4;
                  switch(layers[magnetLayer].type){
                    case "Line":
                      const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                      const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                      const angleInRadians = Math.atan2(deltaY, deltaX);
                      let angleInDegrees = angleInRadians * (180 / Math.PI);
                      angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
  
                      snapAngles = [angleInDegrees];
                      for(let i = 0; i < 3; i++){
                        angleInDegrees += 90;
                        snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                      }
                      break;
                    case "OnePoint":
                      const deltaX2 = x - layers[magnetLayer].point.x;
                      const deltaY2 = y - layers[magnetLayer].point.y;
                      const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                      let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                      angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;
  
                      snapAngles = [angleInDegrees2];
                      break;
                    case "TwoPoint":
                      const vanishingPoints = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                      vp1 = vanishingPoints.vp1;
                      vp2 = vanishingPoints.vp2;
  
                      const deltaX3 = x - vp1.x;
                      const deltaY3 = y - vp1.y;
                      const angleInRadians3 = Math.atan2(deltaY3, deltaX3);
                      let angleInDegrees3 = angleInRadians3 * (180 / Math.PI);
                      angleInDegrees3 = ((angleInDegrees3 % 360) + 360) % 360;
                      const otherAngle3 = (((angleInDegrees3 - 180) % 360) + 360) % 360;
  
                      const deltaX4 = x - vp2.x;
                      const deltaY4 = y - vp2.y;
                      const angleInRadians4 = Math.atan2(deltaY4, deltaX4);
                      let angleInDegrees4 = angleInRadians4 * (180 / Math.PI);
                      angleInDegrees4 = ((angleInDegrees4 % 360) + 360) % 360;
                      const otherAngle4 = (((angleInDegrees4 - 180) % 360) + 360) % 360;
  
                      snapAngles = [angleInDegrees3, otherAngle3, angleInDegrees4, otherAngle4];
                      break;
                    case "ThreePoint":
                      const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                      vp3 = vanishingPoints2.vp1;
                      vp4 = vanishingPoints2.vp2;
                      const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                      vp1 = vanishingPoints3.vp1;
                      vp2 = vanishingPoints3.vp2;
  
                      const deltaX5 = x - vp1.x;
                      const deltaY5 = y - vp1.y;
                      const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                      let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                      angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                      const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;
  
                      const deltaX6 = x - vp2.x;
                      const deltaY6 = y - vp2.y;
                      const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                      let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                      angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                      const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;
  
                      const deltaX8 = x - vp4.x;
                      const deltaY8 = y - vp4.y;
                      const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                      let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                      angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                      const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;

                      const horizontalAngle = layers[magnetLayer].tilt;
                      const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                      snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                      break;
                    case "Grid":
                      snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                      break;
                    case "Isometric":
                      snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                      break;
                    case "Ellipse":
                      snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                      break;
                  }
                }
                
                const deltaX = lastCoordinate.current.x - x;
                const deltaY = lastCoordinate.current.y - y;
                const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

                if(!moveAngles.current && (shiftKey || shiftButton) && length > 0){
                  const angleInRadians = Math.atan2(deltaY, deltaX);
                  let angleInDegrees = angleInRadians * (180 / Math.PI);
                  angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
                  const snapAngle1 = closestAngle(angleInDegrees, snapAngles);
                  const snapAngle2 = (((snapAngle1 - 180) % 360) + 360) % 360;
                  moveAngles.current = [snapAngle1, snapAngle2];
                }
  
                if(moveAngles.current){
                  const snapAngle = closestAngleCoord(lastCoordinate.current, {x, y} , moveAngles.current);
                  const angleInRadians = snapAngle * (Math.PI / 180);
              
                  // Calculate snapped coordinates based on the snap angle and length
                  let snappedX, snappedY;
                  snappedX = length * Math.cos(angleInRadians);
                  snappedY = length * Math.sin(angleInRadians);
              
                  delta = { x: move.x + snappedX, y: move.y + snappedY };
                } else {
                  delta = { x: x - initialMove.current.x, y: y - initialMove.current.y };
                }
              
                selectedLayers.forEach(layerIndex => { 
                  switch(layers[layerIndex].type){
                    case "Canvas":
                      const layerCanvas = layers[layerIndex].ref.current;
                                  
                      if (layers[layerIndex].x + delta.x > offset.x) {
                        if (layers[layerIndex].x + delta.x + layerCanvas.width > offset.x + maxLayerSize) {
                          delta.x = offset.x - layers[layerIndex].x - layerCanvas.width + maxLayerSize;
                        }
                      } else if (layers[layerIndex].x + delta.x < offset.x + resolution.width - maxLayerSize) {
                        delta.x = offset.x + resolution.width - layers[layerIndex].x - maxLayerSize;
                      }
                  
                      if (layers[layerIndex].y + delta.y > offset.y) {
                        if (layers[layerIndex].y + delta.y + layerCanvas.height > offset.y + maxLayerSize) {
                          delta.y = offset.y - layers[layerIndex].y - layerCanvas.height + maxLayerSize;
                        }
                      } else if (layers[layerIndex].y + delta.y < offset.y + resolution.height - maxLayerSize) {
                        delta.y = offset.y + resolution.height - layers[layerIndex].y - maxLayerSize;
                      }
                      break;
                  }    
                });

                selectedLayers.forEach(layerIndex => {
                  switch(layers[layerIndex].type){
                    case "Canvas":
                      const layerCanvas = layers[layerIndex].ref.current;
                      const canvas = canvases[layerIndex].current;
                      const ctx = canvas.getContext("2d");
                      ctx.imageSmoothingEnabled = false;
                  
                      ctx.clearRect(0, 0, resolution.width, resolution.height);
                      ctx.drawImage(
                        layerCanvas,
                        Math.round(layers[layerIndex].x + delta.x - offset.x),
                        Math.round(layers[layerIndex].y + delta.y - offset.y)
                      );
                      break;
                  }
                });
                setMove(delta);
                setDetails(`${Math.round(delta.x)}px, ${Math.round(delta.y)}px`);
              }
            } else if (selectedTool === "crop"){
              if(initialMove.current){
                let delta;
                let snapAngles = [0,90,180,270];
                
                if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                  let vp1, vp2, vp3, vp4;
                  switch(layers[magnetLayer].type){
                    case "Line":
                      const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                      const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                      const angleInRadians = Math.atan2(deltaY, deltaX);
                      let angleInDegrees = angleInRadians * (180 / Math.PI);
                      angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
  
                      snapAngles = [angleInDegrees];
                      for(let i = 0; i < 3; i++){
                        angleInDegrees += 90;
                        snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                      }
                      break;
                    case "OnePoint":
                      const deltaX2 = x - layers[magnetLayer].point.x;
                      const deltaY2 = y - layers[magnetLayer].point.y;
                      const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                      let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                      angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;
  
                      snapAngles = [angleInDegrees2];
                      break;
                    case "TwoPoint":
                      const vanishingPoints = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                      vp1 = vanishingPoints.vp1;
                      vp2 = vanishingPoints.vp2;
  
                      const deltaX3 = x - vp1.x;
                      const deltaY3 = y - vp1.y;
                      const angleInRadians3 = Math.atan2(deltaY3, deltaX3);
                      let angleInDegrees3 = angleInRadians3 * (180 / Math.PI);
                      angleInDegrees3 = ((angleInDegrees3 % 360) + 360) % 360;
                      const otherAngle3 = (((angleInDegrees3 - 180) % 360) + 360) % 360;
  
                      const deltaX4 = x - vp2.x;
                      const deltaY4 = y - vp2.y;
                      const angleInRadians4 = Math.atan2(deltaY4, deltaX4);
                      let angleInDegrees4 = angleInRadians4 * (180 / Math.PI);
                      angleInDegrees4 = ((angleInDegrees4 % 360) + 360) % 360;
                      const otherAngle4 = (((angleInDegrees4 - 180) % 360) + 360) % 360;
  
                      snapAngles = [angleInDegrees3, otherAngle3, angleInDegrees4, otherAngle4];
                      break;
                    case "ThreePoint":
                      const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                      vp3 = vanishingPoints2.vp1;
                      vp4 = vanishingPoints2.vp2;
                      const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                      vp1 = vanishingPoints3.vp1;
                      vp2 = vanishingPoints3.vp2;
  
                      const deltaX5 = x - vp1.x;
                      const deltaY5 = y - vp1.y;
                      const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                      let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                      angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                      const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;
  
                      const deltaX6 = x - vp2.x;
                      const deltaY6 = y - vp2.y;
                      const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                      let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                      angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                      const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;
  
                      const deltaX8 = x - vp4.x;
                      const deltaY8 = y - vp4.y;
                      const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                      let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                      angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                      const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;
  
                      const horizontalAngle = layers[magnetLayer].tilt;
                      const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                      snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                      break;
                    case "Grid":
                      snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                      break;
                    case "Isometric":
                      snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                      break;
                    case "Ellipse":
                      snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                      break;
                  }
                }
                
                const deltaX = x - initialMove.current.x;
                const deltaY = y - initialMove.current.y;

                if(!moveAngles.current && (shiftKey || shiftButton)){
                  const angleInRadians = Math.atan2(deltaY, deltaX);
                  let angleInDegrees = angleInRadians * (180 / Math.PI);
                  angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
                  const snapAngle1 = closestAngle(angleInDegrees, snapAngles);
                  const snapAngle2 = (((snapAngle1 - 180) % 360) + 360) % 360;
                  moveAngles.current = [snapAngle1, snapAngle2];
                }
  
                if(moveAngles.current && nearCrop === 0){
                  const snapAngle = closestAngleCoord(lastCoordinate.current, {x, y} , moveAngles.current);
                  const angleInRadians = snapAngle * (Math.PI / 180);

                  const length = deltaX * Math.cos(angleInRadians) + deltaY * Math.sin(angleInRadians);
              
                  // Calculate snapped coordinates based on the snap angle and length
                  let snappedX, snappedY;
                  snappedX = length * Math.cos(angleInRadians);
                  snappedY = length * Math.sin(angleInRadians);
              
                  delta = { x: snappedX, y: snappedY };
                } else {
                  delta = { x: x - initialMove.current.x, y: y - initialMove.current.y };
                }

                if(outsideX || outsideY){
                  setDeltaTranslate({
                    x: outsideX,
                    y: outsideY
                  });
                } else {
                  setDeltaTranslate(null);
                }
                
                let left = offset.x + resolution.width - maxLayerSize;
                let top = offset.y + resolution.height - maxLayerSize;
                let right = offset.x + maxLayerSize;
                let bottom = offset.y + maxLayerSize;
                for(const layer of layers){
                  if(layer.type !== "Canvas") continue;
                  const layerLeft = layer.x;
                  const layerTop = layer.y;
                  const layerRight = layerLeft + layer.ref.current.width;
                  const layerBottom = layerTop + layer.ref.current.height;

                  left = Math.max(left, layerRight - maxLayerSize);
                  top = Math.max(top, layerBottom - maxLayerSize);
                  right = Math.min(right, layerLeft + maxLayerSize);
                  bottom = Math.min(bottom, layerTop + maxLayerSize)
                }

                if(nearCrop === 0){
                  const cropWidth = initialCrop.current.right - initialCrop.current.left;
                  const cropHeight = initialCrop.current.bottom - initialCrop.current.top;
  
                  if(offset.x + initialCrop.current.right + delta.x > right){
                    delta.x =  right - cropWidth - offset.x - initialCrop.current.left;
                  } else if(offset.x + initialCrop.current.left + delta.x < left){
                    delta.x = left - initialCrop.current.left - offset.x;
                  }
  
                  if(offset.y + initialCrop.current.bottom + delta.y > bottom){
                    delta.y =  bottom - cropHeight - offset.y - initialCrop.current.top;
                  } else if(offset.y + initialCrop.current.top + delta.y < top){
                    delta.y = top - initialCrop.current.top - offset.y;
                  }
  
                  setCrop({
                    left: Math.round(initialCrop.current.left + delta.x),
                    top: Math.round(initialCrop.current.top + delta.y),
                    right: Math.round(initialCrop.current.right + delta.x),
                    bottom: Math.round(initialCrop.current.bottom + delta.y),
                  });
                } else {
                  let deltaLeft = 0;
                  let deltaTop = 0;
                  let deltaRight = 0;
                  let deltaBottom = 0;       

                  let deltaWidth, deltaHeight, maxDelta;             

                  const aspectRatio = (initialCrop.current.right - initialCrop.current.left)/(initialCrop.current.bottom - initialCrop.current.top);
                  
                  if(nearCrop === 7 || nearCrop === 8 || nearCrop === 1){
                    deltaLeft = delta.x;
                    if(offset.x + initialCrop.current.left + deltaLeft < left){
                      deltaLeft = left - initialCrop.current.left - offset.x;
                    }
                    if(initialCrop.current.right - initialCrop.current.left - deltaLeft > maxResolution){
                      deltaLeft = initialCrop.current.right - initialCrop.current.left - maxResolution;
                    }
                  } else if(nearCrop === 3 || nearCrop === 4 || nearCrop === 5){
                    deltaRight = delta.x;
                    if(offset.x + initialCrop.current.right + deltaRight > right){
                      deltaRight =  right - offset.x - initialCrop.current.right;
                    }
                    if(initialCrop.current.right - initialCrop.current.left + deltaRight > maxResolution){
                      deltaRight = maxResolution - initialCrop.current.right + initialCrop.current.left;
                    }
                  }
  
                  if(nearCrop === 1 || nearCrop === 2 || nearCrop === 3){
                    deltaTop = delta.y;
                    if(offset.y + initialCrop.current.top + deltaTop < top){
                      deltaTop = top - initialCrop.current.top - offset.y;
                    }
                    if(initialCrop.current.bottom - initialCrop.current.top - deltaTop > maxResolution){
                      deltaTop = initialCrop.current.bottom - initialCrop.current.top - maxResolution;
                    }                
                  } else if(nearCrop === 5 || nearCrop === 6 || nearCrop === 7){
                    deltaBottom = delta.y
                    if(offset.y + initialCrop.current.bottom + deltaBottom > bottom){
                      deltaBottom =  bottom - offset.y - initialCrop.current.bottom;
                    }
                    if(initialCrop.current.bottom - initialCrop.current.top + deltaBottom > maxResolution){
                      deltaBottom = maxResolution - initialCrop.current.bottom + initialCrop.current.top;
                    }
                  }
                  
                  if (shiftKey || shiftButton) {
                    switch(nearCrop) {
                      case 1: // Top-left corner
                        deltaWidth = initialCrop.current.right - initialCrop.current.left - deltaLeft;
                        deltaHeight = initialCrop.current.bottom - initialCrop.current.top - deltaTop;
                        break;
                      case 3: // Top-right corner
                        deltaWidth = initialCrop.current.right - initialCrop.current.left + deltaRight;
                        deltaHeight = initialCrop.current.bottom - initialCrop.current.top - deltaTop;
                        break;
                      case 5: // Bottom-right corner
                        deltaWidth = initialCrop.current.right - initialCrop.current.left + deltaRight;
                        deltaHeight = initialCrop.current.bottom - initialCrop.current.top + deltaBottom;
                        break;
                      case 7: // Bottom-left corner
                        deltaWidth = initialCrop.current.right - initialCrop.current.left - deltaLeft;
                        deltaHeight = initialCrop.current.bottom - initialCrop.current.top + deltaBottom;
                        break;
                    }
                    
                    maxDelta = Math.max(Math.abs(deltaWidth), Math.abs(deltaHeight));

                    if(aspectRatio > 1){
                      switch (nearCrop) {
                        case 1: // Top-left corner
                          deltaLeft = initialCrop.current.right - initialCrop.current.left - maxDelta;
                          deltaTop = initialCrop.current.bottom - initialCrop.current.top - maxDelta / aspectRatio;
                          if(altKey || altButton){
                            deltaRight = -deltaLeft;
                            deltaBottom = -deltaTop;
                          }
                          break;
                        case 3: // Top-right corner
                          deltaRight = maxDelta - (initialCrop.current.right - initialCrop.current.left);
                          deltaTop = initialCrop.current.bottom - initialCrop.current.top - maxDelta / aspectRatio;
                          if(altKey || altButton){
                            deltaLeft = -deltaRight;
                            deltaBottom = -deltaBottom;
                          }
                          break;
                        case 5: // Bottom-right corner
                          deltaRight = maxDelta - (initialCrop.current.right - initialCrop.current.left);
                          deltaBottom = maxDelta / aspectRatio - (initialCrop.current.bottom - initialCrop.current.top);
                          if(altKey || altButton){
                            deltaLeft = -deltaRight;
                            deltaTop = -deltaBottom;
                          }
                          break;
                        case 7: // Bottom-left corner
                          deltaLeft = initialCrop.current.right - initialCrop.current.left - maxDelta;
                          deltaBottom = maxDelta / aspectRatio - (initialCrop.current.bottom - initialCrop.current.top);
                          if(altKey || altButton){
                            deltaRight = -deltaLeft;
                            deltaTop = -deltaBottom;
                          }
                          break;
                      }
                    } else {
                      switch (nearCrop) {
                        case 1: // Top-left corner
                          deltaLeft = initialCrop.current.right - initialCrop.current.left - maxDelta * aspectRatio;
                          deltaTop = initialCrop.current.bottom - initialCrop.current.top - maxDelta;
                          if(altKey || altButton){
                            deltaRight = -deltaLeft;
                            deltaBottom = -deltaTop;
                          }
                          break;
                        case 3: // Top-right corner
                          deltaRight = maxDelta * aspectRatio - (initialCrop.current.right - initialCrop.current.left);
                          deltaTop = initialCrop.current.bottom - initialCrop.current.top - maxDelta;
                          if(altKey || altButton){
                            deltaLeft = -deltaRight;
                            deltaBottom = -deltaTop;
                          }
                          break;
                        case 5: // Bottom-right corner
                          deltaRight = maxDelta * aspectRatio - (initialCrop.current.right - initialCrop.current.left);
                          deltaBottom = maxDelta - (initialCrop.current.bottom - initialCrop.current.top);
                          if(altKey || altButton){
                            deltaLeft = -deltaRight;
                            deltaTop = -deltaBottom;
                          }
                          break;
                        case 7: // Bottom-left corner
                          deltaLeft = initialCrop.current.right - initialCrop.current.left - maxDelta * aspectRatio;
                          deltaBottom = maxDelta - (initialCrop.current.bottom - initialCrop.current.top);
                          if(altKey || altButton){
                            deltaRight = -deltaLeft;
                            deltaTop = -deltaBottom;
                          }
                          break;
                      }
                    }
                  }

                  if (altKey || altButton){
                    if(nearCrop === 7 || nearCrop === 8 || nearCrop === 1){
                      deltaRight = -deltaLeft;
                      if(offset.x + initialCrop.current.right + deltaRight > right){
                        deltaRight =  right - offset.x - initialCrop.current.right;
                        deltaLeft = -deltaRight;
                      }
                      if(initialCrop.current.right - initialCrop.current.left + deltaRight * 2 > maxResolution){
                        deltaRight = (maxResolution - initialCrop.current.right + initialCrop.current.left) / 2;
                        deltaLeft = -deltaRight;
                      }
                      if(initialCrop.current.right + deltaRight < initialCrop.current.left + 1){
                        deltaRight = initialCrop.current.left + 1 - initialCrop.current.right;
                        deltaLeft = -deltaRight;
                      }
                    } else if(nearCrop === 3 || nearCrop === 4 || nearCrop === 5){
                      deltaLeft = -deltaRight;
                      if(offset.x + initialCrop.current.left + deltaLeft < left){
                        deltaLeft = left - initialCrop.current.left - offset.x;
                        deltaRight = -deltaLeft;
                      }
                      if(initialCrop.current.right - initialCrop.current.left - deltaLeft * 2 > maxResolution){
                        deltaLeft = (initialCrop.current.right - initialCrop.current.left - maxResolution) / 2;
                        deltaRight = -deltaLeft;
                      }
                      if(initialCrop.current.left + deltaLeft > initialCrop.current.right - 1){
                        deltaLeft = initialCrop.current.right - 1 - initialCrop.current.left;
                        deltaRight = -deltaLeft;
                      }
                    }
    
                    if(nearCrop === 1 || nearCrop === 2 || nearCrop === 3){
                      deltaBottom = -deltaTop;
                      if(offset.y + initialCrop.current.bottom + deltaBottom > bottom){
                        deltaBottom =  bottom - offset.y - initialCrop.current.bottom;
                        deltaTop = -deltaBottom;
                      }
                      if(initialCrop.current.bottom - initialCrop.current.top + deltaBottom * 2 > maxResolution){
                        deltaBottom = (maxResolution - initialCrop.current.bottom + initialCrop.current.top)/2;
                        deltaTop = -deltaBottom;
                      }
                      if(initialCrop.current.bottom + deltaBottom < initialCrop.current.top + 1){
                        deltaBottom = initialCrop.current.top + 1 - initialCrop.current.bottom;
                        deltaTop = -deltaBottom;
                      }      
                    } else if(nearCrop === 5 || nearCrop === 6 || nearCrop === 7){
                      deltaTop = -deltaBottom;
                      if(offset.y + initialCrop.current.top + deltaTop < top){
                        deltaTop = top - initialCrop.current.top - offset.y;
                        deltaBottom = -deltaTop;
                      }
                      if(initialCrop.current.bottom - initialCrop.current.top - deltaTop * 2 > maxResolution){
                        deltaTop = (initialCrop.current.bottom - initialCrop.current.top - maxResolution)/2;
                        deltaBottom = -deltaTop;
                      }
                      if(initialCrop.current.top + deltaTop > initialCrop.current.bottom - 1){
                        deltaTop = initialCrop.current.bottom - 1 - initialCrop.current.top;
                        deltaBottom = -deltaTop;
                      }       
                    }
                      
                    if((shiftKey || shiftButton) && (nearCrop === 1 || nearCrop === 3 || nearCrop === 5 || nearCrop === 7)){
                      if(aspectRatio > 1){
                        if(deltaTop < deltaLeft / aspectRatio){
                          deltaTop = deltaLeft / aspectRatio;
                          deltaBottom = -deltaTop;
                        }
                      } else {
                        if(deltaLeft < deltaTop * aspectRatio){
                          deltaLeft = deltaTop * aspectRatio;
                          deltaRight = -deltaLeft;
                        }
                      }
                    }
                  }
                  
                  const proposedLeft = initialCrop.current.left + deltaLeft;
                  const proposedRight = initialCrop.current.right + deltaRight;
                  const proposedTop = initialCrop.current.top + deltaTop;
                  const proposedBottom = initialCrop.current.bottom + deltaBottom;

                  const horizontalCenter = initialCrop.current.left + (initialCrop.current.right - initialCrop.current.left) / 2;
                  const verticalCenter = initialCrop.current.top + (initialCrop.current.bottom - initialCrop.current.top) / 2;

                  if (proposedRight < proposedLeft + 1) {
                    if (altKey || altButton) {
                      deltaLeft = horizontalCenter - initialCrop.current.left - 1;
                      deltaRight = horizontalCenter - initialCrop.current.right + 1;
                    } else {
                      if (nearCrop === 1 || nearCrop === 8 || nearCrop === 7) {
                        deltaLeft = initialCrop.current.right - initialCrop.current.left - 1;
                      } else if (nearCrop === 3 || nearCrop === 4 || nearCrop === 5) {
                        deltaRight = initialCrop.current.left - initialCrop.current.right + 1;
                      }
                    }
                  }

                  if (proposedBottom < proposedTop + 1) {
                    if (altKey || altButton) {
                      deltaTop = verticalCenter - initialCrop.current.top - 1;
                      deltaBottom = verticalCenter - initialCrop.current.bottom + 1;
                    } else {  
                      if (nearCrop === 1 || nearCrop === 2 || nearCrop === 3) {
                        deltaTop = initialCrop.current.bottom - initialCrop.current.top - 1;
                      } else if (nearCrop === 5 || nearCrop === 6 || nearCrop === 7) {
                        deltaBottom = initialCrop.current.top - initialCrop.current.bottom + 1;
                      }
                    }
                  } 

                  setCrop({
                    left: Math.round(initialCrop.current.left + deltaLeft),
                    top: Math.round(initialCrop.current.top + deltaTop),
                    right: Math.round(initialCrop.current.right + deltaRight),
                    bottom: Math.round(initialCrop.current.bottom + deltaBottom),
                  });
                }
              } else if(crop){
                const width = crop.right - crop.left;
                const height = crop.bottom - crop.top;
                const scaledCropProximity = Math.min(width,height) / 10;

                if(
                  x > crop.left - scaledCropProximity && 
                  x < crop.right + scaledCropProximity &&
                  y > crop.top - scaledCropProximity &&
                  y < crop.bottom + scaledCropProximity
                ){
                  const nearLeft = Math.abs(x - crop.left) <= scaledCropProximity;
                  const nearTop = Math.abs(y - crop.top) <= scaledCropProximity;
                  const nearRight = Math.abs(x - crop.right) <= scaledCropProximity;
                  const nearBottom = Math.abs(y - crop.bottom) <= scaledCropProximity;
    
                  if((nearLeft && nearRight) || (nearTop && nearBottom)){
                    setNearCrop(0);
                  } else {
                    if(nearLeft){
                      if(nearTop){
                        setNearCrop(1);
                      } else if(nearBottom){
                        setNearCrop(7);
                      } else {
                        setNearCrop(8);
                      }
                    } else if(nearRight){
                      if(nearTop){
                        setNearCrop(3);
                      } else if(nearBottom){
                        setNearCrop(5);
                      } else {
                        setNearCrop(4);
                      }
                    } else if(nearTop){
                      setNearCrop(2);
                    } else if(nearBottom){
                      setNearCrop(6);
                    } else {
                      setNearCrop(0);
                    }
                  }
                } else {
                  const cropLeft = x < crop.left;
                  const cropRight = x > crop.right;
                  const cropTop = y < crop.top;
                  const cropBottom = y > crop.bottom;

                  if(cropLeft){
                    if(cropTop){
                      setNearCrop(1);
                    } else if(cropBottom){
                      setNearCrop(7);
                    } else {
                      setNearCrop(8);
                    }
                  } else if(cropRight){
                    if(cropTop){
                      setNearCrop(3);
                    } else if(cropBottom){
                      setNearCrop(5);
                    } else {
                      setNearCrop(4);
                    }
                  } else if(cropTop){
                    setNearCrop(2);
                  } else if(cropBottom) {
                    setNearCrop(6);
                  } else{
                    setNearCrop(0);
                  }
                }
              }
            } else if (layers[selectedLayer].type !== "Canvas"){
              if(selectedTool === "guide"){
                const layer = layers[selectedLayer];
                switch(layers[selectedLayer].type){
                  case "Line":
                    if(guidePoints){
                      let coordinates
                      let snapAngles = [0,90,180,270];

                      if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                        let startPoint;
                        switch(nearLine){
                          case 2:
                          default:
                            startPoint = {x: x, y: y};
                            break;
                          case 0:
                            startPoint = {x: guidePoints.x2, y: guidePoints.y2};
                            break;
                          case 1:
                            startPoint = {x: guidePoints.x, y: guidePoints.y};
                            break;
                        }
                        
                        let vp1,vp2,vp3,vp4;
                        switch(layers[magnetLayer].type){
                          case "Line":
                            const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                            const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                            const angleInRadians = Math.atan2(deltaY, deltaX);
                            let angleInDegrees = angleInRadians * (180 / Math.PI);
                            angleInDegrees = ((angleInDegrees % 360) + 360) % 360;

                            snapAngles = [angleInDegrees];
                            for(let i = 0; i < 3; i++){
                              angleInDegrees += 90;
                              snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                            }
                            break;
                          case "OnePoint":
                            const deltaX2 = startPoint.x - layers[magnetLayer].point.x;
                            const deltaY2 = startPoint.y - layers[magnetLayer].point.y;
                            const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                            let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                            angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;
                            const otherAngle = (((angleInDegrees2 - 180) % 360) + 360) % 360;
        
                            snapAngles = [angleInDegrees2, otherAngle];
                            break;
                          case "TwoPoint":                            
                            const vanishingPoints = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                            vp1 = vanishingPoints.vp1;
                            vp2 = vanishingPoints.vp2;
        
                            const deltaX3 = startPoint.x - vp1.x;
                            const deltaY3 = startPoint.y - vp1.y;
                            const angleInRadians3 = Math.atan2(deltaY3, deltaX3);
                            let angleInDegrees3 = angleInRadians3 * (180 / Math.PI);
                            angleInDegrees3 = ((angleInDegrees3 % 360) + 360) % 360;
                            const otherAngle3 = (((angleInDegrees3 - 180) % 360) + 360) % 360;
        
                            const deltaX4 = startPoint.x - vp2.x;
                            const deltaY4 = startPoint.y - vp2.y;
                            const angleInRadians4 = Math.atan2(deltaY4, deltaX4);
                            let angleInDegrees4 = angleInRadians4 * (180 / Math.PI);
                            angleInDegrees4 = ((angleInDegrees4 % 360) + 360) % 360;
                            const otherAngle4 = (((angleInDegrees4 - 180) % 360) + 360) % 360;
        
                            snapAngles = [angleInDegrees3, otherAngle3, angleInDegrees4, otherAngle4];
                            break;
                          case "ThreePoint":
                            const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                            vp3 = vanishingPoints2.vp1;
                            vp4 = vanishingPoints2.vp2;
                            const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                            vp1 = vanishingPoints3.vp1;
                            vp2 = vanishingPoints3.vp2;
        
                            const deltaX5 = startPoint.x - vp1.x;
                            const deltaY5 = startPoint.y - vp1.y;
                            const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                            let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                            angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                            const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;
        
                            const deltaX6 = startPoint.x - vp2.x;
                            const deltaY6 = startPoint.y - vp2.y;
                            const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                            let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                            angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                            const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;
        
                            const deltaX8 = startPoint.x - vp4.x;
                            const deltaY8 = startPoint.y - vp4.y;
                            const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                            let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                            angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                            const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;
        
                            const horizontalAngle = layers[magnetLayer].tilt;
                            const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                            snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                            break;
                          case "Grid":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                            break;
                          case "Isometric":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                            break;
                          case "Ellipse":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                            break;
                        }
                      }
                     
                      if(!layers[selectedLayer].startPoint || nearLine === 1){
                        if(shiftKey || shiftButton){
                          const snapAngle = closestAngleCoord(guidePoints, {x: x, y: y}, snapAngles);
                          const angleInRadians = snapAngle * (Math.PI / 180);
                          const deltaX = x - guidePoints.x;
                          const deltaY = y - guidePoints.y;
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                      
                          // Calculate snapped coordinates based on the snap angle and length
                          const snappedX = guidePoints.x + length * Math.cos(angleInRadians);
                          const snappedY = guidePoints.y + length * Math.sin(angleInRadians);
                      
                          coordinates = {x: guidePoints.x, y: guidePoints.y, x2: snappedX, y2: snappedY};
                        } else {
                          coordinates = {x: guidePoints.x, y: guidePoints.y, x2: x, y2: y};
                        }
                      } else if(nearLine === 0){
                        if(shiftKey || shiftButton){
                          const snapAngle = closestAngleCoord({x: x, y: y}, {x: guidePoints.x2, y: guidePoints.y2} , snapAngles);
                          const angleInRadians = snapAngle * (Math.PI / 180);
                          const deltaX = guidePoints.x2 - x;
                          const deltaY = guidePoints.y2 - y;
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                          const snappedX = guidePoints.x2 - length * Math.cos(angleInRadians);
                          const snappedY = guidePoints.y2 - length * Math.sin(angleInRadians);
                      
                          coordinates = {x: snappedX, y: snappedY, x2: guidePoints.x2, y2: guidePoints.y2};
                        } else {
                          coordinates = {x: x, y: y, x2: guidePoints.x2, y2: guidePoints.y2};
                        }
                      } else if(nearLine === 2){
                        const deltaX = x - lastCoordinate.current.x;
                        const deltaY = y - lastCoordinate.current.y;

                        if(!moveAngles.current && (shiftKey || shiftButton)){
                          const angleInRadians = Math.atan2(deltaY, deltaX);
                          let angleInDegrees = angleInRadians * (180 / Math.PI);
                          angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
                          const snapAngle1 = closestAngle(angleInDegrees, snapAngles);
                          const snapAngle2 = (((snapAngle1 - 180) % 360) + 360) % 360;
                          moveAngles.current = [snapAngle1, snapAngle2];
                        }
          
                        if(moveAngles.current){
                          const snapAngle = closestAngleCoord(lastCoordinate.current, {x, y }, moveAngles.current);
                          const angleInRadians = snapAngle * (Math.PI / 180);
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                          const snappedX = length * Math.cos(angleInRadians);
                          const snappedY = length * Math.sin(angleInRadians);
                          coordinates = {x: guidePoints.x + snappedX, y: guidePoints.y + snappedY, x2: guidePoints.x2 + snappedX, y2: guidePoints.y2 + snappedY};
                        } else {
                          coordinates = {x: guidePoints.x + deltaX, y: guidePoints.y + deltaY, x2: guidePoints.x2 + deltaX, y2: guidePoints.y2 + deltaY};
                        }
                      } 

                      setGuidePoints(coordinates);

                      const dx = coordinates.x2 - coordinates.x;
                      const dy = coordinates.y2 - coordinates.y;
                  
                      const length = Math.sqrt(dx * dx + dy * dy);
                      let angle = -1 * Math.atan2(dy, dx);
                      angle = angle * (180 / Math.PI);
        
                      setDetails(`${Math.round(length)}px, ${angle.toFixed(2)}°`);
                    }
                    break;
                  case "OnePoint":
                    if(nearPerspective === 0){
                      let snapAngles = [0,90,180,270];

                      if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                        let vp1, vp2, vp3, vp4;
                        switch(layers[magnetLayer].type){
                          case "Line":
                            const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                            const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                            const angleInRadians = Math.atan2(deltaY, deltaX);
                            let angleInDegrees = angleInRadians * (180 / Math.PI);
                            angleInDegrees = ((angleInDegrees % 360) + 360) % 360;

                            snapAngles = [angleInDegrees];
                            for(let i = 0; i < 3; i++){
                              angleInDegrees += 90;
                              snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                            }
                            break;
                          case "OnePoint":
                            if(magnetLayer === selectedLayer) break;
                            const deltaX2 = x - layers[magnetLayer].point.x;
                            const deltaY2 = y - layers[magnetLayer].point.y;
                            const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                            let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                            angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;
        
                            snapAngles = [angleInDegrees2];
                            break;
                          case "TwoPoint":                            
                            const vanishingPoints = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                            vp1 = vanishingPoints.vp1;
                            vp2 = vanishingPoints.vp2; 

                            const deltaX3 = x - vp1.x;
                            const deltaY3 = y - vp1.y;
                            const angleInRadians3 = Math.atan2(deltaY3, deltaX3);
                            let angleInDegrees3 = angleInRadians3 * (180 / Math.PI);
                            angleInDegrees3 = ((angleInDegrees3 % 360) + 360) % 360;
                            const otherAngle3 = (((angleInDegrees3 - 180) % 360) + 360) % 360;
        
                            const deltaX4 = x - vp2.x;
                            const deltaY4 = y - vp2.y;
                            const angleInRadians4 = Math.atan2(deltaY4, deltaX4);
                            let angleInDegrees4 = angleInRadians4 * (180 / Math.PI);
                            angleInDegrees4 = ((angleInDegrees4 % 360) + 360) % 360;
                            const otherAngle4 = (((angleInDegrees4 - 180) % 360) + 360) % 360;
        
                            snapAngles = [angleInDegrees3, otherAngle3, angleInDegrees4, otherAngle4];
                            break;
                          case "ThreePoint":
                            const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                            vp3 = vanishingPoints2.vp1;
                            vp4 = vanishingPoints2.vp2;
                            const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                            vp1 = vanishingPoints3.vp1;
                            vp2 = vanishingPoints3.vp2;
        
                            const deltaX5 = x - vp1.x;
                            const deltaY5 = y - vp1.y;
                            const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                            let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                            angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                            const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;
        
                            const deltaX6 = x - vp2.x;
                            const deltaY6 = y - vp2.y;
                            const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                            let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                            angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                            const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;
        
                            const deltaX8 = x - vp4.x;
                            const deltaY8 = y - vp4.y;
                            const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                            let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                            angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                            const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;
        
                            const horizontalAngle = layers[magnetLayer].tilt;
                            const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                            snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                            break;
                          case "Grid":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                            break;
                          case "Isometric":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                            break;
                        }
                      }

                      const deltaX = x- guidePoints.x;
                      const deltaY = y - guidePoints.y;

                      if(!moveAngles.current && (shiftKey || shiftButton)){
                        const angleInRadians = Math.atan2(deltaY, deltaX);
                        let angleInDegrees = angleInRadians * (180 / Math.PI);
                        angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
                        const snapAngle1 = closestAngle(angleInDegrees, snapAngles);
                        const snapAngle2 = (((snapAngle1 - 180) % 360) + 360) % 360;
                        moveAngles.current = [snapAngle1, snapAngle2];
                      }
        
                      if(moveAngles.current){
                        const snapAngle = closestAngleCoord(guidePoints, {x: x, y: y}, moveAngles.current);
                        const angleInRadians = snapAngle * (Math.PI / 180);
                        const length = deltaX * Math.cos(angleInRadians) + deltaY * Math.sin(angleInRadians);
                        const snappedX = guidePoints.x + length * Math.cos(angleInRadians);
                        const snappedY = guidePoints.y + length * Math.sin(angleInRadians);
                        setGuidePoints({x: snappedX, y: snappedY});
                        setDetails(`${snappedX.toFixed(2)}, ${snappedY.toFixed(2)}`);
                      } else {
                        setGuidePoints({x: x, y: y});
                        setDetails(`${(x).toFixed(2)}, ${(y).toFixed(2)}`);
                      }
                    }
                    break;
                  case "TwoPoint":
                    switch(nearPerspective){
                      case 0:
                        let snapAngles = [0,90,180,270];
  
                        if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                          let vp1, vp2, vp3, vp4;
                          switch(layers[magnetLayer].type){
                            case "Line":
                              const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                              const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                              const angleInRadians = Math.atan2(deltaY, deltaX);
                              let angleInDegrees = angleInRadians * (180 / Math.PI);
                              angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
  
                              snapAngles = [angleInDegrees];
                              for(let i = 0; i < 3; i++){
                                angleInDegrees += 90;
                                snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                              }
                              break;
                            case "OnePoint":
                              const deltaX2 = x - layers[magnetLayer].point.x;
                              const deltaY2 = y - layers[magnetLayer].point.y;
                              const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                              let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                              angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;
          
                              snapAngles = [angleInDegrees2];
                              break;
                            case "TwoPoint":                            
                              const vanishingPoints = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                              vp1 = vanishingPoints.vp1;
                              vp2 = vanishingPoints.vp2;
          
                              const deltaX3 = x - vp1.x;
                              const deltaY3 = y - vp1.y;
                              const angleInRadians3 = Math.atan2(deltaY3, deltaX3);
                              let angleInDegrees3 = angleInRadians3 * (180 / Math.PI);
                              angleInDegrees3 = ((angleInDegrees3 % 360) + 360) % 360;
                              const otherAngle3 = (((angleInDegrees3 - 180) % 360) + 360) % 360;
          
                              const deltaX4 = x - vp2.x;
                              const deltaY4 = y - vp2.y;
                              const angleInRadians4 = Math.atan2(deltaY4, deltaX4);
                              let angleInDegrees4 = angleInRadians4 * (180 / Math.PI);
                              angleInDegrees4 = ((angleInDegrees4 % 360) + 360) % 360;
                              const otherAngle4 = (((angleInDegrees4 - 180) % 360) + 360) % 360;
          
                              snapAngles = [angleInDegrees3, otherAngle3, angleInDegrees4, otherAngle4];
                              break;
                            case "ThreePoint":
                              const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                              vp3 = vanishingPoints2.vp1;
                              vp4 = vanishingPoints2.vp2;
                              const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                              vp1 = vanishingPoints3.vp1;
                              vp2 = vanishingPoints3.vp2;
          
                              const deltaX5 = x - vp1.x;
                              const deltaY5 = y - vp1.y;
                              const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                              let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                              angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                              const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;
          
                              const deltaX6 = x - vp2.x;
                              const deltaY6 = y - vp2.y;
                              const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                              let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                              angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                              const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;
          
                              const deltaX8 = x - vp4.x;
                              const deltaY8 = y - vp4.y;
                              const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                              let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                              angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                              const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;
          
                              const horizontalAngle = layers[magnetLayer].tilt;
                              const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                              snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                              break;
                            case "Grid":
                              snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                              break;
                            case "Isometric":
                              snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                              break;
                            case "Ellipse":
                              snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                              break;
                          }
                        }
  
                        const deltaX = x - lastCoordinate.current.x;
                        const deltaY = y - lastCoordinate.current.y;
  
                        if(!moveAngles.current && (shiftKey || shiftButton)){
                          const angleInRadians = Math.atan2(deltaY, deltaX);
                          let angleInDegrees = angleInRadians * (180 / Math.PI);
                          angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
                          const snapAngle1 = closestAngle(angleInDegrees, snapAngles);
                          const snapAngle2 = (((snapAngle1 - 180) % 360) + 360) % 360;
                          moveAngles.current = [snapAngle1, snapAngle2];
                        }
          
                        if(moveAngles.current){
                          const snapAngle = closestAngleCoord(lastCoordinate.current, {x, y} , moveAngles.current);
                          const angleInRadians = snapAngle * (Math.PI / 180);
                          const length = deltaX * Math.cos(angleInRadians) + deltaY * Math.sin(angleInRadians);
                          const snappedX = guidePoints.centerX + length * Math.cos(angleInRadians);
                          const snappedY = guidePoints.centerY + length * Math.sin(angleInRadians);
                          setGuidePoints({centerX: snappedX, centerY: snappedY, angle: layer.angle});
                        } else {
                          setGuidePoints({centerX: x, centerY: y, angle: layer.angle});
                        }
                        break;
                      case 1:
                        const angle = deriveAngleFromCursor(layer.center, layer.focalLength, layer.tilt, {x: x, y: y});
                        setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle});
                        break;
                      case 2:
                        const angle2 = deriveAngleFromCursor2(layer.center, layer.focalLength, layer.tilt, {x: x, y: y});
                        setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle: angle2});
                        break;
                    }
                    break;
                  case "ThreePoint":
                    switch(nearPerspective){
                      case 0:
                        let snapAngles = [0,90,180,270];
  
                        if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                          let vp1, vp2, vp3, vp4;
                          switch(layers[magnetLayer].type){
                            case "Line":
                              const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                              const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                              const angleInRadians = Math.atan2(deltaY, deltaX);
                              let angleInDegrees = angleInRadians * (180 / Math.PI);
                              angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
  
                              snapAngles = [angleInDegrees];
                              for(let i = 0; i < 3; i++){
                                angleInDegrees += 90;
                                snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                              }
                              break;
                            case "OnePoint":
                              const deltaX2 = x - layers[magnetLayer].point.x;
                              const deltaY2 = y - layers[magnetLayer].point.y;
                              const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                              let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                              angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;
          
                              snapAngles = [angleInDegrees2];
                              break;
                            case "TwoPoint":                            
                              const vanishingPoints = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                              vp1 = vanishingPoints.vp1;
                              vp2 = vanishingPoints.vp2;
          
                              const deltaX3 = x - vp1.x;
                              const deltaY3 = y - vp1.y;
                              const angleInRadians3 = Math.atan2(deltaY3, deltaX3);
                              let angleInDegrees3 = angleInRadians3 * (180 / Math.PI);
                              angleInDegrees3 = ((angleInDegrees3 % 360) + 360) % 360;
                              const otherAngle3 = (((angleInDegrees3 - 180) % 360) + 360) % 360;
          
                              const deltaX4 = x - vp2.x;
                              const deltaY4 = y - vp2.y;
                              const angleInRadians4 = Math.atan2(deltaY4, deltaX4);
                              let angleInDegrees4 = angleInRadians4 * (180 / Math.PI);
                              angleInDegrees4 = ((angleInDegrees4 % 360) + 360) % 360;
                              const otherAngle4 = (((angleInDegrees4 - 180) % 360) + 360) % 360;
          
                              snapAngles = [angleInDegrees3, otherAngle3, angleInDegrees4, otherAngle4];
                              break;
                            case "ThreePoint":
                              const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                              vp3 = vanishingPoints2.vp1;
                              vp4 = vanishingPoints2.vp2;
                              const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                              vp1 = vanishingPoints3.vp1;
                              vp2 = vanishingPoints3.vp2;
          
                              const deltaX5 = x - vp1.x;
                              const deltaY5 = y - vp1.y;
                              const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                              let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                              angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                              const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;
          
                              const deltaX6 = x - vp2.x;
                              const deltaY6 = y - vp2.y;
                              const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                              let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                              angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                              const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;
          
                              const deltaX8 = x - vp4.x;
                              const deltaY8 = y - vp4.y;
                              const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                              let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                              angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                              const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;
          
                              const horizontalAngle = layers[magnetLayer].tilt;
                              const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                              snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                              break;
                            case "Grid":
                              snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                              break;
                            case "Isometric":
                              snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                              break;
                            case "Ellipse":
                              snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                              break;
                          }
                        }
  
                        const deltaX = x - lastCoordinate.current.x;
                        const deltaY = y - lastCoordinate.current.y;
  
                        if(!moveAngles.current && (shiftKey || shiftButton)){
                          const angleInRadians = Math.atan2(deltaY, deltaX);
                          let angleInDegrees = angleInRadians * (180 / Math.PI);
                          angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
                          const snapAngle1 = closestAngle(angleInDegrees, snapAngles);
                          const snapAngle2 = (((snapAngle1 - 180) % 360) + 360) % 360;
                          moveAngles.current = [snapAngle1, snapAngle2];
                        }
          
                        if(moveAngles.current){
                          const snapAngle = closestAngleCoord(lastCoordinate.current, {x, y} , moveAngles.current);
                          const angleInRadians = snapAngle * (Math.PI / 180);
                          const length = deltaX * Math.cos(angleInRadians) + deltaY * Math.sin(angleInRadians);
                          const snappedX = guidePoints.centerX + length * Math.cos(angleInRadians);
                          const snappedY = guidePoints.centerY + length * Math.sin(angleInRadians);
                          setGuidePoints({centerX: snappedX, centerY: snappedY, angle: layer.angle, angle2: layer.angle2});
                        } else {
                          setGuidePoints({centerX: x, centerY: y, angle: layer.angle, angle2: layer.angle2});
                        }
                        break;
                      case 1:
                        const verticalVP = deriveVanishingPoints(layer.center, layer.focalLength, layer.tilt + 90, layer.angle2);
                        const vp3 = verticalVP.vp1;
                        const angle = deriveAngleFromCursor({x: vp3.x, y: vp3.y}, layer.focalLength, layer.tilt, {x: x, y: y});
                        setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle, angle2: layer.angle2});
                        break;
                      case 2:
                        const verticalVP2 = deriveVanishingPoints(layer.center, layer.focalLength, layer.tilt + 90, layer.angle2);
                        const vp2Two = verticalVP2.vp1;
                        const angle2 = deriveAngleFromCursor2({x: vp2Two.x, y: vp2Two.y}, layer.focalLength, layer.tilt, {x: x, y: y});
                        setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle: angle2, angle2: layer.angle2});
                        break;
                      case 3:
                        const angle3 = deriveAngleFromCursor(layer.center, layer.focalLength, layer.tilt + 90, {x: x, y: y});
                        setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle: layer.angle, angle2: angle3});
                        break;
                      case 4:
                        const angle4 = deriveAngleFromCursor2(layer.center, layer.focalLength, layer.tilt + 90, {x: x, y: y});
                        setGuidePoints({centerX: layer.center.x, centerY: layer.center.y, angle: layer.angle, angle2: angle4});
                        break;
                    }
                    break;
                  case "Grid":
                    if(guidePoints){
                      let snapAngles = [0,90,180,270];

                      if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                        let startPoint;
                        switch(nearGrid){
                          default:
                            startPoint = {x: guidePoints.cornerX, y: guidePoints.cornerY};
                            break;
                          case 0:
                            startPoint = {x: x, y: y};
                            break;
                        }
                        
                        let vp1,vp2,vp3,vp4;
                        switch(layers[magnetLayer].type){
                          case "Line":
                            const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                            const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                            const angleInRadians = Math.atan2(deltaY, deltaX);
                            let angleInDegrees = angleInRadians * (180 / Math.PI);
                            angleInDegrees = ((angleInDegrees % 360) + 360) % 360;

                            snapAngles = [angleInDegrees];
                            for(let i = 0; i < 3; i++){
                              angleInDegrees += 90;
                              snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                            }
                            break;
                          case "OnePoint":
                            const deltaX2 = startPoint.x - layers[magnetLayer].point.x;
                            const deltaY2 = startPoint.y - layers[magnetLayer].point.y;
                            const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                            let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                            angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;
                            const otherAngle = (((angleInDegrees2 - 180) % 360) + 360) % 360;
        
                            snapAngles = [angleInDegrees2, otherAngle];
                            break;
                          case "TwoPoint":                            
                            const vanishingPoints = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                            vp1 = vanishingPoints.vp1;
                            vp2 = vanishingPoints.vp2;
        
                            const deltaX3 = startPoint.x - vp1.x;
                            const deltaY3 = startPoint.y - vp1.y;
                            const angleInRadians3 = Math.atan2(deltaY3, deltaX3);
                            let angleInDegrees3 = angleInRadians3 * (180 / Math.PI);
                            angleInDegrees3 = ((angleInDegrees3 % 360) + 360) % 360;
                            const otherAngle3 = (((angleInDegrees3 - 180) % 360) + 360) % 360;
        
                            const deltaX4 = startPoint.x - vp2.x;
                            const deltaY4 = startPoint.y - vp2.y;
                            const angleInRadians4 = Math.atan2(deltaY4, deltaX4);
                            let angleInDegrees4 = angleInRadians4 * (180 / Math.PI);
                            angleInDegrees4 = ((angleInDegrees4 % 360) + 360) % 360;
                            const otherAngle4 = (((angleInDegrees4 - 180) % 360) + 360) % 360;
        
                            snapAngles = [angleInDegrees3, otherAngle3, angleInDegrees4, otherAngle4];
                            break;
                          case "ThreePoint":
                            const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                            vp3 = vanishingPoints2.vp1;
                            vp4 = vanishingPoints2.vp2;
                            const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                            vp1 = vanishingPoints3.vp1;
                            vp2 = vanishingPoints3.vp2;
        
                            const deltaX5 = startPoint.x - vp1.x;
                            const deltaY5 = startPoint.y - vp1.y;
                            const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                            let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                            angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                            const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;
        
                            const deltaX6 = startPoint.x - vp2.x;
                            const deltaY6 = startPoint.y - vp2.y;
                            const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                            let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                            angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                            const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;
        
                            const deltaX8 = startPoint.x - vp4.x;
                            const deltaY8 = startPoint.y - vp4.y;
                            const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                            let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                            angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                            const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;
        
                            const horizontalAngle = layers[magnetLayer].tilt;
                            const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                            snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                            break;
                          case "Grid":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                            break;
                          case "Isometric":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                            break;
                          case "Ellipse":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                            break;
                        }
                      }
                      
                      if(nearGrid === 0){
                        const deltaX = x - lastCoordinate.current.x;
                        const deltaY = y - lastCoordinate.current.y;

                        if(!moveAngles.current && (shiftKey || shiftButton)){
                          const angleInRadians = Math.atan2(deltaY, deltaX);
                          let angleInDegrees = angleInRadians * (180 / Math.PI);
                          angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
                          const snapAngle1 = closestAngle(angleInDegrees, snapAngles);
                          const snapAngle2 = (((snapAngle1 - 180) % 360) + 360) % 360;
                          moveAngles.current = [snapAngle1, snapAngle2];
                        }
          
                        if(moveAngles.current){
                          const snapAngle = closestAngleCoord(lastCoordinate.current, {x, y }, moveAngles.current);
                          const angleInRadians = snapAngle * (Math.PI / 180);
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                          const snappedX = length * Math.cos(angleInRadians);
                          const snappedY = length * Math.sin(angleInRadians);
                          setGuidePoints({cornerX: guidePoints.cornerX + snappedX, cornerY: guidePoints.cornerY + snappedY, width: guidePoints.width, height: guidePoints.height, angle: guidePoints.angle});
                        } else {
                          setGuidePoints({cornerX: x, cornerY: y, width: guidePoints.width, height: guidePoints.height, angle: guidePoints.angle});
                        }
                      } else if(nearGrid === 1){
                        if(shiftKey || shiftButton){
                          const snapAngle = closestAngleCoord({x: guidePoints.cornerX, y: guidePoints.cornerY}, {x: x, y: y}, snapAngles);
                          const angleInRadians = snapAngle * (Math.PI / 180);
                          const deltaX = x - guidePoints.cornerX;
                          const deltaY = y - guidePoints.cornerY;
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                      
                          setGuidePoints({cornerX: guidePoints.cornerX, cornerY: guidePoints.cornerY, width: length, height: guidePoints.height, angle: -snapAngle});
                        } else {
                          const deltaX = x - guidePoints.cornerX;
                          const deltaY = y - guidePoints.cornerY;
                          const angleInRadians = Math.atan2(deltaY, deltaX);
                          let angle = angleInRadians * (180 / Math.PI);
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                      
                          setGuidePoints({cornerX: guidePoints.cornerX, cornerY: guidePoints.cornerY, width: length, height: guidePoints.height, angle: -angle});
                        }
                      } else if(nearGrid === 2){
                        if(shiftKey || shiftButton){
                          const snapAngle = closestAngleCoord({x: guidePoints.cornerX, y: guidePoints.cornerY}, {x: x, y: y}, snapAngles);
                          const angleInRadians = snapAngle * (Math.PI / 180);
                          const deltaX = x - guidePoints.cornerX;
                          const deltaY = y - guidePoints.cornerY;
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                      
                          setGuidePoints({cornerX: guidePoints.cornerX, cornerY: guidePoints.cornerY, width: guidePoints.width, height: length, angle: -snapAngle + 90});
                        } else {
                          const deltaX = x - guidePoints.cornerX;
                          const deltaY = y - guidePoints.cornerY;
                          const angleInRadians = Math.atan2(deltaY, deltaX);
                          let angle = angleInRadians * (180 / Math.PI);
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                      
                          setGuidePoints({cornerX: guidePoints.cornerX, cornerY: guidePoints.cornerY, width: guidePoints.width, height: length, angle: -angle + 90});
                        }
                      }
                    }
                    break;
                  case "Isometric":
                    if(guidePoints){
                      let snapAngles = [0,90,180,270];
  
                      if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                        let startPoint;
                        switch(nearGrid){
                          default:
                            startPoint = {x: guidePoints.cornerX, y: guidePoints.cornerY};
                            break;
                          case 0:
                            startPoint = {x: x, y: y};
                            break;
                        }
                        
                        let vp1,vp2,vp3,vp4;
                        switch(layers[magnetLayer].type){
                          case "Line":
                            const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                            const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                            const angleInRadians = Math.atan2(deltaY, deltaX);
                            let angleInDegrees = angleInRadians * (180 / Math.PI);
                            angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
  
                            snapAngles = [angleInDegrees];
                            for(let i = 0; i < 3; i++){
                              angleInDegrees += 90;
                              snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                            }
                            break;
                          case "OnePoint":
                            const deltaX2 = startPoint.x - layers[magnetLayer].point.x;
                            const deltaY2 = startPoint.y - layers[magnetLayer].point.y;
                            const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                            let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                            angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;
                            const otherAngle = (((angleInDegrees2 - 180) % 360) + 360) % 360;
        
                            snapAngles = [angleInDegrees2, otherAngle];
                            break;
                          case "TwoPoint":                            
                            const vanishingPoints = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                            vp1 = vanishingPoints.vp1;
                            vp2 = vanishingPoints.vp2;
        
                            const deltaX3 = startPoint.x - vp1.x;
                            const deltaY3 = startPoint.y - vp1.y;
                            const angleInRadians3 = Math.atan2(deltaY3, deltaX3);
                            let angleInDegrees3 = angleInRadians3 * (180 / Math.PI);
                            angleInDegrees3 = ((angleInDegrees3 % 360) + 360) % 360;
                            const otherAngle3 = (((angleInDegrees3 - 180) % 360) + 360) % 360;
        
                            const deltaX4 = startPoint.x - vp2.x;
                            const deltaY4 = startPoint.y - vp2.y;
                            const angleInRadians4 = Math.atan2(deltaY4, deltaX4);
                            let angleInDegrees4 = angleInRadians4 * (180 / Math.PI);
                            angleInDegrees4 = ((angleInDegrees4 % 360) + 360) % 360;
                            const otherAngle4 = (((angleInDegrees4 - 180) % 360) + 360) % 360;
        
                            snapAngles = [angleInDegrees3, otherAngle3, angleInDegrees4, otherAngle4];
                            break;
                          case "ThreePoint":
                            const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                            vp3 = vanishingPoints2.vp1;
                            vp4 = vanishingPoints2.vp2;
                            const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                            vp1 = vanishingPoints3.vp1;
                            vp2 = vanishingPoints3.vp2;
        
                            const deltaX5 = startPoint.x - vp1.x;
                            const deltaY5 = startPoint.y - vp1.y;
                            const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                            let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                            angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                            const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;
        
                            const deltaX6 = startPoint.x - vp2.x;
                            const deltaY6 = startPoint.y - vp2.y;
                            const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                            let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                            angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                            const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;
        
                            const deltaX8 = startPoint.x - vp4.x;
                            const deltaY8 = startPoint.y - vp4.y;
                            const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                            let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                            angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                            const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;
        
                            const horizontalAngle = layers[magnetLayer].tilt;
                            const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                            snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                            break;
                          case "Grid":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                            break;
                          case "Isometric":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                            break;
                          case "Ellipse":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                            break;
                        }
                      }
                      
                      if(nearGrid === 0){
                        const deltaX = x - lastCoordinate.current.x;
                        const deltaY = y - lastCoordinate.current.y;

                        if(!moveAngles.current && (shiftKey || shiftButton)){
                          const angleInRadians = Math.atan2(deltaY, deltaX);
                          let angleInDegrees = angleInRadians * (180 / Math.PI);
                          angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
                          const snapAngle1 = closestAngle(angleInDegrees, snapAngles);
                          const snapAngle2 = (((snapAngle1 - 180) % 360) + 360) % 360;
                          moveAngles.current = [snapAngle1, snapAngle2];
                        }
          
                        if(moveAngles.current){
                          const snapAngle = closestAngleCoord(lastCoordinate.current, {x, y }, moveAngles.current);
                          const angleInRadians = snapAngle * (Math.PI / 180);
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                          const snappedX = length * Math.cos(angleInRadians);
                          const snappedY = length * Math.sin(angleInRadians);
                          setGuidePoints({cornerX: guidePoints.cornerX + snappedX, cornerY: guidePoints.cornerY + snappedY, width: guidePoints.width, height: guidePoints.height, angle: guidePoints.angle, angle2: guidePoints.angle2});
                        } else {
                          setGuidePoints({cornerX: x, cornerY: y, width: guidePoints.width, height: guidePoints.height, angle: guidePoints.angle, angle2: guidePoints.angle2});
                        }
                      } else if(nearGrid === 1){
                        if(shiftKey || shiftButton){
                          const snapAngle = closestAngleCoord({x: guidePoints.cornerX, y: guidePoints.cornerY}, {x: x, y: y}, snapAngles);
                          const angleInRadians = snapAngle * (Math.PI / 180);
                          const deltaX = x - guidePoints.cornerX;
                          const deltaY = y - guidePoints.cornerY;
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                      
                          setGuidePoints({cornerX: guidePoints.cornerX, cornerY: guidePoints.cornerY, width: guidePoints.width, height: length, angle: guidePoints.angle, angle2: -snapAngle});
                        } else {
                          const deltaX = x - guidePoints.cornerX;
                          const deltaY = y - guidePoints.cornerY;
                          const angleInRadians = Math.atan2(deltaY, deltaX);
                          let angle = angleInRadians * (180 / Math.PI);
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                      
                          setGuidePoints({cornerX: guidePoints.cornerX, cornerY: guidePoints.cornerY, width: guidePoints.width, height: length, angle: guidePoints.angle, angle2: -angle});
                        }
                      } else if(nearGrid === 2){
                        if(shiftKey || shiftButton){
                          const snapAngle = closestAngleCoord({x: guidePoints.cornerX, y: guidePoints.cornerY}, {x: x, y: y}, snapAngles);
                          const angleInRadians = snapAngle * (Math.PI / 180);
                          const deltaX = x - guidePoints.cornerX;
                          const deltaY = y - guidePoints.cornerY;
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                      
                          setGuidePoints({cornerX: guidePoints.cornerX, cornerY: guidePoints.cornerY, width: length, height: guidePoints.height, angle: -snapAngle, angle2: guidePoints.angle2});
                        } else {
                          const deltaX = x - guidePoints.cornerX;
                          const deltaY = y - guidePoints.cornerY;
                          const angleInRadians = Math.atan2(deltaY, deltaX);
                          let angle = angleInRadians * (180 / Math.PI);
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                      
                          setGuidePoints({cornerX: guidePoints.cornerX, cornerY: guidePoints.cornerY, width: length, height: guidePoints.height, angle: -angle, angle2: guidePoints.angle2});
                        }
                      }
                    }
                    break;
                  case "Ellipse":
                    if(guidePoints){
                      let snapAngles = [0,90,180,270];

                      if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                        let startPoint;
                        switch(nearEllipse){
                          default:
                            startPoint = {x: guidePoints.centerX, y: guidePoints.centerY};
                            break;
                          case 0:
                            startPoint = {x: x, y: y};
                            break;
                        }
                        
                        let vp1,vp2,vp3,vp4;
                        switch(layers[magnetLayer].type){
                          case "Line":
                            const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                            const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                            const angleInRadians = Math.atan2(deltaY, deltaX);
                            let angleInDegrees = angleInRadians * (180 / Math.PI);
                            angleInDegrees = ((angleInDegrees % 360) + 360) % 360;

                            snapAngles = [angleInDegrees];
                            for(let i = 0; i < 3; i++){
                              angleInDegrees += 90;
                              snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                            }
                            break;
                          case "OnePoint":
                            const deltaX2 = startPoint.x - layers[magnetLayer].point.x;
                            const deltaY2 = startPoint.y - layers[magnetLayer].point.y;
                            const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                            let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                            angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;
                            const otherAngle = (((angleInDegrees2 - 180) % 360) + 360) % 360;
        
                            snapAngles = [angleInDegrees2, otherAngle];
                            break;
                          case "TwoPoint":                            
                            const vanishingPoints = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                            vp1 = vanishingPoints.vp1;
                            vp2 = vanishingPoints.vp2;
        
                            const deltaX3 = startPoint.x - vp1.x;
                            const deltaY3 = startPoint.y - vp1.y;
                            const angleInRadians3 = Math.atan2(deltaY3, deltaX3);
                            let angleInDegrees3 = angleInRadians3 * (180 / Math.PI);
                            angleInDegrees3 = ((angleInDegrees3 % 360) + 360) % 360;
                            const otherAngle3 = (((angleInDegrees3 - 180) % 360) + 360) % 360;
        
                            const deltaX4 = startPoint.x - vp2.x;
                            const deltaY4 = startPoint.y - vp2.y;
                            const angleInRadians4 = Math.atan2(deltaY4, deltaX4);
                            let angleInDegrees4 = angleInRadians4 * (180 / Math.PI);
                            angleInDegrees4 = ((angleInDegrees4 % 360) + 360) % 360;
                            const otherAngle4 = (((angleInDegrees4 - 180) % 360) + 360) % 360;
        
                            snapAngles = [angleInDegrees3, otherAngle3, angleInDegrees4, otherAngle4];
                            break;
                          case "ThreePoint":
                            const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                            vp3 = vanishingPoints2.vp1;
                            vp4 = vanishingPoints2.vp2;
                            const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                            vp1 = vanishingPoints3.vp1;
                            vp2 = vanishingPoints3.vp2;
        
                            const deltaX5 = startPoint.x - vp1.x;
                            const deltaY5 = startPoint.y - vp1.y;
                            const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                            let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                            angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                            const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;
        
                            const deltaX6 = startPoint.x - vp2.x;
                            const deltaY6 = startPoint.y - vp2.y;
                            const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                            let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                            angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                            const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;
        
                            const deltaX8 = startPoint.x - vp4.x;
                            const deltaY8 = startPoint.y - vp4.y;
                            const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                            let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                            angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                            const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;
        
                            const horizontalAngle = layers[magnetLayer].tilt;
                            const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                            snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                            break;
                          case "Grid":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                            break;
                          case "Isometric":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                            break;
                          case "Ellipse":
                            snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                            break;
                        }
                      }
                      
                      if(nearEllipse === 0){
                        const deltaX = x - lastCoordinate.current.x;
                        const deltaY = y - lastCoordinate.current.y;

                        if(!moveAngles.current && (shiftKey || shiftButton)){
                          const angleInRadians = Math.atan2(deltaY, deltaX);
                          let angleInDegrees = angleInRadians * (180 / Math.PI);
                          angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
                          const snapAngle1 = closestAngle(angleInDegrees, snapAngles);
                          const snapAngle2 = (((snapAngle1 - 180) % 360) + 360) % 360;
                          moveAngles.current = [snapAngle1, snapAngle2];
                        }
          
                        if(moveAngles.current){
                          const snapAngle = closestAngleCoord(lastCoordinate.current, {x, y }, moveAngles.current);
                          const angleInRadians = snapAngle * (Math.PI / 180);
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                          const snappedX = length * Math.cos(angleInRadians);
                          const snappedY = length * Math.sin(angleInRadians);
                          setGuidePoints({centerX: guidePoints.centerX + snappedX, centerY: guidePoints.centerY + snappedY, width: guidePoints.width, height: guidePoints.height, angle: guidePoints.angle});
                        } else {
                          setGuidePoints({centerX: x, centerY: y, width: guidePoints.width, height: guidePoints.height, angle: guidePoints.angle});
                        }
                      } else if(nearEllipse === 1){
                        if(shiftKey || shiftButton){
                          const snapAngle = closestAngleCoord({x: guidePoints.centerX, y: guidePoints.centerY}, {x: x, y: y}, snapAngles);
                          const deltaX = x - guidePoints.centerX;
                          const deltaY = y - guidePoints.centerY;
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                      
                          setGuidePoints({centerX: guidePoints.centerX, centerY: guidePoints.centerY, width: length * 2, height: guidePoints.height, angle: -snapAngle});
                        } else {
                          const deltaX = x - guidePoints.centerX;
                          const deltaY = y - guidePoints.centerY;
                          const angleInRadians = Math.atan2(deltaY, deltaX);
                          let angle = angleInRadians * (180 / Math.PI);
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                      
                          setGuidePoints({centerX: guidePoints.centerX, centerY: guidePoints.centerY, width: length * 2, height: guidePoints.height, angle: -angle});
                        }
                      } else if(nearEllipse === 2){
                        if(shiftKey || shiftButton){                      
                          setGuidePoints({centerX: guidePoints.centerX, centerY: guidePoints.centerY, width: guidePoints.width, height: guidePoints.width, angle: guidePoints.angle});
                        } else {
                          const deltaX = x - guidePoints.centerX;
                          const deltaY = y - guidePoints.centerY;
                          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                      
                          setGuidePoints({centerX: guidePoints.centerX, centerY: guidePoints.centerY, width: guidePoints.width, height: length * 2, angle: guidePoints.angle});
                        }
                      }
                    }
                    break;
                }
              }
            } else if (selectedTool === "pencil" || selectedTool === "erase") { 
              if(cursorStartPoint){
                const deltaX = x - cursorStartPoint.x;
                const deltaY = y - cursorStartPoint.y;
                const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                const diameter = Math.min(64, Math.round(length*2)) || 1
                if(selectedTool === "pencil"){
                  setBrushDiameter(diameter);
                } else {
                  setEraseDiameter(diameter);
                }

                setDetails(`${diameter} px`);

                const resizeStartX = Math.round(cursorStartPoint.x) - Math.round(cursorLayer.current.width / 2);
                const resizeStartY = Math.round(cursorStartPoint.y) - Math.round(cursorLayer.current.height / 2);
                
                if(cursorEdges && cursorEdges.length > 0){
                  const blackPaths = cursorEdges.map((path, index) => {
                    let d = ""
                    path.forEach(edge => {
                        if (d === "") {
                            d += `M ${resizeStartX + edge.x1},${resizeStartY + edge.y1} `;
                        }
                        d += `L ${resizeStartX + edge.x2},${resizeStartY + edge.y2} `;
                    });
                    d += `L ${resizeStartX + path[0].x1},${resizeStartY + path[0].y1} Z`;
          
                    return (
                      <path key={`black-${index}`} d={d} fill="none" stroke="black" strokeWidth={3/window.devicePixelRatio} vectorEffect="non-scaling-stroke" />
                    );
                  });
                  
                  const whitePaths = cursorEdges.map((path, index) => {
                    let d = ""
                    path.forEach(edge => {
                        if (d === "") {
                            d += `M ${resizeStartX + edge.x1},${resizeStartY + edge.y1} `;
                        }
                        d += `L ${resizeStartX + edge.x2},${resizeStartY + edge.y2} `;
                    });
                    d += `L ${resizeStartX + path[0].x1},${resizeStartY + path[0].y1} Z`;
          
                    return (
                      <path key={`white-${index}`} d={d} fill="none" stroke="white" strokeWidth={1.5/window.devicePixelRatio} vectorEffect="non-scaling-stroke" />
                    );
                  });
    
                  setCursorPath([blackPaths,whitePaths]);
                }
              } else {
                const ditherLayer = document.createElement("canvas");
                ditherLayer.width = resolution.width;
                ditherLayer.height = resolution.height;
                const ditherCtx = ditherLayer.getContext("2d");
                ditherCtx.imageSmoothingEnabled = false;

                let oX = (ditherOffsetX - offset.x) % 4;
                let oY = (ditherOffsetY - offset.y) % 4;

                if(cursorEdges && cursorEdges.length > 0){
                  const blackPaths = cursorEdges.map((path, index) => {
                    let d = ""
                    path.forEach(edge => {
                        if (d === "") {
                            d += `M ${startX + edge.x1},${startY + edge.y1} `;
                        }
                        d += `L ${startX + edge.x2},${startY + edge.y2} `;
                    });
                    d += `L ${startX + path[0].x1},${startY + path[0].y1} Z`;
          
                    return (
                      <path key={`black-${index}`} d={d} fill="none" stroke="black" strokeWidth={3/window.devicePixelRatio} vectorEffect="non-scaling-stroke" />
                    );
                  });
                  
                  const whitePaths = cursorEdges.map((path, index) => {
                    let d = ""
                    path.forEach(edge => {
                        if (d === "") {
                            d += `M ${startX + edge.x1},${startY + edge.y1} `;
                        }
                        d += `L ${startX + edge.x2},${startY + edge.y2} `;
                    });
                    d += `L ${startX + path[0].x1},${startY + path[0].y1} Z`;
          
                    return (
                      <path key={`white-${index}`} d={d} fill="none" stroke="white" strokeWidth={1.5/window.devicePixelRatio} vectorEffect="non-scaling-stroke" />
                    );
                  });
    
                  setCursorPath([blackPaths,whitePaths]);
                }

                if(isDrawing.current && lastDraw.current && (!altKey && !altButton)){                  
                  if(shiftKey || shiftButton){
                    if(!startSnap.current) startSnap.current = lastDraw.current;
                  } else{
                    if(startSnap.current) startSnap.current = null;
                  }

                  
                  const getTarget = (point) => {
                    return diameter % 2 === 0 ? {x: point.x - 0.5, y: point.y - 0.5} : {x: Math.floor(point.x) + 0.5, y: Math.floor(point.y) + 0.5};
                  }; 

                  let snapAngles = [0,90,180,270];
                  
                  if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas" && startSnap.current){
                    let vp1, vp2, vp3, vp4;
                    switch(layers[magnetLayer].type){
                      case "Line":
                        const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                        const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                        const angleInRadians = Math.atan2(deltaY, deltaX);
                        let angleInDegrees = angleInRadians * (180 / Math.PI);
                        angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
    
                        snapAngles = [angleInDegrees];
                        for(let i = 0; i < 3; i++){
                          angleInDegrees += 90;
                          snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                        }
                        break;
                      case "OnePoint":
                        const target2 = getTarget(layers[magnetLayer].point);
                        const deltaX2 = startSnap.current.x - target2.x;
                        const deltaY2 = startSnap.current.y - target2.y;
                        const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                        let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                        angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;
    
                        snapAngles = [angleInDegrees2];
                        break;
                      case "ThreePoint":
                        const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                        vp3 = vanishingPoints2.vp1;
                        vp4 = vanishingPoints2.vp2;
                        const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                        vp1 = vanishingPoints3.vp1;
                        vp2 = vanishingPoints3.vp2;
                        const target5 = getTarget(vp1);
                        const target6 = getTarget(vp2);
                        const target8 = getTarget(vp4);
    
                        const deltaX5 = startSnap.current.x - target5.x;
                        const deltaY5 = startSnap.current.y - target5.y;
                        const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                        let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                        angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                        const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;
    
                        const deltaX6 = startSnap.current.x - target6.x;
                        const deltaY6 = startSnap.current.y - target6.y;
                        const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                        let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                        angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                        const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;
    
                        const deltaX8 = startSnap.current.x - target8.x;
                        const deltaY8 = startSnap.current.y - target8.y;
                        const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                        let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                        angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                        const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;
    
                        const horizontalAngle = layers[magnetLayer].tilt;
                        const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                        snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                        break;
                      case "Grid":
                        snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                        break;
                      case "Isometric":
                        snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                        break;
                    }
                  }

                  const ellipseSnap = magnetLayer !== null && layers[magnetLayer].type !== "Canvas" && layers[magnetLayer].type === "Ellipse";

                  if(ellipseSnap){
                    if(shiftKey || shiftButton){
                      if(!ellipseDistance.current) ellipseDistance.current = getRatioToEllipseEdge(layers[magnetLayer], {x : lastDraw.current.x, y: lastDraw.current.y});
                    } else {  
                      ellipseDistance.current = null;
                    }
                  } else {
                    if(!moveAngles.current && startSnap.current){
                      const deltaX = x - startSnap.current.x;
                      const deltaY = y - startSnap.current.y;
                      const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

                      if(length > 0.5){
                        const angleInRadians = Math.atan2(deltaY, deltaX);
                        let angleInDegrees = angleInRadians * (180 / Math.PI);
                        angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
                        const snapAngle1 = closestAngle(angleInDegrees, snapAngles);
                        moveAngles.current = [snapAngle1];
                      }
                    }
      
                    if(moveAngles.current){
                      const angleInRadians = moveAngles.current[0] * (Math.PI / 180);
                  
                      // Calculate snapped coordinates based on the snap angle and length
                      const deltaX = x - lastDraw.current.x;
                      const deltaY = y - lastDraw.current.y;
                      const length = deltaX * Math.cos(angleInRadians) + deltaY * Math.sin(angleInRadians);
                      x = lastDraw.current.x + length * Math.cos(angleInRadians);
                      y = lastDraw.current.y + length * Math.sin(angleInRadians);
                    }
                  }
                    
                  const getNewMidPoint = (x, y, diam) => {
                    if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas" && layers[magnetLayer].type === "Ellipse" && ellipseDistance.current){
                      
                      return getPointAtRatioFromEllipseEdge(layers[magnetLayer], {x: x, y: y}, ellipseDistance.current, diam);
                    } else {
                      return {x, y}
                    }
                  }

                  //get distance since last draw;
                  const dist = distance({ x, y }, lastDraw.current);
                  
                  // Calculate the number of iterations needed with optimized spacing
                  const iterations = Math.ceil(dist / diameter * 8);
      
                  //get the change of each itteration
                  const delta = {
                    x: (x - lastDraw.current.x) / iterations || 0,
                    y: (y - lastDraw.current.y) / iterations || 0,
                    pressure: (pressure - lastDraw.current.pressure) / iterations || 0
                  };

                  if(delta.pressure < -0.35){
                    delta.pressure = 0;
                    pressureChange = false;
                  }                  

                  if(!ellipseSnap && (selectedTool === "pencil" && brushDiameter === 1 && brushPixelPerfect) || (selectedTool === "erase" && eraseDiameter === 1 && erasePixelPerfect)){
                    const ctx = canvases[selectedLayer].current.getContext("2d");
                    ctx.imageSmoothingEnabled = false;
                    ctx.fillStyle = palette[selectedSwatch];

                    //set operation for current canvas based on tool settings
                    if(selectedTool === "erase"){
                      ctx.globalCompositeOperation = "destination-out";
                    } else {
                      ctx.globalCompositeOperation = "source-over";
                    }

                    const mouseX = Math.floor(x);
                    const mouseY = Math.floor(y);

                    if((selectedTool === "pencil" && brushMode === "select") || (selectedTool === "erase" && eraseMode === "select")){
                      const selectionCtx = selectionLayer.current.getContext("2d");
                      selectionCtx.imageSmoothingEnabled = false;
      
                      //set operation for selection based on tool settings
                      selectionCtx.globalCompositeOperation = selectedTool === "erase" ? "destination-out" : "source-over";
                      
                    
                      if (lastPixel.current.x !== mouseX || lastPixel.current.y !== mouseY) {
                        if (Math.abs(mouseX - lastPixel.current.x) > 1 || Math.abs(mouseY - lastPixel.current.y) > 1){
                          pixelLine(selectionCtx, lastPixel.current.x, lastPixel.current.y, mouseX, mouseY);
                        } else {                        
                          //if currentPixel not neighbor to lastDrawn, draw waitingpixel
                          if (Math.abs(mouseX - lastDrawnPixel.current.x) > 1 || Math.abs(mouseY - lastDrawnPixel.current.y) > 1) {
                            selectionCtx.fillRect(waitingPixel.current.x, waitingPixel.current.y, 1, 1);
                            
                            //update queue
                            lastDrawnPixel.current = waitingPixel.current;
                            waitingPixel.current = {x: mouseX, y: mouseY};
                          } else {
                            waitingPixel.current = {x: mouseX, y: mouseY};
                          }
                        }
                      }

                      setUpdateSelection((prevValue) => prevValue + 1);
                      
                    } else if(!hasSelection){  
                      if((selectedTool === "pencil" && brushMode === "dither") || (selectedTool ==="erase" && eraseMode === "dither")){
                        const tempCanvas = document.createElement("canvas");
                        tempCanvas.width = resolution.width;
                        tempCanvas.height = resolution.height;
                        const tempCtx = tempCanvas.getContext("2d");
                        tempCtx.imageSmoothingEnabled = false;

                        if(selectedTool !== "erase" && selectedSwatch === 32 && secondarySwatch === 32) return;

                        ditherCtx.clearRect(0,0,resolution.width,resolution.height);

                        ditherCtx.globalCompositeOperation = "source-over";
                        tempCtx.fillStyle = palette[secondarySwatch];
                        if (lastPixel.current.x !== mouseX || lastPixel.current.y !== mouseY) {
                          if (Math.abs(mouseX - lastPixel.current.x) > 1 || Math.abs(mouseY - lastPixel.current.y) > 1){
                            pixelLine(tempCtx, lastPixel.current.x, lastPixel.current.y, mouseX, mouseY);
                          } else {                        
                            //if currentPixel not neighbor to lastDrawn, draw waitingpixel
                            if (Math.abs(mouseX - lastDrawnPixel.current.x) > 1 || Math.abs(mouseY - lastDrawnPixel.current.y) > 1) {
                              tempCtx.fillRect(waitingPixel.current.x, waitingPixel.current.y, 1, 1);
                            } 
                          }
                        }
                        ditherCtx.drawImage(tempCanvas,0,0);
                        
                        let ratio;
                        if(e.pointerType === "pen"){
                          if(selectedTool === "pencil"){
                            if(ditherPressureMode === "ratio"){
                              ratio = Math.round(16 * pressure);
                            } else {
                              ratio = ditherRatio;
                            }
                          } else {
                            if(eraseDitherPressureMode === "ratio"){
                              ratio = Math.round(16 * pressure);
                              ratio = invertEraseDither ? ratio : 16 - ratio;
                            } else {
                              ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                            }
                          }
                        } else if(selectedTool === "erase"){
                          ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                        } else {
                          ratio = ditherRatio;
                        }

                        ditherCtx.globalCompositeOperation = "destination-out";
                        for(let j = -4; j < resolution.width; j += 68){
                          for(let k = -4; k < resolution.height; k += 68){
                            ditherCtx.drawImage(
                              dither.current,
                              ratio * 68,
                              0,
                              68,
                              68,
                              j + oX,
                              k + oY,
                              68,
                              68
                            );
                          }
                        }
                        if(secondarySwatch !== 32 || selectedTool === "erase"){
                          ctx.drawImage(ditherLayer, 0, 0);
                        }

                        if(selectedSwatch !== 32 && selectedTool !== "erase"){
                          ditherCtx.globalCompositeOperation = "source-out";
                          tempCtx.fillStyle = palette[selectedSwatch];     
                          if (lastPixel.current.x !== mouseX || lastPixel.current.y !== mouseY) {
                            if (Math.abs(mouseX - lastPixel.current.x) > 1 || Math.abs(mouseY - lastPixel.current.y) > 1){
                              pixelLine(tempCtx, lastPixel.current.x, lastPixel.current.y, mouseX, mouseY);
                            } else {                        
                              //if currentPixel not neighbor to lastDrawn, draw waitingpixel
                              if (Math.abs(mouseX - lastDrawnPixel.current.x) > 1 || Math.abs(mouseY - lastDrawnPixel.current.y) > 1) {
                                tempCtx.fillRect(waitingPixel.current.x, waitingPixel.current.y, 1, 1);
                                
                                //update queue
                                lastDrawnPixel.current = waitingPixel.current;
                                waitingPixel.current = {x: mouseX, y: mouseY};
                              } else {
                                waitingPixel.current = {x: mouseX, y: mouseY};
                              }
                            }
                          }
                          ditherCtx.drawImage(tempCanvas,0,0);
                          ctx.drawImage(ditherLayer, 0, 0);
                        }
                      } else if(selectedSwatch !== 32 || selectedTool === "erase"){           
                        if (lastPixel.current.x !== mouseX || lastPixel.current.y !== mouseY) {
                          if (Math.abs(mouseX - lastPixel.current.x) > 1 || Math.abs(mouseY - lastPixel.current.y) > 1){
                            pixelLine(ctx, lastPixel.current.x, lastPixel.current.y, mouseX, mouseY);
                          } else {                        
                            //if currentPixel not neighbor to lastDrawn, draw waitingpixel
                            if (Math.abs(mouseX - lastDrawnPixel.current.x) > 1 || Math.abs(mouseY - lastDrawnPixel.current.y) > 1) {
                              ctx.fillRect(waitingPixel.current.x, waitingPixel.current.y, 1, 1);
                              
                              //update queue
                              lastDrawnPixel.current = waitingPixel.current;
                              waitingPixel.current = {x: mouseX, y: mouseY};
                            } else {
                              waitingPixel.current = {x: mouseX, y: mouseY};
                            }
                          }
                        }
                      }
                    } else {
                      const tempCanvas = document.createElement("canvas");
                      tempCanvas.width = resolution.width;
                      tempCanvas.height = resolution.height;
                      const tempCtx = tempCanvas.getContext("2d");
                      tempCtx.imageSmoothingEnabled = false;

                      combinationLayer.current.width = resolution.width;
                      combinationLayer.current.height = resolution.height;
                      const combinationCtx = combinationLayer.current.getContext("2d");
                      combinationCtx.imageSmoothingEnabled = false;
                      combinationCtx.globalCompositeOperation = "source-over";
                      combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                      combinationCtx.drawImage(selectionLayer.current,0,0);
                      combinationCtx.globalCompositeOperation = "source-in";
                      combinationCtx.fillStyle = palette[selectedSwatch];

                      if((selectedTool === "pencil" && brushMode === "dither") || (selectedTool === "erase" && eraseMode === "dither")){ 
                        if(selectedTool !== "erase" && selectedSwatch === 32 && secondarySwatch === 32) return;

                        ditherCtx.clearRect(0,0,resolution.width,resolution.height);

                        ditherCtx.globalCompositeOperation = "source-over";
                        tempCtx.fillStyle = palette[secondarySwatch];
                        if (lastPixel.current.x !== mouseX || lastPixel.current.y !== mouseY) {
                          if (Math.abs(mouseX - lastPixel.current.x) > 1 || Math.abs(mouseY - lastPixel.current.y) > 1){
                            pixelLine(tempCtx, lastPixel.current.x, lastPixel.current.y, mouseX, mouseY);
                          } else {                        
                            //if currentPixel not neighbor to lastDrawn, draw waitingpixel
                            if (Math.abs(mouseX - lastDrawnPixel.current.x) > 1 || Math.abs(mouseY - lastDrawnPixel.current.y) > 1) {
                              tempCtx.fillRect(waitingPixel.current.x, waitingPixel.current.y, 1, 1);
                            } 
                          }
                        }
                        ditherCtx.drawImage(tempCanvas,0,0);
                        
                        let ratio;
                        if(e.pointerType === "pen"){
                          if(selectedTool === "pencil"){
                            if(ditherPressureMode === "ratio"){
                              ratio = Math.round(16 * pressure);
                            } else {
                              ratio = ditherRatio;
                            }
                          } else {
                            if(eraseDitherPressureMode === "ratio"){
                              ratio = Math.round(16 * pressure);
                              ratio = invertEraseDither ? ratio : 16 - ratio;
                            } else {
                              ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                            }
                          }
                        } else if(selectedTool === "erase"){
                          ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                        } else {
                          ratio = ditherRatio;
                        }

                        ditherCtx.globalCompositeOperation = "destination-out";
                        for(let j = -4; j < resolution.width; j += 68){
                          for(let k = -4; k < resolution.height; k += 68){
                            ditherCtx.drawImage(
                              dither.current,
                              ratio * 68,
                              0,
                              68,
                              68,
                              j + oX,
                              k + oY,
                              68,
                              68
                            );
                          }
                        }
                        if(secondarySwatch !== 32 || selectedTool === "erase"){
                          combinationCtx.drawImage(ditherLayer,0,0);
                          ctx.drawImage(combinationLayer.current, 0, 0);
                        }

                        if(selectedSwatch !== 32 && selectedTool !== "erase"){
                          combinationCtx.globalCompositeOperation = "source-over";
                          combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                          combinationCtx.drawImage(selectionLayer.current,0,0);
                          combinationCtx.globalCompositeOperation = "source-in";

                          ditherCtx.globalCompositeOperation = "source-out";
                          tempCtx.fillStyle = palette[selectedSwatch];     
                          if (lastPixel.current.x !== mouseX || lastPixel.current.y !== mouseY) {
                            if (Math.abs(mouseX - lastPixel.current.x) > 1 || Math.abs(mouseY - lastPixel.current.y) > 1){
                              pixelLine(tempCtx, lastPixel.current.x, lastPixel.current.y, mouseX, mouseY);
                            } else {                        
                              //if currentPixel not neighbor to lastDrawn, draw waitingpixel
                              if (Math.abs(mouseX - lastDrawnPixel.current.x) > 1 || Math.abs(mouseY - lastDrawnPixel.current.y) > 1) {
                                tempCtx.fillRect(waitingPixel.current.x, waitingPixel.current.y, 1, 1);
                                
                                //update queue
                                lastDrawnPixel.current = waitingPixel.current;
                                waitingPixel.current = {x: mouseX, y: mouseY};
                              } else {
                                waitingPixel.current = {x: mouseX, y: mouseY};
                              }
                            }
                          }
                          ditherCtx.drawImage(tempCanvas,0,0);
                          combinationCtx.drawImage(ditherLayer,0,0);
                          ctx.drawImage(combinationLayer.current, 0, 0);
                        }
                      } else if(selectedSwatch !== 32 || selectedTool === "erase"){                                    
                        if (lastPixel.current.x !== mouseX || lastPixel.current.y !== mouseY) {
                          if (Math.abs(mouseX - lastPixel.current.x) > 1 || Math.abs(mouseY - lastPixel.current.y) > 1){
                            pixelLine(tempCtx, lastPixel.current.x, lastPixel.current.y, mouseX, mouseY);
                          } else {                        
                            //if currentPixel not neighbor to lastDrawn, draw waitingpixel
                            if (Math.abs(mouseX - lastDrawnPixel.current.x) > 1 || Math.abs(mouseY - lastDrawnPixel.current.y) > 1) {
                              tempCtx.fillRect(waitingPixel.current.x, waitingPixel.current.y, 1, 1);
                              
                              //update queue
                              lastDrawnPixel.current = waitingPixel.current;
                              waitingPixel.current = {x: mouseX, y: mouseY};
                            } else {
                              waitingPixel.current = {x: mouseX, y: mouseY};
                            }
                          }
                        }
                      }

                      combinationCtx.drawImage(tempCanvas,0,0);
                      ctx.drawImage(combinationLayer.current, 0, 0);
                    }
                      
                    // save last point
                    lastPixel.current = {x: mouseX, y: mouseY};
                    lastDraw.current=({x,y,pressure});
                    lastPoint.current = {x: lastDraw.current.x, y: lastDraw.current.y, pressure: lastDraw.current.pressure};
                    return;
                  }
      
                  if((selectedTool === "pencil" && brushMode === "select") || (selectedTool === "erase" && eraseMode === "select")){

                    const selectionCtx = selectionLayer.current.getContext("2d");
                    selectionCtx.imageSmoothingEnabled = false;
      
                    //set operation for selection based on tool settings
                    selectionCtx.globalCompositeOperation = selectedTool === "erase" ? "destination-out" : "source-over";
      
                    //draw line
                    for (let i = 1; i <= iterations; i++) {
                      const adjustedDiameter = pressureSetting
                        ? Math.round(
                          diameter * (lastDraw.current.pressure + delta.pressure * i) || 1
                          )
                        : diameter;

                      const midPoint = getNewMidPoint(lastDraw.current.x + delta.x * i, lastDraw.current.y + delta.y * i, adjustedDiameter);

                      selectionCtx.drawImage(
                        stencil.current,
                        summate(adjustedDiameter) - adjustedDiameter,
                        maxCursorSize * selectedSwatch,
                        adjustedDiameter,
                        adjustedDiameter,
                        Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                        Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                        adjustedDiameter,
                        adjustedDiameter
                      );
                    }

                    setUpdateSelection((prevValue) => prevValue + 1);
                  } else {
                    const ctx = canvases[selectedLayer].current.getContext("2d");
                    ctx.imageSmoothingEnabled = false;

                    //set operation for current canvas based on tool settings
                    if(selectedTool === "erase"){
                      ctx.globalCompositeOperation = "destination-out";
                    } else {
                      ctx.globalCompositeOperation = "source-over";
                    }
      
                    combinationLayer.current.width = resolution.width;
                    combinationLayer.current.height = resolution.height;
                    const combinationCtx = combinationLayer.current.getContext("2d");
                    combinationCtx.imageSmoothingEnabled = false;

                    if(!hasSelection){
                      //draw line
                      for (let i = 1; i <= iterations; i++) {   

                        if((selectedTool === "pencil" && brushMode === "dither") || (selectedTool ==="erase" && eraseMode === "dither")){
                          if(selectedTool !== "erase" && selectedSwatch === 32 && secondarySwatch === 32) return;
                
                          let adjustedDiameter = diameter;
                          if(
                            (selectedTool === "pencil" && ditherPressureMode === "diameter") ||
                            (selectedTool === "erase" && eraseDitherPressureMode === "diameter")
                          ) adjustedDiameter = Math.round(diameter * (lastDraw.current.pressure + delta.pressure * i)) || 1;

                          const midPoint = getNewMidPoint(lastDraw.current.x + delta.x * i, lastDraw.current.y + delta.y * i, adjustedDiameter);
                          
                          ditherCtx.clearRect(0,0,resolution.width,resolution.height);

                          ditherCtx.globalCompositeOperation = "source-over"
                          ditherCtx.drawImage(
                            stencil.current,
                            summate(adjustedDiameter) - adjustedDiameter,
                            maxCursorSize * secondarySwatch,
                            adjustedDiameter,
                            adjustedDiameter,
                            Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                            Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                            adjustedDiameter,
                            adjustedDiameter
                          );
                          
                          let ratio;
                          if(e.pointerType === "pen"){
                            if(selectedTool === "pencil"){
                              if(ditherPressureMode === "ratio"){
                                ratio = Math.round(16 * (lastDraw.current.pressure + delta.pressure * i));
                              } else {
                                ratio = ditherRatio;
                              }
                            } else {
                              if(eraseDitherPressureMode === "ratio"){
                                ratio = Math.round(16 * (lastDraw.current.pressure + delta.pressure * i));
                                ratio = invertEraseDither ? ratio : 16 - ratio;
                              } else {
                                ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                              }
                            }
                          } else if(selectedTool === "erase"){
                            ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                          } else {
                            ratio = ditherRatio;
                          }

                          ditherCtx.globalCompositeOperation = "destination-out";
                          for(let j = -4; j < resolution.width; j += 68){
                            for(let k = -4; k < resolution.height; k += 68){
                              ditherCtx.drawImage(
                                dither.current,
                                ratio * 68,
                                0,
                                68,
                                68,
                                j + oX,
                                k + oY,
                                68,
                                68
                              );
                            }
                          }
                          if(secondarySwatch !== 32 || selectedTool === "erase"){
                            ctx.drawImage(ditherLayer, 0, 0);
                          }

                          if(selectedSwatch !== 32 && selectedTool !== "erase"){
                            ditherCtx.globalCompositeOperation = "source-out";
                            ditherCtx.drawImage(
                              stencil.current,
                              summate(adjustedDiameter) - adjustedDiameter,
                              maxCursorSize * selectedSwatch,
                              adjustedDiameter,
                              adjustedDiameter,
                              Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                              Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                              adjustedDiameter,
                              adjustedDiameter
                            );
                            ctx.drawImage(ditherLayer, 0, 0);
                          }
                        } else if(selectedSwatch !== 32 || selectedTool === "erase"){
                          const adjustedDiameter = pressureSetting ? Math.round(diameter * (lastDraw.current.pressure + delta.pressure * i)) || 1: diameter;

                          const midPoint = getNewMidPoint(lastDraw.current.x + delta.x * i, lastDraw.current.y + delta.y * i, adjustedDiameter);
                            
                          ctx.drawImage(
                            stencil.current,
                            summate(adjustedDiameter) - adjustedDiameter,
                            maxCursorSize * selectedSwatch,
                            adjustedDiameter,
                            adjustedDiameter,
                            Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                            Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                            adjustedDiameter,
                            adjustedDiameter
                          );
                        }
                      }
                    } else {
                      combinationCtx.globalCompositeOperation = "source-over";
                      combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                      combinationCtx.drawImage(selectionLayer.current,0,0);
                      combinationCtx.globalCompositeOperation = "source-in";

                      //draw line
                      for (let i = 1; i <= iterations; i++) {   

                        if((selectedTool === "pencil" && brushMode === "dither") || (selectedTool === "erase" && eraseMode === "dither")){ 
                          if(selectedTool !== "erase" && selectedSwatch === 32 && secondarySwatch === 32) return;
                          
                          let adjustedDiameter = diameter;
                          if(selectedTool === "pencil" && ditherPressureMode === "diameter"){
                            adjustedDiameter = Math.round(diameter * (lastDraw.current.pressure + delta.pressure * i)) || 1;
                          } else if(eraseDitherPressureMode === "diameter"){
                            adjustedDiameter = Math.round(diameter * (lastDraw.current.pressure + delta.pressure * i)) || 1;
                          }
                          
                          const midPoint = getNewMidPoint(lastDraw.current.x + delta.x * i, lastDraw.current.y + delta.y * i, adjustedDiameter);

                          combinationCtx.globalCompositeOperation = "source-over";
                          combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                          combinationCtx.drawImage(selectionLayer.current,0,0);
                          combinationCtx.globalCompositeOperation = "source-in";

                          ditherCtx.clearRect(0,0,resolution.width,resolution.height);

                          ditherCtx.globalCompositeOperation = "source-over"
                          ditherCtx.drawImage(
                            stencil.current,
                            summate(adjustedDiameter) - adjustedDiameter,
                            maxCursorSize * secondarySwatch,
                            adjustedDiameter,
                            adjustedDiameter,
                            Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                            Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                            adjustedDiameter,
                            adjustedDiameter
                          );
                          
                          let ratio;
                          if(e.pointerType === "pen"){
                            if(selectedTool === "pencil"){
                              if(ditherPressureMode === "ratio"){
                                ratio = Math.round(16 * (lastDraw.current.pressure + delta.pressure * i));
                              } else {
                                ratio = ditherRatio;
                              }
                            } else {
                              if(eraseDitherPressureMode === "ratio"){
                                ratio = Math.round(16 * (lastDraw.current.pressure + delta.pressure * i));
                                ratio = invertEraseDither ? ratio : 16 - ratio;
                              } else {
                                ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                              }
                            }
                          } else if(selectedTool === "erase"){
                            ratio = invertEraseDither ? eraseDitherRatio : 16 - eraseDitherRatio;
                          } else {
                            ratio = ditherRatio;
                          }

                          ditherCtx.globalCompositeOperation = "destination-out";
                          for(let j = -4; j < resolution.width; j += 68){
                            for(let k = -4; k < resolution.height; k += 68){
                              ditherCtx.drawImage(
                                dither.current,
                                ratio * 68,
                                0,
                                68,
                                68,
                                j + oX,
                                k + oY,
                                68,
                                68
                              );
                            }
                          }
                          combinationCtx.drawImage(ditherLayer, 0, 0);
                          if(secondarySwatch !== 32 || selectedTool === "erase"){
                            ctx.drawImage(
                              combinationLayer.current,
                              Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                              Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                              adjustedDiameter,
                              adjustedDiameter,
                              Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                              Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                              adjustedDiameter,
                              adjustedDiameter
                            );
                          }

                          if(selectedSwatch !== 32 && selectedTool !== "erase"){
                            combinationCtx.globalCompositeOperation = "source-over";
                            combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                            combinationCtx.drawImage(selectionLayer.current,0,0);
                            combinationCtx.globalCompositeOperation = "source-in";

                            ditherCtx.globalCompositeOperation = "source-out";
                            ditherCtx.drawImage(
                              stencil.current,
                              summate(adjustedDiameter) - adjustedDiameter,
                              maxCursorSize * selectedSwatch,
                              adjustedDiameter,
                              adjustedDiameter,
                              Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                              Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                              adjustedDiameter,
                              adjustedDiameter
                            );
                            combinationCtx.drawImage(ditherLayer, 0, 0);
                            ctx.drawImage(
                              combinationLayer.current,
                              Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                              Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                              adjustedDiameter,
                              adjustedDiameter,
                              Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                              Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                              adjustedDiameter,
                              adjustedDiameter
                            );
                          }
                        } else if(selectedSwatch !== 32 || selectedTool === "erase"){
                          combinationCtx.globalCompositeOperation = "source-over";
                          combinationCtx.clearRect(0,0,resolution.width,resolution.height);
                          combinationCtx.drawImage(selectionLayer.current,0,0);
                          combinationCtx.globalCompositeOperation = "source-in";

                          const adjustedDiameter = pressureSetting ?
                            Math.round( diameter * (lastDraw.current.pressure + delta.pressure * i) ) || 1
                            :
                            diameter;

                          const midPoint = getNewMidPoint(lastDraw.current.x + delta.x * i, lastDraw.current.y + delta.y * i, adjustedDiameter);
                            
                          combinationCtx.drawImage(
                            stencil.current,
                            summate(adjustedDiameter) - adjustedDiameter,
                            maxCursorSize * selectedSwatch,
                            adjustedDiameter,
                            adjustedDiameter,
                            Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                            Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                            adjustedDiameter,
                            adjustedDiameter
                          );
          
                          ctx.drawImage(
                            combinationLayer.current,
                            Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                            Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                            adjustedDiameter,
                            adjustedDiameter,
                            Math.ceil( midPoint.x ) - Math.round(adjustedDiameter / 2),
                            Math.ceil( midPoint.y ) - Math.round(adjustedDiameter / 2),
                            adjustedDiameter,
                            adjustedDiameter
                          );
                        }
                      }
                    }
                  }
                          
                  let adjustedDiameter = diameter;
                  if(selectedTool === "pencil" && ditherPressureMode === "diameter"){
                    adjustedDiameter = Math.round(diameter * (lastDraw.current.pressure + delta.pressure * i)) || 1;
                  } else if(eraseDitherPressureMode === "diameter"){
                    adjustedDiameter = Math.round(diameter * (lastDraw.current.pressure + delta.pressure * i)) || 1;
                  }

                  const midPoint = getNewMidPoint(x,y, adjustedDiameter);
                  lastDraw.current = {x: midPoint.x, y: midPoint.y, pressure: pressureChange ? pressure : lastDraw.current.pressure};
                  lastPoint.current = {x: lastDraw.current.x, y: lastDraw.current.y, pressure: lastDraw.current.pressure};
                }
              }
            } else if( selectedTool === "line" && line){
              let snapAngles = [0,90,180,270];

              if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                const start = lineWidth % 2 === 0 ? {x: Math.floor(line.startX), y: Math.floor(line.startY)} : {x: Math.ceil(line.startX), y: Math.ceil(line.startY)};
                const getTarget = (point) => {
                  return lineWidth % 2 === 0 ? {x: Math.ceil(point.x), y: Math.ceil(point.y)} : {x: Math.floor(point.x) + 0.5, y: Math.floor(point.y) + 0.5};
                }; 
                let vp1, vp2, vp3, vp4;
                switch(layers[magnetLayer].type){
                  case "Line":
                    const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                    const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                    const angleInRadians = Math.atan2(deltaY, deltaX);
                    let angleInDegrees = angleInRadians * (180 / Math.PI);
                    angleInDegrees = ((angleInDegrees % 360) + 360) % 360;

                    snapAngles = [angleInDegrees];
                    for(let i = 0; i < 3; i++){
                      angleInDegrees += 90;
                      snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                    }
                    break;
                  case "OnePoint":
                    const target2 = getTarget(layers[magnetLayer].point);
                    const deltaX2 = start.x - target2.x;
                    const deltaY2 = start.y - target2.y;
                    const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                    let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                    angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;

                    snapAngles = [angleInDegrees2];
                    break;
                  case "ThreePoint":
                    const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                    vp3 = vanishingPoints2.vp1;
                    vp4 = vanishingPoints2.vp2;
                    const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                    vp1 = vanishingPoints3.vp1;
                    vp2 = vanishingPoints3.vp2;
                    const target5 = getTarget(vp1);
                    const target6 = getTarget(vp2);
                    const target8 = getTarget(vp4);

                    const deltaX5 = Math.floor(line.startX) - target5.x;
                    const deltaY5 = Math.floor(line.startY) - target5.y;
                    const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                    let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                    angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                    const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;

                    const deltaX6 = Math.floor(line.startX) - target6.x;
                    const deltaY6 = Math.floor(line.startY) - target6.y;
                    const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                    let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                    angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                    const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;

                    const deltaX8 = Math.floor(line.startX) - target8.x;
                    const deltaY8 = Math.floor(line.startY) - target8.y;
                    const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                    let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                    angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                    const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;

                    const horizontalAngle = layers[magnetLayer].tilt;
                    const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                    snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                    break;
                  case "Grid":
                    snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                    break;
                  case "Isometric":
                    snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                    break;
                }
              }

              if(shiftKey || shiftButton){
                const snapAngle = closestAngleCoord({x: line.startX, y: line.startY}, {x: x, y: y}, snapAngles);
                const angleInRadians = snapAngle * (Math.PI / 180);
                const deltaX = x - line.startX;
                const deltaY = y - line.startY;
                const length = deltaX * Math.cos(angleInRadians) + deltaY * Math.sin(angleInRadians);
            
                // Calculate snapped coordinates based on the snap angle and length
                x = line.startX + length * Math.cos(angleInRadians);
                y = line.startY + length * Math.sin(angleInRadians);
              }
              
              const dx = x - line.startX;
              const dy = y - line.startY;
          
              const length = Math.sqrt(dx * dx + dy * dy);
              const radians = Math.atan2(dy, dx);
              const angle = -1 * radians * (180 / Math.PI);

              setDetails(`${Math.round(length)}px, ${angle.toFixed(2)}°`);

              const transformCtx = transformLayer.current.getContext("2d");
              transformCtx.imageSmoothingEnabled = false;
              transformCtx.clearRect(0,0,resolution.width,resolution.height);
              transformCtx.globalCompositeOperation = "source-over";           
              
              const diagonal = Math.sqrt(resolution.width * resolution.width + resolution.height * resolution.height);
              transformCtx.save();  
              transformCtx.translate(line.startX, line.startY);
              transformCtx.rotate(radians);
              transformCtx.fillRect(0, -diagonal, Math.floor(length), diagonal * 2);
              transformCtx.restore();
              transformCtx.globalCompositeOperation = "source-in";
        
              const imageData = transformCtx.getImageData(0,0,resolution.width,resolution.height);
              for (let i = 0; i < imageData.data.length; i+=4) {
                if ( imageData.data[i + 3] > 0) {
                  imageData.data[i + 3] = 255;
                }
              }
              transformCtx.putImageData(imageData, 0, 0);

              const tempCanvas = document.createElement("canvas");
              tempCanvas.width = resolution.width;
              tempCanvas.height = resolution.height;
              const tempCtx = tempCanvas.getContext("2d");
              tempCtx.imageSmoothingEnabled = false;
              tempCtx.fillStyle = palette[selectedSwatch];

              pixelLine(tempCtx, Math.floor(line.startX), Math.floor(line.startY), Math.floor(x), Math.floor(y), lineWidth);
              transformCtx.drawImage(tempCanvas,0,0);

              transformCtx.globalCompositeOperation = "source-over";

              setLine({startX: line.startX, startY: line.startY, endX: x, endY: y});
            } else if (selectedTool === "transform" && transformRef.current && selectedViewport === layout){
              //pan view to fit transform
              if(initialMove.current){
                if(outsideX || outsideY){
                  setDeltaTranslate({
                    x: outsideX,
                    y: outsideY
                  });
                } else {
                  setDeltaTranslate(null);
                }

                //save starting state
                const startTransform = {
                  left: transformRef.current.left,
                  top: transformRef.current.top,
                  right: transformRef.current.right,
                  bottom: transformRef.current.bottom,
                };

                //calculate delta
                let delta;
                let snapAngles = [0,90,180,270];
                
                if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                  let vp1, vp2, vp3, vp4;
                  switch(layers[magnetLayer].type){
                    case "Line":
                      const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                      const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                      const angleInRadians = Math.atan2(deltaY, deltaX);
                      let angleInDegrees = angleInRadians * (180 / Math.PI);
                      angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
  
                      snapAngles = [angleInDegrees];
                      for(let i = 0; i < 3; i++){
                        angleInDegrees += 90;
                        snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                      }
                      break;
                    case "OnePoint":
                      const deltaX2 = x - layers[magnetLayer].point.x;
                      const deltaY2 = y - layers[magnetLayer].point.y;
                      const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                      let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                      angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;
  
                      snapAngles = [angleInDegrees2];
                      break;
                  case "TwoPoint":
                    const vanishingPoints = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                    vp1 = vanishingPoints.vp1;
                    vp2 = vanishingPoints.vp2;

                    const deltaX3 = x - vp1.x;
                    const deltaY3 = y - vp1.y;
                    const angleInRadians3 = Math.atan2(deltaY3, deltaX3);
                    let angleInDegrees3 = angleInRadians3 * (180 / Math.PI);
                    angleInDegrees3 = ((angleInDegrees3 % 360) + 360) % 360;
                    const otherAngle3 = (((angleInDegrees3 - 180) % 360) + 360) % 360;

                    const deltaX4 = x - vp2.x;
                    const deltaY4 = y - vp2.y;
                    const angleInRadians4 = Math.atan2(deltaY4, deltaX4);
                    let angleInDegrees4 = angleInRadians4 * (180 / Math.PI);
                    angleInDegrees4 = ((angleInDegrees4 % 360) + 360) % 360;
                    const otherAngle4 = (((angleInDegrees4 - 180) % 360) + 360) % 360;

                    snapAngles = [angleInDegrees3, otherAngle3, angleInDegrees4, otherAngle4];
                    break;
                  case "ThreePoint":
                    const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                    vp3 = vanishingPoints2.vp1;
                    vp4 = vanishingPoints2.vp2;
                    const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                    vp1 = vanishingPoints3.vp1;
                    vp2 = vanishingPoints3.vp2;

                    const deltaX5 = x - vp1.x;
                    const deltaY5 = y - vp1.y;
                    const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                    let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                    angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                    const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;

                    const deltaX6 = x - vp2.x;
                    const deltaY6 = y - vp2.y;
                    const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                    let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                    angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                    const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;

                    const deltaX8 = x - vp4.x;
                    const deltaY8 = y - vp4.y;
                    const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                    let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                    angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                    const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;

                    const horizontalAngle = layers[magnetLayer].tilt;
                    const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                    snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                    break;
                  case "Grid":
                    snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                    break;
                  case "Isometric":
                    snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                    break;
                  case "Ellipse":
                    snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                    break;
                  }
                }
                
                const deltaX = x - initialMove.current.x;
                const deltaY = y - initialMove.current.y;

                if(!moveAngles.current && (shiftKey || shiftButton)){
                  const angleInRadians = Math.atan2(deltaY, deltaX);
                  let angleInDegrees = angleInRadians * (180 / Math.PI);
                  angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
                  const snapAngle1 = closestAngle(angleInDegrees, snapAngles);
                  const snapAngle2 = (((snapAngle1 - 180) % 360) + 360) % 360;
                  moveAngles.current = [snapAngle1, snapAngle2];
                }
  
                if(moveAngles.current && (nearTransform === 0 || nearTransform === -1)){
                  const snapAngle = closestAngleCoord(lastCoordinate.current, {x, y} , moveAngles.current);
                  const angleInRadians = snapAngle * (Math.PI / 180);

                  const length = deltaX * Math.cos(angleInRadians) + deltaY * Math.sin(angleInRadians);
              
                  // Calculate snapped coordinates based on the snap angle and length
                  let snappedX, snappedY;
                  snappedX = length * Math.cos(angleInRadians);
                  snappedY = length * Math.sin(angleInRadians);
              
                  delta = { x: snappedX, y: snappedY };
                } else {
                  delta = { x: x - initialMove.current.x, y: y - initialMove.current.y };
                }

                initialMove.current = { x: x, y: y };

                //calculate rotation
                const newPosition = { x: x - transformRef.current.pivotX, y: y - transformRef.current.pivotY}
                const newAngle = Math.atan2(newPosition.y, newPosition.x);
                let newAngleDegrees = (newAngle * 180) / Math.PI;
                newAngleDegrees = (newAngleDegrees + 360) % 360;

                //calculate layer width and height for constraints
                const lW = layers[selectedLayer].ref.current.width;
                const lH = layers[selectedLayer].ref.current.height;
                let left = layers[selectedLayer].x - offset.x + lW - maxLayerSize;
                let top = layers[selectedLayer].y - offset.y + lH - maxLayerSize;
                let right = layers[selectedLayer].x - offset.x + maxLayerSize;
                let bottom = layers[selectedLayer].y - offset.y + maxLayerSize;

                const pivotRatio = {
                  x: (transformRef.current.pivotX - transformRef.current.left) / (transformRef.current.right - transformRef.current.pivotX),
                  y: (transformRef.current.pivotY - transformRef.current.top) / (transformRef.current.bottom - transformRef.current.pivotY),
                  width: (transformRef.current.pivotX - transformRef.current.left) / (transformRef.current.right - transformRef.current.left),
                  height: (transformRef.current.pivotY - transformRef.current.top) / (transformRef.current.bottom - transformRef.current.top),
                }; 
                switch (nearTransform){
                  case -1:
                    transformRef.current.pivotX += delta.x;
                    transformRef.current.pivotY += delta.y;
                    break;
                  case 0:
                    transformRef.current.left += delta.x;
                    transformRef.current.top += delta.y;
                    transformRef.current.right += delta.x;
                    transformRef.current.bottom += delta.y;
                    transformRef.current.pivotX += delta.x;
                    transformRef.current.pivotY += delta.y;

                    if(transformRef.current.left < left){
                      const dif = left - transformRef.current.left;
                      transformRef.current.left += dif;
                      transformRef.current.right += dif;
                      transformRef.current.pivotX += dif;
                    }
                    if(transformRef.current.top < top){
                      const dif = top - transformRef.current.top;
                      transformRef.current.top += dif;
                      transformRef.current.bottom += dif;
                      transformRef.current.pivotY += dif;
                    }
                    if(transformRef.current.right > right){
                      const dif = transformRef.current.right - right;
                      transformRef.current.left -= dif;
                      transformRef.current.right -= dif;
                      transformRef.current.pivotX -= dif;
                    }
                    if(transformRef.current.bottom > bottom){
                      const dif = transformRef.current.bottom - bottom;
                      transformRef.current.top -= dif;
                      transformRef.current.bottom -= dif;
                      transformRef.current.pivotY -= dif;
                    }
                    break;
                  case 9:
                  case 10:
                  case 11:
                  case 12:
                    let angle = newAngleDegrees;
                    let target = previousRotation.current;
                    angle = (angle + 360) % 360;
                    target = (target + 360) % 360;
                    if (angle < 22.5 && target > 337.5) {
                        angle += 360;
                    } else if (target < 22.5 && angle > 337.5) {
                        target += 360;
                    }
                    if (angle > target + 22.5) {
                        rotateCW();
                        previousRotation.current += 22.5;
                    } else if (angle < target - 22.5) {
                        rotateCCW();
                        previousRotation.current -= 22.5;
                    }
                    previousRotation.current = (previousRotation.current + 360) % 360;

                    const rotateLeft = x < transformRef.current.pivotX;
                    const rotateTop = y < transformRef.current.pivotY;

                    if(rotateLeft && rotateTop){
                      setNearTransform(9);
                    } else if(rotateTop){
                      setNearTransform(10);
                    } else if(rotateLeft){
                      setNearTransform(12);
                    } else {
                      setNearTransform(11);
                    }
                    break;
                  default:
                    switch (nearTransform){
                      case 1:
                      case 7:
                      case 8:
                        transformRef.current.left += delta.x;
                        break;
                      case 3:
                      case 4:
                      case 5:
                        transformRef.current.right += delta.x;
                        break;
                    }

                    switch (nearTransform){
                      case 1:
                      case 2:
                      case 3:
                        transformRef.current.top += delta.y;
                        break;
                      case 5:
                      case 6:
                      case 7:
                        transformRef.current.bottom += delta.y;
                        break;
                    }

                    if(shiftKey || shiftButton){
                      const aspectRatio = transformCanvas.width / transformCanvas.height;
                      let width = transformRef.current.right - transformRef.current.left;
                      let height = transformRef.current.bottom - transformRef.current.top;
                      if(aspectRatio > 1){
                        width = height * aspectRatio;
                        switch (nearTransform){
                          case 1:
                          case 7:
                            transformRef.current.left = transformRef.current.pivotX - width * pivotRatio.width;
                            break;
                          case 3:
                          case 5:
                            transformRef.current.right = transformRef.current.pivotX + width * (1-pivotRatio.width);
                            break;
                        }
                      } else {
                        height = width / aspectRatio;
                        switch (nearTransform){
                          case 1:
                          case 3:
                            transformRef.current.top = transformRef.current.pivotY - height * pivotRatio.height;
                            break;
                          case 5:
                          case 7:
                            transformRef.current.bottom = transformRef.current.pivotY + height * (1-pivotRatio.height);
                            break;
                        }
                      }
                    }

                    if(altKey || altButton){
                      let difX, difY
                      switch (nearTransform){
                        case 1:
                        case 7:
                        case 8:
                          difX = startTransform.left - transformRef.current.left;
                          transformRef.current.right += difX / pivotRatio.x;
                          break;
                        case 3:
                        case 4:
                        case 5:
                          difX = transformRef.current.right - startTransform.right;
                          transformRef.current.left -= difX * pivotRatio.x;
                          break;
                      }

                      switch (nearTransform){
                        case 1:
                        case 2:
                        case 3:
                          difY = startTransform.top - transformRef.current.top;
                          transformRef.current.bottom += difY / pivotRatio.y;
                          break;
                        case 5:
                        case 6:
                        case 7:
                          difY = transformRef.current.bottom - startTransform.bottom;
                          transformRef.current.top -= difY * pivotRatio.y;
                          break;
                      }
                    } else {
                      transformRef.current.pivotX = transformRef.current.left + (transformRef.current.right - transformRef.current.left) * pivotRatio.width;
                      transformRef.current.pivotY = transformRef.current.top + (transformRef.current.bottom - transformRef.current.top) * pivotRatio.height;
                    }
            
                    left = Math.max(left, transformRef.current.right - maxLayerSize);
                    top = Math.max(top, transformRef.current.bottom - maxLayerSize);
                    right = Math.min(right, transformRef.current.left + maxLayerSize);
                    bottom = Math.min(bottom, transformRef.current.top + maxLayerSize);

                    if(transformRef.current.left < left){
                      const dif = left - transformRef.current.left;
                      transformRef.current.left += dif;
                      if(altKey || altButton){
                        transformRef.current.right -= dif / pivotRatio.x;
                      } else {
                        transformRef.current.pivotX += dif / 2;
                      }
                    }
                    if(transformRef.current.top < top){
                      const dif = top - transformRef.current.top;
                      transformRef.current.top += dif;
                      if(altKey || altButton){
                        transformRef.current.bottom -= dif / pivotRatio.x;
                      } else {
                        transformRef.current.pivotY += dif / 2;
                      }
                    }
                    if(transformRef.current.right > right){
                      const dif = transformRef.current.right - right;
                      transformRef.current.right -= dif;
                      if(altKey || altButton){
                        transformRef.current.left += dif / pivotRatio.y;
                      } else {
                        transformRef.current.pivotX -= dif / 2;
                      }
                    }
                    if(transformRef.current.bottom > bottom){
                      const dif = transformRef.current.bottom - bottom;
                      transformRef.current.bottom -= dif;
                      if(altKey || altButton){
                        transformRef.current.top += dif / pivotRatio.y;
                      } else {
                        transformRef.current.pivotY -= dif / 2;
                      }
                    }
                    break;
                }

                switch(nearTransform){
                  case 1:
                    if(transformRef.current.right < transformRef.current.left){
                      if(transformRef.current.bottom < transformRef.current.top){
                        const tempRight = transformRef.current.right;
                        transformRef.current.right = transformRef.current.left;
                        transformRef.current.left = tempRight;
                        const tempBottom = transformRef.current.bottom;
                        transformRef.current.bottom = transformRef.current.top;
                        transformRef.current.top = tempBottom;
                        flipXY();
                        setNearTransform(5);
                      } else {
                        const tempRight = transformRef.current.right;
                        transformRef.current.right = transformRef.current.left;
                        transformRef.current.left = tempRight;
                        flipX();
                        setNearTransform(3);
                      }
                    } else if(transformRef.current.bottom < transformRef.current.top){
                      const tempBottom = transformRef.current.bottom;
                      transformRef.current.bottom = transformRef.current.top;
                      transformRef.current.top = tempBottom;
                      flipY();
                      setNearTransform(7);
                    }
                    break;
                  case 2:
                    if(transformRef.current.bottom < transformRef.current.top){
                      const tempBottom = transformRef.current.bottom;
                      transformRef.current.bottom = transformRef.current.top;
                      transformRef.current.top = tempBottom;
                      flipY();
                      setNearTransform(6);
                    }
                    break;
                  case 3:
                    if(transformRef.current.right < transformRef.current.left){
                      if(transformRef.current.bottom < transformRef.current.top){
                        const tempRight = transformRef.current.right;
                        transformRef.current.right = transformRef.current.left;
                        transformRef.current.left = tempRight;
                        const tempBottom = transformRef.current.bottom;
                        transformRef.current.bottom = transformRef.current.top;
                        transformRef.current.top = tempBottom;
                        flipXY();
                        setNearTransform(7);
                      } else {
                        const tempRight = transformRef.current.right;
                        transformRef.current.right = transformRef.current.left;
                        transformRef.current.left = tempRight;
                        flipX();
                        setNearTransform(1);
                      }
                    } else if(transformRef.current.bottom < transformRef.current.top){
                      const tempBottom = transformRef.current.bottom;
                      transformRef.current.bottom = transformRef.current.top;
                      transformRef.current.top = tempBottom;
                      flipY();
                      setNearTransform(5);
                    }
                    break;
                  case 4:
                    if(transformRef.current.right < transformRef.current.left){
                      const tempRight = transformRef.current.right;
                      transformRef.current.right = transformRef.current.left;
                      transformRef.current.left = tempRight;
                      flipX();
                      setNearTransform(8);
                    }
                    break;
                  case 5:
                    if(transformRef.current.right < transformRef.current.left){
                      if(transformRef.current.bottom < transformRef.current.top){
                        const tempRight = transformRef.current.right;
                        transformRef.current.right = transformRef.current.left;
                        transformRef.current.left = tempRight;
                        const tempBottom = transformRef.current.bottom;
                        transformRef.current.bottom = transformRef.current.top;
                        transformRef.current.top = tempBottom;
                        flipXY();
                        setNearTransform(1);
                      } else {
                        const tempRight = transformRef.current.right;
                        transformRef.current.right = transformRef.current.left;
                        transformRef.current.left = tempRight;
                        flipX();
                        setNearTransform(7);
                      }
                    } else if(transformRef.current.bottom < transformRef.current.top){
                      const tempBottom = transformRef.current.bottom;
                      transformRef.current.bottom = transformRef.current.top;
                      transformRef.current.top = tempBottom;
                      flipY();
                      setNearTransform(3);
                    }
                    break;
                  case 6:
                    if(transformRef.current.bottom < transformRef.current.top){
                      const tempBottom = transformRef.current.bottom;
                      transformRef.current.bottom = transformRef.current.top;
                      transformRef.current.top = tempBottom;
                      flipY();
                      setNearTransform(2);
                    }
                    break;
                  case 7:
                    if(transformRef.current.right < transformRef.current.left){
                      if(transformRef.current.bottom < transformRef.current.top){
                        const tempRight = transformRef.current.right;
                        transformRef.current.right = transformRef.current.left;
                        transformRef.current.left = tempRight;
                        const tempBottom = transformRef.current.bottom;
                        transformRef.current.bottom = transformRef.current.top;
                        transformRef.current.top = tempBottom;
                        flipXY();
                        setNearTransform(3);
                      } else {
                        const tempRight = transformRef.current.right;
                        transformRef.current.right = transformRef.current.left;
                        transformRef.current.left = tempRight;
                        flipX();
                        setNearTransform(5);
                      }
                    } else if(transformRef.current.bottom < transformRef.current.top){
                      const tempBottom = transformRef.current.bottom;
                      transformRef.current.bottom = transformRef.current.top;
                      transformRef.current.top = tempBottom;
                      flipY();
                      setNearTransform(1);
                    }
                    break;
                  case 8:
                    if(transformRef.current.right < transformRef.current.left){
                      const tempRight = transformRef.current.right;
                      transformRef.current.right = transformRef.current.left;
                      transformRef.current.left = tempRight;
                      flipX();
                      setNearTransform(4);
                    }
                    break;
                }

                let pivotDifX = transformRef.current.pivotX - transform.pivotX;
                let pivotDifY = transformRef.current.pivotY - transform.pivotY;
                switch (nearTransform){
                  case -1:
                    break;
                  case 0:
                    pivotDifX = Math.round(pivotDifX);
                    pivotDifY = Math.round(pivotDifY);
                    break;
                  default:
                    pivotDifX = Math.round(pivotDifX*2)/2;
                    pivotDifY = Math.round(pivotDifY*2)/2;
                    break;

                }

                setTransform({
                  left: Math.round(transformRef.current.left),
                  top: Math.round(transformRef.current.top),
                  right: Math.round(transformRef.current.right),
                  bottom: Math.round(transformRef.current.bottom),
                  pivotX: transform.pivotX + pivotDifX,
                  pivotY: transform.pivotY + pivotDifY,
                });
              } else if(transform){
                let canvasScale = canvasHeight === 'auto' ? bg.current.clientWidth / resolution.width :  bg.current.clientHeight / resolution.height;
                const radius = 4 / canvasScale / scale;

                const width = transform.right - transform.left;
                const height = transform.bottom - transform.top;
                const scaledTransformProximity = Math.min(width,height) / 4;
                
                if(
                  (Math.abs(transform.right - transform.left) > radius * 10 &&
                  Math.abs(transform.bottom - transform.top) > radius * 10) &&
                  (x > transform.pivotX - scaledTransformProximity/2 &&
                  x < transform.pivotX + scaledTransformProximity/2 &&
                  y > transform.pivotY - scaledTransformProximity/2  &&
                  y < transform.pivotY + scaledTransformProximity/2 )
                ){
                  setNearTransform(-1);
                } else if(
                  x > transform.left - scaledTransformProximity && 
                  x < transform.right + scaledTransformProximity &&
                  y > transform.top - scaledTransformProximity &&
                  y < transform.bottom + scaledTransformProximity
                ){
                  const nearLeft = Math.abs(x - transform.left) <= scaledTransformProximity;
                  const nearTop = Math.abs(y - transform.top) <= scaledTransformProximity;
                  const nearRight = Math.abs(x - transform.right) <= scaledTransformProximity;
                  const nearBottom = Math.abs(y - transform.bottom) <= scaledTransformProximity;

                  if((nearLeft && nearRight) || (nearTop && nearBottom)){
                    setNearTransform(0);
                  } else if(nearLeft){
                    if(nearTop){
                      setNearTransform(1);
                    } else if(nearBottom){
                      setNearTransform(7);
                    } else {
                      setNearTransform(8);
                    }
                  } else if(nearRight){
                    if(nearTop){
                      setNearTransform(3);
                    } else if(nearBottom){
                      setNearTransform(5);
                    } else {
                      setNearTransform(4);
                    }
                  } else if(nearTop){
                    setNearTransform(2);
                  } else if(nearBottom){
                    setNearTransform(6);
                  } else{
                    setNearTransform(0);
                  } 
                } else{
                  const rotateLeft = x < transform.pivotX;
                  const rotateTop = y < transform.pivotY;

                  if(rotateLeft && rotateTop){
                    setNearTransform(9);
                  } else if(rotateTop){
                    setNearTransform(10);
                  } else if(rotateLeft){
                    setNearTransform(12);
                  } else {
                    setNearTransform(11);
                  }
                }
              }
            } else if (selectedTool === "gradient" && gradientPoints){
              let coordinates;
              let snapAngles = [0,90,180,270];

              if(magnetLayer !== null && layers[magnetLayer].type !== "Canvas"){
                let vp1, vp2, vp3, vp4;
                switch(layers[magnetLayer].type){
                  case "Line":
                    const deltaX = layers[magnetLayer].endPoint.x - layers[magnetLayer].startPoint.x;
                    const deltaY = layers[magnetLayer].endPoint.y - layers[magnetLayer].startPoint.y;
                    const angleInRadians = Math.atan2(deltaY, deltaX);
                    let angleInDegrees = angleInRadians * (180 / Math.PI);
                    angleInDegrees = ((angleInDegrees % 360) + 360) % 360

                    snapAngles = [angleInDegrees];
                    for(let i = 0; i < 3; i++){
                      angleInDegrees += 90;
                      snapAngles.push(((angleInDegrees % 360) + 360) % 360);
                    }
                    break;
                  case "OnePoint":
                    const deltaX2 = gradientPoints.x - layers[magnetLayer].point.x;
                    const deltaY2 = gradientPoints.y - layers[magnetLayer].point.y;
                    const angleInRadians2 = Math.atan2(deltaY2, deltaX2);
                    let angleInDegrees2 = angleInRadians2 * (180 / Math.PI);
                    angleInDegrees2 = ((angleInDegrees2 % 360) + 360) % 360;

                    snapAngles = [angleInDegrees2];
                    break;
                  case "TwoPoint":
                    const vanishingPoints = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                    vp1 = vanishingPoints.vp1;
                    vp2 = vanishingPoints.vp2; 

                    const deltaX3 = gradientPoints.x - vp1.x;
                    const deltaY3 = gradientPoints.y - vp1.y;
                    const angleInRadians3 = Math.atan2(deltaY3, deltaX3);
                    let angleInDegrees3 = angleInRadians3 * (180 / Math.PI);
                    angleInDegrees3 = ((angleInDegrees3 % 360) + 360) % 360;
                    const otherAngle3 = (((angleInDegrees3 - 180) % 360) + 360) % 360;

                    const deltaX4 = gradientPoints.x - vp2.x;
                    const deltaY4 = gradientPoints.y - vp2.y;
                    const angleInRadians4 = Math.atan2(deltaY4, deltaX4);
                    let angleInDegrees4 = angleInRadians4 * (180 / Math.PI);
                    angleInDegrees4 = ((angleInDegrees4 % 360) + 360) % 360;
                    const otherAngle4 = (((angleInDegrees4 - 180) % 360) + 360) % 360;

                    snapAngles = [angleInDegrees3, otherAngle3, angleInDegrees4, otherAngle4];
                    break;
                  case "ThreePoint":
                    const vanishingPoints2 = deriveVanishingPoints(layers[magnetLayer].center, layers[magnetLayer].focalLength, layers[magnetLayer].tilt + 90, layers[magnetLayer].angle2);
                    vp3 = vanishingPoints2.vp1;
                    vp4 = vanishingPoints2.vp2;
                    const vanishingPoints3 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layers[magnetLayer].focalLength, layers[magnetLayer].tilt, layers[magnetLayer].angle);
                    vp1 = vanishingPoints3.vp1;
                    vp2 = vanishingPoints3.vp2;

                    const deltaX5 = gradientPoints.x - vp1.x;
                    const deltaY5 = gradientPoints.y - vp1.y;
                    const angleInRadians5 = Math.atan2(deltaY5, deltaX5);
                    let angleInDegrees5 = angleInRadians5 * (180 / Math.PI);
                    angleInDegrees5 = ((angleInDegrees5 % 360) + 360) % 360;
                    const otherAngle5 = (((angleInDegrees5 - 180) % 360) + 360) % 360;

                    const deltaX6 = gradientPoints.x - vp2.x;
                    const deltaY6 = gradientPoints.y - vp2.y;
                    const angleInRadians6 = Math.atan2(deltaY6, deltaX6);
                    let angleInDegrees6 = angleInRadians6 * (180 / Math.PI);
                    angleInDegrees6 = ((angleInDegrees6 % 360) + 360) % 360;
                    const otherAngle6 = (((angleInDegrees6 - 180) % 360) + 360) % 360;

                    const deltaX8 = gradientPoints.x - vp4.x;
                    const deltaY8 = gradientPoints.y - vp4.y;
                    const angleInRadians8 = Math.atan2(deltaY8, deltaX8);
                    let angleInDegrees8 = angleInRadians8 * (180 / Math.PI);
                    angleInDegrees8 = ((angleInDegrees8 % 360) + 360) % 360;
                    const otherAngle8 = (((angleInDegrees8 - 180) % 360) + 360) % 360;

                    const horizontalAngle = layers[magnetLayer].tilt;
                    const otherHorizontal = (((horizontalAngle - 180) % 360) + 360) % 360;

                    snapAngles = [angleInDegrees5, otherAngle5, angleInDegrees6, otherAngle6, angleInDegrees8, otherAngle8, horizontalAngle, otherHorizontal];
                    break;
                  case "Grid":
                    snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                    break;
                  case "Isometric":
                    snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle2, -layers[magnetLayer].angle2 + 180, 90, 270];
                    break;
                  case "Ellipse":
                    snapAngles = [-layers[magnetLayer].angle, -layers[magnetLayer].angle + 90, -layers[magnetLayer].angle + 180, -layers[magnetLayer].angle + 270];
                    break;
                }
              }

              if(shiftKey || shiftButton){
                const snapAngle = closestAngleCoord(gradientPoints, {x: x, y: y}, snapAngles);
                const angleInRadians = snapAngle * (Math.PI / 180);
                const deltaX = x - gradientPoints.x;
                const deltaY = y - gradientPoints.y;
                const length = deltaX * Math.cos(angleInRadians) + deltaY * Math.sin(angleInRadians);
            
                // Calculate snapped coordinates based on the snap angle and length
                const snappedX = gradientPoints.x + length * Math.cos(angleInRadians);
                const snappedY = gradientPoints.y + length * Math.sin(angleInRadians);
            
                coordinates = {x: gradientPoints.x, y: gradientPoints.y, x2: snappedX, y2: snappedY};
              } else {
                coordinates = {x: gradientPoints.x, y: gradientPoints.y, x2: x, y2: y}
              }

              setGradientPoints(coordinates);
              const dx = coordinates.x2 - coordinates.x;
              const dy = coordinates.y2 - coordinates.y;
          
              const length = Math.sqrt(dx * dx + dy * dy);
              let angle = -1 * Math.atan2(dy, dx);
              angle = angle * (180 / Math.PI);

              setDetails(`${Math.round(length)}px, ${angle.toFixed(2)}°`);
            }

            if((selectedTool === "rectangle" ||
                selectedTool === "ellipse" ||
                selectedTool === "wand") &&
                hasSelection
            ){
              const lineLength = 6 / canvasScale / scale;

              if(shiftM){
                if(altM){
                  setIconPath((
                    <>
                      <line
                        key="plus-diag1-white"
                        x1={x + lineLength}
                        x2={x + lineLength * 2}
                        y1={y + lineLength * 2}
                        y2={y + lineLength}
                        stroke="white"
                        strokeWidth={3 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        key="plus-diag2-white"
                        x1={x + lineLength}
                        x2={x + lineLength * 2}
                        y1={y + lineLength}
                        y2={y + lineLength * 2}
                        stroke="white"
                        strokeWidth={3 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        key="plus-diag1-black"
                        x1={x + lineLength}
                        x2={x + lineLength * 2}
                        y1={y + lineLength * 2}
                        y2={y + lineLength}
                        stroke="black"
                        strokeWidth={1.5 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        key="plus-diag2-black"
                        x1={x + lineLength}
                        x2={x + lineLength * 2}
                        y1={y + lineLength}
                        y2={y + lineLength * 2}
                        stroke="black"
                        strokeWidth={1.5 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                    </>
                  ));
                } else {
                  setIconPath((
                    <>
                      <line
                        key="plus-horizontal-white"
                        x1={x + lineLength}
                        x2={x + lineLength * 2}
                        y1={y + lineLength * 1.5}
                        y2={y + lineLength * 1.5}
                        stroke="white"
                        strokeWidth={3 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        key="plus-verticle-white"
                        x1={x + lineLength * 1.5}
                        x2={x + lineLength * 1.5 }
                        y1={y + lineLength}
                        y2={y + lineLength * 2}
                        stroke="white"
                        strokeWidth={3 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        key="plus-horizontal-black"
                        x1={x + lineLength}
                        x2={x + lineLength * 2}
                        y1={y + lineLength * 1.5}
                        y2={y + lineLength * 1.5}
                        stroke="black"
                        strokeWidth={1.5 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        key="plus-verticle-black"
                        x1={x + lineLength * 1.5}
                        x2={x + lineLength * 1.5 }
                        y1={y + lineLength}
                        y2={y + lineLength * 2}
                        stroke="black"
                        strokeWidth={1.5 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                    </>
                  ));
                }
              } else if (altM){
                setIconPath((
                  <>
                    <line
                      key="minus-white"
                      x1={x + lineLength}
                      x2={x + lineLength * 2}
                      y1={y + lineLength * 1.5}
                      y2={y + lineLength * 1.5}
                      stroke="white"
                      strokeWidth={3 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      key="minus-black"
                      x1={x + lineLength}
                      x2={x + lineLength * 2}
                      y1={y + lineLength * 1.5}
                      y2={y + lineLength * 1.5}
                      stroke="black"
                      strokeWidth={1.5 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                  </>
                ));
              }
            }
          }
        }
      }
      lastPosition.current = {x: e.clientX, y: e.clientY};
      lastCoordinate.current = {x, y};
    };

    // Cancel the previous frame before scheduling a new one
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
    }

    // Schedule the next frame
    const newAnimationFrameId = requestAnimationFrame(handleMovement);
    setAnimationFrameId(newAnimationFrameId);
  }, [ 
    isDragging,
    scale,
    translate,
    resolution,
    offset,
    isSpaceDown,
    modalIsOpen,
    layers,
    selectedTool,
    canvases,
    selectedLayer,
    selectedLayers,
    hasSelection,
    brushPressure,
    erasePressure,
    brushDiameter,
    eraseDiameter,
    brushPixelPerfect,
    erasePixelPerfect,
    brushMode,
    ditherRatio,
    ditherOffsetX,
    ditherOffsetY,
    eraseMode,
    eraseDitherRatio,
    eraseDitherPressureMode,
    invertEraseDither,
    ditherPressureMode,
    selectedSwatch,
    secondarySwatch,
    isMoving,
    move,
    canvasHeight,
    cursorEdges,
    altKey,
    shiftKey,
    altButton,
    shiftButton,
    metaButton,
    erasePressureFactor,
    rectangleAngle,
    ellipseAngle,
    crop,
    transform,
    nearCrop,
    nearTransform,
    transformCanvas,
    transformSelection,
    selectionLayer,
    version,
    gradientPoints,
    shiftM,
    altM,
    shiftRelease,
    altRelease,
    guidePoints,
    nearLine,
    nearPerspective,
    nearGrid,
    magnetLayer,
    cursorStartPoint,
    line,
    lineWidth,
  ]);

  useEffect(() => {  
    const oX = ((translate.x + 50) / 100) * resolution.width;
    const oY = ((translate.y + 50) / 100) * resolution.height;
    const centerX = resolution.width / 2 - oX;
    const centerY = resolution.height / 2 - oY;
    setViewportCenter({x: centerX, y: centerY});
  }, [scale, resolution, translate]);

  useEffect(() => {    
    const updateTranslate = () => {
      if(deltaTranslate){
        setTranslate({
          x: translate.x + deltaTranslate.x * 1.3 / scale,
          y: translate.y + deltaTranslate.y * 1.3 / scale
        })
      }
    };

    // Cancel the previous frame before scheduling a new one
    if (translateFrameId) {
      cancelAnimationFrame(translateFrameId);
    }

    // Schedule the next frame
    const newTranslateFrameId = requestAnimationFrame(updateTranslate);
    setTranslateFrameId(newTranslateFrameId);
  }, [translate, deltaTranslate]);

  useEffect(() => {
    const wrapperCurrent = wrapper.current;
    
    if (!wrapperCurrent) return;
  
    const addEventListeners = () => {
      wrapperCurrent.addEventListener("wheel", handleWheel, { passive: false });
      wrapperCurrent.addEventListener("pointerdown", handlePointerDown);
      window.addEventListener("pointerup", handlePointerUp);
      window.addEventListener("pointercancel", handlePointerUp);
      document.addEventListener("pointermove", handlePointerMove);
      document.addEventListener("pointerout", handleDocumentOut);
    };
  
    const removeEventListeners = () => {
      wrapperCurrent.removeEventListener("wheel", handleWheel);
      wrapperCurrent.removeEventListener("pointerdown", handlePointerDown);
      window.removeEventListener("pointerup", handlePointerUp);
      window.removeEventListener("pointercancel", handlePointerUp);
      document.removeEventListener("pointermove", handlePointerMove);
      document.removeEventListener("pointerout", handleDocumentOut);
    };
  
    addEventListeners();
  
    return () => {
      removeEventListeners();
      // Cancel the animation frame when the component unmounts or event listener is removed
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [handleWheel, handlePointerOut, handlePointerUp, handlePointerDown, handlePointerMove, animationFrameId]);
  
  

  useEffect(() => {
    if(loading){
      setCursor("wait");
    } else if (isDragging) {
      setCursor("grabbing");
    } else if (isSpaceDown || selectedTool === "hand") {
      setCursor("grab");
    }  else if(selectedTool === "eyedropper" || selectedTool === "rectangle" || selectedTool === "ellipse"){
      setCursor("crosshair");
    } else if(selectedTool === "zoom"){
      if(invertZoom){
        if(altKey || altButton){
          setCursor("zoom-in");
        } else {
          setCursor("zoom-out");
        }
      } else {
        if(altKey || altButton){
          setCursor("zoom-out");
        } else {
          setCursor("zoom-in");
        }
      }
    } else if (
      !layers[selectedLayer].visible || 
      (
        layers[selectedLayer].type !== "Canvas" &&
          (
            selectedTool === "pencil" ||
            selectedTool === "eraser" ||
            selectedTool === "bucket" ||
            selectedTool === "gradient" ||
            selectedTool === "wand" ||
            selectedTool === "transform" ||
            selectedTool === "line"
          )
      )
    ){
      setCursor("not-allowed");
    } else if(selectedTool === "pencil"){
      setCursor("none");
    } else if(selectedTool === "erase"){
      setCursor("none");
    } else if(selectedTool === "line"){
      if(selectedSwatch === 32){
        setCursor("not-allowed");
      } else {
        setCursor("crosshair");
      }
    } else if(selectedTool === "wand" || selectedTool === "bucket"){
      setCursor("crosshair");
    } else if(selectedTool === "crop"){
      switch(nearCrop){
        case 0:
        default:
          setCursor("move");
          break;
        case 1:
        case 5:
          setCursor("nwse-resize");
          break;
        case 2:
        case 6:
          setCursor("ns-resize");
          break;
        case 3:
        case 7:
          setCursor("nesw-resize");
          break;
        case 4:
        case 8:
          setCursor("ew-resize");
          break;
      }
    } else if(selectedTool === "transform" && transform){
      switch(nearTransform){
        case -1:
          setCursor("default");
          break;
        case 0:
          setCursor('move');
          break;
        case 1:
        case 5:
          setCursor("nwse-resize");
          break;
        case 2:
        case 6:
          setCursor("ns-resize");
          break;
        case 3:
        case 7:
          setCursor("nesw-resize");
          break;
        case 4:
        case 8:
          setCursor("ew-resize");
          break;
        case 9:
          setCursor(`url(${rotateTopLeft}) 16 16, auto`);
          break;
        case 10:
          setCursor(`url(${rotateTopRight}) 16 16, auto`);
          break;
        case 11:
          setCursor(`url(${rotateBottomRight}) 16 16, auto`);
          break;
        case 12:
          setCursor(`url(${rotateBottomLeft}) 16 16, auto`);
          break;
      }
    } else {
      setCursor("default");
    }
  }, [loading, isDragging, isSpaceDown, layers, selectedLayer, selectedTool, selectedSwatch, invertZoom, altKey, shiftKey, altButton, metaButton, shiftButton, nearCrop, transform, nearTransform, cursorStartPoint]);


  useEffect(() => {
    let canvasScale = canvasHeight === 'auto' ? bg.current.clientWidth / resolution.width :  bg.current.clientHeight / resolution.height;
    const lineLength = 6 / canvasScale / scale;
    if(shiftKey || shiftButton){
      if(altKey || altButton){
        if(!selectionStart.current){
          setShiftM(true);
          setAltM(true);
        }
        if(hasSelection && (!selectionStart.current || selectedTool === "wand")){
          setIconPath((
            <>
              <line
                key="plus-diag1-white"
                x1={lastCoordinate.current.x + lineLength}
                x2={lastCoordinate.current.x + lineLength * 2}
                y1={lastCoordinate.current.y + lineLength * 2}
                y2={lastCoordinate.current.y + lineLength}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
              <line
                key="plus-diag2-white"
                x1={lastCoordinate.current.x + lineLength}
                x2={lastCoordinate.current.x + lineLength * 2}
                y1={lastCoordinate.current.y + lineLength}
                y2={lastCoordinate.current.y + lineLength * 2}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
              <line
                key="plus-diag1-black"
                x1={lastCoordinate.current.x + lineLength}
                x2={lastCoordinate.current.x + lineLength * 2}
                y1={lastCoordinate.current.y + lineLength * 2}
                y2={lastCoordinate.current.y + lineLength}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
              <line
                key="plus-diag2-black"
                x1={lastCoordinate.current.x + lineLength}
                x2={lastCoordinate.current.x + lineLength * 2}
                y1={lastCoordinate.current.y + lineLength}
                y2={lastCoordinate.current.y + lineLength * 2}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
            </>
          ));
        }
      } else{
        setAltRelease(true);
        if(!selectionStart.current){
          setShiftM(true);
          setAltM(false);
        }
        if(hasSelection && (!selectionStart.current || selectedTool === "wand")){
          setIconPath((
            <>
              <line
                key="plus-horizontal-white"
                x1={lastCoordinate.current.x + lineLength}
                x2={lastCoordinate.current.x + lineLength * 2}
                y1={lastCoordinate.current.y + lineLength * 1.5}
                y2={lastCoordinate.current.y + lineLength * 1.5}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
              <line
                key="plus-verticle-white"
                x1={lastCoordinate.current.x + lineLength * 1.5}
                x2={lastCoordinate.current.x + lineLength * 1.5 }
                y1={lastCoordinate.current.y + lineLength}
                y2={lastCoordinate.current.y + lineLength * 2}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
              <line
                key="plus-horizontal-black"
                x1={lastCoordinate.current.x + lineLength}
                x2={lastCoordinate.current.x + lineLength * 2}
                y1={lastCoordinate.current.y + lineLength * 1.5}
                y2={lastCoordinate.current.y + lineLength * 1.5}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
              <line
                key="plus-verticle-black"
                x1={lastCoordinate.current.x + lineLength * 1.5}
                x2={lastCoordinate.current.x + lineLength * 1.5 }
                y1={lastCoordinate.current.y + lineLength}
                y2={lastCoordinate.current.y + lineLength * 2}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
            </>
          ));
        }
      } 
    } else {
      moveAngles.current = null;

      if(!selectionStart.current) setShiftM(false);
      setShiftRelease(true);
      if(altKey || altButton){
        if(!selectionStart.current) setAltM(true);
        if(hasSelection && (!selectionStart.current || selectedTool === "wand")){
          setIconPath((
            <>
              <line
                key="minus-white"
                x1={lastCoordinate.current.x + lineLength}
                x2={lastCoordinate.current.x + lineLength * 2}
                y1={lastCoordinate.current.y + lineLength * 1.5}
                y2={lastCoordinate.current.y + lineLength * 1.5}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
              <line
                key="minus-black"
                x1={lastCoordinate.current.x + lineLength}
                x2={lastCoordinate.current.x + lineLength * 2}
                y1={lastCoordinate.current.y + lineLength * 1.5}
                y2={lastCoordinate.current.y + lineLength * 1.5}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
            </>
          ));
        }
      } else{
        if(!selectionStart.current){
          setAltM(false);
          setIconPath(null);
        }
        setAltRelease(true);
      }
    }
  }, [shiftKey, altKey, shiftButton, altButton, metaButton, hasSelection, canvasHeight, resolution]);

  useEffect(() => {
    if(selectedTool === "crop"){
      if(!crop){
        if(!hasSelection){
          setCrop({
            left: 0,
            top: 0,
            right: resolution.width,
            bottom: resolution.height
          });
          initialCrop.current =  {
            left: 0,
            top: 0,
            right: resolution.width,
            bottom: resolution.height
          };
        } else {
          const joinedEdges = [];
          for (const path of selectionEdges) for (const edge of path) joinedEdges.push(edge);
          const sortX = [...joinedEdges].sort((a, b) => { return a.x1 - b.x1; })
          const sortY = [...joinedEdges].sort((a, b) => { return a.y1 - b.y1; });
          const top = sortY[0].y1;
          const right = sortX[sortX.length-1].x1;
          const bottom = sortY[sortY.length-1].y1;
          const left = sortX[0].x1;
          setCrop({left, top, right, bottom});
          initialCrop.current =  {left, top, right, bottom};
        }
      }
    } else {
      setCrop(null);
      initialCrop.current = null;
    }

    const handleKeyDown = (e) => {
      if (document.activeElement && (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA')){
        if(document.activeElement.className === "layer-name" && e.keyCode === 13) document.activeElement.blur();

        // Optionally, you can check for specific types of inputs like 'text', 'number', etc.
        if (document.activeElement.type === 'text' || document.activeElement.type === 'number' || document.activeElement.type === 'email'|| document.activeElement.type === 'password' ) {
          return; // Do not execute hotkey action if a text input field is focused
        }
      }

      if(selectedTool === "crop"){
        switch(e.keyCode){
          case 27: //esc
            setCrop(null);
            break;
          case 13: //enter
            const width = Math.round(crop.right - crop.left);
            const height = Math.round(crop.bottom-crop.top);
            if(offset.x !== crop.left || offset.y !== crop.top || resolution.width !== width || resolution.height !== height){
              applyCrop();
              setCrop({
                left: 0,
                top: 0,
                right: width,
                bottom: crop.bottom - crop.top
              });
              initialCrop.current =  {
                left: 0,
                top: 0,
                right: width,
                bottom: height
              };
            } 
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    selectedTool,
    crop,
    selectedLayer,
    resolution,
    hasSelection,
    selectionEdges,
    version,
  ]);

  const flipX = useCallback(() => {
    const canvas = document.createElement('canvas');
    canvas.width = transformCanvas.width;
    canvas.height = transformCanvas.height;
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.scale(-1, 1);
    ctx.drawImage(transformCanvas, -canvas.width, 0, canvas.width, canvas.height);
    setTransformCanvas(canvas);

    if(transformSelection){
      const selectionCanvas = document.createElement('canvas');
      selectionCanvas.width = transformSelection.width;
      selectionCanvas.height = transformSelection.height;
      const selectionCtx = selectionCanvas.getContext('2d');
      selectionCtx.imageSmoothingEnabled = false;
      selectionCtx.scale(-1, 1);
      selectionCtx.drawImage(transformSelection, -selectionCanvas.width, 0, selectionCanvas.width, selectionCanvas.height);
      setTransformSelection(selectionCanvas);
    }
  }, [transformCanvas, transformSelection]);

  const flipY = useCallback(() => {
    const canvas = document.createElement('canvas');
    canvas.width = transformCanvas.width;
    canvas.height = transformCanvas.height;
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.scale(1, -1);
    ctx.drawImage(transformCanvas, 0, -canvas.height, canvas.width, canvas.height);
    setTransformCanvas(canvas);

    if(transformSelection){
      const selectionCanvas = document.createElement('canvas');
      selectionCanvas.width = transformSelection.width;
      selectionCanvas.height = transformSelection.height;
      const selectionCtx = selectionCanvas.getContext('2d');
      selectionCtx.imageSmoothingEnabled = false;
      selectionCtx.scale(1, -1);
      selectionCtx.drawImage(transformSelection, 0, -selectionCanvas.height, selectionCanvas.width, selectionCanvas.height);
      setTransformSelection(selectionCanvas);
    }
  }, [transformCanvas, transformSelection]);

  const flipXY = useCallback(() => {
    const canvas = document.createElement('canvas');
    canvas.width = transformCanvas.width;
    canvas.height = transformCanvas.height;
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.scale(-1, -1);
    ctx.drawImage(transformCanvas, -canvas.width, -canvas.height, canvas.width, canvas.height);
    setTransformCanvas(canvas);

    if(transformSelection){
      const selectionCanvas = document.createElement('canvas');
      selectionCanvas.width = transformSelection.width;
      selectionCanvas.height = transformSelection.height;
      const selectionCtx = selectionCanvas.getContext('2d');
      selectionCtx.imageSmoothingEnabled = false;
      selectionCtx.scale(-1, -1);
      selectionCtx.drawImage(transformSelection, selectionCanvas.width, -selectionCanvas.height, selectionCanvas.width, selectionCanvas.height);
      setTransformSelection(selectionCanvas);
    }
  }, [transformCanvas, transformSelection]);

  const rotateCW = useCallback(() => {
    const canvas = document.createElement('canvas');
    canvas.height = transformCanvas.width;
    canvas.width = transformCanvas.height;
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.rotate(90 * Math.PI / 180);
    ctx.drawImage(transformCanvas, 0, -canvas.width, canvas.height, canvas.width);
    setTransformCanvas(canvas);
  
    if (transformSelection) {
      const selectionCanvas = document.createElement('canvas');
      selectionCanvas.height = transformSelection.width;
      selectionCanvas.width = transformSelection.height;
      const selectionCtx = selectionCanvas.getContext('2d');
      selectionCtx.imageSmoothingEnabled = false;
      selectionCtx.rotate(90 * Math.PI / 180);
      selectionCtx.drawImage(transformSelection, 0, -selectionCanvas.width, selectionCanvas.height, selectionCanvas.width);
      setTransformSelection(selectionCanvas);
    }
  
    const pivotX = transformRef.current.pivotX;
    const pivotY = transformRef.current.pivotY;
    const width = transformRef.current.right - transformRef.current.left;
    const height = transformRef.current.bottom - transformRef.current.top;
    const offsetX =  transformRef.current.bottom - pivotY;
    const offsetY = pivotX - transformRef.current.left;

    transformRef.current.left = pivotX -offsetX;
    transformRef.current.top = pivotY - offsetY;
    transformRef.current.right = transformRef.current.left + height;
    transformRef.current.bottom = transformRef.current.top + width;
        
    const lW = layers[selectedLayer].ref.current.width;
    const lH = layers[selectedLayer].ref.current.height;

    let left = layers[selectedLayer].x - offset.x + lW - maxLayerSize;
    let top = layers[selectedLayer].y - offset.y + lH - maxLayerSize;
    let right = layers[selectedLayer].x - offset.x + maxLayerSize;
    let bottom = layers[selectedLayer].y - offset.y + maxLayerSize;

    left = Math.max(left, transform.right - maxLayerSize);
    top = Math.max(top, transform.bottom - maxLayerSize);
    right = Math.min(right, transform.left + maxLayerSize);
    bottom = Math.min(bottom, transform.top + maxLayerSize);

    if(transformRef.current.left < left){
      const dif = left - transformRef.current.left;
      transformRef.current.left += dif;
      transformRef.current.right += dif;
    }
    if(transformRef.current.top < top){
      const dif = top - transformRef.current.top;
      transformRef.current.top += dif;
      transformRef.current.bottom += dif;
    }
    if(transformRef.current.right > right){
      const dif = transformRef.current.right - right;
      transformRef.current.left -= dif;
      transformRef.current.right -= dif;
    }
    if(transformRef.current.bottom > bottom){
      const dif = transformRef.current.bottom - bottom;
      transformRef.current.top -= dif;
      transformRef.current.bottom -= dif;
    }
  }, [transformCanvas, transformSelection, layers, selectedLayer]);  

  const rotateCCW = useCallback(() => {
    const canvas = document.createElement('canvas');
    canvas.height = transformCanvas.width;
    canvas.width = transformCanvas.height;
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.rotate(-90 * Math.PI / 180);
    ctx.drawImage(transformCanvas, -canvas.height, 0, canvas.height, canvas.width);
    setTransformCanvas(canvas);

    if(transformSelection){
      const selectionCanvas = document.createElement('canvas');
      selectionCanvas.height = transformSelection.width;
      selectionCanvas.width = transformSelection.height;
      const selectionCtx = selectionCanvas.getContext('2d');
      selectionCtx.imageSmoothingEnabled = false;
      selectionCtx.rotate(-90 * Math.PI / 180);
      selectionCtx.drawImage(transformSelection, -selectionCanvas.height, 0, selectionCanvas.height, selectionCanvas.width);
      setTransformSelection(selectionCanvas);
    }
  
    const pivotX = transformRef.current.pivotX;
    const pivotY = transformRef.current.pivotY;
    const width = transformRef.current.right - transformRef.current.left;
    const height = transformRef.current.bottom - transformRef.current.top;
    const offsetX =  pivotY - transformRef.current.top;
    const offsetY = transformRef.current.right - pivotX;

    transformRef.current.left = pivotX -offsetX;
    transformRef.current.top = pivotY - offsetY;
    transformRef.current.right = transformRef.current.left + height;
    transformRef.current.bottom = transformRef.current.top + width;
        
    const lW = layers[selectedLayer].ref.current.width;
    const lH = layers[selectedLayer].ref.current.height;

    let left = layers[selectedLayer].x - offset.x + lW - maxLayerSize;
    let top = layers[selectedLayer].y - offset.y + lH - maxLayerSize;
    let right = layers[selectedLayer].x - offset.x + maxLayerSize;
    let bottom = layers[selectedLayer].y - offset.y + maxLayerSize;

    left = Math.max(left, transform.right - maxLayerSize);
    top = Math.max(top, transform.bottom - maxLayerSize);
    right = Math.min(right, transform.left + maxLayerSize);
    bottom = Math.min(bottom, transform.top + maxLayerSize);

    if(transformRef.current.left < left){
      const dif = left - transformRef.current.left;
      transformRef.current.left += dif;
      transformRef.current.right += dif;
    }
    if(transformRef.current.top < top){
      const dif = top - transformRef.current.top;
      transformRef.current.top += dif;
      transformRef.current.bottom += dif;
    }
    if(transformRef.current.right > right){
      const dif = transformRef.current.right - right;
      transformRef.current.left -= dif;
      transformRef.current.right -= dif;
    }
    if(transformRef.current.bottom > bottom){
      const dif = transformRef.current.bottom - bottom;
      transformRef.current.top -= dif;
      transformRef.current.bottom -= dif;
    }
  }, [transformCanvas, transformSelection, layers, selectedLayer]);

  const transformCallback = useCallback(() => { //start transform
    if(layers[selectedLayer].type !== "Canvas") return;
    setSelectedTool("transform");
    if(!transform){
      previousLayer.current = selectedLayer;
      previousViewport.current = layout;
      if(!hasSelection){
        const layer = layers[selectedLayer].ref.current;
        const layerCtx = layer.getContext("2d", { willReadFrequently: true });
        const imageData = layerCtx.getImageData(0, 0, layer.width, layer.height);
        const data = imageData.data;

        let left, top, right, bottom;
        for (let x = 0; x < layer.width; x++) {
          for (let y = 0; y < layer.height; y++) {
            const index = (y * layer.width + x) * 4;
            const isOpaque = data[index + 3] > 0;
            if(isOpaque){
              if(left === undefined || left > x) left = x;
              if(top === undefined || top > y) top = y;
              if(right  === undefined|| right <= x) right = x + 1;
              if(bottom === undefined || bottom <= y) bottom = y + 1;
            }
          }
        }
        const width = right - left;
        const height = bottom - top;

        if(!width || !height) return alert('No pixels are present.');

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(
          layer,
          left,
          top,
          width,
          height,
          0,
          0,
          width,
          height
        );

        left += layers[selectedLayer].x - offset.x;
        top += layers[selectedLayer].y - offset.y;
        right += layers[selectedLayer].x - offset.x;
        bottom += layers[selectedLayer].y - offset.y;
        
        setTransform({ left, top, right, bottom, pivotX: left + (right - left)/2, pivotY: top + (bottom - top)/2});
        transformRef.current =  { left, top, right, bottom, pivotX: left + (right - left)/2, pivotY: top + (bottom - top)/2};
        setTransformStart({ left, top, right, bottom, pivotX: left + (right - left)/2, pivotY: top + (bottom - top)/2});

        const canvasCtx = canvases[selectedLayer].current.getContext("2d");
        canvasCtx.imageSmoothingEnabled = false;
        canvasCtx.clearRect(0,0,resolution.width,resolution.height);

        const transformCtx = transformLayer.current.getContext("2d");
        transformCtx.imageSmoothingEnabled = false;
        transformCtx.drawImage(layer,left,top);

        setTransformCanvas(canvas);
        setTransformStartCanvas(canvas);
      } else {
        const joinedEdges = [];
        for (const path of selectionEdges) for (const edge of path) joinedEdges.push(edge);
        const sortX = [...joinedEdges].sort((a, b) => { return a.x1 - b.x1; })
        const sortY = [...joinedEdges].sort((a, b) => { return a.y1 - b.y1; });
        const top = sortY[0].y1;
        const right = sortX[sortX.length-1].x1;
        const bottom = sortY[sortY.length-1].y1;
        const left = sortX[0].x1;
        setTransform({left, top, right, bottom, pivotX: left + (right - left)/2, pivotY: top + (bottom - top)/2});
        transformRef.current =  {left, top, right, bottom, pivotX: left + (right - left)/2, pivotY: top + (bottom - top)/2};
        setTransformStart({left, top, right, bottom, pivotX: left + (right - left)/2, pivotY: top + (bottom - top)/2});

        const canvas = document.createElement("canvas");
        canvas.width = right - left;
        canvas.height = bottom - top;
        const ctx = canvas.getContext("2d");
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(
          selectionLayer.current,
          left,
          top,
          right - left,
          bottom - top,
          0,
          0,
          right - left,
          bottom - top
        );
        ctx.globalCompositeOperation = "source-in";
        ctx.drawImage(
          canvases[selectedLayer].current,
          left,
          top,
          right - left,
          bottom - top,
          0,
          0,
          right - left,
          bottom - top
        );
        ctx.globalCompositeOperation = "source-over";

        const canvasCtx = canvases[selectedLayer].current.getContext("2d");
        canvasCtx.imageSmoothingEnabled = false;
        canvasCtx.globalCompositeOperation = "destination-out";
        canvasCtx.drawImage(canvas, left, top);
        canvasCtx.globalCompositeOperation = "source-over";

        const transformCtx = transformLayer.current.getContext("2d");
        transformCtx.imageSmoothingEnabled = false;
        transformCtx.drawImage(canvas, left, top);

        const selectionCanvas = document.createElement("canvas");
        selectionCanvas.width = right - left;
        selectionCanvas.height = bottom - top;
        const selectionCanvasCtx = selectionCanvas.getContext("2d");
        selectionCanvasCtx.imageSmoothingEnabled = false;
        selectionCanvasCtx.drawImage(
          selectionLayer.current, 
          left,
          top,
          right - left,
          bottom - top,
          0,
          0,
          right - left,
          bottom - top
        );

        const selectionCtx = selectionLayer.current.getContext("2d");
        selectionCtx.imageSmoothingEnabled = false;
        selectionCtx.clearRect(0,0,resolution.width,resolution.height);
        setUpdateSelection((prevValue) => prevValue + 1)
        
        setTransformStartCanvas(canvas);
        setTransformCanvas(canvas);
        setTransformStartSelection(selectionCanvas);
        setTransformSelection(selectionCanvas);
      }
    }
  }, [
    selectedTool,
    transform,
    layers,
    canvases,
    selectedLayer,
    selectionLayer,
    resolution,
    offset,
    hasSelection,
    selectionEdges,
    transformStart,
    version,
    transformVersion,
    selectedViewport,
  ]);

  useEffect(() => {
    if(transform){ //clear transform on appropriate hooks
      if(
        version === perviousVersion.current &&
        (
          selectedLayer !== previousLayer.current ||
          selectedTool !== "transform" ||
          perviousTransformVersion.current !== transformVersion ||
          !layers[selectedLayer].visible ||
          (
            previousViewport.current === layout &&
            selectedViewport !== layout
          )
        )
      ){
        if(canvases[previousLayer.current] && canvases[previousLayer.current].current){
          const previousCtx = canvases[previousLayer.current].current.getContext("2d");
          previousCtx.imageSmoothingEnabled = false;
          previousCtx.drawImage(
            transformStartCanvas,
            transformStart.left,
            transformStart.top,
            Math.abs(transformStart.right - transformStart.left),
            Math.abs(transformStart.bottom - transformStart.top)
          );
    
          const selectionCtx = selectionLayer.current.getContext("2d");
          selectionCtx.imageSmoothingEnabled = false;
          selectionCtx.clearRect(0,0,resolution.width,resolution.height);
          if(transformStartSelection) selectionCtx.drawImage(
            transformStartSelection, 
            transformStart.left,
            transformStart.top,
            transformStart.right - transformStart.left,
            transformStart.bottom - transformStart.top
          );
          setUpdateSelection((prevValue) => prevValue + 1);
        }

        if(transformLayer.current) {
          const transformCtx = transformLayer.current.getContext("2d");
          transformCtx.clearRect(0,0,resolution.width,resolution.height);
        }

        setTransform(null);
        setTransformStart(null);
        transformRef.current = null;
        setTransformCanvas(null);
        setTransformStartCanvas(null);
        setTransformSelection(null);
        setTransformStartSelection(null);
        previousLayer.current = selectedLayer;
        perviousTransformVersion.current = transformVersion;
        previousViewport.current = null;
      }
    }

    const handleKeyDown = (e) => {
      if (document.activeElement && (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA')){
        if(document.activeElement.className === "layer-name" && e.keyCode === 13) document.activeElement.blur();

        // Optionally, you can check for specific types of inputs like 'text', 'number', etc.
        if (document.activeElement.type === 'text' || document.activeElement.type === 'number' || document.activeElement.type === 'email'|| document.activeElement.type === 'password' ) {
          return; // Do not execute hotkey action if a text input field is focused
        }
      }

      if(selectedViewport !== layout) return;

      if(e.key.toLowerCase() === 't'){
        transformCallback();
      } else if(selectedTool === "transform" && transform){
        switch(e.keyCode){
          case 27: //esc (clear transform)
            resetTransformCallback();
            break;
          case 13: //enter (apply tansform)
            applyTransformCallback();
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    selectedTool,
    transform,
    layers,
    canvases,
    selectedLayer,
    resolution,
    hasSelection,
    selectionEdges,
    transformStart,
    transformStartCanvas,
    transformStartSelection,
    transformCanvas,
    transformSelection,
    version,
    transformVersion,
    selectedViewport,
  ]);

  const applyTransformCallback = useCallback(() => {
    if(!transform) return;

    applyTransform(selectedLayer, canvases[selectedLayer], selectionLayer);
            
    const selectionCtx = selectionLayer.current.getContext("2d");
    selectionCtx.imageSmoothingEnabled = false;
    selectionCtx.clearRect(0,0,resolution.width,resolution.height);
    if(transformSelection) selectionCtx.drawImage(
      transformSelection, 
      transform.left,
      transform.top,
      transform.right - transform.left,
      transform.bottom - transform.top
    );

    const transformCtx = transformLayer.current.getContext("2d");
    transformCtx.clearRect(0,0,resolution.width,resolution.height);

    setTransform(null);
    setTransformStart(null);
    transformRef.current = null;
    setTransformCanvas(null);
    setTransformStartCanvas(null);
    setTransformSelection(null);
    setTransformStartSelection(null);
  }, [
    selectedTool,
    transform,
    layers,
    canvases,
    selectedLayer,
    resolution,
    hasSelection,
    selectionEdges,
    transformStart,
    transformStartCanvas,
    transformStartSelection,
    transformCanvas,
    transformSelection,
    version,
    transformVersion,
    selectedViewport,
  ]);

  const resetTransformCallback = useCallback(() => {
    if(!transform) return;

    const previousCtx = canvases[selectedLayer].current.getContext("2d");
    previousCtx.imageSmoothingEnabled = false;
    previousCtx.drawImage(
      transformStartCanvas,
      transformStart.left,
      transformStart.top,
      Math.abs(transformStart.right - transformStart.left),
      Math.abs(transformStart.bottom - transformStart.top)
    );

    const selectionCtx = selectionLayer.current.getContext("2d");
    selectionCtx.imageSmoothingEnabled = false;
    selectionCtx.clearRect(0,0,resolution.width,resolution.height);
    if(transformStartSelection) selectionCtx.drawImage(
      transformStartSelection, 
      transformStart.left,
      transformStart.top,
      transformStart.right - transformStart.left,
      transformStart.bottom - transformStart.top
    );
    setUpdateSelection((prevValue) => prevValue + 1);

    const transformCtx = transformLayer.current.getContext("2d");
    transformCtx.clearRect(0,0,resolution.width,resolution.height);

    setTransform(null);
    setTransformStart(null);
    transformRef.current = null;
    setTransformCanvas(null);
    setTransformStartCanvas(null);
    setTransformSelection(null);
    setTransformStartSelection(null)
  }, [
    selectedTool,
    transform,
    layers,
    canvases,
    selectedLayer,
    resolution,
    hasSelection,
    selectionEdges,
    transformStart,
    transformStartCanvas,
    transformStartSelection,
    transformCanvas,
    transformSelection,
    version,
    transformVersion,
    selectedViewport,
  ]);

  useEffect(() => { //start transform from header
    if(transformNow !== 0 &&  selectedViewport === layout) transformCallback();
  }, [transformNow]);

  useEffect(() => { //start transform from header
    if(applyTransformNow !== 0 &&  selectedViewport === layout) applyTransformCallback();
  }, [applyTransformNow]);

  useEffect(() => { //start transform from header
    if(resetTransformNow !== 0 &&  selectedViewport === layout) resetTransformCallback();
  }, [resetTransformNow]);

  useEffect(() => { //clear transform on version change
    if(transformLayer.current){
      const transformCtx = transformLayer.current.getContext("2d");
      transformCtx.clearRect(0,0,transformLayer.current.width,transformLayer.current.height);
      setTransform(null);
      setTransformStart(null);
      transformRef.current = null;
      setTransformCanvas(null);
      setTransformSelection(null);
      perviousVersion.current = version;
    }
  }, [version]);

  useEffect(() => {
    if(selectionLayer.current){
      const paths = findPathsInCanvas(selectionLayer.current);
      setSelectionEdges(paths);
      if(paths.length === 0){
        setHasSelection(false);
      } else {
        setHasSelection(true);
      }
    }
  }, [updateSelection]);

  useEffect(() => {    
    const updateSelection = () => {
      if(!bg.current) return;
      let canvasScale = canvasHeight === 'auto' ? bg.current.clientWidth / resolution.width :  bg.current.clientHeight / resolution.height;

      setWhitePathsComp(null);
      setBlackPathsComp(null);
      setToolPath(null);
      setMovePath(null);
      setCropPath(null);
      setTransformPath(null);
      setGuidesPath(null);

      
                
      let d, left, top, right, bottom;

      if((selectionEdges && selectionEdges.length > 0) && !transform){    
        // Create paths as React components
        const blackPaths = selectionEdges.map((path, index) => {
          let d = ""
          path.forEach(edge => {
              if (d === "") {
                  d += `M ${edge.x1},${edge.y1} `;
              }
              d += `L ${edge.x2},${edge.y2} `;
          });
          d += `L ${path[0].x1},${path[0].y1} Z`;

          return (
            <path key={`black-${index}`} className="animated-dash1" d={d} fill="none" stroke="black" strokeWidth={1.5/window.devicePixelRatio} strokeDasharray="4" vectorEffect="non-scaling-stroke" />
          );
        });

        // Create paths as React components
        const whitePaths = selectionEdges.map((path, index) => {
          let d = ""
          path.forEach(edge => {
              if (d === "") {
                  d += `M ${edge.x1},${edge.y1} `;
              }
              d += `L ${edge.x2},${edge.y2} `;
          });
          d += `L ${path[0].x1},${path[0].y1} Z`;

          return (
            <path key={`white-${index}`} className="animated-dash2" d={d} fill="none" stroke="white" strokeWidth={1.5/window.devicePixelRatio} strokeDasharray="4"  vectorEffect="non-scaling-stroke" />
          );
        });
    
        setWhitePathsComp(whitePaths);
        setBlackPathsComp(blackPaths);
      }
      
      if(toolEdges && toolEdges.length > 0){
        const blackPaths = toolEdges.map((path, index) => {
          let d = ""
          path.forEach(edge => {
              if (d === "") {
                  d += `M ${edge.x1},${edge.y1} `;
              }
              d += `L ${edge.x2},${edge.y2} `;
          });
          d += `L ${path[0].x1},${path[0].y1} Z`;

          return (
            <path key={`black-${index}`} d={d} fill="none" stroke="black" strokeWidth={3/window.devicePixelRatio} vectorEffect="non-scaling-stroke" />
          );
        });
        
        const whitePaths = toolEdges.map((path, index) => {
          let d = ""
          path.forEach(edge => {
              if (d === "") {
                  d += `M ${edge.x1},${edge.y1} `;
              }
              d += `L ${edge.x2},${edge.y2} `;
          });
          d += `L ${path[0].x1},${path[0].y1} Z`;

          return (
            <path key={`white-${index}`} d={d} fill="none" stroke="white" strokeWidth={1.5/window.devicePixelRatio} vectorEffect="non-scaling-stroke" />
          );
        });

        setToolPath([blackPaths,whitePaths]);
      }
      
      if(gradientPoints) {
        const lineLength = 6 / canvasScale / scale;

        const startHorizontal = (
          <line
            key="start-horizontal"
            x1={gradientPoints.x - lineLength}
            x2={gradientPoints.x + lineLength}
            y1={gradientPoints.y}
            y2={gradientPoints.y}
            stroke="limegreen"
            strokeWidth={1.5 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        );

        // Create vertical crosshair
        const startVertical = (
          <line
            key="start-vertical"
            x1={gradientPoints.x}
            x2={gradientPoints.x}
            y1={gradientPoints.y - lineLength}
            y2={gradientPoints.y + lineLength}
            stroke="limegreen"
            strokeWidth={1.5 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        );
        const endHorizontal = (
          <line
            key="end-horizontal"
            x1={gradientPoints.x2 - lineLength}
            x2={gradientPoints.x2 + lineLength}
            y1={gradientPoints.y2}
            y2={gradientPoints.y2}
            stroke="limegreen"
            strokeWidth={1.5 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        );

        // Create vertical crosshair
        const endVertical = (
          <line
            key="end-vertical"
            x1={gradientPoints.x2}
            x2={gradientPoints.x2}
            y1={gradientPoints.y2 - lineLength}
            y2={gradientPoints.y2 + lineLength}
            stroke="limegreen"
            strokeWidth={1.5 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        );

        // Create gradient line
        const gradientLine = (
          <line
            key="gradient-line"
            x1={gradientPoints.x}
            x2={gradientPoints.x2}
            y1={gradientPoints.y}
            y2={gradientPoints.y2}
            stroke="limegreen"
            strokeWidth={1.5 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        );

        setToolPath([startHorizontal,startVertical,endHorizontal,endVertical,gradientLine]);
      }

      const movePaths = [];

      if(selectedTool === "move"){
        for(const index of selectedLayers){
          const layer = layers[index];
          if(layer.type !== "Canvas") continue;

          const layerLeft = layer.x;
          const layerTop = layer.y;
          const layerRight = layerLeft + layer.ref.current.width;
          const layerBottom = layerTop + layer.ref.current.height;

          d = `M ${move.x+layerLeft-offset.x},${move.y+layerTop-offset.y} `;
          d += `L ${move.x+layerRight-offset.x}, ${move.y+layerTop-offset.y}`;
          d += `L ${move.x+layerRight-offset.x}, ${move.y+layerBottom-offset.y}`;
          d += `L ${move.x+layerLeft-offset.x}, ${move.y+layerBottom-offset.y} Z`;
          
          movePaths.push((
            <path
              key={"layer-border-" + index}
              d={d}
              fill="none"
              stroke="limegreen"
              strokeWidth={1 / window.devicePixelRatio}
              strokeDasharray="4"
              vectorEffect="non-scaling-stroke"
            />
          ));
        }

        d = `M ${resolution.width - maxLayerSize},${resolution.height - maxLayerSize} `;
        d += `L ${maxLayerSize}, ${resolution.height - maxLayerSize}`;
        d += `L ${maxLayerSize}, ${maxLayerSize}`;
        d += `L ${resolution.width - maxLayerSize}, ${maxLayerSize} Z`;
        movePaths.push((
          <path
            key="layer-max"
            d={d}
            fill="none"
            stroke="limegreen"
            strokeWidth={1 / window.devicePixelRatio}
            strokeDasharray="4"
            vectorEffect="non-scaling-stroke"
          />
        ));
      }

      setMovePath(movePaths);

      if(crop){
        let cropPaths = [];

        d = `M ${crop.left},${crop.top} `;
        d += `L ${crop.right}, ${crop.top}`;
        d += `L ${crop.right}, ${crop.bottom}`;
        d += `L ${crop.left}, ${crop.bottom} Z`;
        cropPaths.push((
          <path
            key="crop-edge"
            d={d}
            fill="none"
            stroke="white"
            strokeWidth={2 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        ));

        const thirdX = (crop.right - crop.left) / 3;
        const thirdY = (crop.bottom - crop.top) / 3;

        d = `M ${crop.left + thirdX},${crop.top} `;
        d += `L ${crop.left + thirdX}, ${crop.bottom}`;
        cropPaths.push((
          <path
            key="crop-third-left"
            d={d}
            fill="none"
            stroke="white"
            strokeWidth={1 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        ));
        
        d = `M ${crop.right - thirdX},${crop.top} `;
        d += `L ${crop.right - thirdX}, ${crop.bottom}`;
        cropPaths.push((
          <path
            key="crop-third-right"
            d={d}
            fill="none"
            stroke="white"
            strokeWidth={1 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        ));
        
        d = `M ${crop.left},${crop.top + thirdY} `;
        d += `L ${crop.right}, ${crop.top + thirdY}`;
        cropPaths.push((
          <path
            key="crop-third-top"
            d={d}
            fill="none"
            stroke="white"
            strokeWidth={1 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        ));
        
        d = `M ${crop.left},${crop.bottom - thirdY} `;
        d += `L ${crop.right}, ${crop.bottom - thirdY}`;
        cropPaths.push((
          <path
            key="crop-third-bottom"
            d={d}
            fill="none"
            stroke="white"
            strokeWidth={1 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        ));

        const thumbLength = 50 / canvasScale / scale;
        if(crop.right - crop.left > thumbLength){
          const center = crop.left + Math.round((crop.right - crop.left)/2);
          
          d = `M ${center - Math.round(thumbLength/3)},${crop.bottom} `;
          d += `L ${center + Math.round(thumbLength/3)}, ${crop.bottom}`;
          cropPaths.push((
            <path
              key="crop-thumb-top"
              d={d}
              fill="none"
              stroke="white"
              strokeWidth={6 /window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          ));
          d = `M ${center - Math.round(thumbLength/3)},${crop.top} `;
          d += `L ${center + Math.round(thumbLength/3)}, ${crop.top}`;
          cropPaths.push((
            <path
              key="crop-thumb-bottom"
              d={d}
              fill="none"
              stroke="white"
              strokeWidth={6 /window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          ));
        }

        if(crop.bottom - crop.top > thumbLength){
          const center = crop.top + Math.round((crop.bottom - crop.top)/2);
          
          d = `M ${crop.left},${center - Math.round(thumbLength/3)} `;
          d += `L ${crop.left}, ${center + Math.round(thumbLength/3)}`;
          cropPaths.push((
            <path
              key="crop-thumb-left"
              d={d}
              fill="none"
              stroke="white"
              strokeWidth={6 /window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          ));

          d = `M ${crop.right},${center - Math.round(thumbLength/3)} `;
          d += `L ${crop.right}, ${center + Math.round(thumbLength/3)}`;
          cropPaths.push((
            <path
              key="crop-thumb-right"
              d={d}
              fill="none"
              stroke="white"
              strokeWidth={6 /window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          ));
        }

        if(crop.right - crop.left > thumbLength * 1.5 && crop.bottom - crop.top > thumbLength * 1.5 ){          
          d = `M ${crop.left + Math.round(thumbLength/3)},${crop.top} `;
          d += `L ${crop.left}, ${crop.top}`;
          d += `L ${crop.left}, ${crop.top + Math.round(thumbLength/3)}`;
          cropPaths.push((
            <path
              key="crop-corner-top-left"
              d={d}
              fill="none"
              stroke="white"
              strokeWidth={6 /window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          ));             
          d = `M ${crop.right - Math.round(thumbLength/3)},${crop.top} `;
          d += `L ${crop.right}, ${crop.top}`;
          d += `L ${crop.right}, ${crop.top + Math.round(thumbLength/3)}`;
          cropPaths.push((
            <path
              key="crop-corner-top-right"
              d={d}
              fill="none"
              stroke="white"
              strokeWidth={6 /window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          ));      
          d = `M ${crop.right},${crop.bottom - Math.round(thumbLength/3)} `;
          d += `L ${crop.right}, ${crop.bottom}`;
          d += `L ${crop.right - Math.round(thumbLength/3)}, ${crop.bottom}`;
          cropPaths.push((
            <path
              key="crop-corner-bottom-right"
              d={d}
              fill="none"
              stroke="white"
              strokeWidth={6 /window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          )); 
          d = `M ${crop.left},${crop.bottom - Math.round(thumbLength/3)} `;
          d += `L ${crop.left}, ${crop.bottom}`;
          d += `L ${crop.left + Math.round(thumbLength/3)}, ${crop.bottom}`;
          cropPaths.push((
            <path
              key="crop-corner-bottom-left"
              d={d}
              fill="none"
              stroke="white"
              strokeWidth={6 /window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          ));
        }
        
        left = offset.x + resolution.width - maxLayerSize;
        top = offset.y + resolution.height - maxLayerSize;
        right = offset.x + maxLayerSize;
        bottom = offset.y + maxLayerSize;
        for(const layer of layers){
          if(layer.type !== "Canvas") continue;
          const layerLeft = layer.x;
          const layerTop = layer.y;
          const layerRight = layerLeft + layer.ref.current.width;
          const layerBottom = layerTop + layer.ref.current.height;

          left = Math.max(left, layerRight - maxLayerSize);
          top = Math.max(top, layerBottom - maxLayerSize);
          right = Math.min(right, layerLeft + maxLayerSize);
          bottom = Math.min(bottom, layerTop + maxLayerSize)
        }

        d = `M ${left - offset.x},${top -offset.y} `;
        d += `L ${right - offset.x}, ${top - offset.y}`;
        d += `L ${right - offset.x}, ${bottom - offset.y}`;
        d += `L ${left - offset.x}, ${bottom - offset.y} Z`;
        cropPaths.push((
          <path
            key="crop-max"
            d={d}
            fill="none"
            stroke="limegreen"
            strokeWidth={1 / window.devicePixelRatio}
            strokeDasharray="4"
            vectorEffect="non-scaling-stroke"
          />
        ));

        setCropPath(cropPaths);
      }
      
      if(transform && transformRef.current && layout === selectedViewport && layers[selectedLayer].type === "Canvas"){
        let transformPaths = [];
        
        const lW = layers[selectedLayer].ref.current.width;
        const lH = layers[selectedLayer].ref.current.height;
  
        left = layers[selectedLayer].x - offset.x + lW - maxLayerSize;
        top = layers[selectedLayer].y - offset.y + lH - maxLayerSize;
        right = layers[selectedLayer].x - offset.x + maxLayerSize;
        bottom = layers[selectedLayer].y - offset.y + maxLayerSize;

        left = Math.max(left, transform.right - maxLayerSize);
        top = Math.max(top, transform.bottom - maxLayerSize);
        right = Math.min(right, transform.left + maxLayerSize);
        bottom = Math.min(bottom, transform.top + maxLayerSize);

        d = `M ${left},${top} `;
        d += `L ${right}, ${top}`;
        d += `L ${right}, ${bottom}`;
        d += `L ${left}, ${bottom} Z`;
        transformPaths.push((
          <path
            key="transform-max"
            d={d}
            fill="none"
            stroke="limegreen"
            strokeWidth={1 / window.devicePixelRatio}
            strokeDasharray="4"
            vectorEffect="non-scaling-stroke"
          />
        ));

        d = `M ${transform.left},${transform.top} `;
        d += `L ${transform.right}, ${transform.top}`;
        d += `L ${transform.right}, ${transform.bottom}`;
        d += `L ${transform.left}, ${transform.bottom} Z`;
        transformPaths.push((
          <path
            key="transform-edge"
            d={d}
            fill="none"
            stroke="limegreen"
            strokeWidth={2 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        ));

        // Size of the corner rectangles
        const rectSize = 5 / canvasScale / scale;
      
        // Function to create a corner rectangle
        const createCornerRect = (x, y, key) => (
          <rect
            key={key}
            x={x - rectSize / 2} 
            y={y - rectSize / 2}
            width={rectSize}
            height={rectSize}
            fill="white"
            stroke="limegreen"
            strokeWidth={1.5 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        );
      
        // Adding corner rectangles
        transformPaths.push(createCornerRect(transform.left, transform.top, "top-left-corner"));
        transformPaths.push(createCornerRect(transform.right, transform.top, "top-right-corner"));
        transformPaths.push(createCornerRect(transform.right, transform.bottom, "bottom-right-corner"));
        transformPaths.push(createCornerRect(transform.left, transform.bottom, "bottom-left-corner"));

        if(transform.bottom - transform.top > rectSize * 3){
          transformPaths.push(createCornerRect(transform.left, transform.top + (transform.bottom - transform.top) / 2, "left-middle"));
          transformPaths.push(createCornerRect(transform.right, transform.top + (transform.bottom - transform.top) / 2, "right-middle"));
        }

        if(transform.right - transform.left > rectSize * 3){
          transformPaths.push(createCornerRect(transform.left + (transform.right - transform.left) / 2, transform.top, "top-middle"));
          transformPaths.push(createCornerRect(transform.left + (transform.right - transform.left) / 2, transform.bottom, "bottom-middle"));
        }

        const transformCtx = transformLayer.current.getContext("2d");
        transformCtx.imageSmoothingEnabled = false;
        transformCtx.clearRect(0,0,resolution.width,resolution.height);
        
        transformCtx.drawImage(
          transformCanvas,
          transform.left,
          transform.top,
          transform.right - transform.left,
          transform.bottom - transform.top,
        );

        const radius = 4 / canvasScale / scale;
        if(Math.abs(transform.right - transform.left) > radius * 10 &&
          Math.abs(transform.bottom - transform.top) > radius * 10){
          const pivotX = transform.pivotX;
          const pivotY = transform.pivotY;

          // Create a circle at the center
          const centerCircle = (
            <circle
              key="center-circle"
              cx={pivotX}
              cy={pivotY}
              r={radius}
              fill="none"
              stroke="limegreen"
              strokeWidth={1.5 / window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          );

          // Create horizontal crosshair
          const horizontalLine = (
            <line
              key="horizontal-crosshair"
              x1={pivotX - radius * 2} // Extends left from the center
              x2={pivotX + radius * 2} // Extends right from the center
              y1={pivotY}
              y2={pivotY}
              stroke="limegreen"
              strokeWidth={1.5 / window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          );

          // Create vertical crosshair
          const verticalLine = (
            <line
              key="vertical-crosshair"
              x1={pivotX}
              x2={pivotX}
              y1={pivotY - radius * 2} // Extends up from the center
              y2={pivotY + radius * 2} // Extends down from the center
              stroke="limegreen"
              strokeWidth={1.5 / window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          );

          // Add these new elements to the transformPaths array
          transformPaths.push(centerCircle);
          transformPaths.push(horizontalLine);
          transformPaths.push(verticalLine);
        }
        setTransformPath(transformPaths);
      }

      const guidePaths = [];
      if(showBorder){
        d = `M ${move.x},${move.y} `;
        d += `L ${move.x + resolution.width},${move.y}`;
        d += `L ${move.x + resolution.width},${move.y + resolution.height}`;
        d += `L ${move.x},${move.y + resolution.height} Z`;
        guidePaths.push((
          <path
            key="canvas-border"
            d={d}
            fill="none"
            stroke={"#fff"}
            strokeWidth={1 / window.devicePixelRatio}
            vectorEffect="non-scaling-stroke"
          />
        ));
      }
      setGuidesPath(guidePaths);
    };

    // Cancel the previous frame before scheduling a new one
    if (selectionFrameId) {
      cancelAnimationFrame(selectionFrameId);
    }

    // Schedule the next frame
    const newSelectionFrameId = requestAnimationFrame(updateSelection);
    setSelectionFrameId(newSelectionFrameId);
  }, [
    resolution,
    offset,
    scale,
    translate,
    selectionEdges,
    toolEdges,
    isMoving,
    move,
    layers,
    selectedLayer,
    selectedLayers,
    crop,
    transform,
    canvasWidth,
    selectedTool,
    nearCrop,
    selectedViewport,
    gradientPoints,
    showBorder,
    pixelGrid,
    hasSelection,
    guidePoints,
    nearLine,
    nearPerspective,
    nearGrid,
  ]);

  const transformStyle = {
    transform: `scale(${scale}) translate(${translate.x}%, ${translate.y}%)`,
    backgroundSize: `${window.devicePixelRatio * 12 / scale}px ${window.devicePixelRatio * 12 / scale}px`,
    width: canvasWidth,
    height: canvasHeight,
  };
  
  useEffect(() => {   
    const layerWidth = bg.current.clientWidth * scale;
    const layerHeight = bg.current.clientHeight * scale;
      
    let svgScale, l, t;
    if(canvasHeight === "auto"){
      const aspectRatio = layerHeight/layerWidth;
      svgScale = resolution.width / layerWidth;
      const oX = ((translate.x + 50) / 100) * resolution.width;
      const oY = ((translate.y + 50) / 100) * resolution.height;
      l = (wrapper.current.clientWidth - layerWidth) / 2 * svgScale + oX;
      t = (wrapper.current.clientWidth - layerWidth) / 2 * aspectRatio * svgScale + oY;
    } else {
      const aspectRatio = layerWidth/layerHeight;
      svgScale = resolution.height / layerHeight;
      const oX = ((translate.x + 50) / 100) * resolution.width;
      const oY = ((translate.y + 50) / 100) * resolution.height;
      l = (wrapper.current.clientHeight - layerHeight) / 2 * aspectRatio * svgScale + oX;
      t = (wrapper.current.clientHeight - layerHeight) / 2 * svgScale + oY;
    }
    
    setSvgViewBox(`${-l} ${-t} ${resolution.width / scale} ${resolution.height / scale}`);
  }, [resolution, scale, translate, canvasHeight]);
  
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if(bg.current && wrapper.current){
        const vpRatio =
          wrapper.current.clientWidth / wrapper.current.clientHeight;
        const ratio = bg.current.clientWidth / bg.current.clientHeight;
        if (vpRatio >= ratio) {
          setCanvasWidth("auto");
          setCanvasHeight(`calc(100% + ${0.7 / window.devicePixelRatio}px)`);
        } else if (vpRatio < ratio) {
          setCanvasWidth(`calc(100% + ${1.45 - window.devicePixelRatio}px)`);
          setCanvasHeight("auto");
        }
      }

      for (let entry of entries) {
        setSvgWidth(entry.contentRect.width);
        setSvgHeight(entry.contentRect.height);
      }
    });

    // observe one or more elements
    if (wrapper.current) {
      resizeObserver.observe(wrapper.current);
    }

    return () => {
      // Cleanup
      if (wrapper.current) {
        resizeObserver.unobserve(wrapper.current);
        resizeObserver.disconnect();
      }
    };
  }, [resolution]);

  const layerClass = (index) => {
    if(layers[index].type === "Canvas"){
      return layers[index].visible ? "layer" : "layer invisible";
    } else {
      return layers[index].visible ? "svg-overlay" : "svg-overlay invisible";
    }
  };

  return (
    <div ref={wrapper} className="canvas-wrapper" style={{ cursor: cursor }}>
      <canvas
        ref={bg}
        width={resolution.width}
        height={resolution.height}
        className="bg"
        style={transformStyle}
      ></canvas>
      {layers.map((layer, index) => {
        if(bg.current){
          let canvasScale = canvasHeight === "auto" ? bg.current.clientWidth / resolution.width :  bg.current.clientHeight / resolution.height;
          const radius = 3.5 / canvasScale / scale;

          const moveX = selectedLayers.includes(index) ? move.x : 0;
          const moveY = selectedLayers.includes(index) ? move.y : 0;

          if(index === selectedLayer){
            switch(layer.type){
              case "Canvas":
                return (
                  <>
                    <canvas
                      ref={canvases[index]}
                      width={resolution.width}
                      height={resolution.height}
                      className={layerClass(index)}
                      style={transformStyle}
                    ></canvas>
                    <canvas
                      ref={transformLayer}
                      width={resolution.width}
                      height={resolution.height}
                      className="layer"
                      style={transformStyle}
                    ></canvas>
                  </>
                );
                break;
              case "Line":
                let newLinePoints, startCircle, endCircle, offsetX, offsetY, midPointX, midPointY;
                if(nearLine !== -1 && guidePoints){ 
                  if(layer.extend){
                    newLinePoints = extendLineToBounds({ x: guidePoints.x, y: guidePoints.y },{ x: guidePoints.x2, y: guidePoints.y2 }); 
                  } else {
                    newLinePoints = { startPoint: { x: guidePoints.x, y: guidePoints.y }, endPoint: { x: guidePoints.x2, y: guidePoints.y2 }};
                  }
                  startCircle = { x: guidePoints.x, y: guidePoints.y };
                  endCircle = { x: guidePoints.x2, y: guidePoints.y2 };

                  const deltaX = guidePoints.x2 - guidePoints.x;
                  const deltaY = guidePoints.y2 - guidePoints.y;
                  let angleInRadians = Math.atan2(deltaY, deltaX);
                  angleInRadians += Math.PI / 2;

                  offsetX = radius * 1.5 * Math.cos(angleInRadians);
                  offsetY = radius * 1.5 * Math.sin(angleInRadians);
                  midPointX = guidePoints.x + (guidePoints.x2 -guidePoints.x)/2;
                  midPointY = guidePoints.y + (guidePoints.y2 - guidePoints.y)/2;
                } else if(layer.startPoint && layer.endPoint){
                  if(layer.extend){
                    newLinePoints = extendLineToBounds(layer.startPoint,layer.endPoint);
                  } else {
                    newLinePoints = { startPoint: layer.startPoint, endPoint: layer.endPoint};
                  }
                  startCircle = layer.startPoint;
                  endCircle = layer.endPoint;

                  const deltaX = layers[selectedLayer].endPoint.x - layers[selectedLayer].startPoint.x;
                  const deltaY = layers[selectedLayer].endPoint.y - layers[selectedLayer].startPoint.y;
                  let angleInRadians = Math.atan2(deltaY, deltaX);
                  angleInRadians += Math.PI / 2;

                  offsetX = radius * 1.5 * Math.cos(angleInRadians);
                  offsetY = radius * 1.5 * Math.sin(angleInRadians);
                  midPointX = layers[selectedLayer].startPoint.x + (layers[selectedLayer].endPoint.x - layers[selectedLayer].startPoint.x)/2;
                  midPointY = layers[selectedLayer].startPoint.y + (layers[selectedLayer].endPoint.y - layers[selectedLayer].startPoint.y)/2;
                } else {
                  return;
                }

                return(
                  <svg
                    viewBox={svgViewBox} 
                    width={svgWidth}
                    height={svgHeight}
                    className={layerClass(index)}
                  >
                    <line
                      x1={newLinePoints.startPoint.x + move.x}
                      y1={newLinePoints.startPoint.y + move.y}
                      x2={newLinePoints.endPoint.x + move.x}
                      y2={newLinePoints.endPoint.y + move.y}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={midPointX - offsetX + move.x}
                      y1={midPointY - offsetY + move.y}
                      x2={midPointX + offsetX + move.x}
                      y2={midPointY + offsetY + move.y}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />

                    <circle
                      cx={startCircle.x + move.x} 
                      cy={startCircle.y + move.y} 
                      r={radius} 
                      fill="white"
                      stroke={layers[index].color}
                      strokeWidth={radius / 2}
                    />
                    <circle
                      cx={endCircle.x + move.x} 
                      cy={endCircle.y + move.y} 
                      r={radius} 
                      fill="white"
                      stroke={layers[index].color}
                      strokeWidth={radius / 2}   
                    />
                  </svg>
                );
                break;
              case "OnePoint":
                if(nearPerspective !== -1 && guidePoints){
                  return(
                    <svg
                      viewBox={svgViewBox} 
                      width={svgWidth}
                      height={svgHeight}
                      className={layerClass(index)}
                    >
                      <circle
                        cx={guidePoints.x + moveX} 
                        cy={guidePoints.y + moveY} 
                        r={radius} 
                        fill="white"
                        stroke={layers[index].color}
                        strokeWidth={radius / 2 / 2}
                      />
                      <line
                        x1={guidePoints.x + radius * 1.5 + moveX}
                        y1={guidePoints.y + moveY}
                        x2={guidePoints.x + radius * 3 + moveX}
                        y2={guidePoints.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={guidePoints.x - radius * 1.5 + moveX}
                        y1={guidePoints.y + moveY}
                        x2={guidePoints.x - radius * 3 + moveX}
                        y2={guidePoints.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={guidePoints.x + moveX}
                        y1={guidePoints.y + radius * 1.5 + moveY}
                        x2={guidePoints.x + moveX}
                        y2={guidePoints.y + radius * 3 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={guidePoints.x + moveX}
                        y1={guidePoints.y - radius * 1.5 + moveY}
                        x2={guidePoints.x + moveX}
                        y2={guidePoints.y - radius * 3 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={guidePoints.x - radius * 1.5/ Math.sqrt(2) + moveX}
                        y1={guidePoints.y - radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={guidePoints.x - radius * 3 / Math.sqrt(2) + moveX}
                        y2={guidePoints.y - radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={guidePoints.x - radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={guidePoints.y + radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={guidePoints.x - radius * 3 / Math.sqrt(2) + moveX}
                        y2={guidePoints.y + radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={guidePoints.x + radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={guidePoints.y - radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={guidePoints.x + radius * 3 / Math.sqrt(2) + moveX}
                        y2={guidePoints.y - radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={guidePoints.x + radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={guidePoints.y + radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={guidePoints.x + radius * 3 / Math.sqrt(2) + moveX}
                        y2={guidePoints.y + radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  );
                } else {
                  return(
                    <svg
                      viewBox={svgViewBox} 
                      width={svgWidth}
                      height={svgHeight}
                      className={layerClass(index)}
                    >
                      <circle
                        cx={layer.point.x + moveX} 
                        cy={layer.point.y + moveY} 
                        r={radius} 
                        fill="white"
                        stroke={layers[index].color}
                        strokeWidth={radius / 2 / 2}
                      />
                      <line
                        x1={layer.point.x + radius * 1.5 + moveX}
                        y1={layer.point.y + moveY}
                        x2={layer.point.x + radius * 3 + moveX}
                        y2={layer.point.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={layer.point.x - radius * 1.5 + moveX}
                        y1={layer.point.y + moveY}
                        x2={layer.point.x - radius * 3 + moveX}
                        y2={layer.point.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={layer.point.x + moveX}
                        y1={layer.point.y + radius * 1.5 + moveY}
                        x2={layer.point.x + moveX}
                        y2={layer.point.y + radius * 3 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={layer.point.x + moveX}
                        y1={layer.point.y - radius * 1.5 + moveY}
                        x2={layer.point.x + moveX}
                        y2={layer.point.y - radius * 3 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={layer.point.x - radius * 1.5/ Math.sqrt(2) + moveX}
                        y1={layer.point.y - radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={layer.point.x - radius * 3 / Math.sqrt(2) + moveX}
                        y2={layer.point.y - radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={layer.point.x - radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={layer.point.y + radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={layer.point.x - radius * 3 / Math.sqrt(2) + moveX}
                        y2={layer.point.y + radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={layer.point.x + radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={layer.point.y - radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={layer.point.x + radius * 3 / Math.sqrt(2) + moveX}
                        y2={layer.point.y - radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={layer.point.x + radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={layer.point.y + radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={layer.point.x + radius * 3 / Math.sqrt(2) + moveX}
                        y2={layer.point.y + radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  );
                }
                break;
              case "TwoPoint":
                let vp1, vp2, center;
                if(nearPerspective !== -1 && guidePoints){
                  center = {x: guidePoints.centerX, y: guidePoints.centerY};
                  const vanishingPoints = deriveVanishingPoints({x: guidePoints.centerX, y: guidePoints.centerY}, layer.focalLength, layer.tilt, guidePoints.angle);
                  vp1 = vanishingPoints.vp1;
                  vp2 = vanishingPoints.vp2;
                } else {
                  center = {x: layer.center.x, y: layer.center.y};
                  const vanishingPoints = deriveVanishingPoints(layer.center, layer.focalLength, layer.tilt, layer.angle);
                  vp1 = vanishingPoints.vp1;
                  vp2 = vanishingPoints.vp2;
                }

                const horizonLine = extendLineToBounds(vp2,vp1);
                const centerLineX = radius * 1.5 * Math.sin(layer.tilt * (Math.PI / 180));
                const centerLineY = radius * 1.5 * Math.cos(layer.tilt * (Math.PI / 180));
                
                return(
                  <svg
                    viewBox={svgViewBox} 
                    width={svgWidth}
                    height={svgHeight}
                    className={layerClass(index)}
                  >
                    <line
                      x1={horizonLine.startPoint.x + moveX}
                      y1={horizonLine.startPoint.y + moveY}
                      x2={horizonLine.endPoint.x + moveX}
                      y2={horizonLine.endPoint.y + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />

                    <line
                      x1={center.x - centerLineX + moveX}
                      y1={center.y - centerLineY + moveY}
                      x2={center.x + centerLineX + moveX}
                      y2={center.y + centerLineY + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />

                    <circle
                      cx={vp2.x + moveX} 
                      cy={vp2.y + moveY} 
                      r={radius} 
                      fill="white"
                      stroke={layers[index].color}
                      strokeWidth={radius / 2 / 2}
                    />
                    <line
                      x1={vp2.x + radius * 1.5 + moveX}
                      y1={vp2.y + moveY}
                      x2={vp2.x + radius * 3 + moveX}
                      y2={vp2.y + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x - radius * 1.5 + moveX}
                      y1={vp2.y + moveY}
                      x2={vp2.x - radius * 3 + moveX}
                      y2={vp2.y + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x + moveX}
                      y1={vp2.y + radius * 1.5 + moveY}
                      x2={vp2.x + moveX}
                      y2={vp2.y + radius * 3 + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x + moveX}
                      y1={vp2.y - radius * 1.5 + moveY}
                      x2={vp2.x + moveX}
                      y2={vp2.y - radius * 3 + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x - radius * 1.5/ Math.sqrt(2) + moveX}
                      y1={vp2.y - radius * 1.5 / Math.sqrt(2) + moveY}
                      x2={vp2.x - radius * 3 / Math.sqrt(2) + moveX}
                      y2={vp2.y - radius * 3 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x - radius * 1.5 / Math.sqrt(2) + moveX}
                      y1={vp2.y + radius * 1.5 / Math.sqrt(2) + moveY}
                      x2={vp2.x - radius * 3 / Math.sqrt(2) + moveX}
                      y2={vp2.y + radius * 3 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x + radius * 1.5 / Math.sqrt(2) + moveX}
                      y1={vp2.y - radius * 1.5 / Math.sqrt(2) + moveY}
                      x2={vp2.x + radius * 3 / Math.sqrt(2) + moveX}
                      y2={vp2.y - radius * 3 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x + radius * 1.5 / Math.sqrt(2) + moveX}
                      y1={vp2.y + radius * 1.5 / Math.sqrt(2) + moveY}
                      x2={vp2.x + radius * 3 / Math.sqrt(2) + moveX}
                      y2={vp2.y + radius * 3 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    
                    <circle
                      cx={vp1.x + moveX} 
                      cy={vp1.y + moveY} 
                      r={radius} 
                      fill="white"
                      stroke={layers[index].color}
                      strokeWidth={radius / 2 / 2}
                    />
                    <line
                      x1={vp1.x + radius * 1.5 + moveX}
                      y1={vp1.y + moveY}
                      x2={vp1.x + radius * 3 + moveX}
                      y2={vp1.y + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x - radius * 1.5 + moveX}
                      y1={vp1.y + moveY}
                      x2={vp1.x - radius * 3 + moveX}
                      y2={vp1.y + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x + moveX}
                      y1={vp1.y + radius * 1.5 + moveY}
                      x2={vp1.x + moveX}
                      y2={vp1.y + radius * 3 + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x + moveX}
                      y1={vp1.y - radius * 1.5 + moveY}
                      x2={vp1.x + moveX}
                      y2={vp1.y - radius * 3 + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x - radius * 1.5/ Math.sqrt(2) + moveX}
                      y1={vp1.y - radius * 1.5 / Math.sqrt(2) + moveY}
                      x2={vp1.x - radius * 3 / Math.sqrt(2) + moveX}
                      y2={vp1.y - radius * 3 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x - radius * 1.5 / Math.sqrt(2) + moveX}
                      y1={vp1.y + radius * 1.5 / Math.sqrt(2) + moveY}
                      x2={vp1.x - radius * 3 / Math.sqrt(2) + moveX}
                      y2={vp1.y + radius * 3 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x + radius * 1.5 / Math.sqrt(2) + moveX}
                      y1={vp1.y - radius * 1.5 / Math.sqrt(2) + moveY}
                      x2={vp1.x + radius * 3 / Math.sqrt(2) + moveX}
                      y2={vp1.y - radius * 3 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x + radius * 1.5 / Math.sqrt(2) + moveX}
                      y1={vp1.y + radius * 1.5 / Math.sqrt(2) + moveY}
                      x2={vp1.x + radius * 3 / Math.sqrt(2) + moveX}
                      y2={vp1.y + radius * 3 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                  </svg>
                );
                break;
              case "ThreePoint":
                if(layer.center){
                  let vp1, vp2, vp3, vp4, center;
                  if(nearPerspective !== -1 && guidePoints){
                    center = {x: guidePoints.centerX, y: guidePoints.centerY};
                    const vanishingPoints = deriveVanishingPoints({x: guidePoints.centerX, y: guidePoints.centerY}, layer.focalLength, layer.tilt + 90, guidePoints.angle2);
                    vp3 = vanishingPoints.vp1;
                    vp4 = vanishingPoints.vp2;
                    const vanishingPoints2 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layer.focalLength, layer.tilt, guidePoints.angle);
                    vp1 = vanishingPoints2.vp1;
                    vp2 = vanishingPoints2.vp2;
                  } else {
                    const vanishingPoints = deriveVanishingPoints(layer.center, layer.focalLength, layer.tilt + 90, layer.angle2);
                    vp3 = vanishingPoints.vp1;
                    vp4 = vanishingPoints.vp2;
                    center = {x: layer.center.x, y: layer.center.y};
                    const vanishingPoints2 = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layer.focalLength, layer.tilt, layer.angle);
                    vp1 = vanishingPoints2.vp1;
                    vp2 = vanishingPoints2.vp2;
                  }
  
                  const horizonLine = extendLineToBounds(vp1,vp2);
                  const verticalLine = extendLineToBounds(vp4,vp3);
                  const centerLineX = radius * 1.5 * Math.sin((layer.tilt + 90) * (Math.PI / 180));
                  const centerLineY = radius * 1.5 * Math.cos((layer.tilt + 90) * (Math.PI / 180));
                  
                  const centerLine2X = radius * 1.5 * Math.sin((layer.tilt) * (Math.PI / 180));
                  const centerLine2Y = radius * 1.5 * Math.cos((layer.tilt) * (Math.PI / 180));

                  const  verticalLinePath = layer.angle2 === 0 ?
                    (
                      <line
                        x1={center.x - centerLine2X + moveX}
                        y1={center.y - centerLine2Y + moveY}
                        x2={center.x + centerLine2X + moveX}
                        y2={center.y + centerLine2Y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                    )
                    :
                    (
                        <line
                          x1={verticalLine.startPoint.x + moveX}
                          y1={verticalLine.startPoint.y + moveY}
                          x2={verticalLine.endPoint.x + moveX}
                          y2={verticalLine.endPoint.y + moveY}
                          stroke={layer.color}
                          strokeWidth={2 / window.devicePixelRatio}
                          vectorEffect="non-scaling-stroke"
                        />
                    );
                  
                  return(
                    <svg
                      viewBox={svgViewBox} 
                      width={svgWidth}
                      height={svgHeight}
                      className={layerClass(index)}
                    >
                      <line
                        x1={horizonLine.startPoint.x + moveX}
                        y1={horizonLine.startPoint.y + moveY}
                        x2={horizonLine.endPoint.x + moveX}
                        y2={horizonLine.endPoint.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />

                      {verticalLinePath}

                      <line
                        x1={center.x - centerLineX + moveX}
                        y1={center.y - centerLineY + moveY}
                        x2={center.x + centerLineX + moveX}
                        y2={center.y + centerLineY + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />

                                          
                    <circle
                        cx={vp1.x + moveX} 
                        cy={vp1.y + moveY} 
                        r={radius} 
                        fill="white"
                        stroke={layers[index].color}
                        strokeWidth={radius / 2 / 2}
                      />
                      <line
                        x1={vp1.x + radius * 1.5 + moveX}
                        y1={vp1.y + moveY}
                        x2={vp1.x + radius * 3 + moveX}
                        y2={vp1.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x - radius * 1.5 + moveX}
                        y1={vp1.y + moveY}
                        x2={vp1.x - radius * 3 + moveX}
                        y2={vp1.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x + moveX}
                        y1={vp1.y + radius * 1.5 + moveY}
                        x2={vp1.x + moveX}
                        y2={vp1.y + radius * 3 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x + moveX}
                        y1={vp1.y - radius * 1.5 + moveY}
                        x2={vp1.x + moveX}
                        y2={vp1.y - radius * 3 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x - radius * 1.5/ Math.sqrt(2) + moveX}
                        y1={vp1.y - radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={vp1.x - radius * 3 / Math.sqrt(2) + moveX}
                        y2={vp1.y - radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x - radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={vp1.y + radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={vp1.x - radius * 3 / Math.sqrt(2) + moveX}
                        y2={vp1.y + radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x + radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={vp1.y - radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={vp1.x + radius * 3 / Math.sqrt(2) + moveX}
                        y2={vp1.y - radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x + radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={vp1.y + radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={vp1.x + radius * 3 / Math.sqrt(2) + moveX}
                        y2={vp1.y + radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                        
                      <circle
                        cx={vp2.x + moveX} 
                        cy={vp2.y + moveY} 
                        r={radius} 
                        fill="white"
                        stroke={layers[index].color}
                        strokeWidth={radius / 2 / 2}
                      />
                      <line
                        x1={vp2.x + radius * 1.5 + moveX}
                        y1={vp2.y + moveY}
                        x2={vp2.x + radius * 3 + moveX}
                        y2={vp2.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x - radius * 1.5 + moveX}
                        y1={vp2.y + moveY}
                        x2={vp2.x - radius * 3 + moveX}
                        y2={vp2.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x + moveX}
                        y1={vp2.y + radius * 1.5 + moveY}
                        x2={vp2.x + moveX}
                        y2={vp2.y + radius * 3 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x + moveX}
                        y1={vp2.y - radius * 1.5 + moveY}
                        x2={vp2.x + moveX}
                        y2={vp2.y - radius * 3 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x - radius * 1.5/ Math.sqrt(2) + moveX}
                        y1={vp2.y - radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={vp2.x - radius * 3 / Math.sqrt(2) + moveX}
                        y2={vp2.y - radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x - radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={vp2.y + radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={vp2.x - radius * 3 / Math.sqrt(2) + moveX}
                        y2={vp2.y + radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x + radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={vp2.y - radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={vp2.x + radius * 3 / Math.sqrt(2) + moveX}
                        y2={vp2.y - radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x + radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={vp2.y + radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={vp2.x + radius * 3 / Math.sqrt(2) + moveX}
                        y2={vp2.y + radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      
                      <circle
                        cx={vp4.x + moveX} 
                        cy={vp4.y + moveY} 
                        r={radius} 
                        fill="white"
                        stroke={layers[index].color}
                        strokeWidth={radius / 2 / 2}
                      />
                      <line
                        x1={vp4.x + radius * 1.5 + moveX}
                        y1={vp4.y + moveY}
                        x2={vp4.x + radius * 3 + moveX}
                        y2={vp4.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x - radius * 1.5 + moveX}
                        y1={vp4.y + moveY}
                        x2={vp4.x - radius * 3 + moveX}
                        y2={vp4.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x + moveX}
                        y1={vp4.y + radius * 1.5 + moveY}
                        x2={vp4.x + moveX}
                        y2={vp4.y + radius * 3 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x + moveX}
                        y1={vp4.y - radius * 1.5 + moveY}
                        x2={vp4.x + moveX}
                        y2={vp4.y - radius * 3 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x - radius * 1.5/ Math.sqrt(2) + moveX}
                        y1={vp4.y - radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={vp4.x - radius * 3 / Math.sqrt(2) + moveX}
                        y2={vp4.y - radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x - radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={vp4.y + radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={vp4.x - radius * 3 / Math.sqrt(2) + moveX}
                        y2={vp4.y + radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x + radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={vp4.y - radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={vp4.x + radius * 3 / Math.sqrt(2) + moveX}
                        y2={vp4.y - radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x + radius * 1.5 / Math.sqrt(2) + moveX}
                        y1={vp4.y + radius * 1.5 / Math.sqrt(2) + moveY}
                        x2={vp4.x + radius * 3 / Math.sqrt(2) + moveX}
                        y2={vp4.y + radius * 3 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  );
                }
                break;
              case "Grid":
                let corner, width, height, angle;
                if(guidePoints){
                  corner = {x: guidePoints.cornerX, y: guidePoints.cornerY};
                  width = guidePoints.width;
                  height = guidePoints.height;
                  angle = -guidePoints.angle;
                } else {
                  corner = layer.corner;
                  width = layer.width;
                  height = layer.height;
                  angle = -layer.angle;
                }
                
                const gridLines = [];
                const circles = [];
              
                // Calculate the step size for rows and columns
                const rowHeight = height / layer.rows;
                const columnWidth = width / layer.columns;
              
                // Center of rotation (corner point)
                const cx = corner.x;
                const cy = corner.y;
              
                // Calculate horizontal grid lines
                for (let i = 0; i <= layer.rows; i++) {
                  const y1 = corner.y + i * rowHeight;
                  const y2 = y1;
                  const x1 = corner.x;
                  const x2 = corner.x + width;
              
                  const p1 = calculateRotatedPoint(x1, y1, angle, cx, cy);
                  const p2 = calculateRotatedPoint(x2, y2, angle, cx, cy);
              
                  gridLines.push(
                    <line
                      key={`h${i}`}
                      x1={p1.x}
                      y1={p1.y}
                      x2={p2.x}
                      y2={p2.y}
                      stroke={layer.color}
                      strokeWidth={1 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                  );
                }
              
                // Calculate vertical grid lines
                for (let j = 0; j <= layer.columns; j++) {
                  const x1 = corner.x + j * columnWidth;
                  const x2 = x1;
                  const y1 = corner.y;
                  const y2 = corner.y + height;
              
                  const p1 = calculateRotatedPoint(x1, y1, angle, cx, cy);
                  const p2 = calculateRotatedPoint(x2, y2, angle, cx, cy);
              
                  gridLines.push(
                    <line
                      key={`v${j}`}
                      x1={p1.x}
                      y1={p1.y}
                      x2={p2.x}
                      y2={p2.y}
                      stroke={layer.color}
                      strokeWidth={1 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                  );
                }
              
                // Calculate rotated positions for circles
                const topLeft = calculateRotatedPoint(corner.x, corner.y, angle, cx, cy);
                const bottomLeft = calculateRotatedPoint(corner.x, corner.y + height, angle, cx, cy);
                const topRight = calculateRotatedPoint(corner.x + width, corner.y, angle, cx, cy);
              
                circles.push(
                  <circle
                    key="topLeft"
                    cx={topLeft.x}
                    cy={topLeft.y}
                    r={radius} 
                    fill="white"
                    stroke={layer.color}
                    strokeWidth={radius / 2 / 2}
                  />,
                  <circle
                    key="bottomLeft"
                    cx={bottomLeft.x}
                    cy={bottomLeft.y}
                    r={radius} 
                    fill="white"
                    stroke={layer.color}
                    strokeWidth={radius / 2 / 2}
                  />,
                  <circle
                    key="topRight"
                    cx={topRight.x}
                    cy={topRight.y}
                    r={radius} 
                    fill="white"
                    stroke={layer.color}
                    strokeWidth={radius / 2 / 2}
                  />
                );
              
                return (
                  <svg
                    viewBox={svgViewBox}
                    width={svgWidth}
                    height={svgHeight}
                    className={layerClass(index)}
                  >
                    {gridLines}
                    {circles}
                  </svg>
                );
                break;                  
              case "Isometric":
                if(layer.corner){
                  let corner, width, height, angle, angle2;
                  if (guidePoints) {
                    corner = { x: guidePoints.cornerX, y: guidePoints.cornerY };
                    width = guidePoints.width;
                    height = guidePoints.height;
                    angle = guidePoints.angle;
                    angle2 = guidePoints.angle2;
                  } else {
                    corner = layer.corner;
                    width = layer.width;
                    height = layer.height;
                    angle = layer.angle;
                    angle2 = layer.angle2;
                  }
                
                  const gridLines = [];
                  const circles = [];
                
                  // Calculate the step size for rows and columns
                  const rowHeight = width / layer.rows;
                  const columnWidth = height / layer.columns;
                
                  // Convert angles to radians
                  const radian = angle * (Math.PI / 180);
                  const radian2 = angle2 * (Math.PI / 180);
                
                  // Calculate the position of the grid lines for rows
                  for (let i = 0; i <= layer.rows; i++) {
                    const startX = corner.x + i * rowHeight * Math.cos(radian);
                    const startY = corner.y - i * rowHeight * Math.sin(radian);
                    const endPoint = getEndPoint(startX, startY, angle2, height);
                
                    gridLines.push(
                      <line
                        key={`h${i}`}
                        x1={startX}
                        y1={startY}
                        x2={endPoint.x}
                        y2={endPoint.y}
                        stroke={layer.color}
                        strokeWidth={1 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                    );
                  }
                
                  // Calculate the position of the grid lines for columns
                  for (let j = 0; j <= layer.columns; j++) {
                    const startX = corner.x + j * columnWidth * Math.cos(radian2);
                    const startY = corner.y - j * columnWidth * Math.sin(radian2);
                    const endPoint = getEndPoint(startX, startY, angle, width);
                
                    gridLines.push(
                      <line
                        key={`v${j}`}
                        x1={startX}
                        y1={startY}
                        x2={endPoint.x}
                        y2={endPoint.y}
                        stroke={layer.color}
                        strokeWidth={1 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                    );
                  }
                
                  // Calculate corner points for circles
                  const bottom = corner;
                  const left = getEndPoint(corner.x, corner.y, angle2, height);
                  const right = getEndPoint(corner.x, corner.y, angle, width);

                  circles.push(
                    <circle
                      key="bottom"
                      cx={bottom.x}
                      cy={bottom.y}
                      r={radius}
                      fill="white"
                      stroke={layer.color}
                      strokeWidth={radius / 2 / 2}
                    />,
                    <circle
                      key="left"
                      cx={left.x}
                      cy={left.y}
                      r={radius}
                      fill="white"
                      stroke={layer.color}
                      strokeWidth={radius / 2 / 2}
                    />,
                    <circle
                      key="right"
                      cx={right.x}
                      cy={right.y}
                      r={radius}
                      fill="white"
                      stroke={layer.color}
                      strokeWidth={radius / 2 / 2}
                    />
                  );
                
                  return (
                    <svg
                      viewBox={svgViewBox}
                      width={svgWidth}
                      height={svgHeight}
                      className={layerClass(index)}
                    >
                      {gridLines}
                      {circles}
                    </svg>
                  );
                }
                break;
              case "Ellipse":
                if (layer.center) {
                  let center, width, height, angle;
                  if (guidePoints) {
                    center = { x: guidePoints.centerX, y: guidePoints.centerY };
                    width = guidePoints.width;
                    height = guidePoints.height;
                    angle = -guidePoints.angle;
                  } else {
                    center = layer.center;
                    width = layer.width;
                    height = layer.height;
                    angle = -layer.angle;
                  }
              
                  const circles = [];
                  
                  const widthCircle = calculateRotatedPoint(center.x + width/2, center.y, angle, center.x, center.y);
                  const heightCircle = calculateRotatedPoint(center.x, center.y - height/2, angle, center.x, center.y);
              
                  circles.push(
                    <circle
                      key="center"
                      cx={center.x}
                      cy={center.y}
                      r={radius}
                      fill="white"
                      stroke={layer.color}
                      strokeWidth={radius / 2 / 2}
                    />,
                    <circle
                      key="width"
                      cx={widthCircle.x}
                      cy={widthCircle.y}
                      r={radius}
                      fill="white"
                      stroke={layer.color}
                      strokeWidth={radius / 2 / 2}
                    />,
                    <circle
                      key="height"
                      cx={heightCircle.x}
                      cy={heightCircle.y}
                      r={radius}
                      fill="white"
                      stroke={layer.color}
                      strokeWidth={radius / 2 / 2}
                    />
                  );
              
                  return (
                    <svg
                      viewBox={svgViewBox}
                      width={svgWidth}
                      height={svgHeight}
                      className={layerClass(index)}
                    >
                      <line
                        x1={center.x}
                        y1={center.y}
                        x2={widthCircle.x}
                        y2={widthCircle.y}
                        stroke={layer.color}
                        strokeDasharray="4"
                        strokeWidth={1 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <ellipse 
                        cx={center.x}
                        cy={center.y} 
                        rx={width/2}
                        ry={height/2}
                        fill="transparent"
                        transform={`rotate(${angle}, ${center.x}, ${center.y})`}
                        stroke={layer.color}
                        strokeWidth={1 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      {circles}
                    </svg>
                  );
                }
                break;
            }
          } else {
            switch(layer.type){
              case "Canvas":
                return (
                  <canvas
                    ref={canvases[index]}
                    width={resolution.width}
                    height={resolution.height}
                    className={layerClass(index)}
                    style={transformStyle}
                  ></canvas>
                ); 
                break;
              case "Line":
                let newLinePoints;
                if(layers[index].startPoint && layers[index].endPoint){ 
                  if(layer.extend){
                    newLinePoints = extendLineToBounds(layer.startPoint,layer.endPoint);
                  } else {
                    newLinePoints = { startPoint: layer.startPoint, endPoint: layer.endPoint};
                  }

                  return(
                    <svg
                      viewBox={svgViewBox} 
                      width={svgWidth}
                      height={svgHeight}
                      className={layerClass(index)}
                    >
                      <line
                        x1={newLinePoints.startPoint.x + moveX}
                        y1={newLinePoints.startPoint.y + moveY}
                        x2={newLinePoints.endPoint.x + moveX}
                        y2={newLinePoints.endPoint.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <circle
                        cx={layer.startPoint.x + moveX} 
                        cy={layer.startPoint.y + moveY} 
                        r={radius * 0.6} 
                        fill={layers[index].color}
                      />
                      <circle
                        cx={layer.endPoint.x + moveX} 
                        cy={layer.endPoint.y + moveY} 
                        r={radius * 0.6} 
                        fill={layers[index].color}
                      />
                    </svg>
                  );
                }
                break;
              case "OnePoint":
                return(
                  <svg
                    viewBox={svgViewBox} 
                    width={svgWidth}
                    height={svgHeight}
                    className={layerClass(index)}
                  >
                    <circle
                      cx={layer.point.x + moveX} 
                      cy={layer.point.y + moveY} 
                      r={radius / 2} 
                      fill="transparent"
                      stroke={layers[index].color}
                      strokeWidth={radius / 2 / 2}
                    />
                    <line
                      x1={layer.point.x + radius + moveX}
                      y1={layer.point.y + moveY}
                      x2={layer.point.x + radius * 2 + moveX}
                      y2={layer.point.y + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={layer.point.x - radius + moveX}
                      y1={layer.point.y + moveY}
                      x2={layer.point.x - radius * 2 + moveX}
                      y2={layer.point.y + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={layer.point.x + moveX}
                      y1={layer.point.y + radius + moveY}
                      x2={layer.point.x + moveX}
                      y2={layer.point.y + radius * 2 + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={layer.point.x + moveX}
                      y1={layer.point.y - radius + moveY}
                      x2={layer.point.x + moveX}
                      y2={layer.point.y - radius * 2 + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={layer.point.x - radius / Math.sqrt(2) + moveX}
                      y1={layer.point.y - radius / Math.sqrt(2) + moveY}
                      x2={layer.point.x - radius * 2 / Math.sqrt(2) + moveX}
                      y2={layer.point.y - radius * 2 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={layer.point.x - radius / Math.sqrt(2) + moveX}
                      y1={layer.point.y + radius / Math.sqrt(2) + moveY}
                      x2={layer.point.x - radius * 2 / Math.sqrt(2) + moveX}
                      y2={layer.point.y + radius * 2 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={layer.point.x + radius / Math.sqrt(2) + moveX}
                      y1={layer.point.y - radius / Math.sqrt(2) + moveY}
                      x2={layer.point.x + radius * 2 / Math.sqrt(2) + moveX}
                      y2={layer.point.y - radius * 2 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={layer.point.x + radius / Math.sqrt(2) + moveX}
                      y1={layer.point.y + radius / Math.sqrt(2) + moveY}
                      x2={layer.point.x + radius * 2 / Math.sqrt(2) + moveX}
                      y2={layer.point.y + radius * 2 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                  </svg>
                );
                break;
              case "TwoPoint":
                const { vp1, vp2 } = deriveVanishingPoints(layer.center, layer.focalLength, layer.tilt, layer.angle);
                const horizonLine = extendLineToBounds(vp2,vp1);
                const centerLineX = radius * 1.5 * Math.sin(layer.tilt * (Math.PI / 180));
                const centerLineY = radius * 1.5 * Math.cos(layer.tilt * (Math.PI / 180));
                
                return(
                  <svg
                    viewBox={svgViewBox} 
                    width={svgWidth}
                    height={svgHeight}
                    className={layerClass(index)}
                  >
                    <line
                      x1={horizonLine.startPoint.x + moveX}
                      y1={horizonLine.startPoint.y + moveY}
                      x2={horizonLine.endPoint.x + moveX}
                      y2={horizonLine.endPoint.y + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />

                    <line
                      x1={layer.center.x - centerLineX + moveX}
                      y1={layer.center.y - centerLineY + moveY}
                      x2={layer.center.x + centerLineX + moveX}
                      y2={layer.center.y + centerLineY + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />

                    <circle
                      cx={vp2.x + moveX} 
                      cy={vp2.y + moveY} 
                      r={radius / 2} 
                      fill="transparent"
                      stroke={layers[index].color}
                      strokeWidth={radius / 2 / 2}
                    />
                    <line
                      x1={vp2.x + radius + moveX}
                      y1={vp2.y + moveY}
                      x2={vp2.x + radius * 2 + moveX}
                      y2={vp2.y + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x - radius + moveX}
                      y1={vp2.y + moveY}
                      x2={vp2.x - radius * 2 + moveX}
                      y2={vp2.y + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x + moveX}
                      y1={vp2.y + radius + moveY}
                      x2={vp2.x + moveX}
                      y2={vp2.y + radius * 2 + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x + moveX}
                      y1={vp2.y - radius + moveY}
                      x2={vp2.x + moveX}
                      y2={vp2.y - radius * 2 + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x - radius / Math.sqrt(2) + moveX}
                      y1={vp2.y - radius / Math.sqrt(2) + moveY}
                      x2={vp2.x - radius * 2 / Math.sqrt(2) + moveX}
                      y2={vp2.y - radius * 2 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x - radius / Math.sqrt(2) + moveX}
                      y1={vp2.y + radius / Math.sqrt(2) + moveY}
                      x2={vp2.x - radius * 2 / Math.sqrt(2) + moveX}
                      y2={vp2.y + radius * 2 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x + radius / Math.sqrt(2) + moveX}
                      y1={vp2.y - radius / Math.sqrt(2) + moveY}
                      x2={vp2.x + radius * 2 / Math.sqrt(2) + moveX}
                      y2={vp2.y - radius * 2 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp2.x + radius / Math.sqrt(2) + moveX}
                      y1={vp2.y + radius / Math.sqrt(2) + moveY}
                      x2={vp2.x + radius * 2 / Math.sqrt(2) + moveX}
                      y2={vp2.y + radius * 2 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    
                    <circle
                      cx={vp1.x + moveX} 
                      cy={vp1.y + moveY} 
                      r={radius / 2} 
                      fill="transparent"
                      stroke={layers[index].color}
                      strokeWidth={radius / 2 / 2}
                    />
                    <line
                      x1={vp1.x + radius + moveX}
                      y1={vp1.y + moveY}
                      x2={vp1.x + radius * 2 + moveX}
                      y2={vp1.y + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x - radius + moveX}
                      y1={vp1.y + moveY}
                      x2={vp1.x - radius * 2 + moveX}
                      y2={vp1.y + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x + moveX}
                      y1={vp1.y + radius + moveY}
                      x2={vp1.x + moveX}
                      y2={vp1.y + radius * 2 + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x + moveX}
                      y1={vp1.y - radius + moveY}
                      x2={vp1.x + moveX}
                      y2={vp1.y - radius * 2 + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x - radius / Math.sqrt(2) + moveX}
                      y1={vp1.y - radius / Math.sqrt(2) + moveY}
                      x2={vp1.x - radius * 2 / Math.sqrt(2) + moveX}
                      y2={vp1.y - radius * 2 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x - radius / Math.sqrt(2) + moveX}
                      y1={vp1.y + radius / Math.sqrt(2) + moveY}
                      x2={vp1.x - radius * 2 / Math.sqrt(2) + moveX}
                      y2={vp1.y + radius * 2 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x + radius / Math.sqrt(2) + moveX}
                      y1={vp1.y - radius / Math.sqrt(2) + moveY}
                      x2={vp1.x + radius * 2 / Math.sqrt(2) + moveX}
                      y2={vp1.y - radius * 2 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                    <line
                      x1={vp1.x + radius / Math.sqrt(2) + moveX}
                      y1={vp1.y + radius / Math.sqrt(2) + moveY}
                      x2={vp1.x + radius * 2 / Math.sqrt(2) + moveX}
                      y2={vp1.y + radius * 2 / Math.sqrt(2) + moveY}
                      stroke={layer.color}
                      strokeWidth={2 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                  </svg>
                );
                break;
              case "ThreePoint":
                if(layer.center){
                  const verticalVP = deriveVanishingPoints(layer.center, layer.focalLength, layer.tilt + 90, layer.angle2);
                  const vp3 = verticalVP.vp1;
                  const vp4 = verticalVP.vp2;
                  const { vp1, vp2 } = deriveVanishingPoints({x: vp3.x, y: vp3.y}, layer.focalLength, layer.tilt, layer.angle);
                  const horizonLine = extendLineToBounds(vp2,vp1);
                  const verticalLine = extendLineToBounds(vp4,vp3);
                  const centerLineX = radius * 1.5 * Math.sin((layer.tilt + 90) * (Math.PI / 180));
                  const centerLineY = radius * 1.5 * Math.cos((layer.tilt + 90) * (Math.PI / 180));
                  
                  const centerLine2X = radius * 1.5 * Math.sin((layer.tilt) * (Math.PI / 180));
                  const centerLine2Y = radius * 1.5 * Math.cos((layer.tilt) * (Math.PI / 180));

                  const  verticalLinePath = layer.angle2 === 0 ?
                    (
                      <line
                        x1={layer.center.x - centerLine2X + moveX}
                        y1={layer.center.y - centerLine2Y + moveY}
                        x2={layer.center.x + centerLine2X + moveX}
                        y2={layer.center.y + centerLine2Y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                    )
                    :
                    (
                          <line
                          x1={verticalLine.startPoint.x + moveX}
                          y1={verticalLine.startPoint.y + moveY}
                          x2={verticalLine.endPoint.x + moveX}
                          y2={verticalLine.endPoint.y + moveY}
                          stroke={layer.color}
                          strokeWidth={2 / window.devicePixelRatio}
                          vectorEffect="non-scaling-stroke"
                        />
                    );
                  
                  return(
                    <svg
                      viewBox={svgViewBox} 
                      width={svgWidth}
                      height={svgHeight}
                      className={layerClass(index)}
                    >
                      <line
                        x1={horizonLine.startPoint.x + moveX}
                        y1={horizonLine.startPoint.y + moveY}
                        x2={horizonLine.endPoint.x + moveX}
                        y2={horizonLine.endPoint.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />

                      {verticalLinePath}

                      <line
                        x1={layer.center.x - centerLineX + moveX}
                        y1={layer.center.y - centerLineY + moveY}
                        x2={layer.center.x + centerLineX + moveX}
                        y2={layer.center.y + centerLineY + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      
                      <circle
                        cx={vp1.x + moveX} 
                        cy={vp1.y + moveY} 
                        r={radius / 2} 
                        fill="transparent"
                        stroke={layers[index].color}
                        strokeWidth={radius / 2 / 2}
                      />
                      <line
                        x1={vp1.x + radius + moveX}
                        y1={vp1.y + moveY}
                        x2={vp1.x + radius * 2 + moveX}
                        y2={vp1.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x - radius + moveX}
                        y1={vp1.y + moveY}
                        x2={vp1.x - radius * 2 + moveX}
                        y2={vp1.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x + moveX}
                        y1={vp1.y + radius + moveY}
                        x2={vp1.x + moveX}
                        y2={vp1.y + radius * 2 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x + moveX}
                        y1={vp1.y - radius + moveY}
                        x2={vp1.x + moveX}
                        y2={vp1.y - radius * 2 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x - radius / Math.sqrt(2) + moveX}
                        y1={vp1.y - radius / Math.sqrt(2) + moveY}
                        x2={vp1.x - radius * 2 / Math.sqrt(2) + moveX}
                        y2={vp1.y - radius * 2 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x - radius / Math.sqrt(2) + moveX}
                        y1={vp1.y + radius / Math.sqrt(2) + moveY}
                        x2={vp1.x - radius * 2 / Math.sqrt(2) + moveX}
                        y2={vp1.y + radius * 2 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x + radius / Math.sqrt(2) + moveX}
                        y1={vp1.y - radius / Math.sqrt(2) + moveY}
                        x2={vp1.x + radius * 2 / Math.sqrt(2) + moveX}
                        y2={vp1.y - radius * 2 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp1.x + radius / Math.sqrt(2) + moveX}
                        y1={vp1.y + radius / Math.sqrt(2) + moveY}
                        x2={vp1.x + radius * 2 / Math.sqrt(2) + moveX}
                        y2={vp1.y + radius * 2 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />

                      <circle
                        cx={vp2.x + moveX} 
                        cy={vp2.y + moveY} 
                        r={radius / 2} 
                        fill="transparent"
                        stroke={layers[index].color}
                        strokeWidth={radius / 2 / 2}
                      />
                      <line
                        x1={vp2.x + radius + moveX}
                        y1={vp2.y + moveY}
                        x2={vp2.x + radius * 2 + moveX}
                        y2={vp2.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x - radius + moveX}
                        y1={vp2.y + moveY}
                        x2={vp2.x - radius * 2 + moveX}
                        y2={vp2.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x + moveX}
                        y1={vp2.y + radius + moveY}
                        x2={vp2.x + moveX}
                        y2={vp2.y + radius * 2 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x + moveX}
                        y1={vp2.y - radius + moveY}
                        x2={vp2.x + moveX}
                        y2={vp2.y - radius * 2 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x - radius / Math.sqrt(2) + moveX}
                        y1={vp2.y - radius / Math.sqrt(2) + moveY}
                        x2={vp2.x - radius * 2 / Math.sqrt(2) + moveX}
                        y2={vp2.y - radius * 2 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x - radius / Math.sqrt(2) + moveX}
                        y1={vp2.y + radius / Math.sqrt(2) + moveY}
                        x2={vp2.x - radius * 2 / Math.sqrt(2) + moveX}
                        y2={vp2.y + radius * 2 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x + radius / Math.sqrt(2) + moveX}
                        y1={vp2.y - radius / Math.sqrt(2) + moveY}
                        x2={vp2.x + radius * 2 / Math.sqrt(2) + moveX}
                        y2={vp2.y - radius * 2 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp2.x + radius / Math.sqrt(2) + moveX}
                        y1={vp2.y + radius / Math.sqrt(2) + moveY}
                        x2={vp2.x + radius * 2 / Math.sqrt(2) + moveX}
                        y2={vp2.y + radius * 2 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />

                      <circle
                        cx={vp4.x + moveX} 
                        cy={vp4.y + moveY} 
                        r={radius / 2} 
                        fill="transparent"
                        stroke={layers[index].color}
                        strokeWidth={radius / 2 / 2}
                      />
                      <line
                        x1={vp4.x + radius + moveX}
                        y1={vp4.y + moveY}
                        x2={vp4.x + radius * 2 + moveX}
                        y2={vp4.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x - radius + moveX}
                        y1={vp4.y + moveY}
                        x2={vp4.x - radius * 2 + moveX}
                        y2={vp4.y + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x + moveX}
                        y1={vp4.y + radius + moveY}
                        x2={vp4.x + moveX}
                        y2={vp4.y + radius * 2 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x + moveX}
                        y1={vp4.y - radius + moveY}
                        x2={vp4.x + moveX}
                        y2={vp4.y - radius * 2 + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x - radius / Math.sqrt(2) + moveX}
                        y1={vp4.y - radius / Math.sqrt(2) + moveY}
                        x2={vp4.x - radius * 2 / Math.sqrt(2) + moveX}
                        y2={vp4.y - radius * 2 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x - radius / Math.sqrt(2) + moveX}
                        y1={vp4.y + radius / Math.sqrt(2) + moveY}
                        x2={vp4.x - radius * 2 / Math.sqrt(2) + moveX}
                        y2={vp4.y + radius * 2 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x + radius / Math.sqrt(2) + moveX}
                        y1={vp4.y - radius / Math.sqrt(2) + moveY}
                        x2={vp4.x + radius * 2 / Math.sqrt(2) + moveX}
                        y2={vp4.y - radius * 2 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      <line
                        x1={vp4.x + radius / Math.sqrt(2) + moveX}
                        y1={vp4.y + radius / Math.sqrt(2) + moveY}
                        x2={vp4.x + radius * 2 / Math.sqrt(2) + moveX}
                        y2={vp4.y + radius * 2 / Math.sqrt(2) + moveY}
                        stroke={layer.color}
                        strokeWidth={2 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  );
                }
                break;
              case "Grid":
                const corner = layer.corner;
                const width = layer.width;
                const height = layer.height;
                const angle = -layer.angle;
                
                const gridLines = [];
                const circles = [];
              
                // Calculate the step size for rows and columns
                const rowHeight = height / layer.rows;
                const columnWidth = width / layer.columns;
              
                // Center of rotation (corner point)
                const cx = corner.x;
                const cy = corner.y;
              
                // Calculate horizontal grid lines
                for (let i = 0; i <= layer.rows; i++) {
                  const y1 = corner.y + i * rowHeight;
                  const y2 = y1;
                  const x1 = corner.x;
                  const x2 = corner.x + width;
              
                  const p1 = calculateRotatedPoint(x1, y1, angle, cx, cy);
                  const p2 = calculateRotatedPoint(x2, y2, angle, cx, cy);
              
                  gridLines.push(
                    <line
                      key={`h${i}`}
                      x1={p1.x}
                      y1={p1.y}
                      x2={p2.x}
                      y2={p2.y}
                      stroke={layer.color}
                      strokeWidth={1 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                  );
                }
              
                // Calculate vertical grid lines
                for (let j = 0; j <= layer.columns; j++) {
                  const x1 = corner.x + j * columnWidth;
                  const x2 = x1;
                  const y1 = corner.y;
                  const y2 = corner.y + height;
              
                  const p1 = calculateRotatedPoint(x1, y1, angle, cx, cy);
                  const p2 = calculateRotatedPoint(x2, y2, angle, cx, cy);
              
                  gridLines.push(
                    <line
                      key={`v${j}`}
                      x1={p1.x}
                      y1={p1.y}
                      x2={p2.x}
                      y2={p2.y}
                      stroke={layer.color}
                      strokeWidth={1 / window.devicePixelRatio}
                      vectorEffect="non-scaling-stroke"
                    />
                  );
                }
              
                // Calculate rotated positions for circles
                const topLeft = calculateRotatedPoint(corner.x, corner.y, angle, cx, cy);
                const bottomLeft = calculateRotatedPoint(corner.x, corner.y + height, angle, cx, cy);
                const topRight = calculateRotatedPoint(corner.x + width, corner.y, angle, cx, cy);
              
                circles.push(
                  <circle
                    key="topLeft"
                    cx={topLeft.x}
                    cy={topLeft.y}
                    r={radius * 0.6} 
                    fill={layer.color}
                  />,
                  <circle
                    key="bottomLeft"
                    cx={bottomLeft.x}
                    cy={bottomLeft.y}
                    r={radius * 0.6} 
                    fill={layer.color}
                  />,
                  <circle
                    key="topRight"
                    cx={topRight.x}
                    cy={topRight.y}
                    r={radius * 0.6} 
                    fill={layer.color}
                  />
                );
              
                return (
                  <svg
                    viewBox={svgViewBox}
                    width={svgWidth}
                    height={svgHeight}
                    className={layerClass(index)}
                  >
                    {gridLines}
                    {circles}
                  </svg>
                );
                break; 
              case "Isometric":
                if(layer.corner){
                  const corner = layer.corner;
                  const width = layer.width;
                  const height = layer.height;
                  const angle = layer.angle;
                  const angle2 = layer.angle2;
                  
                  const gridLines = [];
                  const circles = [];
                
                  // Calculate the step size for rows and columns
                  const rowHeight = width / layer.rows;
                  const columnWidth = height / layer.columns;

                  // Convert angles to radians
                  const radian = angle * (Math.PI / 180);
                  const radian2 = angle2 * (Math.PI / 180);
                
                  // Calculate the position of the grid lines for rows
                  for (let i = 0; i <= layer.rows; i++) {
                    const startX = corner.x + i * rowHeight * Math.cos(radian);
                    const startY = corner.y - i * rowHeight * Math.sin(radian);
                    const endPoint = getEndPoint(startX, startY, angle2, height);
                
                    gridLines.push(
                      <line
                        key={`h${i}`}
                        x1={startX}
                        y1={startY}
                        x2={endPoint.x}
                        y2={endPoint.y}
                        stroke={layer.color}
                        strokeWidth={1 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                    );
                  }
                
                  // Calculate the position of the grid lines for columns
                  for (let j = 0; j <= layer.columns; j++) {
                    const startX = corner.x + j * columnWidth * Math.cos(radian2);
                    const startY = corner.y - j * columnWidth * Math.sin(radian2);
                    const endPoint = getEndPoint(startX, startY, angle, width);
                
                    gridLines.push(
                      <line
                        key={`v${j}`}
                        x1={startX}
                        y1={startY}
                        x2={endPoint.x}
                        y2={endPoint.y}
                        stroke={layer.color}
                        strokeWidth={1 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                    );
                  }
                
                  // Calculate corner points for circles
                  const bottom = corner;
                  const left = getEndPoint(corner.x, corner.y, angle2, height);
                  const right = getEndPoint(corner.x, corner.y, angle, width);

                  circles.push(
                    <circle
                      key="bottom"
                      cx={bottom.x}
                      cy={bottom.y}
                      r={radius * 0.6} 
                      fill={layer.color}
                    />,
                    <circle
                      key="left"
                      cx={left.x}
                      cy={left.y}
                      r={radius * 0.6} 
                      fill={layer.color}
                    />,
                    <circle
                      key="right"
                      cx={right.x}
                      cy={right.y}
                      r={radius * 0.6} 
                      fill={layer.color}
                    />
                  );
                
                  return (
                    <svg
                      viewBox={svgViewBox}
                      width={svgWidth}
                      height={svgHeight}
                      className={layerClass(index)}
                    >
                      {gridLines}
                      {circles}
                    </svg>
                  );
                }
                break;
              case "Ellipse":
                if (layer.center) {
                  const center = layer.center;
                  const width = layer.width;
                  const height = layer.height;
                  const angle = -layer.angle;
              
                  const circles = [];
              
                  const widthCircle = calculateRotatedPoint(center.x + width/2, center.y, angle, center.x, center.y);
                  const heightCircle = calculateRotatedPoint(center.x, center.y - height/2, angle, center.x, center.y);
              
                  circles.push(
                    <circle
                      key="center"
                      cx={center.x}
                      cy={center.y}
                      r={radius * 0.6} 
                      fill={layer.color}
                    />,
                    <circle
                      key="width"
                      cx={widthCircle.x}
                      cy={widthCircle.y}
                      r={radius * 0.6} 
                      fill={layer.color}
                    />,
                    <circle
                      key="height"
                      cx={heightCircle.x}
                      cy={heightCircle.y}
                      r={radius * 0.6} 
                      fill={layer.color}
                    />
                  );
              
                  return (
                    <svg
                      viewBox={svgViewBox}
                      width={svgWidth}
                      height={svgHeight}
                      className={layerClass(index)}
                    >
                      <ellipse 
                        cx={center.x}
                        cy={center.y} 
                        rx={width/2}
                        ry={height/2}
                        fill="transparent"
                        transform={`rotate(${angle}, ${center.x}, ${center.y})`}
                        stroke={layer.color}
                        strokeWidth={1 / window.devicePixelRatio}
                        vectorEffect="non-scaling-stroke"
                      />
                      {circles}
                    </svg>
                  );
                }
                break;
            }              
          }
        }
      })}
      <svg viewBox={svgViewBox} className="svg-overlay" width={svgWidth} height={svgHeight}>
        <g>{blackPathsComp}</g>
        <g>{whitePathsComp}</g>
        <g>{toolPath} </g>
        <g>{movePath} </g>
        <g>{cropPath}</g>
        <g>{transformPath}</g>
        <g>{cursorPath}</g>
        <g>{iconPath}</g>
        <g>{guidesPath}</g>
      </svg>
      {details && <div className="details">{details}</div>}
      {contextMenu && 
        <div
          className="layer-context-menu"
          style={{left: contextMenu.x, top: contextMenu.y}}
        >
          {contextMenu.layers.map((layer,index) => <div key={index} data-id={layer.index}>{layer.name}</div>)}
        </div>
      }
    </div>
  );
});

export default Viewport;
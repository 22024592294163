import { useState, useRef, useEffect, useCallback } from "react";
import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";
import { onlyIntInput, onlyHexInput} from "../utils/basics";
import Modal from 'react-bootstrap/Modal';
import { ChromePicker } from "react-color";

function GuideTool() {
  const {
    layers,
    selectedLayer,
    lastColor, 
    setLastColor,
    lastFocalLength, 
    setLastFocalLength,
    updateGuide,
    version,
    isDocumentLoaded,
  } = useMainContext();

  const [extend, setExtend] = useState();
  const [color, setColor] = useState();
  const [tempColor, setTempColor] = useState('');
  const [colorModal, setColorModal] = useState(false);

  const [startPointX, setStartPointX] = useState('');
  const [startPointY, setStartPointY] = useState('');
  const [endPointX, setEndPointX] = useState('');
  const [endPointY, setEndPointY] = useState('');

  const [pointX, setPointX] = useState('');
  const [pointY, setPointY] = useState('');

  const [centerX, setCenterX] = useState('');
  const [centerY, setCenterY] = useState('');
  const [angle, setAngle] = useState('');
  const [angle2, setAngle2] = useState('');
  const [tilt, setTilt] = useState('');
  const [focalLength, setFocalLength] = useState('');
  
  const [cornerX, setCornerX] = useState('');
  const [cornerY, setCornerY] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [rows, setRows] = useState('');
  const [columns, setColumns] = useState('');

  useEffect(() => {
    const layer = layers[selectedLayer];
    switch(layer.type){
      case "Line":
        const deltaX = layer.endPoint.x - layer.startPoint.x;
        const deltaY = layer.endPoint.y - layer.startPoint.y;
        let angle = -1 * Math.atan2(deltaY, deltaX);
        angle = angle * (180 / Math.PI);

        setExtend(layer.extend);
        setColor(layer.color);
        setTempColor(layer.color);
        setStartPointX(layer.startPoint.x);
        setStartPointY(layer.startPoint.y);
        setEndPointX(layer.endPoint.x);
        setEndPointY(layer.endPoint.y);
        setAngle(Math.round(angle));
        break;
      case "OnePoint":
        setColor(layer.color);
        setTempColor(layer.color);
        setPointX(layer.point.x);
        setPointY(layer.point.y);
        break;
      case "TwoPoint":
        setCenterX(layer.center.x);
        setCenterY(layer.center.y);
        setAngle(layer.angle);
        setTilt(layer.tilt);
        setFocalLength(layer.focalLength);
        setColor(layer.color);
        setTempColor(layer.color);
        break;
      case "ThreePoint":
        setCenterX(layer.center.x);
        setCenterY(layer.center.y);
        setAngle(layer.angle);
        setAngle2(layer.angle2);
        setTilt(layer.tilt);
        setFocalLength(layer.focalLength);
        setColor(layer.color);
        setTempColor(layer.color);
        break;
      case "Grid":
        setCornerX(layer.corner.x);
        setCornerY(layer.corner.y);
        setWidth(layer.width);
        setHeight(layer.height);
        setRows(layer.rows);
        setColumns(layer.columns);
        setAngle(layer.angle);
        setColor(layer.color);
        setTempColor(layer.color);
        break;
      case "Isometric":
        setCornerX(layer.corner.x);
        setCornerY(layer.corner.y);
        setWidth(layer.width);
        setHeight(layer.height);
        setRows(layer.rows);
        setColumns(layer.columns);
        setAngle(layer.angle);
        setAngle2(layer.angle2);
        setColor(layer.color);
        setTempColor(layer.color);
        break;
      case "Ellipse":
        setCenterX(layer.center.x);
        setCenterY(layer.center.y);
        setWidth(layer.width);
        setHeight(layer.height);
        setAngle(layer.angle);
        setColor(layer.color);
        setTempColor(layer.color);
        break;
    }
  }, [layers, selectedLayer, version]);

  const handleExtend = (checked) => {
    setExtend(checked);

    const layer = layers[selectedLayer];
    updateGuide(selectedLayer, {
      startPoint: layer.startPoint,
      endPoint: layer.endPoint,
      color: layer.color,
      extend: checked,
    });
  };

  const closeColorPicker = useCallback(() => {
    setColorModal(false);
    setTempColor(color);
  }, [tempColor]);


  const okayColorPicker = useCallback(() => {
    if (tempColor) {
      setColor(tempColor);
      setLastColor(tempColor);

      const layer = layers[selectedLayer];
      switch (layer.type){
        case "Line":
          updateGuide(selectedLayer, {
            startPoint: layer.startPoint,
            endPoint: layer.endPoint,
            color: tempColor,
            extend: layer.extend,
          });
          break;
        case "OnePoint":
          updateGuide(selectedLayer, {
            point: layer.point,
            color: tempColor,
          });
          break;
        case "TwoPoint":
          updateGuide(selectedLayer, {
            center: layer.center,
            focalLength: layer.focalLength,
            angle: layer.angle,
            tilt: layer.tilt,
            color: tempColor,
          });
          break;
        case "ThreePoint":
          updateGuide(selectedLayer, {
            center: layer.center,
            focalLength: layer.focalLength,
            angle: layer.angle,
            angle2: layer.angle2,
            tilt: layer.tilt,
            color: tempColor,
          });
          break;
        case "Grid":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: layer.width,
            height: layer.height,
            rows: layer.rows,
            columns: layer.columns,
            angle: layer.angle,
            color: tempColor,
          });
          break;
        case "Isometric":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: layer.width,
            height: layer.height,
            rows: layer.rows,
            columns: layer.columns,
            angle: layer.angle,
            angle2: layer.angle2,
            color: tempColor,
          });
          break;
        case "Ellipse":
          updateGuide(selectedLayer, {
            center: layer.center,
            width: layer.width,
            height: layer.height,
            angle: layer.angle,
            color: tempColor,
          });
          break;
      }
    }
    setColorModal(false);
  }, [tempColor]);

  const handleColorChange = (color) => {
    setTempColor(color.hex);
  };

  const handleStartXChange = (e) => {
    setStartPointX(e.target.value);
    if(e.target.value !== ''){
      updateGuide(selectedLayer, {
        startPoint: { x: e.target.value, y: layers[selectedLayer].startPoint.y },
        endPoint: layers[selectedLayer].endPoint,
        color: layers[selectedLayer].color,
        extend: layers[selectedLayer].extend,
      });
    }
  };

  const handleStartYChange = (e) => {
    setStartPointY(e.target.value);
    if(e.target.value !== ''){
      updateGuide(selectedLayer, {
        startPoint: { x: layers[selectedLayer].startPoint.x, y: e.target.value },
        endPoint: layers[selectedLayer].endPoint,
        color: layers[selectedLayer].color,
        extend: layers[selectedLayer].extend,
      });
    }
  };

  const handleEndXChange = (e) => {
    setEndPointX(e.target.value);
    if(e.target.value !== ''){
      updateGuide(selectedLayer, {
        startPoint: layers[selectedLayer].startPoint,
        endPoint: { x: e.target.value, y: layers[selectedLayer].endPoint.y },
        color: layers[selectedLayer].color,
        extend: layers[selectedLayer].extend,
      });
    }
  };

  const handleEndYChange = (e) => {
    setEndPointY(e.target.value);
    if(e.target.value !== ''){
      updateGuide(selectedLayer, {
        startPoint: layers[selectedLayer].startPoint,
        endPoint: { x: layers[selectedLayer].endPoint.x, y: e.target.value },
        color: layers[selectedLayer].color,
        extend: layers[selectedLayer].extend,
      });
    }
  };

  const handlePointXChange = (e) => {
    setPointX(e.target.value);
    if(e.target.value !== ''){
      switch(layers[selectedLayer].type){
        case "OnePoint":
          updateGuide(selectedLayer, {
            point: { x: e.target.value, y: layers[selectedLayer].point.y },
            color: layers[selectedLayer].color,
          });
          break;
      }
    }
  };

  const handlePointYChange = (e) => { 
    setPointY(e.target.value);
    if(e.target.value !== ''){
      switch(layers[selectedLayer].type){
        case "OnePoint":
          updateGuide(selectedLayer, {
            point: { x: layers[selectedLayer].point.x, y: e.target.value },
            color: layers[selectedLayer].color,
          });
          break;
      }
    }
  };

  const handleCenterXChange = (e) => {
    const layer = layers[selectedLayer];
    setCenterX(e.target.value);
    if(e.target.value !== ''){
      switch(layers[selectedLayer].type){
        case "TwoPoint":
          updateGuide(selectedLayer, {
            center: { x: e.target.value, y: layer.center.y },
            focalLength: layer.focalLength,
            angle: layer.angle,
            tilt: layer.tilt,
            color: layer.color,
          });
          break;
        case "ThreePoint":
          updateGuide(selectedLayer, {
            center: { x: e.target.value, y: layer.center.y },
            focalLength: layer.focalLength,
            angle: layer.angle,
            angle2: layer.angle2,
            tilt: layer.tilt,
            color: layer.color,
          });
          break;
        case "Ellipse":
          updateGuide(selectedLayer, {
            center: { x: e.target.value, y: layer.center.y },
            width: layer.width,
            height: layer.height,
            angle: layer.angle,
            color: layer.color,
          });
          break;
      }
    }
  };

  const handleCenterYChange = (e) => { 
    const layer = layers[selectedLayer];
    setCenterY(e.target.value);
    if(e.target.value !== ''){
      switch(layer.type){
        case "TwoPoint":
          updateGuide(selectedLayer, {
            center: { x: layer.center.x, y: e.target.value },
            focalLength: layer.focalLength,
            angle: layer.angle,
            tilt: layer.tilt,
            color: layer.color,
          });
          break;
        case "ThreePoint":
          updateGuide(selectedLayer, {
            center: { x: layer.center.x, y: e.target.value },
            focalLength: layer.focalLength,
            angle: layer.angle,
            angle2: layer.angle2,
            tilt: layer.tilt,
            color: layer.color,
          });
          break;
        case "Ellipse":
          updateGuide(selectedLayer, {
            center: { x: layer.center.x, y: e.target.value },
            width: layer.width,
            height: layer.height,
            angle: layer.angle,
            color: layer.color,
          });
          break;
      }
    }
  };

  const handleCornerXChange = (e) => {
    const layer = layers[selectedLayer];
    setCornerX(e.target.value);
    if(e.target.value !== ''){
      switch(layers[selectedLayer].type){
        case "Grid":
          updateGuide(selectedLayer, {
            corner: { x: e.target.value, y: layer.corner.y },
            width: layer.width,
            height: layer.height,
            rows: layer.rows,
            columns: layer.columns,
            angle: layer.angle,
            color: layer.color,
          });
          break;
        case "Isometric":
          updateGuide(selectedLayer, {
            corner: { x: e.target.value, y: layer.corner.y },
            width: layer.width,
            height: layer.height,
            rows: layer.rows,
            columns: layer.columns,
            angle: layer.angle,
            angle2: layer.angle2,
            color: layer.color,
          });
          break;
      }
    }
  };

  const handleCornerYChange = (e) => { 
    const layer = layers[selectedLayer];
    setCornerY(e.target.value);
    if(e.target.value !== ''){
      switch(layers[selectedLayer].type){
        case "Grid":
          updateGuide(selectedLayer, {
            corner: { x: layer.corner.x, y: e.target.value },
            width: layer.width,
            height: layer.height,
            rows: layer.rows,
            columns: layer.columns,
            angle: layer.angle,
            color: layer.color,
          });
          break;
        case "Isometric":
          updateGuide(selectedLayer, {
            corner: { x: layer.corner.x, y: e.target.value },
            width: layer.width,
            height: layer.height,
            rows: layer.rows,
            columns: layer.columns,
            angle: layer.angle,
            angle2: layer.angle2,
            color: layer.color,
          });
          break;
      }
    }
  };

  const handleAngleChange = (e) => {
    const layer = layers[selectedLayer];
    setAngle(e.target.value);
    if(e.target.value !== ''){
      let newValue = e.target.value;
      if(e.target.value < -90) newValue = -90; 
      if(e.target.value > 90) newValue = 90; 
      switch(layer.type){
        case "TwoPoint":
          updateGuide(selectedLayer, {
            center: layer.center,
            focalLength: layer.focalLength,
            angle: newValue,
            tilt: layer.tilt,
            color: layer.color,
          });
          break;
        case "ThreePoint":
          updateGuide(selectedLayer, {
            center: layer.center,
            focalLength: layer.focalLength,
            angle: newValue,
            angle2: layer.angle2,
            tilt: layer.tilt,
            color: layer.color,
          });
          break;
        case "Ellipse":
          updateGuide(selectedLayer, {
            center: { x: layer.center.x, y: e.target.value },
            width: layer.width,
            height: layer.height,
            angle: layer.angle,
            color: layer.color,
          });
          break;
      }
    }
  };

  const handleAngle2Change = (e) => {
    const layer = layers[selectedLayer];
    setAngle2(e.target.value);
    if(e.target.value !== ''){
      let newValue = e.target.value;
      if(e.target.value < -90) newValue = -90; 
      if(e.target.value > 90) newValue = 90; 
      switch(layer.type){
        case "ThreePoint":
          updateGuide(selectedLayer, {
            center: layer.center,
            focalLength: layer.focalLength,
            angle: layer.angle,
            angle2: newValue,
            tilt: layer.tilt,
            color: layer.color,
          });
          break;
      }
    }
  };

  const handleAnyAngleChange = (e) => {
    const layer = layers[selectedLayer];
    setAngle(e.target.value);
    if(e.target.value !== ''){
      switch(layer.type){
        case "Grid":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: layer.width,
            height: layer.height,
            rows: layer.rows,
            columns: layer.columns,
            angle: e.target.value,
            color: layer.color,
          });
          break;
        case "Isometric":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: layer.width,
            height: layer.height,
            rows: layer.rows,
            columns: layer.columns,
            angle: e.target.value,
            angle2: layer.angle2,
            color: layer.color,
          });
          break;
        case "Ellipse":
          updateGuide(selectedLayer, {
            center: layer.center,
            width: layer.width,
            height: layer.height,
            angle: e.target.value,
            color: layer.color,
          });
          break;
      }
    }
  };

  
  const handleAnyAngle2Change = (e) => {
    const layer = layers[selectedLayer];
    setAngle(e.target.value);
    if(e.target.value !== ''){
      switch(layer.type){
        case "Isometric":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: layer.width,
            height: layer.height,
            rows: layer.rows,
            columns: layer.columns,
            angle: layer.angle,
            angle2: e.target.value,
            color: layer.color,
          });
          break;
      }
    }
  };

  const handleLineAngleChange = (e) => {
    const layer = layers[selectedLayer];
    setAngle(e.target.value);

    if(e.target.value !== ''){
      const newAngle = Math.round(e.target.value);
      const deltaX = layer.endPoint.x - layer.startPoint.x;
      const deltaY = layer.endPoint.y - layer.startPoint.y;
      const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

      const angleInRadians = -newAngle * (Math.PI / 180);

      const newEndX = layer.startPoint.x + length * Math.cos(angleInRadians);
      const newEndY = layer.startPoint.y + length * Math.sin(angleInRadians);

      updateGuide(selectedLayer, {
        startPoint: layer.startPoint,
        endPoint: {x: newEndX, y: newEndY},
        color: layers[selectedLayer].color,
        extend: layers[selectedLayer].extend,
      });
    }
  };

  const handleTiltChange = (e) => {
    const layer = layers[selectedLayer];
    setTilt(e.target.value);
    if(e.target.value !== ''){
      let newValue = e.target.value;
      if(e.target.value < -90) newValue = -90; 
      if(e.target.value > 90) newValue = 90; 
      switch(layer.type){
        case "TwoPoint":
          updateGuide(selectedLayer, {
            center: layer.center,
            focalLength: layer.focalLength,
            angle: layer.angle,
            tilt: newValue,
            color: layer.color,
          });
          break;
        case "ThreePoint":
          updateGuide(selectedLayer, {
            center: layer.center,
            focalLength: layer.focalLength,
            angle: layer.angle,
            angle2: layer.angle2,
            tilt: newValue,
            color: layer.color,
          });
          break;
      }
    }
  };

  const handleFLChange = (e) => {
    const layer = layers[selectedLayer];

    setFocalLength(e.target.value);
    if(e.target.value !== ''){
      let newValue = e.target.value;
      if(e.target.value < 1) newValue = 1;
      setLastFocalLength(newValue);
      switch(layer.type){
        case "TwoPoint":
          updateGuide(selectedLayer, {
            center: layer.center,
            focalLength: newValue,
            angle: layer.angle,
            tilt: layer.tilt,
            color: layer.color,
          });
          break;
        case "ThreePoint":
          updateGuide(selectedLayer, {
            center: layer.center,
            focalLength: newValue,
            angle: layer.angle,
            angle2: layer.angle2,
            tilt: layer.tilt,
            color: layer.color,
          });
          break;
      }
    }
  };
  
  const handleWidthChange = (e) => { 
    const layer = layers[selectedLayer];
    setWidth(e.target.value);
    if(e.target.value !== ''){
      switch(layers[selectedLayer].type){
        case "Grid":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: e.target.value,
            height: layer.height,
            rows: layer.rows,
            columns: layer.columns,
            angle: layer.angle,
            color: layer.color,
          });
          break;
        case "Isometric":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: e.target.value,
            height: layer.height,
            rows: layer.rows,
            columns: layer.columns,
            angle: layer.angle,
            angle2: layer.angle2,
            color: layer.color,
          });
          break;
        case "Ellipse":
          updateGuide(selectedLayer, {
            center: layer.center,
            width: e.target.value,
            height: layer.height,
            angle: layer.angle,
            color: layer.color,
          });
          break;
      }
    }
  };
  
  const handleHeightChange = (e) => { 
    const layer = layers[selectedLayer];
    setHeight(e.target.value);
    if(e.target.value !== ''){
      switch(layers[selectedLayer].type){
        case "Grid":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: layer.width,
            height: e.target.value,
            rows: layer.rows,
            columns: layer.columns,
            angle: layer.angle,
            color: layer.color,
          });
          break;
        case "Isometric":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: layer.width,
            height: e.target.value,
            rows: layer.rows,
            columns: layer.columns,
            angle: layer.angle,
            angle2: layer.angle2,
            color: layer.color,
          });
          break;
        case "Ellipse":
          updateGuide(selectedLayer, {
            center: layer.center,
            width: layer.width,
            height: e.target.value,
            angle: layer.angle,
            color: layer.color,
          });
          break;
      }
    }
  };
  const handleRowsChange = (e) => { 
    const layer = layers[selectedLayer];
    setRows(e.target.value);
    if(e.target.value !== ''){
      let newValue = e.target.value;
      if(newValue <= 0) newValue = 1;
      switch(layers[selectedLayer].type){
        case "Grid":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: layer.width,
            height: layer.height,
            rows: newValue,
            columns: layer.columns,
            angle: layer.angle,
            color: layer.color,
          });
          break;
        case "Isometric":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: layer.width,
            height: layer.height,
            rows: newValue,
            columns: layer.columns,
            angle: layer.angle,
            angle2: layer.angle2,
            color: layer.color,
          });
          break;
      }
    }
  };
  const handleColumnsChange = (e) => { 
    const layer = layers[selectedLayer];
    setColumns(e.target.value);
    if(e.target.value !== ''){
      let newValue = e.target.value;
      if(newValue <= 0) newValue = 1;
      switch(layers[selectedLayer].type){
        case "Grid":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: layer.width,
            height: layer.height,
            rows: layer.rows,
            columns: newValue,
            angle: layer.angle,
            color: layer.color,
          });
          break;
        case "Isometric":
          updateGuide(selectedLayer, {
            corner: layer.corner,
            width: layer.width,
            height: layer.height,
            rows: layer.rows,
            columns: newValue,
            angle: layer.angle,
            angle2: layer.angle2,
            color: layer.color,
          });
          break;
      }
    }
  };

  const renderToolOptions = () => {
    switch (layers[selectedLayer].type) {
      case "Line":
        return (
          <>
            <label className="tool-label">Start Point:</label>
            <input
              className="tool-coord"
              type="number"
              value={startPointX}
              onChange={handleStartXChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={startPointY}
              onChange={handleStartYChange}
            ></input>

            
            <label className="tool-label">End Point:</label>
            <input
              className="tool-coord"
              type="number"
              value={endPointX}
              onChange={handleEndXChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={endPointY}
              onChange={handleEndYChange}
            ></input>

            <label className="tool-label">Angle: <input
              className="tool-coord"
              type="number"
              value={angle}
              onChange={handleLineAngleChange}
              step="22.5"
            ></input></label>

            <label className="tool-label">
              Extend: 
              <input
                className="tool-checkbox"
                type="checkbox"
                onChange={(e) => handleExtend(e.target.checked)}
                checked={extend}
              ></input>
            </label>

            <label className="tool-label">Color: 
              <div 
                onClick={() => setColorModal(true)}
                className="guide-swatch"
                style={{
                  backgroundColor: color,
                }}
              ></div>
            </label>
          </>
        );
      case "OnePoint":
        return (
          <>   
            <label className="tool-label">Center:</label>
            <input
              className="tool-coord"
              type="number"
              value={pointX}
              onChange={handlePointXChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={pointY}
              onChange={handlePointYChange}
            ></input>

            <label className="tool-label">Color: 
              <div 
                onClick={() => setColorModal(true)}
                className="guide-swatch"
                style={{
                  backgroundColor: color,
                }}
              ></div>
            </label>
          </>
        );
        break;
      case "TwoPoint":
        return (
          <>
            <label className="tool-label">Center:</label>
            <input
              className="tool-coord"
              type="number"
              value={centerX}
              onChange={handleCenterXChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={centerY}
              onChange={handleCenterYChange}
            ></input>

            <label className="tool-label">Angle: <input
              className="tool-coord"
              type="number"
              value={angle}
              onChange={handleAngleChange}
              min={-90}
              max={90}
              step="11.25"
            ></input></label>

            <label className="tool-label">Tilt: <input
              className="tool-coord"
              type="number"
              value={tilt}
              onChange={handleTiltChange}
              min={-90}
              max={90}
              step="11.25"
            ></input></label>

            <label className="tool-label">Focal Length: <input
              className="tool-coord"
              type="text"
              value={focalLength}
              onChange={handleFLChange}
              min={1} 
              onKeyDown={onlyIntInput}
            ></input></label>

            <label className="tool-label">Color: 
              <div 
                onClick={() => setColorModal(true)}
                className="guide-swatch"
                style={{
                  backgroundColor: color,
                }}
              ></div>
            </label>
          </>
        );
        break;
      case "ThreePoint":
        return (
          <>
            <label className="tool-label">Center:</label>
            <input
              className="tool-coord"
              type="number"
              value={centerX}
              onChange={handleCenterXChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={centerY}
              onChange={handleCenterYChange}
            ></input>

            <label className="tool-label">Angle: <input
              className="tool-coord"
              type="number"
              value={angle}
              onChange={handleAngleChange}
              min={-90}
              max={90}
              step="11.25"
            ></input></label>

            <label className="tool-label">Angle2: <input
              className="tool-coord"
              type="number"
              value={angle2}
              onChange={handleAngle2Change}
              min={-90}
              max={90}
              step="11.25"
            ></input></label>

            <label className="tool-label">Tilt: <input
              className="tool-coord"
              type="number"
              value={tilt}
              onChange={handleTiltChange}
              min={-90}
              max={90}
              step="11.25"
            ></input></label>

            <label className="tool-label">Focal Length: <input
              className="tool-coord"
              type="text"
              value={focalLength}
              onChange={handleFLChange}
              min={1} 
              onKeyDown={onlyIntInput}
            ></input></label>

            <label className="tool-label">Color: 
              <div 
                onClick={() => setColorModal(true)}
                className="guide-swatch"
                style={{
                  backgroundColor: color,
                }}
              ></div>
            </label>
          </>
        );
        break;
      case "Grid":
        return (
          <>
            <label className="tool-label">Corner:</label>
            <input
              className="tool-coord"
              type="number"
              value={cornerX}
              onChange={handleCornerXChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={cornerY}
              onChange={handleCornerYChange}
            ></input>

            <label className="tool-label">Width/Height:</label> 
            <input
              className="tool-coord"
              type="number"
              value={width}
              onChange={handleWidthChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={height}
              onChange={handleHeightChange}
            ></input>
            
            <label className="tool-label">Coumns/Rows:</label>
            <input
              className="tool-coord"
              type="number"
              min={1}
              value={columns}
              onKeyDown={onlyIntInput}
              onChange={handleColumnsChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              min={1}
              value={rows}
              onKeyDown={onlyIntInput}
              onChange={handleRowsChange}
            ></input>

            <label className="tool-label">Angle: <input
              className="tool-coord"
              type="number"
              value={angle}
              step="22.5"
              onChange={handleAnyAngleChange}
            ></input></label>

            <label className="tool-label">Color: 
              <div 
                onClick={() => setColorModal(true)}
                className="guide-swatch"
                style={{
                  backgroundColor: color,
                }}
              ></div>
            </label>
          </>
        );
        break;
      case "Isometric":
        return (
          <>
            <label className="tool-label">Corner:</label>
            <input
              className="tool-coord"
              type="number"
              value={cornerX}
              onChange={handleCornerXChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={cornerY}
              onChange={handleCornerYChange}
            ></input>

            <label className="tool-label">Edge Lengths:</label> 
            <input
              className="tool-coord"
              type="number"
              value={width}
              onChange={handleWidthChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={height}
              onChange={handleHeightChange}
            ></input>
            
            <label className="tool-label">Coumns/Rows:</label>
            <input
              className="tool-coord"
              type="number"
              value={columns}
              min={1}
              onKeyDown={onlyIntInput}
              onChange={handleColumnsChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={rows}
              min={1}
              onKeyDown={onlyIntInput}
              onChange={handleRowsChange}
            ></input>

            <label className="tool-label">Angles:</label>
            <input
              className="tool-coord"
              type="number"
              value={angle}
              step="22.5"
              onChange={handleAnyAngleChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={angle2}
              step="22.5"
              onChange={handleAnyAngle2Change}
            ></input>

            <label className="tool-label">Color: 
              <div 
                onClick={() => setColorModal(true)}
                className="guide-swatch"
                style={{
                  backgroundColor: color,
                }}
              ></div>
            </label>
          </>
        );
        break;
      case "Ellipse":
        return (
          <>
            <label className="tool-label">Center:</label>
            <input
              className="tool-coord"
              type="number"
              value={centerX}
              onChange={handleCenterXChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={centerY}
              onChange={handleCenterYChange}
            ></input>

            <label className="tool-label">Width/Height:</label> 
            <input
              className="tool-coord"
              type="number"
              value={width}
              onChange={handleWidthChange}
            ></input>
            <input
              className="tool-coord"
              type="number"
              value={height}
              onChange={handleHeightChange}
            ></input>

            <label className="tool-label">Angle:
              <input
                className="tool-coord"
                type="number"
                value={angle}
                step="22.5"
                onChange={handleAnyAngleChange}
              ></input>
            </label>

            <label className="tool-label">Color: 
              <div 
                onClick={() => setColorModal(true)}
                className="guide-swatch"
                style={{
                  backgroundColor: color,
                }}
              ></div>
            </label>
          </>
        );
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <h4>Guide Tool</h4>
      {renderToolOptions()}
    
      <Modal show={colorModal} onHide={closeColorPicker} animation={false} backdrop="static" centered>
        <div className="color-wrapper">
          <ChromePicker
            color={tempColor}
            onChangeComplete={handleColorChange}
            disableAlpha={true}
          />
          <button className="color-okay" onClick={okayColorPicker}>Okay</button>
        </div>
        <button onClick={closeColorPicker}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>
    </>
  );
}

export default GuideTool;

import { useState, useEffect } from "react";
import { maxResolution, maxLayerSize} from "../constants";
import { useMainContext } from "../contexts/MainContext";
import "../css/ResizeCanvas.css";
import { onlyIntInput} from "../utils/basics";

function ResizeCanvas({ onResize}) {
    const {
        layers,
        resolution,
        offset,
        version,
        resize
    } = useMainContext();

    const [width, setWidth] = useState(resolution.width);
    const [height, setHeight] = useState(resolution.height);
    const [aspectRatio, setAspectRatio] = useState(resolution.width/resolution.height);
    const [displayWidth, setDisplayWidth] = useState(width);
    const [displayHeight, setDisplayHeight] = useState(height);
    const [maxWidth, setMaxWidth] = useState(maxResolution);
    const [maxHeight, setMaxHeight] = useState(maxResolution);
    const [maintainRatio, setMaintainRatio] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        let left = offset.x + resolution.width - maxLayerSize;
        let top = offset.y + resolution.height - maxLayerSize;
        let right = offset.x + maxLayerSize;
        let bottom = offset.y + maxLayerSize;
        for(const layer of layers){
            if(layer.type !== "Canvas") continue;
            const layerLeft = layer.x;
            const layerTop = layer.y;
            const layerRight = layerLeft + layer.ref.current.width;
            const layerBottom = layerTop + layer.ref.current.height;

            left = Math.max(left, layerRight - maxLayerSize);
            top = Math.max(top, layerBottom - maxLayerSize);
            right = Math.min(right, layerLeft + maxLayerSize);
            bottom = Math.min(bottom, layerTop + maxLayerSize)
        }

        const leftMargin = offset.x - left;
        const rightMargin = right - offset.x - resolution.width;
        const widthMargin = Math.min(leftMargin, rightMargin);
        setMaxWidth(Math.min(maxResolution, resolution.width + widthMargin * 2));

        const topMargin = offset.y - top;
        const bottomMargin = bottom - offset.y - resolution.height;
        const heightMargin = Math.min(topMargin, bottomMargin);
        setMaxHeight(Math.min(maxResolution, resolution.height + heightMargin * 2));
    }, [layers, resolution, offset, version]);

    const handleWidthChange = (e) => {
        if(e.target.value > maxWidth){ 
            if(maintainRatio){
                if(e.target.value / aspectRatio > maxHeight){
                    setHeight(maxHeight);
                    setDisplayHeight(maxHeight);
                    setWidth(Math.round(maxHeight * aspectRatio));
                    setDisplayWidth(Math.round(maxHeight * aspectRatio));
                } else {
                    setWidth(maxWidth);
                    setDisplayWidth(maxWidth);
                    setHeight(Math.round(maxWidth / aspectRatio));
                    setDisplayHeight(Math.round(maxWidth / aspectRatio));
                }
            } else {
                setWidth(maxWidth);
                setDisplayWidth(maxWidth);
            }
        } else if(e.target.value <= 0){
            setWidth(1);
            setDisplayWidth(''); 
            if(maintainRatio){
                setHeight(1);
                setDisplayHeight('');
            }
        } else {
            if(maintainRatio){
                if(e.target.value / aspectRatio > maxHeight){
                    setHeight(maxHeight);
                    setDisplayHeight(maxHeight);
                    setWidth(Math.round(maxHeight * aspectRatio));
                    setDisplayWidth(Math.round(maxHeight * aspectRatio));
                } else {
                    setWidth(e.target.value);
                    setDisplayWidth(e.target.value);
                    setHeight(Math.round(e.target.value / aspectRatio));
                    setDisplayHeight(Math.round(e.target.value / aspectRatio));
                }
            } else {
                setWidth(e.target.value);
                setDisplayWidth(e.target.value);
            }
        }
    };
    const handleHeightChange = (e) => {
        if(e.target.value > maxHeight){ 
            if(maintainRatio){
                if(e.target.value * aspectRatio > maxWidth){
                    setWidth(maxWidth);
                    setDisplayWidth(maxWidth);
                    setHeight(Math.round(maxWidth / aspectRatio));
                    setDisplayHeight(Math.round(maxWidth / aspectRatio));
                } else {
                    setHeight(maxHeight);
                    setDisplayHeight(maxHeight);
                    setWidth(Math.round(maxHeight * aspectRatio));
                    setDisplayWidth(Math.round(maxHeight * aspectRatio));
                }
            } else {
                setHeight(maxHeight); 
                setDisplayHeight(maxHeight);
            }
        } else if(e.target.value <= 0){
            setHeight(1);
            setDisplayHeight('');
            if(maintainRatio){
                setWidth(1);
                setDisplayHeight('');
            }
        } else {
            if(maintainRatio){
                if(e.target.value * aspectRatio > maxWidth){
                    setWidth(maxWidth);
                    setDisplayWidth(maxWidth);
                    setHeight(Math.round(maxWidth / aspectRatio));
                    setDisplayHeight(Math.round(maxWidth / aspectRatio));
                } else {
                    setHeight(e.target.value);
                    setDisplayHeight(e.target.value);
                    setWidth(Math.round(e.target.value * aspectRatio));
                    setDisplayWidth(Math.round(e.target.value * aspectRatio));
                }
            } else {
                setHeight(e.target.value);
                setDisplayHeight(e.target.value);
            }
        }
    };
    
    // Helper function to store document ID in local storage
    const handleSubmit = (e) => {
        e.preventDefault();

        if(width > maxWidth) return setErrorMessage("Width must be less than maxResolution px.");
        if(height > maxHeight) return setErrorMessage("Height must be less than maxResolution px.");
        if(!/^[0-9]+$/.test(width)) return setErrorMessage("Width contains invalid characters.");
        if(!/^[0-9]+$/.test(height)) return setErrorMessage("Height contains invalid characters.");

        const centerX = resolution.width/2;
        const centerY = resolution.height/2;

        resize({
            left: Math.round(centerX - width/2),
            top: Math.round(centerY - height/2),
            right: Math.round(centerX + width/2),
            bottom: Math.round(centerY + height/2)
        });
                
        onResize();
    };

    const handleMaintainRatio = (checked) => {
      setMaintainRatio(checked);
      setHeight(Math.round(width / aspectRatio));
      setDisplayHeight(Math.round(width / aspectRatio));
    };

    return (
        <div className="resize-wrapper">
            <h2 className="resize-heading">Resize Canvas</h2>
            <label className="resize-label" htmlFor="width">New Width:</label>
            <div className="resize-input-wrapper">
                <input
                    id="width"
                    type="number"
                    className="resize-input"
                    value={displayWidth}
                    onKeyDown={onlyIntInput}
                    onChange={handleWidthChange}
                    max={maxWidth}
                /> Max {maxWidth}px
            </div>
            <label className="resize-label" htmlFor="height">New Height:</label>
            <div className="resize-input-wrapper">
                <input
                    id="height"
                    type="number"
                    className="resize-input"
                    value={displayHeight}
                    onKeyDown={onlyIntInput}
                    onChange={handleHeightChange}
                    max={maxHeight}
                /> Max {maxHeight}px
            </div>
            <label className="ratio-label">
                Maintain Ratio:
                <input
                className="tool-checkbox"
                type="checkbox"
                onChange={(e) => handleMaintainRatio(e.target.checked)}
                checked={maintainRatio}
                ></input>
            </label>
            {errorMessage && <div className="resize-error">{errorMessage}</div>}
            <div className="resize-bottom"><input type="submit" value="Resize" onClick={(event) => {handleSubmit(event)}}/></div>
        </div>
    );
}

export default ResizeCanvas;

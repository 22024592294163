import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useUserContext } from "../contexts/UserContext";
import axios from 'axios';
import { apiUrl } from "../constants";
import "../css/Home.css";
import SignIn from "./SignIn";
import Register from "./Register";
import Modal from 'react-bootstrap/Modal';
import logo from '../assets/images/logo.svg';
import phone from '../assets/images/landing/phone.jpg';
import pressure from '../assets/images/landing/pressure.jpg';
import guidesImage from '../assets/images/landing/perspective.jpg';
import exportImg from '../assets/images/landing/export.jpg';
import layers from '../assets/images/landing/layers.jpg';
import tools from '../assets/images/landing/tools.jpg';
import madmonkey from '../assets/images/landing/MadMonkey.png';
import madmonkeySquare from '../assets/images/landing/MadMonkey Square.png';

function Home() {
  const { user, setUser} = useUserContext();

  const navigate = useNavigate();

  const [mobileMenuDisplay, setMobileMenuDisplay] = useState("none");
  const [signInModal, setSignInModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);

  const closeModal = () => {
    setSignInModal(false);
    setRegisterModal(false);
  };

  const onSuccessfulLogin = (userData) => {
    setUser(userData);
    closeModal();
    navigate("/dashboard");
  };

  const handleMenuButton = () => {
    if(mobileMenuDisplay === "none"){
      setMobileMenuDisplay("block");
    } else {
      setMobileMenuDisplay("none");
    }
  }

  const handleSignOut = () => {
    axios.post(apiUrl + "/api/auth/signOut")
      .then(response => {
        if (response.status === 200) {
          // Update UserContext
          setUser(null);
        }
      })
      .catch(error => {
          console.error('Error signing out:', error);
          window.alert('Error signing out.');
      });
  };

  return (
    <div className="home-wrapper grow">
      <div className="gradient one"></div>
      <div className="gradient two"></div>
      <div className="gradient three"></div>
      <div className="gradient four"></div>

      <div className="home">
        <header className="header-nav">
          <a href="/" className="home-link">
            <img src={logo} alt="Aliasing.Pro Logo" className="home-logo"></img>
            <h1 className="home-link-title">Aliasing.Pro</h1>
          </a>
          <div className="home-header-spacer"></div>
          <a href="/dashboard" className="home-header-button">Dashboard</a>
          <a href="/guide" className="home-header-button">Guide</a>
          <a href="https://discord.gg/ZUFWfzfR4G" className="home-header-button">Discord</a>
          <a href="https://www.kickstarter.com/projects/monkeymakes/aliasingpro" className="home-header-button">Kickstarter</a>
          {/* <div className="home-header-line">|</div> */}
          {user ?
            <>
              <a href="/dashboard" className="home-header-button sign-in">{user.displayName}</a>
            </>
            :
            <>
              <div className="home-header-button sign-in" onClick={() => setSignInModal(true)}>Sign In</div>
            </>
          }
        </header>
        <header className="header-nav-mobile">
          <a href="/" className="home-link">
            <img src={logo} alt="Aliasing.Pro Logo" className="home-logo"></img>
            <h1 className="home-link-title">Aliasing.Pro</h1>
          </a>
          <div className="grow"></div>
          <div className="home-menu-button" onClick={handleMenuButton}>
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="14" viewBox="0 0 33 14" fill="none">
              <path d="M2 1.5H31" stroke="#4DB848" stroke-width="3" stroke-linecap="round"/>
              <path d="M2 12.5H31" stroke="#4DB848" stroke-width="3" stroke-linecap="round"/>
            </svg>
          </div>
        </header>
        
        <div className="description">
          <div className="description-left">
            <h2 className="description-heading">
              Unleash Your <br></br>Creativity
              <span className="ease"> with Ease
                <svg xmlns="http://www.w3.org/2000/svg" width="248" height="25" viewBox="0 0 248 25" fill="none" className="ease-underline">
                  <path d="M10.346 18.6831C9.00077 18.8998 7.63959 19.1475 6.26871 19.3273C5.21706 19.4661 4.15393 19.6105 3.10104 19.5849C1.98018 19.5584 1.34177 18.9743 1.27004 18.1292C1.18083 17.0783 1.82398 16.5645 2.7551 16.3448C7.01556 15.3363 11.236 14.0504 15.5532 13.4056C25.6421 11.8998 35.8299 11.9871 46.0055 12.1524C52.1894 12.253 58.3737 12.2697 64.5523 12.4961C72.474 12.7859 80.3791 12.4843 88.2744 11.983C101.351 11.1537 114.374 9.88418 127.258 7.36424C136.125 5.62998 145.046 4.07198 154.109 3.79672C162.147 3.55255 170.193 3.47674 178.234 3.45768C184.88 3.44223 191.53 3.54189 198.174 3.7329C205.882 3.95437 213.584 4.30804 221.287 4.64911C227.661 4.93185 234.028 5.44847 240.412 4.73361C242.618 4.48712 242.93 4.76375 243.944 6.85436C244.472 7.00228 245.058 7.12202 245.609 7.33202C246.984 7.85809 247.705 9.0335 247.554 10.4203C247.411 11.7167 246.535 12.6961 245.145 12.9614C244.573 13.0696 243.957 13.0137 243.367 12.9663C235.685 12.3511 228.007 11.6771 220.321 11.1145C212.249 10.5238 204.251 11.3617 196.276 12.5393C183.784 14.382 171.295 16.2528 158.788 17.9944C147.636 19.5462 136.428 20.0219 125.163 19.6331C114.534 19.2661 103.894 19.1645 93.2604 18.9411C85.9495 18.7881 78.6345 18.4195 71.3267 18.5219C62.3552 18.6475 53.3807 19.035 44.4223 19.5519C37.2555 19.9671 30.1165 20.7983 22.955 21.339C20.0493 21.5575 17.1227 21.4981 14.2044 21.5619C14.0061 21.5686 13.806 21.5543 13.6067 21.5316C11.4185 21.2998 10.9161 20.8776 10.3438 18.6822L10.346 18.6831ZM126.556 13.1598C133.553 14.4924 174.663 10.7915 179.438 8.40082C161.634 8.46603 144.036 8.71189 126.556 13.1598Z" fill="#4DB848"/>
                </svg>
              </span>
            </h2>
            <p className="description-content">
              Aliasing.Pro is a pixel art tool with 32-Color palettes. You can create art from anywhere, with any device!
            </p>
            <div className="description-bottom">
              <a href="/dashboard"className="description-get-started">Get Started</a>
              <a href="/guide" className="description-guide">View Guide</a>
            </div>
          </div>
          <div className="grow"></div>
          <div className="description-image-wrapper">
            <img src={phone} className="description-image"></img>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" className="description-bucket">
              <path d="M17.901 1.15988C21.875 1.35972 25.1851 1.67316 28.283 3.09309C30.2074 3.97521 31.1744 5.22755 30.7894 7.5573C29.8025 13.5259 28.9814 19.5233 28.1778 25.5203C27.9643 27.1142 27.2406 28.18 25.8337 28.8626C20.8047 31.3017 15.7676 31.3315 10.7315 28.9015C9.90974 28.505 9.09039 28.0212 8.70332 27.1541C8.30364 26.2594 8.11572 25.2928 9.56229 25.1974C13.8382 24.9148 16.4646 21.6879 19.6894 19.5457C20.5165 18.9963 20.6143 18.0003 20.2272 17.0726C19.8366 16.1365 19.0825 15.6646 18.0665 15.7473C17.0529 15.8297 16.2241 16.4082 16.1687 17.3987C15.9723 20.9113 13.2555 21.7696 10.6709 22.4929C7.74373 23.3119 4.8986 22.7331 2.67264 20.5137C0.161986 18.0094 0.734867 14.4459 4.11396 12.5407C5.82173 11.578 6.18671 10.5967 5.85259 8.76126C5.14087 4.85031 5.83891 3.82199 9.58333 2.53774C10.6541 2.17031 11.7606 1.84881 12.8769 1.68613C14.747 1.41372 16.6381 1.28645 17.901 1.15988Z" fill="white"/>
              <path d="M18.6485 9.38286C15.3728 9.49224 12.4891 8.97125 9.76112 7.70348C9.22891 7.45631 8.55821 7.20913 8.53122 6.49916C8.50387 5.77307 9.16545 5.49189 9.70257 5.2675C15.3665 2.90165 21.0477 2.99526 26.7169 5.24997C27.261 5.4663 27.93 5.7338 27.9457 6.44237C27.9615 7.1597 27.2894 7.40897 26.7561 7.67122C24.1144 8.96985 21.2826 9.37725 18.6485 9.38321V9.38286Z" fill="#4DB848"/>
              <path d="M7.38509 19.1839C7.38685 19.4991 7.46257 19.9647 6.81992 20.0846C5.81545 20.2722 3.6866 18.5683 3.49377 17.3973C3.30445 16.2494 4.81168 14.2962 5.89364 14.2068C6.58187 14.15 6.57941 14.6468 6.70282 15.0482C6.81291 15.4065 6.88514 15.7792 6.94298 16.1505C7.09339 17.1168 7.22662 18.0858 7.38544 19.1839H7.38509Z" fill="#4DB848"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="78" height="21" viewBox="0 0 78 21" fill="none" className="description-swatches">
              <rect x="0.866423" y="0.682341" width="19.7152" height="19.7152" rx="2.9896" fill="black" stroke="#CECECE" stroke-width="0.4848"/>
              <rect x="29.1463" y="0.682402" width="19.7152" height="19.7152" rx="2.9896" fill="#A14C4C" stroke="#CECECE" stroke-width="0.4848"/>
              <rect x="57.4264" y="0.682402" width="19.7152" height="19.7152" rx="2.9896" fill="#4C4FA1" stroke="#CECECE" stroke-width="0.4848"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="103" height="44" viewBox="0 0 103 44" fill="none" className="description-slider">
              <rect x="0.263916" y="0.463989" width="102.616" height="42.824" rx="13.736" fill="#B5D9B3"/>
              <rect x="18.8479" y="19.856" width="66.256" height="4.848" rx="1.616" fill="#3D3D3D"/>
              <rect x="28.5439" y="15.816" width="12.928" height="12.928" rx="6.464" fill="#3D3D3D"/>
            </svg>
          </div>
        </div>

        <div className="create-account">
          <h2 className="create-account-heading">Create a <span className="bold">Free account!</span></h2>
          <div className="create-account-text">Sign up for free to access your documents across devices and start creating!</div>
          <div className="grow create-account-line">
            <svg xmlns="http://www.w3.org/2000/svg" width="1" height="90" viewBox="0 0 1 90" fill="none">
              <path d="M0.488037 0.255981V89.136" stroke="#4DB848" stroke-width="0.808"/>
            </svg>
          </div>
          <div className="create-account-button" onClick={() => setRegisterModal(true)}>Create an Account</div>
        </div>

        <div className="features">
          <h2 className="features-heading">Features</h2>
          <p className="features-text">Check out some of our exciting features that make it easy for you to create.</p>
          <div className="pressure">
            <div className="features-content">
              <div className="features-number">1</div>
              <h3 className="features-subheading">Drawing with a stylus allows pressure sensitivity</h3>
              Harness the versatility of pressure sensitivity when drawing with a stylus. This intuitive feature allows for a more expressive and immersive artistic experience.
            </div>
            <div className="grow"></div>
            <img src={pressure} className="features-image"></img>
            <img src={pressure} className="features-image-mobile"></img>
          </div>
          <div className="transformation">
            <img src={tools} className="features-image"></img>
            <div className="grow"></div>
            <div className="features-content">
              <div className="features-number">2</div>
              <h3 className="features-subheading">Selection and Transformation Tools</h3>
              With our advanced selection and transformation tools, you can effortlessly fine-tune every detail of your creations. Our intuitive features make it a breeze to bring your ideas to life.
            </div>
            <img src={tools} className="features-image-mobile"></img>
          </div>
          <div className="layers">
            <div className="features-content">
              <div className="features-number">3</div>
              <h3 className="features-subheading">Draw on up to 32 layers at a time.</h3>
              You have the flexibility to draw on up to 32 layers at once. Dive into your artwork, layer by layer, and let your imagination run wild.
            </div>
            <div className="grow"></div>
            <img src={layers} className="features-image"></img>
            <img src={layers} className="features-image-mobile"></img>
          </div>
          <div className="export">
            <img src={guidesImage} className="features-image"></img>
            <div className="grow"></div>
            <div className="features-content">
              <div className="features-number">4</div>
              <h3 className="features-subheading">Advanced Guide Tools</h3>
              You can create various guides such as perspective vanishing points and restrict your drawing using the shift key.
            </div>
            <img src={guidesImage} className="features-image-mobile"></img>
          </div>
          <div className="export">
            <div className="grow"></div>
            <div className="features-content">
              <div className="features-number">5</div>
              <h3 className="features-subheading">Export as GIF or PNG</h3>
              You can effortlessly export your creations as GIFs or PNGs. Share your work with friends, family, or the world, and let your creativity shine bright!
            </div>
            <img src={exportImg} className="features-image"></img>
            <img src={exportImg} className="features-image-mobile"></img>
          </div>
        </div>

        <div className="about">
          <div className="about-content">
            <h2 className="about-heading">About</h2>
            <p>
              Aliasing.Pro is developed by a digital artist and programmer from 
              California who goes by Monkey online. Monkey is also the author of <a href="https://losingisfun.org" target="_blank" rel="noreferrer">Losing is Fun</a>,
              an interactive webcomic with a pixelated style. Monkey found 
              a need for a tool specializing in this style so he decided to start developing a tool for anyone to use.
            </p>
          </div>
          <img src={madmonkey} alt="MadMonkey's Avatar" className='about-avatar'></img>
          <img src={madmonkeySquare} alt="MadMonkey's Avatar Mobile" className='about-avatar-square'></img>
        </div>

        <div className="home-guide">
          <div className="home-guide-left">
            <h2 className="home-guide-heading">Guide</h2>
            <p>View this guide to Aliasing.Pro and learn the tools<br></br>and how to navigate the web app.</p>
          </div>
          <div className="home-guide-right">
            <a href="/guide" className="home-guide-button">View the Guide</a>
          </div>
        </div>

        <div className="footer-wrapper">
          <footer className="footer-nav">
            <div className="footer-left">
              <a className="footer-div" href="/terms">Terms of Service</a>
              <a className="footer-div" href="/privacy">Privacy Policy</a>
              <a className="footer-div" href="/support">Support</a>
            </div>
            <div className="footer-right"> 
              <div className="footer-div">©2023-{new Date().getFullYear()} Aliasing.Pro</div>
              <div>•</div>
              <div className="footer-div">All right reserved.</div>
            </div>
          </footer>
        </div>
      </div>

      <div className="mobile-menu-wrapper" style={{display: mobileMenuDisplay}}>
        <div className="mobile-menu-gradient one"></div>
        <div className="mobile-menu-gradient two"></div>
        <div className="mobile-menu">
          <div className="mobile-menu-close-wrapper" onClick={handleMenuButton}>
            <div className="grow"></div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
              <path d="M2 22.9998L22.5061 2.49366" stroke="#4DB848" stroke-width="3" stroke-linecap="round"/>
              <path d="M2 1.99976L22.5061 22.5059" stroke="#4DB848" stroke-width="3" stroke-linecap="round"/>
            </svg>
          </div>
          <a href="/dashboard" className="mobile-menu-button">Dashboard</a>
          <a href="/guide" className="mobile-menu-button">Guide</a>
          <a href="https://discord.gg/ZUFWfzfR4G" className="mobile-menu-button">Discord</a>
          <a href="https://www.kickstarter.com/projects/monkeymakes/aliasingpro" className="mobile-menu-button">Kickstarter</a>
          <div className="mobile-menu-section line">|</div>
          <div className="mobile-menu-section">
            {user ?
              <>
                <a href="/dashboard" className="mobile-menu-button sign-in">{user.displayName}</a>
                <div className="mobile-menu-button sign-in" onClick={handleSignOut}>Sign Out</div>
              </>
              :
              <>
                <div className="mobile-menu-button sign-in" onClick={() => setSignInModal(true)}>Sign In</div>
                <div className="mobile-menu-button sign-in" onClick={() => setRegisterModal(true)}>Sign Up</div>
              </>
            }
          </div>
          <div className="mobile-menu-section">
            <a href="/" className="home-link">
              <img src={logo} alt="Aliasing.Pro Logo" className="mobile-menu-logo"></img>
              <h1 className="mobile-menu-title">Aliasing.Pro</h1>
            </a>
          </div>
        </div>
      </div>

      <Modal show={signInModal} onHide={closeModal} animation={false} backdrop="static">
        <SignIn onSuccessfulLogin={onSuccessfulLogin} />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>
      <Modal show={registerModal} onHide={closeModal} animation={false} backdrop="static">
        <Register onSuccessfulLogin={onSuccessfulLogin} />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>
    </div>
  );
}

export default Home;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMainContext } from "../contexts/MainContext";
import { windowLayouts } from "../constants";
import "../css/Window.css";
import ToolOptions from "./ToolOptions";
import Layers from "./Layers";
import Viewport from "./Viewport";
import Timeline from "./Timeline";
import Palette from "./Palette";

function BottomWindow() {
  const bottomWindowRef = useRef(null);
  const dividerRef = useRef(null);

  const [dragging, setDragging] = useState(false);
  const [initialMousePosition, setInitialMousePosition] = useState(null);
  const [initialWindowHeight, setInitialWindowHeight] = useState(null);

  const {
    orientation,
    rightWindowHeight,
    setRightWindowHeight,
    rightWindowHeightPortrait,
    setRightWindowHeightPortrait,
    bottomRightWindow,
    setBottomRightWindow, 
    isDocumentLoaded,
    setSavePaletteModal,
    loadPalettes,
    setSelectedViewport,
    deleteSwatch,
  } = useMainContext();

  const [scale, setScale] = useState(0.9);
  const [translate, setTranslate] = useState({ x: -50, y: -50 });

  const startDragging = (e) => {
    setInitialMousePosition(e.clientY);
    const rect = bottomWindowRef.current.getBoundingClientRect();
    setInitialWindowHeight(rect.height); // Store the current height in pixels for calculation
    setDragging(true);
  };

  const stopDragging = useCallback((e) => {
    setDragging(false);
  });

  const handleDrag = useCallback(
    (e) => {
      if (dragging) {
        const newHeightPixels =
          initialWindowHeight - (e.clientY - initialMousePosition);
        const parentHeight = bottomWindowRef.current.parentElement.getBoundingClientRect()
          .height;
        let newHeightPercentage = (newHeightPixels / parentHeight) * 100;
        if(newHeightPixels <= dividerRef.current.getBoundingClientRect().height) newHeightPercentage = 0;
        if(orientation === "landscape"){
          setRightWindowHeight(`${newHeightPercentage}%`); // Update the height in percentage
        } else {
          setRightWindowHeightPortrait(`${newHeightPercentage}%`); // Update the height in percentage
        }
      }
    },
    [dragging, initialMousePosition, initialWindowHeight, orientation]
  );

  const maximizeWindow = useCallback(() => {
    if (Math.abs(initialWindowHeight - bottomWindowRef.current.getBoundingClientRect().height) > 6) {
      return;
    }

    if(orientation === "landscape"){
      const sizeInt = parseInt(rightWindowHeight.match(/(\d+(\.\d+)?)%/));
      if(sizeInt > 50){
        if(sizeInt === 100){
          setRightWindowHeight('0%');
        } else {
          setRightWindowHeight('100%');
        }
      } else {
        if(sizeInt === 0){
          setRightWindowHeight('100%');
        } else {
          setRightWindowHeight('0%');
        }
      }
    } else {
      const sizeInt = parseInt(rightWindowHeightPortrait.match(/(\d+(\.\d+)?)%/));
      if(sizeInt > 50){
        if(sizeInt === 100){
          setRightWindowHeightPortrait('0%');
        } else {
          setRightWindowHeightPortrait('100%');
        }
      } else {
        if(sizeInt === 0){
          setRightWindowHeightPortrait('100%');
        } else {
          setRightWindowHeightPortrait('0%');
        }
      }
    }
  }, [orientation, initialWindowHeight, rightWindowHeight, rightWindowHeightPortrait]);

  useEffect(() => {
    dividerRef.current.addEventListener("pointerdown", startDragging);
    window.addEventListener("pointermove", handleDrag);
    window.addEventListener("pointerup", stopDragging);

    return () => {
      if (dividerRef.current) {
        dividerRef.current.removeEventListener("pointerdown", startDragging);
      }
      window.removeEventListener("pointermove", handleDrag);
      window.removeEventListener("pointerup", stopDragging);
    };
  }, [handleDrag, stopDragging]);

  useEffect(() => {
    if (bottomWindowRef.current && bottomWindowRef.current.parentElement) {
      const parentHeight = bottomWindowRef.current.parentElement.getBoundingClientRect().height;
      const initialHeightPixels = bottomWindowRef.current.getBoundingClientRect().height;
      if(orientation === "landscape" && !rightWindowHeight){
        if(initialHeightPixels/parentHeight > 0.33){
          setRightWindowHeight("0%");
        } else {
          const initialHeightPercentage = (initialHeightPixels / parentHeight) * 100;
          setRightWindowHeight(`${initialHeightPercentage}%`);
        }
      } else if(orientation === "portrait" && !rightWindowHeightPortrait){
        if(initialHeightPixels/parentHeight > 0.33){
          setRightWindowHeightPortrait("0%");
        } else {
          const initialHeightPercentage = (initialHeightPixels / parentHeight) * 100;
          setRightWindowHeightPortrait(`${initialHeightPercentage}%`);
        }
      }
    }
  }, [orientation, rightWindowHeight, rightWindowHeightPortrait]);

  const handleLayoutChange = (e) => {
    setBottomRightWindow(e.target.value);
    if(e.target.value === "viewport") setSelectedViewport(3);
  };



  const handleZoomOut = () => {
    setScale((prevScale) => prevScale * 0.8);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale * 1.25);
  };

  const handleCenter = () => {
    setScale(0.9);
    setTranslate({ x: -50, y: -50 });
  };

  const renderWindowContent = () => {
    switch (bottomRightWindow) {
      case "toolOptions":
        return <ToolOptions />;
      case "layers":
        return <Layers />;
      case "viewport":
        return <Viewport scale={scale} setScale={setScale} translate={translate} setTranslate={setTranslate} layout={3} />;
      case "timeline":
        return <Timeline />;
      case "palette":
        return <Palette />;
      default:
        return <ToolOptions />;
    }
  };

  const windowStyle = () => {
    if(orientation === "landscape"){
      return { height: rightWindowHeight };
    } else {
      return { height: rightWindowHeightPortrait };
    }
  }

  return (
    <div
      ref={bottomWindowRef}
      className="bottom-window right"
      style={windowStyle()}
    >
      <div ref={dividerRef} className="bottom-divider">
        <div className="divider-decoration"></div>
      </div>
      <div className="window-header">
        <select
          className="window-select"
          value={bottomRightWindow}
          onChange={handleLayoutChange}
        >
          {windowLayouts.map((layout) => (
            <option key={layout.id} value={layout.id}>
              {layout.name}
            </option>
          ))}
        </select>
        <div className="grow"></div>
        {bottomRightWindow === "viewport" && (
          <>
            <button 
              className="viewport-button"
              onClick={handleZoomOut}
            >
              <span className="icon-zoom-out"></span>
            </button>
            <button 
              className="viewport-button"
              onClick={handleZoomIn}
            >
              <span className="icon-zoom-in"></span>
            </button>
            <button
              className="viewport-button" 
              onClick={handleCenter}
            >
              <span className="icon-enlarge"></span>
            </button>
          </>
        )}
        {bottomRightWindow === "palette" && (
          <>
            <button 
              className="palette-button" 
              onClick={() => {deleteSwatch()}}
            >
              <span className="icon-bin"></span>
            </button>
            <button 
              className="palette-button" 
              onClick={() => {loadPalettes(false)}}
            >
              <span className="icon-import"></span>
            </button>
            <button 
              className="palette-button" 
              onClick={() => {setSavePaletteModal(true)}}
            >
              <span className="icon-export"></span>
            </button>
          </>
        )}
      </div>
      {isDocumentLoaded && <div className="window-content">{renderWindowContent()}</div>}
    </div>
  );
}

export default BottomWindow;

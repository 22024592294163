import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, NavigationType, useLocation, useNavigationType} from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { apiUrl, defaultPalettes} from "../constants";
import { relativeTime } from "../utils/document";
import { useUserContext } from "../contexts/UserContext";
import "../css/Dashboard.css";
import SignIn from "./SignIn";
import NewDocument from "./NewDocument";
import NewGroup from "./NewGroup";
import Open from "./Open";
import Profile from "./Profile";
import RenameHome from "./RenameHome";
import Hotkeys from "./Hotkeys";
import logo from '../assets/images/logo.svg';
import { storeGroupData } from "../utils/document";
import { distance } from "../utils/drawing";
import DocumentContextMenu from "./DocumentContextMenu";
 
function Dashboard() {
  const scrollSpeed = 20;
  const scrollThreshold = 100;
  const dragInitDelay = 500;

  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const location = useLocation();
  
  const [menuOpen, setMenuOpen] = useState(false);
  const [userSettings, setUserSettings] = useState(false);
  const [signInModal, setSignInModal] = useState(false);
  const [newDocumentModal, setNewDocumentModal] = useState(false);
  const [newGroupModal, setNewGroupModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [renameGroupModal, setRenameGroupModal] = useState(false);
  const [hotkeyModal, setHotkeyModal] = useState(false);
  const [palettes, setPalettes] = useState(defaultPalettes);
  const numUntitled = useRef(0);
  const numGroup = useRef(0);

  const [loadNow, setLoadNow] = useState(false);
  const [areDocumentsLoaded, setAreDocumentsLoaded] = useState(false);
  const [documents, setDocuments] = useState(null);
  const [showGroup, setShowGroup] = useState(null);
  const [archiveView, setArchiveView] = useState(false);
  const [starredView, setStarredView] = useState(false);
  const [contextMenu, setContextMenu] = useState({ visible: false, itemId: null, group: null, name: null, position: { x: 0, y: 0 } });
  const [draggingDoc, setDraggingDoc] = useState(null);
  const [clickDoc, setClickDoc] = useState(null);
  const [preventClick, setPreventClick] = useState(false);
  
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  const [scrollDirection, setScrollDirection] = useState(null);

  const requestId = useRef(null);
  const documentsRef = useRef(null);
  const dragInitTimer = useRef(null);
  const startPosition = useRef(null);

  const { user, setUser} = useUserContext();

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const handleWrapperClick = (e) => {
    if(e.target.className === "sidebar-wrapper") setMenuOpen(false);
  };

  const sidebarWrapperClass = () => {
    return menuOpen ? "sidebar-wrapper" : "sidebar-wrapper hidden";
  };

  const closeModal = () => {
    setSignInModal(false);
    setNewDocumentModal(false);
    setOpenImageModal(false);
    setProfileModal(false);
    setNewGroupModal(false);
    setRenameModal(false);
    setRenameGroupModal(false);
    setHotkeyModal(false);
  };

  const onSuccessfulLogin = (userData) => {
    setUser(userData);
    handleSortRecent();
    closeModal();
  };

  const onRename = (docId, newName) => {
    setDocuments(currentDocuments => 
      currentDocuments.map(doc => 
        doc.id === docId ? { ...doc, name: newName } : doc
      )
    );
    closeModal();
  }

  const createGroup = (group) => { 
    if(archiveView){
      handleSortArchive();
    } else if(starredView){
      handleSortStarred();
    } else {
      handleSortRecent();
    }
    closeModal();
  }

  const toggleUserSettings = () => {
    setUserSettings(!userSettings);
  }
  const handleSignOut = () => {
      axios.post(apiUrl + "/api/auth/signOut")
        .then(response => {
          if (response.status === 200) {
            // Update UserContext
            setUser(null);
            handleSortRecent();
          }
        })
        .catch(error => {
            console.error('Error signing out:', error);
            window.alert('Error signing out.');
        });
  };

  const handleNewDocumentOpen = async (open) => {
    try{
      const response = await axios.get(apiUrl + "/api/palettes");
  
      const palettesData = response.data;
      if(palettesData){
        await setPalettes([...defaultPalettes,...palettesData]);
      } else{
        await setPalettes(defaultPalettes);
      }
    } catch(error){
      console.log('No palettes loaded.');
      await setPalettes(defaultPalettes);
    }
      
    if(open){
      setOpenImageModal(true);
    } else{
      setNewDocumentModal(true);
    }
  };

  const loadDocuments = async (starred,archive) => {
    setAreDocumentsLoaded(false);

    if(archive){
      setArchiveView(true);
    } else {
      setArchiveView(false);
    }

    if(starred){
      setStarredView(true);
    } else {
      setStarredView(false);
    }

    console.log('loading documents');
    try {
      // Retrieve the tokens from localStorage
      const localDocsStorage = JSON.parse(localStorage.getItem('documentData')) || null;
      const localGroupStorage = JSON.parse(localStorage.getItem('groupData')) || null;

      const  localGroups = [];
      if(localGroupStorage){
        for(const key of Object.keys(localGroupStorage)){
          localGroups.push({
            id: key,
            token: localGroupStorage[key] 
          });
        }
      }

      const  localDocs = [];
      if(localDocsStorage){
        for(const key of Object.keys(localDocsStorage)){
          localDocs.push({
            id: key,
            token: localDocsStorage[key] 
          });
        }
      }

      const groupResponse = await axios.post(apiUrl + "/api/groups/", {localGroups });
      let groups = groupResponse.data;
      if(groups && groups.length > 0){
        for(const index in groups){
          //convert iso timestamps to unix timestamps
          groups[index].lastEditDate = new Date(groups[index].lastEditDate).getTime();

          groups[index].isGroup = true;

          const match = groups[index].name.match(/Group(?: (\d+))?/);
          if (match){
            const num = parseInt(match[1], 10) || 0;
            numGroup.current = Math.max(numGroup.current, num+1);
          }
        }
      }

      const docResponse = await axios.post(apiUrl + "/api/documents/", {localDocs });
      let docs = docResponse.data;
      if(docs && docs.length > 0) {
        for(const index in docs){
          //convert iso timestamps to unix timestamps
          docs[index].lastEditDate = new Date(docs[index].lastEditDate).getTime();

          const match = docs[index].name.match(/Untitled(?: (\d+))?/);
          if (match){
            const num = parseInt(match[1], 10) || 0;
            numUntitled.current = Math.max(numUntitled.current, num+1);
          }
        }
      }

      let items = [...groups, ...docs];

      if(archive){
        items = items.filter(item => item.archive);
      } else {
        items = items.filter(item => !item.archive);
      }
      
      if(starred){
        items = items.sort((a, b) => b.lastEditDate-a.lastEditDate);
        const starred = items.filter((doc) => doc.starred);
        const unstarred = items.filter((doc) => !doc.starred);
        setDocuments([...starred, ...unstarred]);
      } else {
        items = items.sort((a, b) => b.lastEditDate-a.lastEditDate);
        setDocuments(items);
      }
    } catch (error) {  
      console.log(error);
    } finally{
      setAreDocumentsLoaded(true);
    }
  };

  useEffect(() => {
    if(loadNow) loadDocuments();
  }, [loadNow, user]);

  useEffect(() => {
    setLoadNow(!loadNow);
  }, []);  


  useEffect(() => {
    // Step 3: Listen for popstate event
    const handlePopState = (event) => {
      const state = event.state;

      if (state && state.group) {
        setShowGroup(state.group);
      } else {
        setShowGroup(null);
      }
    };

    window.addEventListener("popstate", handlePopState);

    // Step 5: Cleanup the event listener
    return () => window.removeEventListener("popstate", handlePopState);
  }, [archiveView, starredView]);
  
  useEffect(() => {
    if(!loadNow) return;
    if(showGroup){
      axios.post(apiUrl + "/api/group", {groupId: showGroup._id}).then(response => {
        let docs = response.data.docs;

        if(docs && docs.length > 0) {
          for(const index in docs){
            //convert iso timestamps to unix timestamps
            docs[index].lastEditDate = new Date(docs[index].lastEditDate).getTime();
  
            const match = docs[index].name.match(/Untitled(?: (\d+))?/);
            if (match){
              const num = parseInt(match[1], 10) || 0;
              numUntitled.current = Math.max(numUntitled.current, num+1);
            }
          }

          docs = docs.sort((a, b) => b.lastEditDate-a.lastEditDate);
          setDocuments(docs);
        } else {
          setDocuments(null);
        }

        window.history.pushState({ pageTitle: `Aliasing.Pro - ${showGroup.name}`, group: showGroup }, "", "");
      })
    } else {
      window.history.pushState({ pageTitle: "Aliasing.Pro - Dashboard", group: showGroup }, "", "");
      setLoadNow(true);
      setShowGroup(null);
      if (archiveView) {
        handleSortArchive();
      } else if (starredView) {
        handleSortStarred();
      } else {
        handleSortRecent();
      }
    }
  }, [showGroup]);
  
  const handleStar = async (event, id, index, group) => {
    event.stopPropagation();

    if(group){
      // Retrieve the token from localStorage
      const groupDataStored = JSON.parse(localStorage.getItem('groupData')) || {};
      const token = groupDataStored[id];
  
      const response = await axios.post(apiUrl + "/api/starGroup/" + id, {token});
      
      if(response.data){
        const tempDocs = [...documents];
        tempDocs[index].starred = response.data.starred;
        setDocuments([...tempDocs])
      }
    } else {
      // Retrieve the token from localStorage
      const documentDataStored = JSON.parse(localStorage.getItem('documentData')) || {};
      const token = documentDataStored[id];
  
      const response = await axios.post(apiUrl + "/api/star/" + id, {token});
      
      if(response.data){
        const tempDocs = [...documents];
        tempDocs[index].starred = response.data.starred;
        setDocuments([...tempDocs])
      }
    }
  };

  const handleSortRecent = useCallback(() => {
    document.title = `Aliasing.Pro - Dashboard`;
    loadDocuments();
    setShowGroup(null);
    setMenuOpen(false);
  }, [documents]);

  const handleSortStarred = useCallback(() => {
    document.title = `Aliasing.Pro - Dashboard`;
    loadDocuments(true);
    setShowGroup(null);
    setMenuOpen(false);
  }, [documents]);

  const handleSortArchive = useCallback(() => {
    document.title = `Aliasing.Pro - Dashboard`;
    loadDocuments(false,true);
    setShowGroup(null);
    setMenuOpen(false);
  }, [documents]);


  // Function to perform the auto-scroll
  const performScroll = () => {
    if (scrollDirection === 'up') {
      documentsRef.current.scrollBy(0, -scrollSpeed);
    } else if (scrollDirection === 'down') {
      documentsRef.current.scrollBy(0, scrollSpeed);
    }
    if (scrollDirection) {
      requestId.current = window.requestAnimationFrame(performScroll);
    }
  };

  // Start or stop the auto-scroll based on `scrollDirection`
  useEffect(() => {
    if (scrollDirection) {
      requestId.current = window.requestAnimationFrame(performScroll);
    } else {
      window.cancelAnimationFrame(requestId.current);
    }

    // Cleanup on component unmount
    return () => window.cancelAnimationFrame(requestId.current);
  }, [scrollDirection]);

  useEffect(() => {
    const handlePointerDown = (e) => {
      startPosition.current = { x: e.clientX, y: e.clientY };
      setDragPosition({ x: e.clientX, y: e.clientY });
    }

    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      setDragPosition({ x: touch.clientX, y: touch.clientY });

      if(draggingDoc){
        const yPos = touch.clientY;
        if (yPos < scrollThreshold) {
          setScrollDirection('up');
        } else if (window.innerHeight - yPos < scrollThreshold) {
          setScrollDirection('down');
        } else {
          setScrollDirection(null); // Stop scrolling if not near edges
        }
      } else {
        if(startPosition.current && distance(startPosition.current, { x: touch.clientX, y: touch.clientY }) > 10){
          clearTimeout(dragInitTimer.current);
          if(documentsRef.current) documentsRef.current.style.overflowY = 'auto';
        }
        setScrollDirection(null);
      }
    };
    
    const handlePointerMove = (e) => {
      if(e.pointerType === 'touch') return;
      setDragPosition({ x: e.clientX, y: e.clientY });
      
      if(draggingDoc){
        const yPos = e.clientY;
        if (yPos < scrollThreshold) {
          setScrollDirection('up');
        } else if (window.innerHeight - yPos < scrollThreshold) {
          setScrollDirection('down');
        } else {
          setScrollDirection(null); // Stop scrolling if not near edges
        }
      } else if (clickDoc && startPosition.current && distance(startPosition.current, { x: e.clientX, y: e.clientY }) > 10){
        setDraggingDoc(clickDoc);
        setClickDoc(null);
      } else {
        setScrollDirection(null);
      } 
    }

    const handlePointerUp = (e) => {
      if (dragInitTimer.current) {
        clearTimeout(dragInitTimer.current);
      } 
      
      setTimeout(() => { 
        setDraggingDoc(null);
        if(documentsRef.current) documentsRef.current.style.overflowY = 'auto';
      }, 200);

      setClickDoc(null);
    };
    
    const stopContext = (e) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    };

    const handleKeyDown = (e) => {
      if(e.keyCode === 27){
        setPreventClick(true);
        setDraggingDoc(null);
      }
    };

    // Attach event listeners
    document.addEventListener("keydown", handleKeyDown);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("pointermove", handlePointerMove);
    window.addEventListener("touchend", handlePointerUp);
    window.addEventListener("pointerup", handlePointerUp);
    window.addEventListener("pointerdown", handlePointerDown);
    window.addEventListener("contextmenu", stopContext);
    return () => {
      // Detach event listeners
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("pointermove", handlePointerMove);
      window.removeEventListener("touchend", handlePointerUp);
      window.removeEventListener("pointerup", handlePointerUp);
      window.removeEventListener("pointerdown", handlePointerDown);
      window.removeEventListener("contextmenu", stopContext);
    };
  }, [draggingDoc, clickDoc]);

  const handleDrop = (dragging, target) => {
    if(target){
      if(target.isGroup){
        setAreDocumentsLoaded(false);

        const docId = dragging.id;
        const documentDataStored = JSON.parse(localStorage.getItem('documentData')) || {};
        const docToken = documentDataStored[dragging.id];

        axios.post(apiUrl + "/api/addToGroup/" + target.id, { token: docToken, docId})
          .then(response => {
            if (response.status === 200) { 
              if(archiveView){
                handleSortArchive();
              } else if(starredView){
                handleSortStarred();
              } else {
                handleSortRecent();
              }
            }
          });
      } else if(target.id !== dragging.id){
        setAreDocumentsLoaded(false);
        
        const documentDataStored = JSON.parse(localStorage.getItem('documentData')) || {};
        const targetToken = documentDataStored[target.id];
        const docToken = documentDataStored[dragging.id];

        axios.post(apiUrl + "/api/newGroup", { name: `Group ${numGroup.current+1}`})
          .then(response => {
            if (response.status === 201) {
              // Store the document ID in local storage for all users
              const { token, group } = response.data;
              storeGroupData(group.id, token);

              axios.post(apiUrl + "/api/addToGroup/" + group.id, { token: targetToken, docId: target.id})
              .then(response => {
                if (response.status === 200) { 
                  axios.post(apiUrl + "/api/addToGroup/" + group.id, { token: docToken, docId: dragging.id})
                  .then(response => {
                    if (response.status === 200) { 
                      if(archiveView){
                        handleSortArchive();
                      } else if(starredView){
                        handleSortStarred();
                      } else {
                        handleSortRecent();
                      }
                    }
                  });
                }
              });
            }
          });
      }
    } else {
      setAreDocumentsLoaded(false);
      
      const docId = dragging.id;
      const documentDataStored = JSON.parse(localStorage.getItem('documentData')) || {};
      const docToken = documentDataStored[dragging.id];

      axios.post(apiUrl + "/api/newGroup", { name: `Group ${numGroup.current+1}`})
        .then(response => {
          if (response.status === 201) {
            // Store the document ID in local storage for all users
            const { token, group } = response.data;
            storeGroupData(group.id, token);

            axios.post(apiUrl + "/api/addToGroup/" + group.id, { token: docToken, docId})
            .then(response => {
              if (response.status === 200) { 
                if(archiveView){
                  handleSortArchive();
                } else if(starredView){
                  handleSortStarred();
                } else {
                  handleSortRecent();
                }
              }
            });
          }
        });
    }
  };

  const handleRemove = (doc) => {

    if(!showGroup || !doc) return;

    const documentDataStored = JSON.parse(localStorage.getItem('documentData')) || {};
    const token = documentDataStored[doc.id];

    axios.post(apiUrl + "/api/removeFromGroup/" + doc.id, { token })
      .then(response => {
        if (response.status === 200 || response.status === 404) {
          const docs = documents.filter(item => item.id !== doc.id)
          setDocuments(docs);
        } else {
          alert("Error removing document from group.")
        }
      })
  };

  const docItemClass = (doc) => {
    if(draggingDoc){
      if(doc.id === draggingDoc.id){
        return "document-item hidden";
      } else {
        return "document-item";
      }
    } else {
      return "document-item";
    }
  };

  const pageHeading = () => {
    if (archiveView){
      return "Your Archive"
    } else {
      return "Your Documents"
    }
  }

  useEffect(() => {
    const closeContextMenu = () => {
      setContextMenu({ ...contextMenu, visible: false });
    };
  
    if (contextMenu.visible) {
      window.addEventListener('click', closeContextMenu);
    }
  
    return () => window.removeEventListener('click', closeContextMenu);
  }, [contextMenu]);

  const deleteNow = (docId, group, name) => {
    const confirmed = confirm(`Are you sure you want to delete ${name}? This can't be undon.`);
    if(!confirmed) return;

    if(group){
      try {
        // Retrieve the token from localStorage
        const groupDataStored = JSON.parse(localStorage.getItem('groupData')) || {};
        const token = groupDataStored[docId];
    
        axios.post(`${apiUrl}/api/deleteGroup/${docId}`, { token })
          .then(async response => {
            if (response.status === 200) {
              if(archiveView){
                handleSortArchive();
              } else if(starredView){
                handleSortStarred();
              } else {
                handleSortRecent();
              }
            } else {
              window.alert('Error deleting document');
            }
          });
      } catch (error) {
        console.log(error);
        if(error.response && error.response.data && error.response.data.message){
          window.alert(error.response.data.message);
        } else {
          window.alert('Error deleting document');
        }
      }
    } else {
      try {
        // Retrieve the token from localStorage
        const documentDataStored = JSON.parse(localStorage.getItem('documentData')) || {};
        const token = documentDataStored[docId];
    
        axios.post(`${apiUrl}/api/deleteDocument/${docId}`, { token })
          .then(async response => {
            if (response.status === 200) {
              const docs = documents.filter(item => item.id !== docId);
              setDocuments(docs);
            } else {
              window.alert('Error deleting document');
            }
          });
      } catch (error) {
        console.log(error);
        if(error.response && error.response.data && error.response.data.message){
          window.alert(error.response.data.message);
        } else {
          window.alert('Error deleting document');
        }
      }
    }
  }

  const archive = (docId, group) => {
    if(group){
      try {
        // Retrieve the token from localStorage
        const groupDataStored = JSON.parse(localStorage.getItem('groupData')) || {};
        const token = groupDataStored[docId];
    
        axios.post(`${apiUrl}/api/archiveGroup/${docId}`, { token })
          .then(async response => {
            if (response.status === 200) {
              const docs = documents.filter(item => item.id !== docId)
              setDocuments(docs);
            } else {
              window.alert('Error archiving group');
            }
          });
      } catch (error) {
        console.log(error);
        if(error.response && error.response.data && error.response.data.message){
          window.alert(error.response.data.message);
        } else {
          window.alert('Error archiving group');
        }
      }
    } else {
      try {
        // Retrieve the token from localStorage
        const documentDataStored = JSON.parse(localStorage.getItem('documentData')) || {};
        const token = documentDataStored[docId];
    
        axios.post(`${apiUrl}/api/archive/${docId}`, { token })
          .then(async response => {
            if (response.status === 200) {
              const docs = documents.filter(item => item.id !== docId)
              setDocuments(docs);
            } else {
              window.alert('Error archiving document');
            }
          });
      } catch (error) {
        console.log(error);
        if(error.response && error.response.data && error.response.data.message){
          window.alert(error.response.data.message);
        } else {
          window.alert('Error archiving document');
        }
      }
    }
  }

  return (
    <div className="dashboard-wrapper grow">
      {!menuOpen && ( <button className="menu-button" onClick={handleMenuOpen}><span className="icon-paragraph-left2"></span></button> )}
      <div className={sidebarWrapperClass()} onClick={(event) => {handleWrapperClick(event)}}>
        <div className="sidebar">
          <button className="sidebar-button menu" onClick={handleMenuOpen}><span className="icon-paragraph-left2"></span></button>

          <a href="/" className="sidebar-heading-wrapper">
            <img src={logo} alt="Aliasing.Pro Logo" className="logo"></img>
            <h1 className="sidebar-heading">Aliasing.Pro</h1>
          </a>

          {user ? 
            <div className="user-settings">
              <button className='sidebar-button display-name' onClick={toggleUserSettings}>{user.displayName}<span className="icon-arrow-down5"></span></button>
              { userSettings &&
                <>
                  <button className='sidebar-button' onClick={() => setProfileModal(true)}>Profile</button>
                  <button className='sidebar-button' onClick={handleSignOut}>Sign Out</button>
                </>
              }
            </div>
            :
            <button className="sidebar-button" onClick={() => setSignInModal(true)}>Sign In</button>
          }

          <hr/>

          <h2 className="sidebar-subheading">Documents</h2>
          <button
            className="sidebar-button"
            onClick={handleSortRecent}
          ><span className="icon-file-picture sidebar-button-icon"></span>Recent</button>
          <button
            className="sidebar-button"
            onClick={handleSortStarred}
          ><span className="icon-star-full sidebar-button-icon"></span>Starred</button>
          <button
            className="sidebar-button"
            onClick={handleSortArchive}
          ><span className="icon-box sidebar-button-icon"></span>Archive</button>

          <hr/>

          <h2 className="sidebar-subheading">Resources</h2>
          <a className="sidebar-button" href="/guide" target="_blank" rel="noreferrer">
            <span className="icon-book sidebar-button-icon"></span>Guide
          </a>
          <button
            className="sidebar-button"
            onClick={() => {setHotkeyModal(true)}}
          ><span className="icon-keyboard sidebar-button-icon"></span>Hotkeys</button>

          <hr/>

          <div className="grow"></div>

          <a className="sidebar-button" href="https://www.kickstarter.com/projects/monkeymakes/aliasingpro" target="_blank" rel="noreferrer">Kickstarter</a>
          <a className="sidebar-button" href="https://discord.gg/ZUFWfzfR4G" target="_blank" rel="noreferrer">Discord</a>
          <a className="sidebar-button" href="/support">Support</a>
          <a className="sidebar-button" href="/terms" target="_blank" rel="noreferrer">Terms of Service</a>
          <a className="sidebar-button" href="/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>
        </div>
      </div>
      <div className="documents" ref={documentsRef}>
        { showGroup ? 
          <div className="group-heading-wrapper-outer">
            <div className="group-heading-wrapper-inner">
              <h2 className="group-heading" onClick={() => {setRenameGroupModal(true)}}>
                {showGroup.name}
                <span className="icon-pencil group-rename"></span>
              </h2>
            </div>
            <span className="icon-back group-back" onClick={handleSortRecent}></span>
          </div>
          :
          <h2 className="documents-heading">{pageHeading()}</h2>
        }
        <div className="documents-list">
          {!archiveView && <>
            <div className="add-document document-item" style={{ opacity: draggingDoc ? 0.5 : 1 }} onClick={() => {handleNewDocumentOpen(false)}}>
              <div className="document-title">New Document</div>
              <span className="icon-plus document-plus grow"></span>
            </div>
            <div className="open-image document-item"  style={{ opacity: draggingDoc ? 0.5 : 1 }} onClick={() => {handleNewDocumentOpen(true)}}>
              <div className="document-title">Open Image</div>
              <span className="icon-download2 document-plus"></span>
            </div>
            {showGroup ? 
              <div 
                data-id="remove"
                className="document-item"
                onPointerUp={(e) => {
                  if(draggingDoc) handleRemove(draggingDoc);
                }}
              >
                Remove From Group
              </div>
              :
              <div 
                className="open-image document-item"
                onClick={() => {setNewGroupModal(true)}}
                data-id="new"
                onPointerUp={(e) => {
                  if(draggingDoc) handleDrop(draggingDoc);
                }}
              >
                <div className="document-title" data-id="new">New Group</div>
                <span className="icon-plus document-plus" data-id="new"></span>
              </div>
            }
          </>}
          {documents && documents.map((doc, index) => 
            <div
              key={doc.id}
              data-id={doc.id}
              className={docItemClass(doc)}
              onClick={() => {
                if(preventClick) return setPreventClick(false);
                if(doc.isGroup){
                  setArchiveView(false);
                  setShowGroup(doc);
                } else {
                  navigate(`/editor/${doc.id}`);
                }
              }}
              onMouseDown={(e) => {
                if( e.button === 1 && !doc.isGroup) {
                  return window.open(`/editor/${doc.id}`, '_blank', 'noopener,noreferrer');
                }
              }}
              onPointerDown={(e) => {
                if(e.pointerType === "touch"){
                  if(!doc.isGroup){
                    dragInitTimer.current = setTimeout(() => {
                      documentsRef.current.style.overflowY = 'hidden'
                      setDraggingDoc(doc);
                    }, dragInitDelay);
                  }
                } else {
                  if(e.button === 1) return;
                  setClickDoc(doc);
                }
              }}
              onPointerUp={(e) => {
                if(draggingDoc) handleDrop(draggingDoc, doc);
              }}
              onTouchEnd={(e) => {
                if(!draggingDoc) return
                const targetElement = document.elementFromPoint(dragPosition.x, dragPosition.y);
          
                // Retrieve the document ID from the data-id attribute
                const docId = targetElement.dataset.id;
          
                if (docId === "new") {
                  handleDrop(draggingDoc);
                } else if (docId === "remove") {
                  handleRemove(draggingDoc);
                } else {
                  const doc = documents.find((item) => item.id === docId);
                  if (doc) handleDrop(draggingDoc, doc);
                }
              }}
            >
              <div  data-id={doc.id} className="document-header">
                <div  data-id={doc.id} className="document-time">{relativeTime(doc.lastEditDate)}</div>
                <div data-id={doc.id} className="grow"></div>
                {doc.starred ?
                  <button
                    data-id={doc.id}
                    className="star-button starred"
                    onClick={(event) => handleStar(event,doc.id,index,doc.isGroup)}
                  >
                    <span data-id={doc.id} className="icon-star-full" ></span>
                  </button>
                  :
                  <button
                    data-id={doc.id}
                    className="star-button"
                    onClick={(event) => handleStar(event,doc.id,index,doc.isGroup)}
                  >
                    <span  data-id={doc.id} className="icon-star-full" ></span>
                  </button>
                }
                <div data-id={doc.id} className="document-settings" onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering click events on parent elements
                  setContextMenu({
                    visible: true,
                    itemId: doc.id,
                    group: doc.isGroup,
                    name: doc.name,
                    position: { x: e.clientX-110, y: e.clientY-10 }
                  });
                }}><span data-id={doc.id} className="icon-dots"></span></div>
              </div>
              <div data-id={doc.id} className="document-image-wrapper">
                {doc.isGroup ?
                  <span data-id={doc.id} className="icon-folder group-icon"></span>
                  :
                  <img  draggable="false" data-id={doc.id} className="document-image" src={`https://aliasing.nyc3.digitaloceanspaces.com/${doc.id}/preview.png`}></img>
                }
              </div>
              <div  data-id={doc.id} className="document-title">{doc.name}</div>
            </div>
          )}
          <div className="document-item extra"></div>
          <div className="document-item extra"></div>
          <div className="document-item extra"></div>
          <div className="document-item extra"></div>
          <div className="document-item extra"></div>
          <div className="document-item extra"></div>
          <div className="document-item extra"></div>
          <div className="document-item extra"></div>
          <div className="document-item extra"></div>
        </div>
        <div className="grow"></div>
        <div className="copywrite">© 2023-{new Date().getFullYear()} Aliasing.Pro</div>
        {!areDocumentsLoaded &&
          <div className="loading-wrapper opaque">
            <span className="icon-spinner spinner"></span>
          </div>
        }   
      </div>
      {draggingDoc && 
        <div
          className="document-item flex column floating"
          style={{
            left: `${dragPosition.x - 78}px`,
            top: `${dragPosition.y - 78}px`,
          }} 
        >
          <div className="document-title">{draggingDoc.name}</div>
          <div className="document-image-wrapper">
            <img draggable="false" className="document-image" src={`https://aliasing.nyc3.digitaloceanspaces.com/${draggingDoc.id}/preview.png`}></img>
          </div>
        </div>
      }   

      
      {
        contextMenu.visible && (
          <DocumentContextMenu 
            position={contextMenu.position} 
            rename={() => setRenameModal(true)}
            archive={() => archive(contextMenu.itemId, contextMenu.group)}
            deleteNow={() => deleteNow(contextMenu.itemId, contextMenu.group, contextMenu.name)}
            onClose={() => setContextMenu({ ...contextMenu, visible: false })} 
            unarchive={archiveView}
            groupView={showGroup}
          />
        )
      }

      <Modal show={signInModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <SignIn onSuccessfulLogin={onSuccessfulLogin} />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={newDocumentModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <NewDocument palettes={palettes} newTab={false} onCreate={closeModal} num={numUntitled.current} group={showGroup}/>
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={newGroupModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <NewGroup onCreate={createGroup} num={numGroup.current+1}/>
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={openImageModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <Open palettes={palettes} newTab={false} onCreate={closeModal}  group={showGroup}/>
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={profileModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <Profile />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={renameModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <RenameHome docId={contextMenu.itemId} docName={contextMenu.name} group={contextMenu.group} onSave={onRename} />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={renameGroupModal} onHide={closeModal} animation={false} backdrop="static" centered>
        {showGroup && <RenameHome docId={showGroup.id} docName={showGroup.name} group={true} onSave={onRename} />}
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={hotkeyModal} onHide={closeModal} animation={false}backdrop="static" centered>
          <Hotkeys />
          <button onClick={closeModal}>
            <span className="close-button icon-x"></span>
          </button>
      </Modal>
    </div>
  );
}

export default Dashboard;

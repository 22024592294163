import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef
} from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import GIF from 'gif.js';
import chroma from "chroma-js";
import RgbQuant from 'rgbquant';
import { apiUrl, defaultPalettes, maxLayerCount, maxCursorSize, maxHistory, maxLayerSize, metaKey} from "../constants";
import { randomHex, summate, findPathsInCanvas, extendLineToBounds, deriveVanishingPoints} from "../utils/drawing";
import { generateId, canvasToBlob, loadLayer, storeDocumentData } from "../utils/document";
import { useUserContext } from "../contexts/UserContext";
import stencilFile from "../assets/images/stencil64.gif";
import previewBGFile from "../assets/images/preview-background.png";
import ditherFile from "../assets/images/dither.gif";

const MainContext = createContext();

export const useMainContext = () => {
  return useContext(MainContext);
};

export const MainProvider = ({ children, docId}) => {
  const navigate = useNavigate();

  const { user, setUser} = useUserContext();

  //layout settings
  const [orientation, setOrientation] = useState(null);
  const [wrapperWidth, setWrapperWidth] = useState(null);
  const [leftWindowHeight, setLeftWindowHeight] = useState(null);
  const [rightWindowHeight, setRightWindowHeight] = useState(null);
  const [wrapperWidthPortrait, setWrapperWidthPortrait] = useState(null);
  const [leftWindowHeightPortrait, setLeftWindowHeightPortrait] = useState(null);
  const [rightWindowHeightPortrait, setRightWindowHeightPortrait] = useState(null);
  const [topLeftWindow, setTopLeftWindow] = useState("toolOptions");
  const [topRightWindow, setTopRightWindow] = useState("viewport");
  const [bottomLeftWindow, setBottomLeftWindow] = useState("layers");
  const [bottomRightWindow, setBottomRightWindow] = useState("palette");
  const [selectedViewport, setSelectedViewport] = useState(1); // 0=top-left 1=top-right 2=bottom-left 3=bottom-right
  const [contextMenu, setContextMenu] = useState(null);

  //document settings
  const [docName, setDocName] = useState('Untitled');
  const [starred, setStarred] = useState(false);
  const [autoSave, setAutoSave] = useState(true);
  const [showBorder, setShowBorder] = useState(true);
  const [resolution, setResolution] = useState({ width: 36, height: 24 });
  const [offset, setOffset] = useState({x: 0, y: 0});
  const [palette, setPalette] = useState(defaultPalettes[0].palette);
  const [selectedSwatch, setSelectedSwatch] = useState(0);
  const [secondarySwatch, setSecondarySwatch] = useState(1);
  const [clipboard, setClipboard] = useState(null);
  const [details, setDetails] = useState(null);

  //layer settings
  const [layers, setLayers] = useState([]);
  const [selectedLayer, setSelectedLayer] = useState(0);
  const [selectedLayers, setSelectedLayers] = useState([0]);
  const [magnetLayer, setMagnetLayer] = useState(null);
  const [layersCreated, setLayersCreated] = useState(1);
  const [linesCreated, setLinesCreated] = useState(0);
  const [isometricsCreated, setIsometricsCreated] = useState(0);
  const [ellipsesCreated, setEllipsesCreated] = useState(0);
  const [gridsCreated, setGridsCreated] = useState(0);
  const [perspectivesCreated, setPerspectivesCreated] = useState(0);
  const [vanishingPointsCreated, setVanishingPointsCreated] = useState(0);
  const [palettesCreated, setPalettesCreated] = useState(0);
  const [selection, setSelection] = useState(React.createRef());

  //tool settings
  const [selectedTool, setSelectedTool] = useState("pencil");
  const [previousTool, setPreviousTool] = useState("pencil");
  const [brushDiameter, setBrushDiameter] = useState(6);
  const [brushPixelPerfect, setBrushPixelPerfect] = useState(false);
  const [brushMode, setBrushMode] = useState("normal");
  const [brushPressure, setBrushPressure] = useState(true);
  const [brushPressureFactor, setBrushPressureFactor] = useState(3);
  const [ditherRatio, setDitherRatio] = useState(8);
  const [ditherOffsetX, setDitherOffsetX] = useState(0);
  const [ditherOffsetY, setDitherOffsetY] = useState(0);
  const [ditherPressureMode, setDitherPressureMode] = useState("ratio");
  const [eraseDiameter, setEraseDiameter] = useState(6);
  const [erasePixelPerfect, setErasePixelPerfect] = useState(false);
  const [eraseMode, setEraseMode] = useState("normal");
  const [erasePressure, setErasePressure] = useState(true);
  const [erasePressureFactor, setErasePressureFactor] = useState(3);
  const [eraseDitherRatio, setEraseDitherRatio] = useState(8);
  const [eraseDitherPressureMode, setEraseDitherPressureMode] = useState("ratio");
  const [invertEraseDither, setInvertEraseDither] = useState(false);
  const [dropperCurrent, setDropperCurrent] = useState(false);
  const [dropperReplace, setDropperReplace] = useState(false);
  const [autoSelect, setAutoSelect] = useState(false);
  const [invertZoom, setInvertZoom] = useState(false);
  const [brushSelect, setBrushSelect] = useState(false);
  const [eraseSelect, setEraseSelect] = useState(false);
  const [wandContinguous, setWandContinguous] = useState(true);
  const [bucketContinguous, setBucketContinguous] = useState(true);
  const [bucketMode, setBucketMode] = useState("normal")
  const [rectangleAngle, setRectangleAngle] = useState(0);
  const [ellipseAngle, setEllipseAngle] = useState(0);
  const [lastColor, setLastColor] = useState("#32CD32");
  const [lastFocalLength, setLastFocalLength] = useState(null);
  const [lineWidth, setLineWidth] = useState(1);

  //non saved settings
  const [loadNow, setLoadNow] = useState(false);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentSaving, setDocumentSaving] = useState(false);
  const [isTabVisible, setIsTabVisible] = useState(true);
  const [colorModal, setColorModal] = useState(false);
  const [swatchModal, setSwatchModal] = useState(false);
  const [signInModal, setSignInModal] = useState(false);
  const [newDocumentModal, setNewDocumentModal] = useState(false);
  const [savePaletteModal, setSavePaletteModal] = useState(false);
  const [loadPaletteModal, setLoadPaletteModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [resizeModal, setResizeModal] = useState(false);
  const [hotkeyModal, setHotkeyModal] = useState(false);
  const [history, setHistory] = useState([]);
  const [version, setVersion] = useState(0);
  const [transformVersion , setTransformVersion] = useState(0);
  const [stencil, setStencil] = useState(React.createRef());
  const [dither, setDither] = useState(React.createRef());
  const [ditherLayer, setDitherLayer] = useState(React.createRef());
  const [upToDate, setUpToDate] = useState(true);
  const [altKey, setAltKey] = useState(false);
  const [shiftKey, setShiftKey] = useState(false);
  const [shiftButton, setShiftButton] = useState(false);
  const [altButton, setAltButton] = useState(false);
  const [metaButton, setMetaButton] = useState(false);
  const [isSpaceDown, setIsSpaceDown] = useState(false);
  const [centerView, setCenterView] = useState(0);
  const [saveNow, setSaveNow] = useState(0);
  const [saveNewNow, setSaveNewNow] = useState(0);
  const [autoSaveNow, setAutoSaveNow] = useState(0);
  const [exportNow, setExportNow] = useState(0);
  const [addLayerNow, setAddLayerNow] = useState(0);
  const [deleteLayerNow, setDeleteLayerNow] = useState(0);
  const [mergeDownNow, setMergeDownNow] = useState(0);
  const [duplicateNow, setDuplicateNow] = useState(0);
  const [mixColorsNow, setMixColorsNow] = useState(0);
  const [deleteSwatchNow, setDeleteSwatchNow] = useState(0);
  const [deleteDocNow, setDeleteDocNow] = useState(0);
  const [selectAllNow, setSelectAllNow] = useState(0);
  const [deselectNow, setDeslectNow] = useState(0);
  const [invertNow, setInvertNow] = useState(0);
  const [growNow, setGrowNow] = useState(0);
  const [shrinkNow, setShrinkNow] = useState(0);
  const [deleteSelectionNow, setDeleteSelectionNow] = useState(0);
  const [copyNow, setCopyNow] = useState(0);
  const [cutNow, setCutNow] = useState(0);
  const [pasteNow, setPasteNow] = useState(0);
  const [pasteInPlaceNow, setPasteInPlaceNow] = useState(0);
  const [transformNow, setTransformNow] = useState(0);
  const [applyTransformNow, setApplyTransformNow] = useState(0);
  const [resetTransformNow, setResetTransformNow] = useState(0);
  const [undoNow, setUndoNow] = useState(0);
  const [redoNow, setRedoNow] = useState(0);
  const [flipHorizontalNow, setFlipHorizontalNow] = useState(0);
  const [flipVerticalNow, setFlipVerticalNow] = useState(0);
  const [rotateCWNow, setRotateCWNow] = useState(0);
  const [rotateCCWNow, setRotateCCWNow] = useState(0);
  const [palettes, setPalettes] = useState(defaultPalettes);
  const [filename, setFilename] = useState("Untitled");
  const [exportType, setExportType] = useState("gif");
  const [upscaling, setUpscaling] = useState(1);
  const [hasSelection, setHasSelection] = useState(false);
  const [crop, setCrop] = useState(null);
  const [prevCrop, setPrevCrop] = useState({left: 0, top: 0, right: resolution.width, bottom: resolution.height});
  const [transform, setTransform] = useState(null);
  const [transformStartCanvas, setTransformStartCanvas] = useState(null);
  const [transformStartSelection, setTransformStartSelection] = useState(null);
  const [transformCanvas, setTransformCanvas] = useState(null);
  const [transformSelection, setTransformSelection] = useState(null);
  const [transformStart, setTransformStart] = useState(null);
  const [viewportCenter, setViewportCenter] = useState({x: resolution.width/2, y: resolution.height/2});
  const [maxUpscale, setMaxUpscale] = useState(8);
  const [bgColor, setBgColor] = useState(0);

  
  const oldResolution = useRef(resolution);
  const oldOffset = useRef(offset);
  const previewBG = useRef(new Image());
  const intervalId = useRef(null);

  useEffect(() => {
      document.title = docName;
      setFilename(docName.replaceAll(" ", "-"));
  }, [docName]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setIsTabVisible(true);
      } else {
        setIsTabVisible(false);
      }
    };

    const updateOrientation = () => {
      if (window.innerWidth > window.innerHeight) {
        setOrientation('landscape');
      } else {
        setOrientation('portrait');
      }
    };

    updateOrientation();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('resize', updateOrientation);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('resize', updateOrientation);
    };
  }, []);

  useEffect(() => {
    switch(selectedTool){
      case "crop":
        if(crop){
          setDetails(`${crop.right - crop.left}px x ${crop.bottom-crop.top}px`);
        } else {
          setDetails(null);
        }
        break;
      case "transform":
        if(transform){
          setDetails(`${transform.right - transform.left}px x ${transform.bottom-transform.top}px`);
        } else {
          setDetails(null);
        } 
        break;
      default:
        setDetails(null);
        break;
    }
  }, [selectedTool, crop, transform]);

  const updateHistory = useCallback(() => {
    let oldLayers = [];
    for (const index in layers) {
      //create temporary canvas to store current layer for undo/redo
      switch (layers[index].type){
        case "Canvas":
          oldLayers[index] = {
            type: "Canvas",
            id: layers[index].id,
            ref: React.createRef(),
            visible: layers[index].visible,
            name: layers[index].name,
            x: layers[index].x,
            y: layers[index].y
          };

          const tempCanvas = document.createElement("canvas");
          tempCanvas.width = layers[index].ref.current.width;
          tempCanvas.height = layers[index].ref.current.height;
          oldLayers[index].ref.current = tempCanvas;
    
          //draw temp canvas
          const ctx = oldLayers[index].ref.current.getContext("2d");
          ctx.imageSmoothingEnabled = false;
          ctx.drawImage(
            layers[index].ref.current,
            0,
            0,
            layers[index].ref.current.width,
            layers[index].ref.current.height
          );
          break;
        case "Line":
          if(layers[index].startPoint && layers[index].endPoint){
            oldLayers[index] = {
              type: "Line",
              id: layers[index].id,
              name: layers[index].name,
              visible: layers[index].visible,
              color: layers[index].color,
              startPoint: layers[index].startPoint,
              endPoint: layers[index].endPoint,
              extend: layers[index].extend,
            };
          } else {
            oldLayers[index] = {
              type: "Line",
              id: layers[index].id,
              name: layers[index].name,
              visible: layers[index].visible,
              color: layers[index].color,
              extend: layers[index].extend,
            };
          }
          break;
        case "OnePoint":
          oldLayers[index] = {
            type: "OnePoint",
            id: layers[index].id,
            name: layers[index].name,
            visible: layers[index].visible,
            color: layers[index].color,
            point: layers[index].point,
          };
          break;
        case "TwoPoint":
          oldLayers[index] = {
            type: "TwoPoint",
            id: layers[index].id,
            name: layers[index].name,
            visible: layers[index].visible,
            color: layers[index].color,
            center: layers[index].center,
            angle: layers[index].angle,
            focalLength: layers[index].focalLength,
            tilt: layers[index].tilt,
          };
          break;
        case "ThreePoint":
          oldLayers[index] = {
            type: "ThreePoint",
            id: layers[index].id,
            name: layers[index].name,
            visible: layers[index].visible,
            color: layers[index].color,
            center: layers[index].center,
            angle: layers[index].angle,
            angle2: layers[index].angle2,
            focalLength: layers[index].focalLength,
            tilt: layers[index].tilt,
          };
          break;
        case "Grid":
          oldLayers[index] = {
            type: "Grid",
            id: layers[index].id,
            name: layers[index].name,
            visible: layers[index].visible,
            color: layers[index].color,
            corner: layers[index].corner,
            width: layers[index].width, 
            height: layers[index].height,
            rows: layers[index].rows,
            columns: layers[index].columns,
            angle: layers[index].angle,
          };
          break;
        case "Isometric":
          oldLayers[index] = {
            type: "Isometric",
            id: layers[index].id,
            name: layers[index].name,
            visible: layers[index].visible,
            color: layers[index].color,
            corner: layers[index].corner,
            width: layers[index].width, 
            height: layers[index].height,
            rows: layers[index].rows,
            columns: layers[index].columns,
            angle: layers[index].angle,
            angle2: layers[index].angle2,
          };
          break;
        case "Ellipse":
          oldLayers[index] = {
            type: "Ellipse",
            id: layers[index].id,
            name: layers[index].name,
            visible: layers[index].visible,
            color: layers[index].color,
            center: layers[index].center,
            width: layers[index].width, 
            height: layers[index].height,
            angle: layers[index].angle,
          };
          break;
      }
    }
    const oldSelection = React.createRef();

    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = resolution.width;
    tempCanvas.height = resolution.height;
    oldSelection.current = tempCanvas;

    //draw temp canvas
    const ctx = oldSelection.current.getContext("2d");
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      selection.current,
      0,
      0,
      resolution.width,
      resolution.height
    );

    //update history
    let tempHistory = [...history];
    if (tempHistory.length >= maxHistory) tempHistory.splice(0, 1);
    for (const i in tempHistory) {
      if (tempHistory[i].version > version) {
        tempHistory.splice(i);
        break;
      }
    }
    tempHistory.push({
      resolution,
      offset,
      palette: [...palette],
      layers: [...oldLayers],
      selection: oldSelection,
      selectedLayer,
      selectedLayers: [...selectedLayers],
      magnetLayer,
      version: version + 1
    });

    setHistory([...tempHistory]);
    setVersion((prevVale) => prevVale + 1);
  }, [resolution, offset, palette, layers, selection, selectedLayer, selectedLayers, magnetLayer, version]);
  
  const updateLayer = useCallback((index, canvas, selectionLayer) => {
    updateHistory();
  
    setLayers(prevLayers => {
      if(prevLayers[index].type === "Canvas"){
        // Update offscreen canvas
        const ctx = prevLayers[index].ref.current.getContext("2d");
        ctx.imageSmoothingEnabled = false;
        ctx.clearRect(-prevLayers[index].x + offset.x, -prevLayers[index].y + offset.y, resolution.width, resolution.height);
        ctx.drawImage(canvas.current, -prevLayers[index].x + offset.x, -prevLayers[index].y + offset.y, resolution.width, resolution.height);
      } 
    
      const selectionCtx = selection.current.getContext("2d");
      selectionCtx.imageSmoothingEnabled = false;
      selectionCtx.clearRect(0, 0, resolution.width, resolution.height);
      selectionCtx.drawImage(selectionLayer.current, 0, 0);
  
      return [...prevLayers];
    });
  }, [offset, resolution, selection, updateHistory]);
  
  const updateGuide = useCallback((index, layer) => {
    updateHistory();
  
    setLayers(prevLayers => {
      switch(prevLayers[index].type){
        case "Line":
          prevLayers[index].color = layer.color;
          prevLayers[index].startPoint = {x: parseFloat(parseFloat(layer.startPoint.x).toFixed(2)), y: parseFloat(parseFloat(layer.startPoint.y).toFixed(2))};
          prevLayers[index].endPoint = {x: parseFloat(parseFloat(layer.endPoint.x).toFixed(2)), y: parseFloat(parseFloat(layer.endPoint.y).toFixed(2))};
          prevLayers[index].extend = layer.extend;
          break;
        case "OnePoint":
          prevLayers[index].color = layer.color;
          prevLayers[index].point = {x: parseFloat(parseFloat(layer.point.x).toFixed(2)), y: parseFloat(parseFloat(layer.point.y).toFixed(2))};
          break;
        case "TwoPoint":
          prevLayers[index].color = layer.color;
          prevLayers[index].center = {x: parseFloat(parseFloat(layer.center.x).toFixed(2)), y: parseFloat(parseFloat(layer.center.y).toFixed(2))};
          prevLayers[index].angle = parseFloat(parseFloat(layer.angle).toFixed(2));
          prevLayers[index].focalLength = layer.focalLength;
          prevLayers[index].tilt = parseFloat(parseFloat(layer.tilt).toFixed(2));
          break;
        case "ThreePoint":
          prevLayers[index].color = layer.color;
          prevLayers[index].center = {x: parseFloat(parseFloat(layer.center.x).toFixed(2)), y: parseFloat(parseFloat(layer.center.y).toFixed(2))};
          prevLayers[index].angle = parseFloat(parseFloat(layer.angle).toFixed(2));
          prevLayers[index].angle2 = parseFloat(parseFloat(layer.angle2).toFixed(2));
          prevLayers[index].focalLength = layer.focalLength;
          prevLayers[index].tilt = parseFloat(parseFloat(layer.tilt).toFixed(2));
          break;
        case "Grid":
          prevLayers[index].color = layer.color;
          prevLayers[index].corner = {x: parseFloat(parseFloat(layer.corner.x).toFixed(2)), y: parseFloat(parseFloat(layer.corner.y).toFixed(2))};
          prevLayers[index].width = parseFloat(parseFloat(layer.width).toFixed(2));
          prevLayers[index].height = parseFloat(parseFloat(layer.height).toFixed(2));
          prevLayers[index].rows = layer.rows;
          prevLayers[index].columns = layer.columns;
          prevLayers[index].angle = parseFloat(parseFloat(layer.angle).toFixed(2));
          break;
        case "Isometric":
          prevLayers[index].color = layer.color;
          prevLayers[index].corner = {x: parseFloat(parseFloat(layer.corner.x).toFixed(2)), y: parseFloat(parseFloat(layer.corner.y).toFixed(2))};
          prevLayers[index].width = parseFloat(parseFloat(layer.width).toFixed(2));
          prevLayers[index].height = parseFloat(parseFloat(layer.height).toFixed(2));
          prevLayers[index].rows = layer.rows;
          prevLayers[index].columns = layer.columns;
          prevLayers[index].angle = parseFloat(parseFloat(layer.angle).toFixed(2));
          prevLayers[index].angle2 = parseFloat(parseFloat(layer.angle2).toFixed(2));
          break;
        case "Ellipse":
          prevLayers[index].color = layer.color;
          prevLayers[index].center = {x: parseFloat(parseFloat(layer.center.x).toFixed(2)), y: parseFloat(parseFloat(layer.center.y).toFixed(2))};
          prevLayers[index].width = parseFloat(parseFloat(layer.width).toFixed(2));
          prevLayers[index].height = parseFloat(parseFloat(layer.height).toFixed(2));
          prevLayers[index].angle = parseFloat(parseFloat(layer.angle).toFixed(2));
          break;
      }
  
      return [...prevLayers];
    });
  }, [updateHistory]);
  
  const mergeDown = useCallback(() => {
    const hasLowerCanvasLayer = layers.some((layer, index) => index < selectedLayer && layer.type === "Canvas");
    const canvasLayerCount = selectedLayers.filter(index => layers[index].type === "Canvas").length;
    if (!hasLowerCanvasLayer && canvasLayerCount <= 1) return alert("Must be merging two or more canvas layers.");
  
    updateHistory();
  
    setLayers(prevLayers => {
      const tempLayers = [...prevLayers];
      const selectedCanvasLayers = selectedLayers.filter(index => layers[index].type === "Canvas");

      //sort selected layer in ascending and descending order
      const ascendingLayers = selectedCanvasLayers.slice().sort((a, b) => a - b);
      const descendingLayers = selectedCanvasLayers.slice().sort((a, b) => b - a);

      let newMagnetLayer = magnetLayer;
  
      if (ascendingLayers.length > 1) {
        // Calculate the bounding box of all selected layers
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
        for (const index of ascendingLayers) {
          const layer = tempLayers[index];
          minX = Math.min(minX, layer.x);
          minY = Math.min(minY, layer.y);
          maxX = Math.max(maxX, layer.x + layer.ref.current.width);
          maxY = Math.max(maxY, layer.y + layer.ref.current.height);
        }
  
        // Ensure the bounding box does not exceed maxLayerSize
        const width = Math.min(maxLayerSize, maxX - minX);
        const height = Math.min(maxLayerSize, maxY - minY);
  
        const lowestIndex = Math.min(...ascendingLayers);
        const lowestLayer = tempLayers[lowestIndex];
  
        // Make a copy of the lowest layer's canvas content
        const lowestLayerCanvas = lowestLayer.ref.current;
        const lowestLayerCopy = document.createElement("canvas");
        lowestLayerCopy.width = lowestLayerCanvas.width;
        lowestLayerCopy.height = lowestLayerCanvas.height;
        const copyCtx = lowestLayerCopy.getContext("2d");
        copyCtx.drawImage(lowestLayerCanvas, 0, 0);
  
        // Set the new size and position of the lowest layer
        lowestLayer.ref.current.width = width;
        lowestLayer.ref.current.height = height;
  
        const ctx = lowestLayer.ref.current.getContext("2d");
        ctx.imageSmoothingEnabled = false;
  
        // Draw the copy back to the resized canvas
        ctx.drawImage(lowestLayerCopy, lowestLayer.x - minX, lowestLayer.y - minY);
  
        lowestLayer.x = minX;
        lowestLayer.y = minY;
  
        // Draw other sorted layers onto the lowest layer's canvas in ascending order
        for (const index of ascendingLayers) {
          if (index !== lowestIndex) {
            const layer = tempLayers[index];
            ctx.drawImage(
              layer.ref.current,
              layer.x - minX,
              layer.y - minY
            );
          }
        }
        
        //remove layers in descending order
        for (const index of descendingLayers) {
          if (index !== lowestIndex) {
            tempLayers.splice(index, 1);
            // Update the magnet layer if it was affected
            if (magnetLayer !== null && index < magnetLayer) newMagnetLayer --;
          }
        }
  
        setSelectedLayer(lowestIndex);
        setSelectedLayers([lowestIndex]);
      } else {
        // Merge the selected layer with the closest canvas underneath it
        let closestLowerCanvasIndex = -1;
        for (let i = selectedLayer - 1; i >= 0; i--) {
          if (tempLayers[i].type === "Canvas") {
            closestLowerCanvasIndex = i;
            break;
          }
        }
  
        if (closestLowerCanvasIndex === -1) {
          return alert("No canvas layer below the selected layer.");
        }

        const ctx = tempLayers[closestLowerCanvasIndex].ref.current.getContext("2d");
        ctx.imageSmoothingEnabled = false;
  
        const selectedLayerObj = tempLayers[selectedLayer];
        const lowerLayerObj = tempLayers[closestLowerCanvasIndex];
  
        // Make a copy of the lower layer's canvas content
        const lowerLayerCanvas = lowerLayerObj.ref.current;
        const lowerLayerCopy = document.createElement("canvas");
        lowerLayerCopy.width = lowerLayerCanvas.width;
        lowerLayerCopy.height = lowerLayerCanvas.height;
        const copyCtx = lowerLayerCopy.getContext("2d");
        copyCtx.drawImage(lowerLayerCanvas, 0, 0);
  
        // Calculate the new bounding box
        const minX = Math.min(selectedLayerObj.x, lowerLayerObj.x);
        const minY = Math.min(selectedLayerObj.y, lowerLayerObj.y);
        const maxX = Math.max(selectedLayerObj.x + selectedLayerObj.ref.current.width, lowerLayerObj.x + lowerLayerObj.ref.current.width);
        const maxY = Math.max(selectedLayerObj.y + selectedLayerObj.ref.current.height, lowerLayerObj.y + lowerLayerObj.ref.current.height);
  
        const width = Math.min(maxLayerSize, maxX - minX);
        const height = Math.min(maxLayerSize, maxY - minY);
  
        lowerLayerObj.ref.current.width = width;
        lowerLayerObj.ref.current.height = height;
  
        // Draw the copy back to the resized canvas
        ctx.drawImage(lowerLayerCopy, lowerLayerObj.x - minX, lowerLayerObj.y - minY);
        
        lowerLayerObj.x = minX;
        lowerLayerObj.y = minY;
  
        ctx.drawImage(
          selectedLayerObj.ref.current,
          selectedLayerObj.x - minX,
          selectedLayerObj.y - minY
        );
  
        tempLayers.splice(selectedLayer, 1);
  
        // Update the magnet layer if it was affected
        if (magnetLayer !== null && selectedLayer < magnetLayer) newMagnetLayer --;
  
        setSelectedLayer(closestLowerCanvasIndex);
        setSelectedLayers([closestLowerCanvasIndex]);
      }
  
      setMagnetLayer(newMagnetLayer);  

      // Return the updated layers
      return tempLayers;
    });
  }, [layers, selectedLayer, selectedLayers, magnetLayer, updateHistory]);     
  
  const addLayer = useCallback((type) => {
    if(type === 'Canvas'){
      const canvasCount = layers.filter(layer => layer.type === 'Canvas').length;
      if (canvasCount >= maxLayerCount) return window.alert(`Max canvas count reached. (${maxLayerCount})`);
    }
    
    updateHistory();
  
    setLayers(prevLayers => {
      // Add new layer at index
      let newLayers;
      const focalLength = lastFocalLength ? lastFocalLength : Math.round(Math.sqrt(resolution.width * resolution.width + resolution.height * resolution.height) * 35 / 43);
      switch(type){
        case "Canvas":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "Canvas",
              id: generateId(8),
              name: `Layer ${layersCreated + 1}`,
              visible: true,
              x: offset.x,
              y: offset.y,
              ref: React.createRef(),
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];
      
          // Create the first layer dynamically
          const offscreenCanvas = document.createElement("canvas");
          offscreenCanvas.width = resolution.width;
          offscreenCanvas.height = resolution.height;
          // Set ref for new layer
          newLayers[selectedLayer + 1].ref.current = offscreenCanvas;

          setLayersCreated(layersCreated + 1);

          if(selectedTool === "guide") setSelectedTool(previousTool);
          break;
        case "Line":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "Line",
              id: generateId(8),
              name: `Line ${linesCreated + 1}`,
              visible: true,
              color: lastColor,
              startPoint: {x: Math.floor(resolution.width/3), y: Math.round(resolution.height/3*2)},
              endPoint: {x: Math.ceil(resolution.width/3*2), y: Math.round(resolution.height/3*2)},
              extend: true,
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];

          setLinesCreated(linesCreated + 1);
          break;
        case "OnePoint":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "OnePoint",
              id: generateId(8),
              name: `Vanishing Point ${vanishingPointsCreated + 1}`,
              visible: true,
              color: lastColor,
              point: {x: parseFloat(parseFloat(resolution.width/2).toFixed(1)), y: parseFloat(parseFloat(resolution.height/2).toFixed(1))},
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];

          setVanishingPointsCreated(vanishingPointsCreated + 1);
          break;
        case "TwoPoint":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "TwoPoint",
              id: generateId(8),
              name: `Perspective ${perspectivesCreated + 1}`,
              visible: true,
              color: lastColor,
              center: {x: parseFloat(parseFloat(resolution.width/2).toFixed(1)), y: parseFloat(parseFloat(resolution.height/2).toFixed(1))},
              angle: 45,
              focalLength,
              tilt: 0,
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];

          setPerspectivesCreated(perspectivesCreated + 1);
          setLastFocalLength(focalLength);
          break;
        case "ThreePoint":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "ThreePoint",
              id: generateId(8),
              name: `Perspective ${perspectivesCreated + 1}`,
              visible: true,
              color: lastColor,
              center: {x: parseFloat(parseFloat(resolution.width/2).toFixed(1)), y: parseFloat(parseFloat(resolution.height/2).toFixed(1))},
              angle: 45,
              angle2: 0,
              focalLength,
              tilt: 0,
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];

          setPerspectivesCreated(perspectivesCreated + 1);
          setLastFocalLength(focalLength);
          break;
        case "Grid":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "Grid",
              id: generateId(8),
              name: `Grid ${gridsCreated + 1}`,
              visible: true,
              color: lastColor,
              corner: {x: 0, y: 0},
              width: resolution.width, 
              height: resolution.height,
              rows: 3,
              columns: 3,
              angle: 0,
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];

          setGridsCreated(gridsCreated + 1);
          setLastFocalLength(focalLength);
          break;
        case "Isometric":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "Isometric",
              id: generateId(8),
              name: `Isometric ${isometricsCreated + 1}`,
              visible: true,
              color: lastColor,
              corner: {x: parseFloat(parseFloat(resolution.width/2).toFixed(1)), y: Math.ceil(resolution.height/4*3)},
              width: Math.ceil(resolution.width/2), 
              height: Math.ceil(resolution.width/2),
              rows: 3,
              columns: 3,
              angle: 153.43,
              angle2: 26.57,
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];

          setIsometricsCreated(isometricsCreated + 1);
          break;
        case "Ellipse":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "Ellipse",
              id: generateId(8),
              name: `Ellipse ${ellipsesCreated + 1}`,
              visible: true,
              color: lastColor,
              center: {x: parseFloat(parseFloat(resolution.width/2).toFixed(2)), y: parseFloat(parseFloat(resolution.height/2).toFixed(2))},
              width: parseFloat(parseFloat(resolution.width/2).toFixed(2)), 
              height: parseFloat(parseFloat(resolution.width/3).toFixed(2)),
              angle: 0,
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];

          setEllipsesCreated(ellipsesCreated + 1);
          break;
      }
  
      return newLayers;
    });

    if(type !== "Canvas"){
      setSelectedTool(prevTool => {
        if(prevTool !== "guide") setPreviousTool(prevTool);    
        return "guide";
      });
    }
    
    if(magnetLayer && magnetLayer > selectedLayer){
      setMagnetLayer(prevVale => prevVale + 1);
    }
  
    setSelectedLayer(selectedLayer + 1);
    setSelectedLayers([selectedLayer + 1]);
  }, [
    layers, 
    maxLayerCount, 
    updateHistory, 
    selectedLayer, 
    layersCreated, 
    linesCreated, 
    perspectivesCreated, 
    vanishingPointsCreated,
    gridsCreated, 
    isometricsCreated, 
    ellipsesCreated, 
    offset, 
    resolution, 
    selectedTool,
    lastColor,
    lastFocalLength,
    magnetLayer,
    selectedTool,
    previousTool,
    updateHistory,
  ]);
  
  const duplicateLayer = useCallback(() => {
    if (layers.length >= maxLayerCount) return window.alert(`Max layer count reached. (${maxLayerCount})`);
    
    updateHistory();
  
    setLayers(prevLayers => {
      // Add new layer at index
      let newLayers;
      switch(prevLayers[selectedLayer].type){
        case "Canvas":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "Canvas",
              id: generateId(8),
              ref: React.createRef(),
              visible: true,
              name: prevLayers[selectedLayer].name + " copy",
              x: prevLayers[selectedLayer].x,
              y: prevLayers[selectedLayer].y
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];
  
          // Create the first layer dynamically
          const offscreenCanvas = document.createElement("canvas");
          offscreenCanvas.width = prevLayers[selectedLayer].ref.current.width;
          offscreenCanvas.height = prevLayers[selectedLayer].ref.current.height;
      
          // Set ref for new layer
          newLayers[selectedLayer + 1].ref.current = offscreenCanvas;
      
          // Draw selected layer on new layer
          const ctx = newLayers[selectedLayer + 1].ref.current.getContext("2d");
          ctx.imageSmoothingEnabled = false;
          ctx.drawImage(prevLayers[selectedLayer].ref.current, 0, 0);

          break;
        case "Line":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "Line",
              id: generateId(8),
              visible: true,
              name: prevLayers[selectedLayer].name + " copy",
              color: prevLayers[selectedLayer].color,
              extend: prevLayers[selectedLayer].extend,
              startPoint: { x: prevLayers[selectedLayer].startPoint.x, y: prevLayers[selectedLayer].startPoint.y},
              endPoint: { x: prevLayers[selectedLayer].endPoint.x, y: prevLayers[selectedLayer].endPoint.y},
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];
          break;
        case "OnePoint":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "OnePoint",
              id: generateId(8),
              visible: true,
              name: prevLayers[selectedLayer].name + " copy",
              color: prevLayers[selectedLayer].color,
              point: { x: prevLayers[selectedLayer].point.x, y: prevLayers[selectedLayer].point.y},
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];
          break;
        case "TwoPoint":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "TwoPoint",
              id: generateId(8),
              visible: true,
              name: prevLayers[selectedLayer].name + " copy",
              color: prevLayers[selectedLayer].color,
              center: prevLayers[selectedLayer].center,
              angle: prevLayers[selectedLayer].angle,
              focalLength: prevLayers[selectedLayer].focalLength,
              tilt: prevLayers[selectedLayer].tilt,
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];
          break;
        case "ThreePoint":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "ThreePoint",
              id: generateId(8),
              visible: true,
              name: prevLayers[selectedLayer].name + " copy",
              color: prevLayers[selectedLayer].color,
              center: prevLayers[selectedLayer].center,
              angle: prevLayers[selectedLayer].angle,
              angle2: prevLayers[selectedLayer].angle2,
              focalLength: prevLayers[selectedLayer].focalLength,
              tilt: prevLayers[selectedLayer].tilt,
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];
          break;
        case "Grid":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "Grid",
              id: generateId(8),
              visible: true,
              name: prevLayers[selectedLayer].name + " copy",
              color: prevLayers[selectedLayer].color,
              corner: prevLayers[selectedLayer].corner,
              width: prevLayers[selectedLayer].width,
              height: prevLayers[selectedLayer].height,
              rows: prevLayers[selectedLayer].rows,
              columns: prevLayers[selectedLayer].columns,
              angle: prevLayers[selectedLayer].angle,
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];
          break;
        case "Isometric":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "Isometric",
              id: generateId(8),
              visible: true,
              name: prevLayers[selectedLayer].name + " copy",
              color: prevLayers[selectedLayer].color,
              corner: prevLayers[selectedLayer].corner,
              width: prevLayers[selectedLayer].width,
              height: prevLayers[selectedLayer].height,
              rows: prevLayers[selectedLayer].rows,
              columns: prevLayers[selectedLayer].columns,
              angle: prevLayers[selectedLayer].angle,
              angle2: prevLayers[selectedLayer].angle2,
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];
          break;
        case "Ellipse":
          newLayers = [
            ...prevLayers.slice(0, selectedLayer + 1),
            {
              type: "Ellipse",
              id: generateId(8),
              visible: true,
              name: prevLayers[selectedLayer].name + " copy",
              color: prevLayers[selectedLayer].color,
              center: prevLayers[selectedLayer].center,
              width: prevLayers[selectedLayer].width,
              height: prevLayers[selectedLayer].height,
              angle: prevLayers[selectedLayer].angle,
            },
            ...prevLayers.slice(selectedLayer + 1)
          ];
          break;
      }
      return newLayers;
    });

    if(magnetLayer && magnetLayer > selectedLayer){
      setMagnetLayer(prevVale => prevVale + 1);
    }
  
    setSelectedLayer(selectedLayer + 1);
    setSelectedLayers([selectedLayer + 1]);
    setLayersCreated(prevValue => prevValue + 1);
  }, [layers.length, maxLayerCount, magnetLayer, updateHistory, selectedLayer]);
  
  const deleteLayer = useCallback(() => {
    const canvasCount = layers.filter(layer => layer.type === 'Canvas').length;
  
    // Filter out Canvas layers if they are not the last remaining one
    let selectedCanvasCount = 0;
    for(const index of selectedLayers){
      if(layers[index].type === "Canvas") selectedCanvasCount ++;
    }

    if(selectedCanvasCount >= canvasCount) return alert("Must leave one canvas layer remaining.")
  
    updateHistory();

    setLayers(prevLayers => {
      // Create a copy of the previous layers
      let tempLayers = [...prevLayers];

      // Sort selectedLayers in descending order to avoid index shifting issues while splicing
      const sortedSelectedLayers = [...selectedLayers].sort((a, b) => b - a);

      // Remove each selected layer
      sortedSelectedLayers.forEach(layerIndex => {
        tempLayers.splice(layerIndex, 1);
      });

      return tempLayers;
    });

    // Calculate the new selected layer index
    let  newSelectedLayer = selectedLayers.length ? Math.min(...selectedLayers) - 1 : -1;
    if(newSelectedLayer < 0) newSelectedLayer = 0;
    setSelectedLayer(newSelectedLayer);
    setSelectedLayers([newSelectedLayer]);

    if (magnetLayer && selectedLayers.includes(magnetLayer)) {
      setMagnetLayer(null);
    } else if (magnetLayer > Math.min(...selectedLayers)) {
      setMagnetLayer(prevVale => prevVale - selectedLayers.filter(layer => layer < magnetLayer).length);
    }
  }, [layers, selectedLayer, selectedLayers, magnetLayer, updateHistory]);

  useEffect(() => {
    if(addLayerNow > 0){
      addLayer("Canvas");
    }
  }, [addLayerNow]); 

  useEffect(() => {
    if(mergeDownNow > 0){
      mergeDown();
    }
  }, [mergeDownNow]); 

  useEffect(() => {
    if(deleteLayerNow > 0){
      deleteLayer();
    }
  }, [deleteLayerNow]); 

  useEffect(() => {
    if(duplicateNow > 0){
      duplicateLayer();
    }
  }, [duplicateNow]);
  
  const reorderLayers = (indices, newIndex) => {
    updateHistory();
  
    // Sort indices to maintain order
    const sortedIndices = [...indices].sort((a, b) => a - b);
    const tempLayers = [...layers];
    const layersToMove = sortedIndices.map(index => tempLayers[index]);
  
    // Remove the layers from their original positions
    for (let i = sortedIndices.length - 1; i >= 0; i--) {
      tempLayers.splice(sortedIndices[i], 1);
    }
  
    // Adjust the newIndex if it's after the original indices
    let adjustedNewIndex = newIndex;
    sortedIndices.forEach(index => {
      if (newIndex > index) {
        adjustedNewIndex--;
      }
    });
  
    // Insert the layers at the new position
    tempLayers.splice(adjustedNewIndex, 0, ...layersToMove);
  
    setLayers([...tempLayers]);
  
    // Calculate the new indices for the moved layers
    const newIndices = layersToMove.map((_, i) => adjustedNewIndex + i);
  
    // Update the selected layers to the new indices
    setSelectedLayers(newIndices);
    setSelectedLayer(newIndices[newIndices.length-1]);

    // Determine the new index of the magnet layer
    if(magnetLayer){
      let newMagnetLayerIndex = magnetLayer;
      if (sortedIndices.includes(magnetLayer)) {
        // If the magnet layer is being moved
        const indexInMovedLayers = sortedIndices.indexOf(magnetLayer);
        newMagnetLayerIndex = adjustedNewIndex + indexInMovedLayers;
      } else {
        // Adjust the magnet layer index if layers are moved before it
        sortedIndices.forEach(index => {
          if (index < magnetLayer && adjustedNewIndex <= magnetLayer) {
            newMagnetLayerIndex--;
          } else if (index > magnetLayer && adjustedNewIndex >= magnetLayer) {
            newMagnetLayerIndex++;
          }
        });
      }
  
      setMagnetLayer(newMagnetLayerIndex);
    }
  };
  
  
  const importImage = (canvas, layerName) => {
    if (layers.length >= maxLayerCount) return window.alert(`Max layer count reached. (${maxLayerCount})`);
  
    updateHistory();
  
    setLayers(prevLayers => {
      let width = canvas.width;
      let height = canvas.height;
  
      let offsetX = Math.round(resolution.width / 2) - Math.round(width / 2);
      let offsetY = Math.round(resolution.height / 2) - Math.round(height / 2);
  
      let x, y;
      if (offsetX < 0) {
        x = offsetX;
        offsetX = 0;
      } else {
        x = 0;
      }
      if (offsetY < 0) {
        y = offsetY;
        offsetY = 0;
      } else {
        y = 0;
      }
  
      width = resolution.width > width ? resolution.width : width;
      height = resolution.height > height ? resolution.height : height;
  
      // Add new layer at index
      const newLayers = [
        ...prevLayers.slice(0, selectedLayer + 1),
        {
          type: "Canvas",
          id: generateId(8),
          ref: React.createRef(),
          visible: true,
          name: layerName,
          x: x + offset.x,
          y: y + offset.y
        },
        ...prevLayers.slice(selectedLayer + 1)
      ];
  
      // Create the first layer dynamically
      const offscreenCanvas = document.createElement("canvas");
      offscreenCanvas.width = width;
      offscreenCanvas.height = height;
      // Set ref for new layer
      newLayers[selectedLayer + 1].ref.current = offscreenCanvas;
  
      // Draw image
      const ctx = newLayers[selectedLayer + 1].ref.current.getContext("2d");
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(canvas, offsetX, offsetY);
      const imageData = ctx.getImageData(0, 0, width, height);
      ctx.putImageData(imageData, 0, 0);
  
      return newLayers;
    });
  
    setDocumentSaving(false);
    setSelectedLayer(prevValue => {
      setSelectedLayers([prevValue + 1]);
      return prevValue + 1
    });
    setLayersCreated(prevValue => prevValue + 1);
  };
  
  const applyTransform = useCallback((index, canvas, selectionLayer) => {
    updateHistory();

    const layer = layers[index].ref.current;
    const ctx = layer.getContext("2d");
    ctx.imageSmoothingEnabled = false;

    const copy = document.createElement('canvas');
    copy.width = layer.width;
    copy.height = layer.height
    const copyCtx = copy.getContext("2d");
    copyCtx.imageSmoothingEnabled = false;
    copyCtx.drawImage(layer, 0, 0);
      
    let left = transform.left - layers[index].x + offset.x;
    let top = transform.top - layers[index].y + offset.y;
    let right = transform.right - layers[index].x + offset.x;
    let bottom = transform.bottom - layers[index].y + offset.y;
    let width = right - left;
    let height = bottom - top;
    
    let deltaX = 0;
    let deltaY = 0;
    let layerWidth = layer.width;
    let layerHeight = layer.height;
    if(left < 0){
      deltaX = -left;
      layerWidth += deltaX;
      left = 0;
    }
    if(top < 0){
      deltaY = -top
      layerHeight += deltaY;
      top = 0;
    }
    if(right > layer.width){
      layerWidth += right - layer.width;
    }
    if(bottom > layer.height){
      layerHeight += bottom - layer.height;
    }
    right = left + width;
    bottom = top + height;

    layer.width = layerWidth;
    layer.height = layerHeight;

    //you have to reapply image smoothing false after layer size change
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(copy, deltaX, deltaY);
    ctx.clearRect(deltaX - layers[index].x + offset.x, deltaY - layers[index].y + offset.y, resolution.width, resolution.height);
    ctx.drawImage(canvas.current, deltaX - layers[index].x + offset.x, deltaY - layers[index].y + offset.y, resolution.width, resolution.height);

    ctx.drawImage(transformCanvas, left, top, width, height);

    layers[index].x -= deltaX;
    layers[index].y -= deltaY;

    const selectionCtx = selection.current.getContext("2d");
    selectionCtx.imageSmoothingEnabled = false;
    selectionCtx.clearRect(0,0,resolution.width,resolution.height);
    selectionCtx.drawImage(selectionLayer.current,0,0);
  }, [layers,resolution,offset,transformCanvas,transform,selection, updateHistory]);

  const loadPalettes = async (newDocumentAfter) => {
    try{
      const response = await axios.get(apiUrl + "/api/palettes");
  
      const palettesData = response.data;
      if(palettesData){
        await setPalettes([...defaultPalettes,...palettesData]);
      } else{
        await setPalettes(defaultPalettes);
      }
    } catch(error){
      console.log('No palettes loaded.');
      await setPalettes(defaultPalettes);
    }

    if(newDocumentAfter){
      setNewDocumentModal(true);
    } else {
      setLoadPaletteModal(true);
    }
  };

  const updatePalette = useCallback((swatch, color) => {
    updateHistory();

    const stencilCtx = stencil.current.getContext("2d");
    stencilCtx.imageSmoothingEnabled = false;
    stencilCtx.fillStyle = color;
    stencilCtx.fillRect(0, swatch*maxCursorSize, stencil.current.width, maxCursorSize);

    const newPalette = [...palette];
    newPalette[swatch] = color;

    if (palette[swatch] && !newPalette.includes(palette[swatch])) {
      //convert colors to rgba
      const oldColor = chroma(palette[swatch]).rgb();
      const newColor = chroma(color).rgb();

      for (const layer of layers) {
        if(layer.type !== "Canvas") continue;
        const ctx = layer.ref.current.getContext("2d");
        ctx.imageSmoothingEnabled = false;
        const imageData = ctx.getImageData(
          0,
          0,
          layer.ref.current.width,
          layer.ref.current.height
        );
        for (let i = 0; i < imageData.data.length; i += 4) {
          if (
            imageData.data[i] === oldColor[0] &&
            imageData.data[i + 1] === oldColor[1] &&
            imageData.data[i + 2] === oldColor[2] &&
            imageData.data[i + 3] !== 0
          ) {
            imageData.data[i] = newColor[0];
            imageData.data[i + 1] = newColor[1];
            imageData.data[i + 2] = newColor[2];
            imageData.data[i + 3] = 255;
          }
        }
        ctx.putImageData(imageData, 0, 0);
      }
    }

    setPalette(newPalette);
  }, [palette, updateHistory]);

  const newPalette = useCallback((newPalette) => {
    updateHistory();

    const combinations = [];
    
    //get every combination
    for(let i = 0; i < 32; i++){
      if(palette[i] === '') continue;
      for(let j = 0; j < newPalette.length; j++){
        //get color distance in lab space
        combinations.push({
          oldIndex: i,
          newIndex: j,
          distance: chroma.distance(palette[i], newPalette[j])
        });
      }
    }

    const sorted = combinations.sort((a, b) => a.distance-b.distance);

    //arrays to store which swatches are already assigned
    const assigned = [];
  
    const tempLayers = [...layers];
    for(const combo of sorted){
      //skip combo if color is assigned already
      if(assigned.includes(combo.oldIndex)) continue;

      //add combo to assigned indexes
      assigned.push(combo.oldIndex);

      //update the color in the document for this color combo if new palette doesnt include old color
      if (!newPalette.includes(palette[combo.oldIndex])) {
        //convert colors to rgba
        const oldColor = chroma(palette[combo.oldIndex]).rgb();
        const newColor = chroma(newPalette[combo.newIndex]).rgb();
        for (const layer of tempLayers) {
          if(layer.type !== "Canvas") continue;
          const ctx = layer.ref.current.getContext("2d");
          ctx.imageSmoothingEnabled = false;
          const imageData = ctx.getImageData(
            0,
            0,
            layer.ref.current.width,
            layer.ref.current.height
          );
          for (let i = 0; i < imageData.data.length; i += 4) {
            if (
              imageData.data[i] === oldColor[0] &&
              imageData.data[i + 1] === oldColor[1] &&
              imageData.data[i + 2] === oldColor[2] &&
              imageData.data[i + 3] !== 0
            ) {
              imageData.data[i] = newColor[0];
              imageData.data[i + 1] = newColor[1];
              imageData.data[i + 2] = newColor[2];
              imageData.data[i + 3] = 255;
            }
          }
          ctx.putImageData(imageData, 0, 0);
        }
      }
    }
    setLayers([...tempLayers]);

    //update brush stencil
    const stencilCtx = stencil.current.getContext("2d");
    stencilCtx.imageSmoothingEnabled = false;

    for(const index in newPalette){
      stencilCtx.fillStyle = newPalette[index];
      stencilCtx.fillRect(0, index*maxCursorSize, stencil.current.width, maxCursorSize);
    }

    //add blank swatches
    const tempPalette = [...newPalette];
    for(let i = newPalette.length; i < 32; i++){
      tempPalette.push('');
    }

    setPalette([...tempPalette]);
  }, [layers, palette, stencil, updateHistory]);

  const deleteSwatch = useCallback(() => {
    if(selectedSwatch === 32) return alert("Can't delete alpha swatch.");

    //check if there is more than one color remaining
    let filledSwatches = 0;
    for(const swatch of palette) if(swatch) filledSwatches ++;
    if(filledSwatches <= 1) return alert("Must have at least one color.");

    updateHistory();

    const color = palette[selectedSwatch];

    //find closest color to replace it with
    let closestColor, closestDistance;
    for(const index in palette){
      if(palette[index] === '' || parseInt(index) === selectedSwatch) continue;
      const distance = chroma.distance(palette[index], color);
      if(typeof closestDistance === "undefined" || distance < closestDistance){
        closestColor = palette[index];
        closestDistance = distance;
      }
    }
    
    //replace color
    const oldColor = chroma(color).rgb();
    const newColor = chroma(closestColor).rgb();
    const tempLayers = [...layers];
    for (const layer of tempLayers) {
      if(layer.type !== "Canvas") continue;
      const ctx = layer.ref.current.getContext("2d");
      ctx.imageSmoothingEnabled = false;
      const imageData = ctx.getImageData(
        0,
        0,
        layer.ref.current.width,
        layer.ref.current.height
      );
      for (let i = 0; i < imageData.data.length; i += 4) {
        if (
          imageData.data[i] === oldColor[0] &&
          imageData.data[i + 1] === oldColor[1] &&
          imageData.data[i + 2] === oldColor[2] &&
          imageData.data[i + 3] !== 0
        ) {
          imageData.data[i] = newColor[0];
          imageData.data[i + 1] = newColor[1];
          imageData.data[i + 2] = newColor[2];
          imageData.data[i + 3] = 255;
        }
      }
      ctx.putImageData(imageData, 0, 0);
    }
    setLayers([...tempLayers]);

    //remove color from palette
    const newPalette = [...palette];
    newPalette[selectedSwatch] = '';
    setPalette(newPalette);

    let i = selectedSwatch + 1;
    let newSelectedSwatch;
    while(typeof newSelectedSwatch === "undefined"){
      if(i > 32) i = 0;
      if(palette[i] || i === 32) newSelectedSwatch = i;
      i++;
    }
    if(secondarySwatch === selectedSwatch) setSecondarySwatch(newSelectedSwatch);
    setSelectedSwatch(newSelectedSwatch);
  }, [palette, selectedSwatch, updateHistory]);
  
  useEffect(() => {
    if(deleteSwatchNow > 0){
      deleteSwatch();
    }
  }, [deleteSwatchNow]);

  const mixColors = useCallback(() => {
    if(selectedSwatch === 32 || secondarySwatch === 32) return alert("Can't mix with alpha.");
    
    updateHistory();

    const color = chroma.mix(palette[selectedSwatch], palette[secondarySwatch], 0.5, "lab").hex();

    const stencilCtx = stencil.current.getContext("2d");
    stencilCtx.imageSmoothingEnabled = false;
    stencilCtx.fillStyle = color;
    stencilCtx.fillRect(0, selectedSwatch*maxCursorSize, stencil.current.width, maxCursorSize);

    const newPalette = [...palette];
    newPalette[selectedSwatch] = color;

    if (!newPalette.includes(palette[selectedSwatch])) {
      //convert colors to rgba
      const oldColor = chroma(palette[selectedSwatch]).rgb();
      const newColor = chroma(color).rgb();

      for (const layer of layers) {
        if(layer.type !== "Canvas") continue;
        const ctx = layer.ref.current.getContext("2d");
        ctx.imageSmoothingEnabled = false;

        const imageData = ctx.getImageData(
          0,
          0,
          layer.ref.current.width,
          layer.ref.current.height
        );
        for (let i = 0; i < imageData.data.length; i += 4) {
          if (
            imageData.data[i] === oldColor[0] &&
            imageData.data[i + 1] === oldColor[1] &&
            imageData.data[i + 2] === oldColor[2] &&
            imageData.data[i + 3] !== 0
          ) {
            imageData.data[i] = newColor[0];
            imageData.data[i + 1] = newColor[1];
            imageData.data[i + 2] = newColor[2];
            imageData.data[i + 3] = 255;
          }
        }
        ctx.putImageData(imageData, 0, 0);
      }
    }

    setPalette(newPalette);
  }, [palette, selectedSwatch, secondarySwatch, updateHistory]);

  useEffect(() => {
    if(mixColorsNow > 0){
      mixColors();
    }
  }, [mixColorsNow]);

  const applyCrop = useCallback(() => {
    if (crop) {
      updateHistory();
  
      // New resolution after crop
      const width = crop.right - crop.left;
      const height = crop.bottom - crop.top;

      setMaxUpscale( Math.floor( 1024 / Math.max( width, height ) ) );
  
      // Update selection canvas
      const selectionCtx = selection.current.getContext("2d");
      const imageData = selectionCtx.getImageData(0, 0, selection.current.width, selection.current.height);
      selection.current.width = width;
      selection.current.height = height;
      selectionCtx.putImageData(imageData, -crop.left, -crop.top);
  
      // Create new layers array to ensure immutability
      const newLayers = layers.map(layer => {
        switch (layer.type) {
          case "Canvas":
            const canvas = layer.ref.current;
            const ctx = canvas.getContext("2d");
      
            // Create a temporary canvas to hold current image
            const tempCanvas = document.createElement("canvas");
            tempCanvas.width = canvas.width;
            tempCanvas.height = canvas.height;
            const tempCtx = tempCanvas.getContext("2d");
            tempCtx.drawImage(canvas, 0, 0);
      
            let left = offset.x + crop.left;
            let top = offset.y + crop.top;
            let right = offset.x + crop.right;
            let bottom = offset.y + crop.bottom;
            let offsetX = 0;
            let offsetY = 0;
    
            if(layer.x > left){
              offsetX = layer.x - left;
            } else {
              left = layer.x;
            }
    
            if(layer.y > top){
              offsetY = layer.y - top;
            } else {
              top = layer.y;
            }
    
            right = Math.max(right, layer.x + canvas.width);
            bottom = Math.max(bottom, layer.y + canvas.height);
            
            canvas.width = right - left;
            canvas.height = bottom - top;
    
            // drawing logic here
            ctx.drawImage(tempCanvas, offsetX, offsetY);
    
            return {
              ...layer,
              x: left,
              y: top,
            };
          case "Line":
            return {
              ...layer,
              startPoint: {
                x: layer.startPoint.x - crop.left,
                y: layer.startPoint.y - crop.top
              },
              endPoint: {
                x: layer.endPoint.x - crop.left,
                y: layer.endPoint.y - crop.top
              }
            };
          case "OnePoint":
            return {
              ...layer,
              point: {
                x: layer.point.x - crop.left,
                y: layer.point.y - crop.top
              }
            };
          case "TwoPoint":
            return {
              ...layer,
              center: {
                x: layer.center.x - crop.left,
                y: layer.center.y - crop.top
              }
            };
          case "ThreePoint":
            return {
              ...layer,
              center: {
                x: layer.center.x - crop.left,
                y: layer.center.y - crop.top
              }
            };
          case "Grid":
            return {
              ...layer,
              corner: {
                x: layer.corner.x - crop.left,
                y: layer.corner.y - crop.top
              }
            };
          case "Isometric":
            return {
              ...layer,
              corner: {
                x: layer.corner.x - crop.left,
                y: layer.corner.y - crop.top
              }
            };
          case "Ellipse":
            return {
              ...layer,
              center: {
                x: layer.center.x - crop.left,
                y: layer.center.y - crop.top
              }
            };
          default:
            return layer;
        }
      });
    
      // Update the state with the new layers
      setLayers(newLayers);
  
      // Update canvas resolution and offset
      setResolution({ width, height });
      setOffset({ x: offset.x + crop.left, y: offset.y + crop.top });
      setPrevCrop(crop);
  
      // Update previous offset and resolution
      oldOffset.current = { x: crop.left, y: crop.top };
      oldResolution.current = { width, height };
    }
  }, [crop, offset, resolution, layers, selection, updateHistory]);

  const resize = useCallback((newSize) => {
    updateHistory();
  
    // New resolution after crop
    const width = newSize.right - newSize.left;
    const height = newSize.bottom - newSize.top;
  
    setMaxUpscale( Math.floor( 1024 / Math.max( width, height ) ) );
  
    // Update selection canvas
    const selectionCtx = selection.current.getContext("2d");
    const imageData = selectionCtx.getImageData(0, 0, selection.current.width, selection.current.height);
    selection.current.width = width;
    selection.current.height = height;
    selectionCtx.putImageData(imageData, -newSize.left, -newSize.top);
  
    // Create new layers array to ensure immutability
    const newLayers = layers.map(layer => {
      switch (layer.type) {
        case "Canvas":
          const canvas = layer.ref.current;
          const ctx = canvas.getContext("2d");
          ctx.imageSmoothingEnabled = false;
  
          // Create a temporary canvas to hold current image
          const tempCanvas = document.createElement("canvas");
          tempCanvas.width = canvas.width;
          tempCanvas.height = canvas.height;
          const tempCtx = tempCanvas.getContext("2d");
          tempCtx.imageSmoothingEnabled = false;
          tempCtx.drawImage(canvas, 0, 0);
  
          let left = offset.x + newSize.left;
          let top = offset.y + newSize.top;
          let right = offset.x + newSize.right;
          let bottom = offset.y + newSize.bottom;
          let offsetX = 0;
          let offsetY = 0;
  
          if (layer.x > left) {
            offsetX = layer.x - left;
          } else {
            left = layer.x;
          }
  
          if (layer.y > top) {
            offsetY = layer.y - top;
          } else {
            top = layer.y;
          }
  
          right = Math.max(right, layer.x + canvas.width);
          bottom = Math.max(bottom, layer.y + canvas.height);
          
          canvas.width = right - left;
          canvas.height = bottom - top;
      
          // drawing logic here
          ctx.drawImage(tempCanvas, offsetX, offsetY);
  
          return {
            ...layer,
            x: left,
            y: top,
          };
        case "Line":
          return {
            ...layer,
            startPoint: {
              x: layer.startPoint.x - newSize.left,
              y: layer.startPoint.y - newSize.top
            },
            endPoint: {
              x: layer.endPoint.x - newSize.left,
              y: layer.endPoint.y - newSize.top
            }
          };
        case "OnePoint":
          return {
            ...layer,
            point: {
              x: layer.point.x - newSize.left,
              y: layer.point.y - newSize.top
            }
          };
        case "TwoPoint":
          return {
            ...layer,
            center: {
              x: layer.center.x - newSize.left,
              y: layer.center.y - newSize.top
            }
          };
        case "ThreePoint":
          return {
            ...layer,
            center: {
              x: layer.center.x - newSize.left,
              y: layer.center.y - newSize.top
            }
          };
        case "Grid":
          return {
            ...layer,
            corner: {
              x: layer.corner.x - newSize.left,
              y: layer.corner.y - newSize.top
            }
          };
        case "Isometric":
          return {
            ...layer,
            corner: {
              x: layer.corner.x - newSize.left,
              y: layer.corner.y - newSize.top
            }
          };
        case "Ellipse":
          return {
            ...layer,
            center: {
              x: layer.center.x - newSize.left,
              y: layer.center.y - newSize.top
            }
          };
        default:
          return layer;
      }
    });
  
    // Update the state with the new layers
    setLayers(newLayers);
  
    // Update canvas resolution and offset
    setResolution({ width, height });
    setOffset({ x: offset.x + newSize.left, y: offset.y + newSize.top });

    if(crop) setCrop({left: 0, top: 0, right: width, bottom: height});
  
    // Update previous offset and resolution
    oldOffset.current = { x: newSize.left, y: newSize.top };
    oldResolution.current = { width, height };
  }, [offset, resolution, layers, selection, crop, updateHistory]);  

  // Function to draw something on a layer
  const moveLayers = useCallback((x, y) => {
    updateHistory();
  
    x = Math.round(x);
    y = Math.round(y);
  
    const newLayers = layers.map((layer, index) => {
      if (!selectedLayers.includes(index)) {
        return layer;
      }
  
      switch (layer.type) {
        case "Canvas":
          const layerCanvas = layer.ref.current;
          const ctx = layerCanvas.getContext("2d");
          ctx.imageSmoothingEnabled = false;
    
          const tempCanvas = document.createElement('canvas');
          tempCanvas.width = layerCanvas.width;
          tempCanvas.height = layerCanvas.height;
          const tempCtx = tempCanvas.getContext("2d");
          tempCtx.imageSmoothingEnabled = false;
          tempCtx.drawImage(layerCanvas, 0, 0);
    
          let left = offset.x;
          let top = offset.y;
          let right = left + resolution.width;
          let bottom = top + resolution.height;
          let offsetX = 0;
          let offsetY = 0;
      
          if(layer.x + x > left){
            offsetX = left + layer.x + x;
          } else {
            left = layer.x + x;
          }
      
          if(layer.y + y > top){
            offsetY = top + layer.y + y;
          } else {
            top = layer.y + y;
          }
      
          right = Math.max(right, layer.x + x + layerCanvas.width);
          bottom = Math.max(bottom, layer.y + y + layerCanvas.height);
          
          layerCanvas.width = right - left;
          layerCanvas.height = bottom - top;
      
          // drawing logic here
          ctx.drawImage(tempCanvas, offsetX, offsetY);
  
          return {
            ...layer,
            x: left,
            y: top,
          };
        case "Line":
          return {
            ...layer,
            startPoint: {
              x: layer.startPoint.x + x,
              y: layer.startPoint.y + y
            },
            endPoint: {
              x: layer.endPoint.x + x,
              y: layer.endPoint.y + y
            }
          };
        case "OnePoint":
          return {
            ...layer,
            point: {
              x: layer.point.x + x,
              y: layer.point.y + y
            }
          };
        case "TwoPoint":
          return {
            ...layer,
            center: {
              x: layer.center.x + x,
              y: layer.center.y + y
            }
          };
        case "ThreePoint":
          return {
            ...layer,
            center: {
              x: layer.center.x + x,
              y: layer.center.y + y
            }
          };
        case "Grid":
          return {
            ...layer,
            corner: {
              x: layer.corner.x + x,
              y: layer.corner.y + y
            }
          };
        case "Isometric":
          return {
            ...layer,
            corner: {
              x: layer.corner.x + x,
              y: layer.corner.y + y
            }
          };
        case "Ellipse":
          return {
            ...layer,
            center: {
              x: layer.center.x + x,
              y: layer.center.y + y
            }
          };
        default:
          return layer;
      }
    });
  
    // Update the state with the new layers
    setLayers(newLayers);
  }, [offset, resolution, layers, selectedLayers, updateHistory]);

  const reorderSwatches = (index, newIndex) => {
    updateHistory();

    //get layer before making any changes
    const swatch = palette[index];
    const tempPalette = [...palette];

    if(selectedSwatch === index){
      if(newIndex < index){
        setSelectedSwatch(newIndex);
      } else {
        setSelectedSwatch(newIndex - 1);
      }
    } else if(selectedSwatch < index && selectedSwatch >= newIndex){
      setSelectedSwatch((prevValue) => prevValue + 1);
    } else if(selectedSwatch > index && selectedSwatch < newIndex){
      setSelectedSwatch((prevValue) => prevValue - 1);
    }

    if(secondarySwatch === index){
      if(newIndex < index){
        setSecondarySwatch(newIndex);
      } else {
        setSecondarySwatch(newIndex - 1);
      }
    } else if(secondarySwatch < index && secondarySwatch >= newIndex){
      setSecondarySwatch((prevValue) => prevValue + 1);
    } else if(secondarySwatch > index && secondarySwatch < newIndex){
      setSecondarySwatch((prevValue) => prevValue - 1);
    }

    //insert layer in new index
    tempPalette.splice(newIndex, 0, swatch);

    if (index > newIndex) index++;
    if (newIndex > index) newIndex--;

    //delete layer at old index
    tempPalette.splice(index, 1);

    //update stencil
    const stencilCtx = stencil.current.getContext("2d");
    stencilCtx.imageSmoothingEnabled = false;
    stencilCtx.globalCompositeOperation = "source-atop";
    
    for(const index in tempPalette){
      stencilCtx.fillStyle = tempPalette[index];
      stencilCtx.fillRect(0, index*maxCursorSize, stencil.current.width, maxCursorSize);
    }

    setPalette([...tempPalette]);
  };

  const selectAll = useCallback(() => {
    updateHistory();

    //fill selection canvas
    const ctx = selection.current.getContext("2d");
    ctx.imageSmoothingEnabled = false;
    ctx.fillRect(0,0,resolution.width,resolution.height);

    setHasSelection(true);
  }, [selection, resolution, updateHistory]);

  const deselect = useCallback(() => {
    updateHistory();

    //fill selection canvas
    const ctx = selection.current.getContext("2d");
    ctx.globalCompositeOperation = "source-over";
    ctx.imageSmoothingEnabled = false;
    ctx.clearRect(0,0,resolution.width,resolution.height);
  }, [selection,resolution, updateHistory]);

  const invert = useCallback(() => {
    updateHistory();

    //fill selection canvas
    const ctx = selection.current.getContext("2d");
    ctx.imageSmoothingEnabled = false;

    const imageData = ctx.getImageData(0,0,resolution.width,resolution.height);
    for (let i = 0; i < imageData.data.length; i+=4) {
      if ( imageData.data[i + 3] < 255) {
        imageData.data[i + 3] = 255;
      } else {
        imageData.data[i + 3] = 0;
      }
    }
    ctx.putImageData(imageData, 0, 0);
  }, [selection,resolution, updateHistory]);

  const grow = useCallback(() => {
    updateHistory();

    //fill selection canvas
    const ctx = selection.current.getContext("2d");
    ctx.globalCompositeOperation = "source-over";
    ctx.imageSmoothingEnabled = false;

    const imageData = ctx.getImageData(0, 0, resolution.width, resolution.height);
    const newImageData = new ImageData(resolution.width, resolution.height);

    for (let y = 0; y < resolution.height; y++) {
      for (let x = 0; x < resolution.width; x++) {
        const index = (y * resolution.width + x) * 4;
        if ( imageData.data[index + 3] > 0) {
          newImageData.data[index + 3] = 255;

          const topLeft = ((y-1) * resolution.width + x - 1) * 4;
          newImageData.data[topLeft + 3] = 255;

          const top = ((y-1) * resolution.width + x) * 4;
          newImageData.data[top + 3] = 255;

          const topRight = ((y-1) * resolution.width + x + 1) * 4;
          newImageData.data[topRight + 3] = 255;
          
          const right = (y * resolution.width + x + 1) * 4;
          newImageData.data[right + 3] = 255;

          const bottomRight = ((y+1) * resolution.width + x + 1) * 4;
          newImageData.data[bottomRight + 3] = 255;

          const bottom = ((y+1) * resolution.width + x) * 4;
          newImageData.data[bottom + 3] = 255;

          const bottomleft = ((y+1) * resolution.width + x - 1) * 4;
          newImageData.data[bottomleft + 3] = 255;

          const left = (y * resolution.width + x - 1) * 4;
          newImageData.data[left + 3] = 255;
        }
      } 
    }
    ctx.putImageData(newImageData, 0, 0);
  }, [selection,resolution, updateHistory]);

  const shrink = useCallback(() => {
    updateHistory();

    //fill selection canvas
    const ctx = selection.current.getContext("2d");
    ctx.globalCompositeOperation = "source-over";
    ctx.imageSmoothingEnabled = false;
    const imageData = ctx.getImageData(0, 0, resolution.width, resolution.height);

    var canvas = document.createElement('canvas');
    canvas.width = resolution.width;
    canvas.height = resolution.height;
    var newCtx = canvas.getContext('2d');
    newCtx.imageSmoothingEnabled = false;
    newCtx.drawImage(selection.current,0,0);
    var newImageData = newCtx.getImageData(0, 0, resolution.width, resolution.height);

    for (let y = 0; y < resolution.height; y++) {
      for (let x = 0; x < resolution.width; x++) {
        const index = (y * resolution.width + x) * 4;
        if ( imageData.data[index + 3] < 255) {
          newImageData.data[index + 3] = 0;

          const topLeft = ((y-1) * resolution.width + x - 1) * 4;
          newImageData.data[topLeft + 3] = 0;

          const top = ((y-1) * resolution.width + x) * 4;
          newImageData.data[top + 3] = 0;

          const topRight = ((y-1) * resolution.width + x + 1) * 4;
          newImageData.data[topRight + 3] = 0;
          
          const right = (y * resolution.width + x + 1) * 4;
          newImageData.data[right + 3] = 0;

          const bottomRight = ((y+1) * resolution.width + x + 1) * 4;
          newImageData.data[bottomRight + 3] = 0;

          const bottom = ((y+1) * resolution.width + x) * 4;
          newImageData.data[bottom + 3] = 0;

          const bottomleft = ((y+1) * resolution.width + x - 1) * 4;
          newImageData.data[bottomleft + 3] = 0;

          const left = (y * resolution.width + x - 1) * 4;
          newImageData.data[left + 3] = 0;
        } else if (x === 0 || x === resolution.width-1 || y === 0 || y === resolution.height-1){
          newImageData.data[index + 3] = 0;
        }
      } 
    }
    ctx.putImageData(newImageData, 0, 0);
  }, [selection,resolution, updateHistory]);

  const deleteSelection = useCallback(() => {
    updateHistory();

    const ctx = layers[selectedLayer].ref.current.getContext("2d");
    ctx.globalCompositeOperation = "destination-out";
    ctx.drawImage(selection.current, -layers[selectedLayer].x + offset.x,  -layers[selectedLayer].y + offset.y);
    ctx.globalCompositeOperation = "source-over";
  }, [selection,offset,layers,selectedLayer, updateHistory]);

  useEffect(() => {
    if(selectAllNow > 0){
      selectAll();
    }
  }, [selectAllNow]);

  useEffect(() => {
    if(deselectNow > 0){
      deselect();
    }
  }, [deselectNow]);

  useEffect(() => {
    if(invertNow > 0){
      invert();
    }
  }, [invertNow]);

  useEffect(() => {
    if(growNow > 0){
      grow();
    }
  }, [growNow]);

  useEffect(() => {
    if(shrinkNow > 0){
      shrink();
    }
  }, [shrinkNow]);

  useEffect(() => {
    if(deleteSelectionNow > 0){
      deleteSelection();
    }
  }, [deleteSelectionNow]);

  const layerSelect = useCallback((index) => {
    updateHistory();

    const layer = layers[index];
    const layerCanvas = layer.ref.current;

    const ctx = selection.current.getContext("2d");
    ctx.imageSmoothingEnabled = false;
    ctx.clearRect(0,0,resolution.width, resolution.height)
    ctx.drawImage(layerCanvas, layer.x - offset.x, layer.y - offset.y);
  }, [selection, resolution,offset,layers, updateHistory]);

  const layerSelectAdd = useCallback((index) => {
    updateHistory();

    const layer = layers[index];
    const layerCanvas = layer.ref.current;

    const ctx = selection.current.getContext("2d");
    ctx.imageSmoothingEnabled = false;
    ctx.drawImage(layerCanvas, layer.x - offset.x, layer.y - offset.y);
  }, [selection,offset,layers, updateHistory]);

  const layerSelectRemove = useCallback((index) => {
    updateHistory();

    const layer = layers[index];
    const layerCanvas = layer.ref.current;

    const ctx = selection.current.getContext("2d");
    ctx.imageSmoothingEnabled = false;
    ctx.globalCompositeOperation = 'destination-out';
    ctx.drawImage(layerCanvas, layer.x - offset.x, layer.y - offset.y);
    ctx.globalCompositeOperation = 'source-over';
  }, [selection,offset,layers, updateHistory]);

  const layerSelectIn = useCallback((index) => {
    updateHistory();

    const layer = layers[index];
    const layerCanvas = layer.ref.current;

    const ctx = selection.current.getContext("2d");
    ctx.imageSmoothingEnabled = false;
    ctx.globalCompositeOperation = 'source-in';
    ctx.drawImage(layerCanvas, layer.x - offset.x, layer.y - offset.y);
    ctx.globalCompositeOperation = 'source-over';
  }, [selection,offset,layers, updateHistory]);

  const swatchSelect = useCallback((swatch) => {
    updateHistory();

    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = resolution.width;
    tempCanvas.height = resolution.height;
    const tempCtx = tempCanvas.getContext("2d");
    tempCtx.imageSmoothingEnabled = false;
    
    //merge upscaled layers
    for(const layer of layers){
      if(layer.visible && layer.type === "Canvas"){
        tempCtx.drawImage(
          layer.ref.current,
          layer.x - offset.x,
          layer.y - offset.y,
          layer.ref.current.width,
          layer.ref.current.height
        );
      }
    }
    
    const tempImageData = tempCtx.getImageData(0,0,resolution.width,resolution.height);
    const imageData = tempCtx.getImageData(0,0,resolution.width,resolution.height);

    const selectionCtx = selection.current.getContext("2d");
    selectionCtx.imageSmoothingEnabled = false;
    
    if(swatch === 32){
      for(let i = 0; i < imageData.data.length; i += 4){
        if(imageData.data[i + 3] < 255){
          tempImageData.data[i + 3] = 255;
        } else {
          tempImageData.data[i + 3] = 0;
        }
      }
    } else {
      const rgbColor = chroma(palette[swatch]).rgb()
      for(let i = 0; i < imageData.data.length; i += 4){
        if(
          imageData.data[i] === rgbColor[0] && 
          imageData.data[i + 1] === rgbColor[1] &&
          imageData.data[i + 2] === rgbColor[2] &&
          imageData.data[i + 3] === 255
        ) {
          tempImageData.data[i + 3] = 255;
        } else {
          tempImageData.data[i + 3] = 0;
        }
      }
    }
    selectionCtx.putImageData(tempImageData, 0, 0);
  }, [layers, selection, resolution, offset, layers, selectedLayer, palette, updateHistory]);

  const swatchSelectAdd = useCallback((swatch) => {
    updateHistory();

    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = resolution.width;
    tempCanvas.height = resolution.height;
    const tempCtx = tempCanvas.getContext("2d");
    tempCtx.imageSmoothingEnabled = false;
    
    //merge upscaled layers
    for(const layer of layers){
      if(layer.visible && layer.type === "Canvas"){
        tempCtx.drawImage(
          layer.ref.current,
          layer.x - offset.x,
          layer.y - offset.y,
          layer.ref.current.width,
          layer.ref.current.height
        );
      }
    }
    
    const imageData = tempCtx.getImageData(0,0,resolution.width,resolution.height);

    const selectionCtx = selection.current.getContext("2d");
    selectionCtx.imageSmoothingEnabled = false;
    const selectionImageData = selectionCtx.getImageData(0,0,resolution.width,resolution.height);
    
    if(swatch === 32){
      for(let i = 0; i < imageData.data.length; i += 4){
        if(imageData.data[i + 3] < 255){
          selectionImageData.data[i + 3] = 255;
        }
      }
    } else {
      const rgbColor = chroma(palette[swatch]).rgb()
      for(let i = 0; i < imageData.data.length; i += 4){
        if(
          imageData.data[i] === rgbColor[0] && 
          imageData.data[i + 1] === rgbColor[1] &&
          imageData.data[i + 2] === rgbColor[2] &&
          imageData.data[i + 3] === 255
        ) {
          selectionImageData.data[i + 3] = 255;
        }
      }
    }
    selectionCtx.putImageData(selectionImageData, 0, 0);
  }, [selection,offset,layers, selectedLayer, palette, updateHistory]);

  const swatchSelectRemove = useCallback((swatch) => {
    updateHistory();

    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = resolution.width;
    tempCanvas.height = resolution.height;
    const tempCtx = tempCanvas.getContext("2d");
    tempCtx.imageSmoothingEnabled = false;
    
    //merge upscaled layers
    for(const layer of layers){
      if(layer.visible && layer.type === "Canvas"){
        tempCtx.drawImage(
          layer.ref.current,
          layer.x - offset.x,
          layer.y - offset.y,
          layer.ref.current.width,
          layer.ref.current.height
        );
      }
    }
    
    const imageData = tempCtx.getImageData(0,0,resolution.width,resolution.height);

    const selectionCtx = selection.current.getContext("2d");
    selectionCtx.imageSmoothingEnabled = false;
    const selectionImageData = selectionCtx.getImageData(0,0,resolution.width,resolution.height);
    
    if(swatch === 32){
      for(let i = 0; i < imageData.data.length; i += 4){
        if(imageData.data[i + 3] < 255){
          selectionImageData.data[i + 3] = 0;
        }
      }
    } else {
      const rgbColor = chroma(palette[swatch]).rgb()
      for(let i = 0; i < imageData.data.length; i += 4){
        if(
          imageData.data[i] === rgbColor[0] && 
          imageData.data[i + 1] === rgbColor[1] &&
          imageData.data[i + 2] === rgbColor[2] &&
          imageData.data[i + 3] === 255
        ) {
          selectionImageData.data[i + 3] = 0;
        } 
      }
    }
    selectionCtx.putImageData(selectionImageData, 0, 0);
  }, [selection,offset,layers, selectedLayer, palette, updateHistory]);

  const swatchSelectIn = useCallback((swatch) => {
    updateHistory();

    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = resolution.width;
    tempCanvas.height = resolution.height;
    const tempCtx = tempCanvas.getContext("2d");
    tempCtx.imageSmoothingEnabled = false;
    
    //merge upscaled layers
    for(const layer of layers){
      if(layer.visible && layer.type === "Canvas"){
        tempCtx.drawImage(
          layer.ref.current,
          layer.x - offset.x,
          layer.y - offset.y,
          layer.ref.current.width,
          layer.ref.current.height
        );
      }
    }
    
    const tempImageData = tempCtx.getImageData(0,0,resolution.width,resolution.height);
    const imageData = tempCtx.getImageData(0,0,resolution.width,resolution.height);

    const selectionCtx = selection.current.getContext("2d");
    selectionCtx.imageSmoothingEnabled = false;
    
    if(swatch === 32){
      for(let i = 0; i < imageData.data.length; i += 4){
        if(imageData.data[i + 3] < 255){
          tempImageData.data[i + 3] = 255;
        }
      }
    } else {
      const rgbColor = chroma(palette[swatch]).rgb()
      for(let i = 0; i < imageData.data.length; i += 4){
        if(
          imageData.data[i] === rgbColor[0] && 
          imageData.data[i + 1] === rgbColor[1] &&
          imageData.data[i + 2] === rgbColor[2] &&
          imageData.data[i + 3] === 255
        ) {
          tempImageData.data[i + 3] = 255;
        } else {
          tempImageData.data[i + 3] = 0;
        }
      }
    }
    tempCtx.putImageData(tempImageData, 0, 0);
    selectionCtx.globalCompositeOperation = "source-in";
    selectionCtx.drawImage(tempCanvas, 0, 0);
    selectionCtx.globalCompositeOperation = "source-over";
  }, [selection,offset,layers, selectedLayer, palette, updateHistory]);

  const copy = useCallback(() => {
    let clipboardData;
    if(layers[selectedLayer].type === "Canvas"){

      let left = 0;
      let top = 0;
      let right = resolution.width;
      let bottom = resolution.height;
      if(hasSelection){ //get offset coordinates
        const paths = findPathsInCanvas(selection.current);
        const joinedEdges = [];
        for (const path of paths) for (const edge of path) joinedEdges.push(edge);
        const sortX = [...joinedEdges].sort((a, b) => { return a.x1 - b.x1; })
        const sortY = [...joinedEdges].sort((a, b) => { return a.y1 - b.y1; });
        left = sortX[0].x1;
        top = sortY[0].y1;
        right = sortX[sortX.length-1].x1;
        bottom = sortY[sortY.length-1].y1;
      }
  
      //get width and height
      const width = right - left;
      const height = bottom - top;
      
      //add selection to a temp canvas
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.globalCompositeOperation = "source-over";
      ctx.imageSmoothingEnabled = false;
  
      if(hasSelection){ //draw selection and set next draw to be within that selection
        ctx.drawImage(selection.current, left, top, width, height, 0, 0, width, height);
        ctx.globalCompositeOperation = "source-in";
      }
  
      //draw layer within selection
      const layer = layers[selectedLayer];
      const layerCanvas = layer.ref.current;
      ctx.drawImage(
        layerCanvas,
        offset.x - layer.x + left,
        offset.y - layer.y + top,
        width,
        height,
        0,
        0,
        width,
        height
      );
  
      //add data to clipboard
      clipboardData = {
        image: canvas.toDataURL('image/png'),
        coordinates: {
          x: left,
          y: top,
        },
      };
    } else {
      clipboardData = {...layers[selectedLayer]};
    }
    
    // Step 2: Use navigator.clipboard.write() to copy the combined data to the clipboard
    navigator.clipboard.writeText(JSON.stringify(clipboardData))
      .then(() => {
        console.log('Layer copied to clipboard successfully.');
      })
      .catch((error) => {
        console.error('Unable to copy layer to clipboard:', error);
      });

    setClipboard(JSON.stringify(clipboardData));
  }, [resolution, hasSelection, selection, layers, selectedLayer, offset, updateHistory]);

  useEffect(() => {
    if(copyNow > 0){
      copy();
    }
  }, [copyNow]);

  useEffect(() => {
    if(cutNow > 0){
      copy();
      deleteSelection();
    }
  }, [cutNow]);

  const paste = useCallback(() => {
    if(!navigator.clipboard.readText){
      if(!clipboard) return;

      try {
        const clipboardData = JSON.parse(clipboard);
      
        if(clipboardData && clipboardData.type){            
          updateHistory();
          
          setLayers(prevLayers => {
            const newLayers = [
              ...prevLayers.slice(0, selectedLayer + 1),
              { 
                ...clipboardData
              },
              ...prevLayers.slice(selectedLayer + 1)
            ];
            return newLayers
          });

          setSelectedLayer(selectedLayer + 1);
          setSelectedLayers([selectedLayer + 1]);
        } else if (clipboardData && clipboardData.image && clipboardData.coordinates) {
          const pasteImage = new Image();
          pasteImage.onload = () => {
            if(layers.length >= maxLayerCount) return window.alert(`Max layer count reached. (${maxLayerCount})`);
            
            updateHistory();

            //get coordinate based on center of view and paste resolution. (not taking offset into account)
            let x = Math.round(viewportCenter.x) - Math.round(pasteImage.width/2);
            let y = Math.round(viewportCenter.y) - Math.round(pasteImage.height/2);

            //constrain coordinate to max layer size
            if(x + pasteImage.width > maxLayerSize) x = maxLayerSize - pasteImage.width;
            if(x < resolution.width - maxLayerSize) x = resolution.width - maxLayerSize;
            if(y + pasteImage.height > maxLayerSize) y = maxLayerSize - pasteImage.height;
            if(y < resolution.height - maxLayerSize) y = resolution.height - maxLayerSize;

            //initialize boundary variables
            let left = 0
            let top = 0;
            const right = x + pasteImage.width;
            const bottom = y + pasteImage.height;

            //apply correct boundaries and coordinates if left or above canvas bounds
            if(x < 0){
              left = x;
              x = 0;
            }
            if(y < 0){
              top = y;
              y = 0;
            }

            //get width and height of new layer
            let width = Math.max(resolution.width - left, right - left);
            let height = Math.max(resolution.height - top, bottom - top);

            // Create the first layer dynamically
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.imageSmoothingEnabled = false;

            // Draw the pasted image onto the destination canvas at the specified coordinates
            ctx.drawImage(pasteImage, x, y);

            //quantize image    
            const rgbPalette = [];
            for(const color of palette){
              if(color !== '') rgbPalette.push(chroma(color).rgb());
            }
            const q = new RgbQuant({colors: 32, palette: rgbPalette});
            q.sample(canvas);
            const u8  = q.reduce(canvas, true);
            const clamped = new Uint8ClampedArray(u8);
            const imgData = new ImageData(clamped, width, height);
            ctx.putImageData(imgData,0,0);

            // add new layer at index (coordinate offset gets applied here)
            const newLayers = [
              ...layers.slice(0, selectedLayer + 1),
              { 
                type: "Canvas",
                id: generateId(8),
                ref: React.createRef(),
                visible: true,
                name: `Layer ${layersCreated + 1}`,
                x: offset.x + left,
                y: offset.y + top
              },
              ...layers.slice(selectedLayer + 1)
            ];
            //set ref for new layer
            newLayers[selectedLayer + 1].ref.current = canvas;

            setLayers([...newLayers]);
            setSelectedLayer(selectedLayer + 1);
            setSelectedLayers([selectedLayer + 1]);
          };
          pasteImage.src = clipboardData.image;
        }
      } catch (error) {
        console.error('Invalid JSON in clipboard:', error);
        // Handle the case where the clipboard content is not valid JSON
      }
    } else {
      navigator.clipboard.readText().then((clipboardText) => {
        try {
          const clipboardData = JSON.parse(clipboardText);
        
          if(clipboardData && clipboardData.type){            
            updateHistory();

            setLayers(prevLayers => {
              const newLayers = [
                ...prevLayers.slice(0, selectedLayer + 1),
                { 
                  ...clipboardData
                },
                ...prevLayers.slice(selectedLayer + 1)
              ];
              return newLayers
            });

            setSelectedLayer(selectedLayer + 1);
            setSelectedLayers([selectedLayer + 1]);
          } else if (clipboardData && clipboardData.image && clipboardData.coordinates) {
            const pasteImage = new Image();
            pasteImage.onload = () => {
              if(layers.length >= maxLayerCount) return window.alert(`Max layer count reached. (${maxLayerCount})`);
              
              updateHistory();
  
              //get coordinate based on center of view and paste resolution. (not taking offset into account)
              let x = Math.round(viewportCenter.x) - Math.round(pasteImage.width/2);
              let y = Math.round(viewportCenter.y) - Math.round(pasteImage.height/2);
  
              //constrain coordinate to max layer size
              if(x + pasteImage.width > maxLayerSize) x = maxLayerSize - pasteImage.width;
              if(x < resolution.width - maxLayerSize) x = resolution.width - maxLayerSize;
              if(y + pasteImage.height > maxLayerSize) y = maxLayerSize - pasteImage.height;
              if(y < resolution.height - maxLayerSize) y = resolution.height - maxLayerSize;
  
              //initialize boundary variables
              let left = 0
              let top = 0;
              const right = x + pasteImage.width;
              const bottom = y + pasteImage.height;
  
              //apply correct boundaries and coordinates if left or above canvas bounds
              if(x < 0){
                left = x;
                x = 0;
              }
              if(y < 0){
                top = y;
                y = 0;
              }
  
              //get width and height of new layer
              let width = Math.max(resolution.width - left, right - left);
              let height = Math.max(resolution.height - top, bottom - top);
    
              // Create the first layer dynamically
              const canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext("2d");
              ctx.imageSmoothingEnabled = false;
    
              // Draw the pasted image onto the destination canvas at the specified coordinates
              ctx.drawImage(pasteImage, x, y);
  
              //quantize image    
              const rgbPalette = [];
              for(const color of palette){
                if(color !== '') rgbPalette.push(chroma(color).rgb());
              }
              const q = new RgbQuant({colors: 32, palette: rgbPalette});
              q.sample(canvas);
              const u8  = q.reduce(canvas, true);
              const clamped = new Uint8ClampedArray(u8);
              const imgData = new ImageData(clamped, width, height);
              ctx.putImageData(imgData,0,0);
    
              // add new layer at index (coordinate offset gets applied here)
              const newLayers = [
                ...layers.slice(0, selectedLayer + 1),
                { 
                  type: "Canvas",
                  id: generateId(8),
                  ref: React.createRef(),
                  visible: true,
                  name: `Layer ${layersCreated + 1}`,
                  x: offset.x + left,
                  y: offset.y + top
                },
                ...layers.slice(selectedLayer + 1)
              ];
              //set ref for new layer
              newLayers[selectedLayer + 1].ref.current = canvas;
    
              setLayers([...newLayers]);
              setSelectedLayer(selectedLayer + 1);
              setSelectedLayers([selectedLayer + 1]);
            };
            pasteImage.src = clipboardData.image;
          }
        } catch (error) {
          console.error('Invalid JSON in clipboard:', error);
          // Handle the case where the clipboard content is not valid JSON
        }
      });
    }
  }, [clipboard, selection, layers, selectedLayer, viewportCenter, resolution, offset, updateHistory]);

  useEffect(() => {
    if(pasteNow > 0){
      paste();
    }
  }, [pasteNow]);

  const pasteInPlace = useCallback(() => {
    if(!navigator.clipboard.readText){
      if(!clipboard) return;

      try {
        const clipboardData = JSON.parse(clipboard);
      
        if(clipboardData && clipboardData.type){           
          updateHistory();

          setLayers(prevLayers => {
            const newLayers = [
              ...prevLayers.slice(0, selectedLayer + 1),
              { 
                ...clipboardData
              },
              ...prevLayers.slice(selectedLayer + 1)
            ];
            return newLayers
          });

          setLayers([...newLayers]);
          setSelectedLayer(selectedLayer + 1);
          setSelectedLayers([selectedLayer + 1]);
        } else if (clipboardData && clipboardData.image && clipboardData.coordinates) {
          const pasteImage = new Image();
          pasteImage.onload = () => {
            if(layers.length >= maxLayerCount) return window.alert(`Max layer count reached. (${maxLayerCount})`);
            
            updateHistory();
  
            const x = clipboardData.coordinates.x;
            const y = clipboardData.coordinates.y;
            const width = Math.max(x + pasteImage.width, resolution.width);
            const height = Math.max(y + pasteImage.height, resolution.height);
  
            // Create the first layer dynamically
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.imageSmoothingEnabled = false;
  
            // Draw the pasted image onto the destination canvas at the specified coordinates
            ctx.drawImage(pasteImage, x, y);

            //quantize image    
            const rgbPalette = [];
            for(const color of palette){
              if(color !== '') rgbPalette.push(chroma(color).rgb());
            }
            const q = new RgbQuant({colors: 32, palette: rgbPalette});
            q.sample(canvas);
            const u8  = q.reduce(canvas, true);
            const clamped = new Uint8ClampedArray(u8);
            const imgData = new ImageData(clamped, width, height);
            ctx.putImageData(imgData,0,0);
  
            // add new layer at index
            const newLayers = [
              ...layers.slice(0, selectedLayer + 1),
              { 
                type: "Canvas",
                id: generateId(8),
                ref: React.createRef(),
                visible: true,
                name: `Layer ${layersCreated + 1}`,
                x: offset.x,
                y: offset.y
              },
              ...layers.slice(selectedLayer + 1)
            ];
            //set ref for new layer
            newLayers[selectedLayer + 1].ref.current = canvas;
  
            setLayers([...newLayers]);
            setSelectedLayer(selectedLayer + 1);
            setSelectedLayers([selectedLayer + 1]);
            setLayersCreated((prevValue) => prevValue + 1);
          };
          pasteImage.src = clipboardData.image;
        }
      } catch (error) {
        console.error('Invalid JSON in clipboard:', error);
        // Handle the case where the clipboard content is not valid JSON
      }
    } else {
      navigator.clipboard.readText().then((clipboardText) => {
        try {
          const clipboardData = JSON.parse(clipboardText);
        
          if(clipboardData && clipboardData.type){         
            updateHistory();

            setLayers(prevLayers => {
              const newLayers = [
                ...prevLayers.slice(0, selectedLayer + 1),
                { 
                  ...clipboardData
                },
                ...prevLayers.slice(selectedLayer + 1)
              ];
              return newLayers
            });
  
            setLayers([...newLayers]);
            setSelectedLayer(selectedLayer + 1);
            setSelectedLayers([selectedLayer + 1]);
          } else if (clipboardData && clipboardData.image && clipboardData.coordinates) {
            const pasteImage = new Image();
            pasteImage.onload = () => {
              if(layers.length >= maxLayerCount) return window.alert(`Max layer count reached. (${maxLayerCount})`);
              
              updateHistory();
    
              const x = clipboardData.coordinates.x;
              const y = clipboardData.coordinates.y;
              const width = Math.max(x + pasteImage.width, resolution.width);
              const height = Math.max(y + pasteImage.height, resolution.height);
    
              // Create the first layer dynamically
              const canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext("2d");
              ctx.imageSmoothingEnabled = false;
    
              // Draw the pasted image onto the destination canvas at the specified coordinates
              ctx.drawImage(pasteImage, x, y);
  
              //quantize image    
              const rgbPalette = [];
              for(const color of palette){
                  if(color !== '') rgbPalette.push(chroma(color).rgb());
              }
              const q = new RgbQuant({colors: 32, palette: rgbPalette});
              q.sample(canvas);
              const u8  = q.reduce(canvas, true);
              const clamped = new Uint8ClampedArray(u8);
              const imgData = new ImageData(clamped, width, height);
              ctx.putImageData(imgData,0,0);
    
              // add new layer at index
              const newLayers = [
                ...layers.slice(0, selectedLayer + 1),
                { 
                  type: "Canvas",
                  id: generateId(8),
                  ref: React.createRef(),
                  visible: true,
                  name: `Layer ${layersCreated + 1}`,
                  x: offset.x,
                  y: offset.y
                },
                ...layers.slice(selectedLayer + 1)
              ];
              //set ref for new layer
              newLayers[selectedLayer + 1].ref.current = canvas;
    
              setLayers([...newLayers]);
              setSelectedLayer(selectedLayer + 1);
              setSelectedLayers([selectedLayer + 1]);
              setLayersCreated((prevValue) => prevValue + 1);
            };
            pasteImage.src = clipboardData.image;
          }
        } catch (error) {
          console.error('Invalid JSON in clipboard:', error);
          // Handle the case where the clipboard content is not valid JSON
        }
      });
    }
  }, [clipboard, selection, layers, selectedLayer, resolution, offset, updateHistory]);

  useEffect(() => {
    if(pasteInPlaceNow > 0){
      pasteInPlace();
    }
  }, [pasteInPlaceNow]);

  const flipHorizontal = useCallback(() => {
    updateHistory();

    const newLayers = layers.map(layer => {
      switch (layer.type) {
        case "Canvas":
          const x = offset.x + resolution.width - layer.x - layer.ref.current.width;
    
          const canvas = document.createElement('canvas');
          canvas.width = layer.ref.current.width;
          canvas.height = layer.ref.current.height;
          const ctx = canvas.getContext('2d');
          ctx.imageSmoothingEnabled = false;
          ctx.scale(-1, 1);
          ctx.drawImage(layer.ref.current, -canvas.width, 0, canvas.width, canvas.height);
    
          //update offscreen canvas
          const ctx2 = layer.ref.current.getContext("2d");
          ctx2.imageSmoothingEnabled = false;
          ctx2.clearRect(0, 0, layer.ref.current.width, layer.ref.current.height);
          ctx2.drawImage(canvas, offset.x, 0);

          return {
            ...layer,
            x,
          };
          break;
        case "Line":
          return {
            ...layer,
            startPoint: {x: resolution.width - layer.startPoint.x, y: layer.startPoint.y},
            endPoint: {x: resolution.width - layer.endPoint.x, y: layer.endPoint.y},
          }
          break;
        case "OnePoint":
          return {
            ...layer,
            point: {x: resolution.width - layer.point.x, y: layer.point.y},
          }
          break;
        case "ThreePoint":
          return {
            ...layer,
            center: {x: resolution.width - layer.center.x, y: layer.center.y},
            angle: -layer.angle,
            tilt: -layer.tilt,
          }
          break;
        case "Grid":
          return {
            ...layer,
            corner: {x: resolution.width - layer.corner.x, y: layer.corner.y},
            angle: 180 - layer.angle,
            height: -layer.height,
          }
          break;
        case "Isometric":
          return {
            ...layer,
            corner: {x: resolution.width - layer.corner.x, y: layer.corner.y},
            angle: 180 - layer.angle2,
            angle2: 180 - layer.angle,
            width: layer.height,
            height: layer.width,
          }
          break;
        case "Ellipse":
          return {
            ...layer,
            center: {x: resolution.width - layer.center.x, y: layer.center.y},
            angle: 180 - layer.angle,
          }
          break;
        default:
          return layer;
      }
    });
  
    // Update the state with the new layers
    setLayers(newLayers);

    const selectionCanvas = document.createElement('canvas');
    selectionCanvas.width = resolution.width;
    selectionCanvas.height = resolution.height;
    const ctx = selectionCanvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.scale(-1, 1);
    ctx.drawImage(selection.current, -selectionCanvas.width, 0, selectionCanvas.width, selectionCanvas.height);

    const selectionCtx = selection.current.getContext("2d");
    selectionCtx.imageSmoothingEnabled = false;
    selectionCtx.clearRect(0,0,resolution.width,resolution.height);
    selectionCtx.drawImage(selectionCanvas,0,0);
  }, [offset, resolution, layers, selection, updateHistory]);

  const flipVertical = useCallback(() => {
    updateHistory();
    
    const newLayers = layers.map(layer => {
      switch (layer.type) {
        case "Canvas":
          const y = offset.y + resolution.height - layer.y - layer.ref.current.height;
    
          const canvas = document.createElement('canvas');
          canvas.width = layer.ref.current.width;
          canvas.height = layer.ref.current.height;
          const ctx = canvas.getContext('2d');
          ctx.imageSmoothingEnabled = false;
          ctx.scale(1, -1);
          ctx.drawImage(layer.ref.current, 0, -canvas.height, canvas.width, canvas.height);
    
          //update offscreen canvas
          const ctx2 = layer.ref.current.getContext("2d");
          ctx2.imageSmoothingEnabled = false;
          ctx2.clearRect(0, 0, layer.ref.current.width, layer.ref.current.height);
          ctx2.drawImage(canvas, 0, offset.y);

          return {
            ...layer,
            y,
          };
          break;
        case "Line":
          return {
            ...layer,
            startPoint: {x: layer.startPoint.x, y: resolution.height - layer.startPoint.y},
            endPoint: {x: layer.endPoint.x, y: resolution.height - layer.endPoint.y},
          }
          break;
        case "OnePoint":
          return {
            ...layer,
            point: {x: layer.point.x, y: resolution.height - layer.point.y},
          }
          break;
        case "ThreePoint":
          return {
            ...layer,
            center: {x: layer.center.x, y: resolution.height - layer.center.y},
            angle2: -layer.angle2,
            tilt: -layer.tilt,
          }
          break;
        case "Grid":
          return {
            ...layer,
            corner: {x: layer.corner.x, y: resolution.height - layer.corner.y},
            angle:  180 - layer.angle,
            width: -layer.width,
          }
          break;
        case "Isometric":
          return {
            ...layer,
            corner: {x: layer.corner.x, y: resolution.height - layer.corner.y},
            angle: -layer.angle,
            angle2: -layer.angle2,
          }
          break;
        case "Ellipse":
          return {
            ...layer,
            center: {x: layer.center.x, y: resolution.height - layer.center.y},
            angle: 180 - layer.angle,
          }
          break;
        default:
          return layer;
      }
    });
  
    // Update the state with the new layers
    setLayers(newLayers);

    const selectionCanvas = document.createElement('canvas');
    selectionCanvas.width = resolution.width;
    selectionCanvas.height = resolution.height;
    const ctx = selectionCanvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.scale(1, -1);
    ctx.drawImage(selection.current, 0, -selectionCanvas.height, selectionCanvas.width, selectionCanvas.height);

    const selectionCtx = selection.current.getContext("2d");
    selectionCtx.imageSmoothingEnabled = false;
    selectionCtx.clearRect(0,0,resolution.width,resolution.height);
    selectionCtx.drawImage(selectionCanvas,0,0);
  }, [offset, resolution, layers, selection, updateHistory]);

  const rotateCW = useCallback(() => {
    updateHistory();

    const centerX = Math.round(resolution.width/2) + offset.x;
    const centerY = Math.round(resolution.height/2) + offset.y;
    
    const newLayers = layers.map(layer => {
      switch (layer.type) {
        case "Canvas":
          const layerCenterX = layer.x + layer.ref.current.width / 2;
          const layerCenterY = layer.y + layer.ref.current.height / 2;
      
          const newCenterX = -layerCenterY + centerY + centerX;
          const newCenterY = layerCenterX - centerX + centerY;
      
          const newLayerX = newCenterX - layer.ref.current.height / 2;
          const newLayerY = newCenterY - layer.ref.current.width / 2;
          
          const canvas = document.createElement('canvas');
          canvas.width = layer.ref.current.height;
          canvas.height = layer.ref.current.width;
          const ctx = canvas.getContext('2d');
          ctx.imageSmoothingEnabled = false;
          ctx.rotate(90 * Math.PI / 180);
          ctx.drawImage(layer.ref.current, 0, -canvas.width, canvas.height, canvas.width);
    
          //update offscreen canvas
          const ctx2 = layer.ref.current.getContext("2d");
          ctx2.imageSmoothingEnabled = false;
          layer.ref.current.width = canvas.width;
          layer.ref.current.height = canvas.height;
          ctx2.drawImage(canvas, 0, 0);

          return {
            ...layer,
            x: newLayerX,
            y: newLayerY,
          };
          break;
        case "Line":
          return {
            ...layer,
            startPoint: {x: resolution.height - layer.startPoint.y, y: layer.startPoint.x},
            endPoint: {x: resolution.height - layer.endPoint.y, y: layer.endPoint.x},
          }
          break;
        case "OnePoint":
          return {
            ...layer,
            point: {x: resolution.height - layer.point.y, y: layer.point.x},
          }
          break;
        case "ThreePoint":
          const newTilt = layer.tilt - 90;
          const newAngle = layer.angle;
          const newAngle2 = layer.angle2;
          if(newTilt < -90){
            newTilt += 180;
            newAngle = -layer.angle;
            newAngle2 = -layer.angle2;
          }
          return {
            ...layer,
            center: {x: resolution.height - layer.center.y, y: layer.center.x},
            angle: newAngle,
            angle2: newAngle2,
            tilt: newTilt,
          }
          break;
        case "Grid":
          return {
            ...layer,
            corner: {x: resolution.height -  layer.corner.y, y: layer.corner.x},
            angle:  layer.angle - 90,
          }
          break;
        case "Isometric":
          return {
            ...layer,
            corner: {x: resolution.height -  layer.corner.y, y: layer.corner.x},
            angle: layer.angle - 90,
            angle2: layer.angle2 - 90,
          }
          break;
        case "Ellipse":
          return {
            ...layer,
            center: {x: resolution.height - layer.center.y, y: layer.center.x},
            angle: layer.angle - 90,
          }
          break;
        default:
          return layer;
      }
    });
  
    // Update the state with the new layers
    setLayers(newLayers);

    const selectionCanvas = document.createElement('canvas');
    selectionCanvas.width = resolution.height;
    selectionCanvas.height = resolution.width;
    const ctx = selectionCanvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.rotate(90 * Math.PI / 180);
    ctx.drawImage(selection.current, 0, -selectionCanvas.width, selectionCanvas.height, selectionCanvas.width);

    const selectionCtx = selection.current.getContext("2d");
    selectionCtx.imageSmoothingEnabled = false;
    selection.current.width = resolution.height;
    selection.current.height = resolution.width;
    selectionCtx.drawImage(selectionCanvas,0,0);

    setOffset({
      x: centerX - Math.floor(resolution.height/2),
      y: centerY - Math.ceil(resolution.width/2)
    });
    setResolution({ width: resolution.height, height: resolution.width });

    setCrop(null);
  }, [offset, resolution, layers, selection, updateHistory]);

  const rotateCCW = useCallback(() => {
    updateHistory();

    const centerX = Math.round(resolution.width/2) + offset.x;
    const centerY = Math.round(resolution.height/2) + offset.y;
    
    const newLayers = layers.map(layer => {
      switch (layer.type) {
        case "Canvas":
          const layerCenterX = layer.x + layer.ref.current.width / 2;
          const layerCenterY = layer.y + layer.ref.current.height / 2;
      
          const newCenterX = layerCenterY - centerY + centerX;
          const newCenterY = -layerCenterX + centerX + centerY;
      
          const newLayerX = newCenterX - layer.ref.current.height / 2;
          const newLayerY = newCenterY - layer.ref.current.width / 2;
          
          const canvas = document.createElement('canvas');
          canvas.width = layer.ref.current.height;
          canvas.height = layer.ref.current.width;
          const ctx = canvas.getContext('2d');
          ctx.imageSmoothingEnabled = false;
          ctx.rotate(-90 * Math.PI / 180);
          ctx.drawImage(layer.ref.current, -canvas.height, 0, canvas.height, canvas.width);
    
          //update offscreen canvas
          const ctx2 = layer.ref.current.getContext("2d");
          ctx2.imageSmoothingEnabled = false;
          layer.ref.current.width = canvas.width;
          layer.ref.current.height = canvas.height;
          ctx2.drawImage(canvas, 0, 0);

          return {
            ...layer,
            x: newLayerX,
            y: newLayerY,
          };
          break;
        case "Line":
          return {
            ...layer,
            startPoint: {x: layer.startPoint.y, y: resolution.width - layer.startPoint.x},
            endPoint: {x: layer.endPoint.y, y: resolution.width - layer.endPoint.x},
          }
          break;
        case "OnePoint":
          return {
            ...layer,
            point: {x: layer.point.y, y: resolution.width - layer.point.x},
          }
          break;
        case "ThreePoint":
          const newTilt = layer.tilt + 90;
          const newAngle = layer.angle;
          const newAngle2 = layer.angle2;
          if(newTilt > 90){
            newTilt -= 180;
            newAngle = -layer.angle;
            newAngle2 = -layer.angle2;
          }
          return {
            ...layer,
            center: {x: layer.center.y, y: resolution.width - layer.center.x},
            angle: newAngle,
            angle2: newAngle2,
            tilt: newTilt,
          }
          break;
        case "Grid":
          return {
            ...layer,
            corner: {x: layer.corner.y, y: resolution.width -  layer.corner.x},
            angle:  layer.angle + 90,
          }
          break;
        case "Isometric":
          return {
            ...layer,
            corner: {x: layer.corner.y, y: resolution.width - layer.corner.x},
            angle: layer.angle + 90,
            angle2: layer.angle2 + 90,
          }
          break;
        case "Ellipse":
          return {
            ...layer,
            center: {x: layer.center.y, y: resolution.width - layer.center.x},
            angle: layer.angle + 90,
          }
          break;
        default:
          return layer;
      }
    });
  
    // Update the state with the new layers
    setLayers(newLayers);

    const selectionCanvas = document.createElement('canvas');
    selectionCanvas.width = resolution.height;
    selectionCanvas.height = resolution.width;
    const ctx = selectionCanvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.rotate(-90 * Math.PI / 180);
    ctx.drawImage(selection.current, -selectionCanvas.width, 0, selectionCanvas.height, selectionCanvas.width);

    const selectionCtx = selection.current.getContext("2d");
    selectionCtx.imageSmoothingEnabled = false;
    selection.current.width = resolution.height;
    selection.current.height = resolution.width;
    selectionCtx.drawImage(selectionCanvas,0,0);

    setOffset({
      x: centerX - Math.ceil(resolution.height/2),
      y: centerY - Math.floor(resolution.width/2)
    });
    setResolution({ width: resolution.height, height: resolution.width });

    setCrop(null);
  }, [offset, resolution, layers, selection, updateHistory]);

  useEffect(() => {
    if(flipHorizontalNow > 0){
      flipHorizontal();
    }
  }, [flipHorizontalNow]);

  useEffect(() => {
    if(flipVerticalNow > 0){
      flipVertical();
    }
  }, [flipVerticalNow]);

  useEffect(() => {
    if(rotateCWNow > 0){
      rotateCW();
    }
  }, [rotateCWNow]);

  useEffect(() => {
    if(rotateCCWNow > 0){
      rotateCCW();
    }
  }, [rotateCCWNow]);

  const undo = useCallback(() => {
    if (transform){
      setTransformVersion((prevValue) => prevValue + 1);
    } else if(history[0] && version > history[0].version) {
      if(history[history.length - 1] && version >= history[history.length-1].version){
        updateHistory();
      }

      const change = history.find((el) => el.version === version);
      if (change) {
        setResolution(change.resolution);
        setMaxUpscale( Math.floor( 1024 / Math.max( change.resolution.width, change.resolution.height ) ) );
        setOffset(change.offset);
        oldResolution.current = change.resolution;
        oldOffset.current = change.offset;

        setSelectedLayer(change.selectedLayer);
        setSelectedLayers(change.selectedLayers);
        setMagnetLayer(change.magnetLayer);
        setLayers([...change.layers]);
        setPalette([...change.palette]);
        setVersion(change.version-1);

        selection.current.width = change.selection.current.width;
        selection.current.height = change.selection.current.height;
        const selectionCtx = selection.current.getContext("2d");
        selectionCtx.imageSmoothingEnabled = false;
        selectionCtx.globalCompositeOperation = "source-over"
        selectionCtx.drawImage(change.selection.current, 0, 0);
        
        //update stencil
        const stencilCtx = stencil.current.getContext("2d");
        stencilCtx.imageSmoothingEnabled = false;
        stencilCtx.globalCompositeOperation = "source-atop";
        
        for(const index in change.palette){
          stencilCtx.fillStyle = change.palette[index];
          stencilCtx.fillRect(0, index*maxCursorSize, stencil.current.width, maxCursorSize);
        }

        if(change.palette[selectedSwatch] === ''){
          let i = selectedSwatch - 1;
          let newSelectedSwatch;
          while(typeof newSelectedSwatch === "undefined"){
            if(i < 0) i = 31;
            if(change.palette[i]) newSelectedSwatch = i;
            i--;
          }
          setSelectedSwatch(newSelectedSwatch);
        }

        if(change.palette[secondarySwatch] === ''){
          let i = selectedSwatch - 1;
          let newSecondarySwatch;
          while(typeof newSecondarySwatch === "undefined"){
            if(i < 0) i = 31;
            if(change.palette[i]) newSelectedSwatch = i;
            i--;
          }
          setSecondarySwatch(newSelectedSwatch);
        }

        setCrop(null);

        if(selectedTool === "guide" && change.layers[change.selectedLayer].type === "Canvas") setSelectedTool(previousTool);
      }
    }
  }, [transform, history, version, selectedSwatch, secondarySwatch, previousTool, updateHistory]);

  const redo = useCallback(() => {
    if (transform){
      setTransformVersion((prevValue) => prevValue + 1);
    } else if (history[history.length - 1] && version < history[history.length - 1].version) {
      const change = history.find((el) => el.version === version + 2);
      if (change) {
        setResolution(change.resolution);
        setMaxUpscale( Math.floor( 1024 / Math.max( change.resolution.width, change.resolution.height ) ) );
        setOffset(change.offset);
        oldResolution.current = change.resolution;
        oldOffset.current = change.offset;

        setSelectedLayer(change.selectedLayer);
        setSelectedLayers(change.selectedLayers);
        setMagnetLayer(change.magnetLayer);
        setLayers([...change.layers]);
        setPalette([...change.palette]);
        setVersion(change.version-1);

        selection.current.width = change.selection.current.width;
        selection.current.height = change.selection.current.height;
        const selectionCtx = selection.current.getContext("2d");
        selectionCtx.imageSmoothingEnabled = false;
        selectionCtx.globalCompositeOperation = "source-over"
        selectionCtx.drawImage(change.selection.current, 0, 0);
        
        //update stencil
        const stencilCtx = stencil.current.getContext("2d");
        stencilCtx.imageSmoothingEnabled = false;
        stencilCtx.globalCompositeOperation = "source-atop";
        
        for(const index in change.palette){
          stencilCtx.fillStyle = change.palette[index];
          stencilCtx.fillRect(0, index*maxCursorSize, stencil.current.width, maxCursorSize);
        }

        if(change.palette[selectedSwatch] === ''){
          let i = selectedSwatch - 1;
          let newSelectedSwatch;
          while(typeof newSelectedSwatch === "undefined"){
            if(i < 0) i = 31;
            if(change.palette[i]) newSelectedSwatch = i;
            i--;
          }
          setSelectedSwatch(newSelectedSwatch);
        }

        if(change.palette[secondarySwatch] === ''){
          let i = selectedSwatch - 1;
          let newSecondarySwatch;
          while(typeof newSecondarySwatch === "undefined"){
            if(i < 0) i = 31;
            if(change.palette[i]) newSelectedSwatch = i;
            i--;
          }
          setSecondarySwatch(newSelectedSwatch);
        }

        setCrop(null);

        if(selectedTool === "guide" && change.layers[change.selectedLayer].type === "Canvas") setSelectedTool(previousTool);
      }
    }
  }, [transform, history, version, selectedSwatch, secondarySwatch, previousTool, updateHistory]);

  useEffect(() => {
    if(undoNow > 0){
      undo();
    }
  }, [undoNow]);

  useEffect(() => {
    if(redoNow > 0){
      redo();
    }
  }, [redoNow]);

  useEffect(() => {
    const handleBeforeUnload = (e) => { //prevent leave on unsaved
      if(!upToDate){
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [upToDate]);

  const loadDocument = async () => {
    console.log('loading document');
    try {
      const response = await axios.get(`${apiUrl}/api/document/${docId}`);

      const docData = response.data;
      if(!docData){
        return navigate('/404');
      }

      setDocName(docData.name);
      
      if(docData.starred) setStarred(true);
      if(!docData.autoSave) setAutoSave(false);
      if(!docData.showBorder) setShowBorder(false);
      
      setResolution({width: docData.width, height: docData.height});
      oldResolution.current = {width: docData.width, height: docData.height};
      setMaxUpscale( Math.floor( 1024 / Math.max( docData.width, docData.height ) ) );

      if(docData.offset){
        setOffset(docData.offset);
        oldOffset.current = docData.offset;
      }

      if(docData.layoutSettings){
        setWrapperWidth(docData.layoutSettings.wrapperWidth);
        setLeftWindowHeight(docData.layoutSettings.leftWindowHeight);
        setRightWindowHeight(docData.layoutSettings.rightWindowHeight);

        setWrapperWidthPortrait(docData.layoutSettings.wrapperWidthPortrait);
        setLeftWindowHeightPortrait(docData.layoutSettings.leftWindowHeightPortrait);
        setRightWindowHeightPortrait(docData.layoutSettings.rightWindowHeightPortrait);

        setTopLeftWindow(docData.layoutSettings.topLeftWindow || "toolOptions");
        if(docData.layoutSettings.topLeftWindow === "viewport") setSelectedViewport(0);

        setTopRightWindow(docData.layoutSettings.topRightWindow || "viewport");
        if(docData.layoutSettings.topRightWindow === "viewport") setSelectedViewport(1);

        setBottomLeftWindow(docData.layoutSettings.bottomLeftWindow || "layers");
        if(docData.layoutSettings.bottomLeftWindow === "viewport") setSelectedViewport(2);

        setBottomRightWindow(docData.layoutSettings.bottomRightWindow || "palette");
        if(docData.layoutSettings.bottomRightWindow === "viewport") setSelectedViewport(3);
      }

      stencil.current = document.createElement("canvas");
      stencil.current.width = summate(maxCursorSize);
      stencil.current.height = 33 * maxCursorSize;
      const stencilImage = new Image();
          
      dither.current = document.createElement("canvas");
      dither.current.width = 1156;
      dither.current.height = 68;
      const ditherImage = new Image();

      stencilImage.onload = async () => {
        console.log('Stencil Loaded.');
        previewBG.current.onload = async () => {
          console.log('Preview Background Loaded.');
          ditherImage.onload = async () => {
            console.log('Dither Loaded.');
            try{
              const stencilCtx = stencil.current.getContext("2d");
              stencilCtx.imageSmoothingEnabled = false;
              stencilCtx.drawImage(stencilImage, 0, 0);
              stencilCtx.globalCompositeOperation = "source-atop";

              const ditherCtx = dither.current.getContext("2d");
              ditherCtx.imageSmoothingEnabled = false;
              ditherCtx.drawImage(ditherImage, 0, 0);
    
              let oldPalette = [...palette];
              if(docData.palette.length > 0) {
                for(const index in docData.palette){
                  stencilCtx.fillStyle = docData.palette[index];
                  stencilCtx.fillRect(0, index*maxCursorSize, stencil.current.width, maxCursorSize);
                }
                setPalette([...docData.palette]);
                oldPalette = [...docData.palette];
                if(docData.secondarySwatch){
                  if(docData.secondarySwatch && docData.palette[docData.secondarySwatch] === '') setSecondarySwatch(32);
                } else if(docData.palette[secondarySwatch] === '') setSecondarySwatch(32);
              } else {
                if(docData.selectedSwatch) setSelectedSwatch(docData.selectedSwatch);
                if(docData.secondarySwatch) setSecondarySwatch(docData.secondarySwatch);
              }
        

              

              if(docData.magnetLayer) setMagnetLayer(docData.magnetLayer);
              if(docData.layersCreated) setLayersCreated(docData.layersCreated);
              if(docData.linesCreated) setLinesCreated(docData.linesCreated);
              if(docData.ellipsesCreated) setEllipsesCreated(docData.ellipsesCreated);
              if(docData.gridsCreated) setGridsCreated(docData.gridsCreated);
              if(docData.perspectivesCreated) setPerspectivesCreated(docData.perspectivesCreated);
              if(docData.vanishingPointsCreated) setVanishingPointsCreated(docData.vanishingPointsCreated);
              if(docData.isometricsCreated) setIsometricsCreated(docData.isometricsCreated);
              if(docData.palettesCreated) setPalettesCreated(docData.palettesCreated);
        
              if(docData.toolSettings){
                setSelectedTool(docData.toolSettings.selectedTool || "pencil");
                setPreviousTool(docData.toolSettings.prevTool || "pencil");
                setBrushDiameter(docData.toolSettings.brushDiameter || 6);
                setEraseDiameter(docData.toolSettings.eraseDiameter || 6);
                setBrushPixelPerfect(docData.toolSettings.brushPixelPerfect);
                setErasePixelPerfect(docData.toolSettings.erasePixelPerfect);
                
                if(docData.toolSettings.brushMode) setBrushMode(docData.toolSettings.brushMode);
                if(docData.toolSettings.brushPressure) setBrushPressure(docData.toolSettings.brushPressure);
                if(docData.toolSettings.eraseMode) setEraseMode(docData.toolSettings.eraseMode);
                if(docData.toolSettings.erasePressure) setErasePressure(docData.toolSettings.erasePressure);
                if(docData.toolSettings.dropperCurrent) setDropperCurrent(docData.toolSettings.dropperCurrent);
                if(docData.toolSettings.dropperReplace) setDropperReplace(docData.toolSettings.dropperReplace);        
                if(docData.toolSettings.autoSelect) setAutoSelect(docData.toolSettings.autoSelect);       
                if(docData.toolSettings.invertZoom) setInvertZoom(docData.toolSettings.invertZoom);     
                if(docData.toolSettings.brushSelect) setBrushSelect(docData.toolSettings.brushSelect);
                if(docData.toolSettings.eraseSelect) setEraseSelect(docData.toolSettings.eraseSelect);
                if(docData.toolSettings.wandContinguous){
                  setWandContinguous(true);
                } else {
                  setWandContinguous(false);
                }
                if(docData.toolSettings.bucketContinguous){
                  setBucketContinguous(true);
                } else {
                  setBucketContinguous(false);
                }
                if(docData.toolSettings.brushPressureFactor) setBrushPressureFactor(docData.toolSettings.brushPressureFactor);
                if(docData.toolSettings.erasePressureFactor) setErasePressureFactor(docData.toolSettings.erasePressureFactor);
                if(docData.toolSettings.ditherRatio) setDitherRatio(docData.toolSettings.ditherRatio);
                if(docData.toolSettings.ditherOffsetX) setDitherOffsetX(docData.toolSettings.ditherOffsetX);
                if(docData.toolSettings.ditherOffsetY) setDitherOffsetY(docData.toolSettings.ditherOffsetY);
                if(docData.toolSettings.eraseDitherRatio) setEraseDitherRatio(docData.toolSettings.ditherRatio);
                if(docData.toolSettings.bucketMode) setBucketMode(docData.toolSettings.bucketMode);                
                if(docData.toolSettings.ditherPressureMode) setDitherPressureMode(docData.toolSettings.ditherPressureMode);
                if(docData.toolSettings.eraseDitherPressureMode) setEraseDitherPressureMode(docData.toolSettings.eraseDitherPressureMode);
                if(docData.toolSettings.invertEraseDither) setInvertEraseDither(docData.toolSettings.invertEraseDither);

                if(docData.toolSettings.lastColor) setLastColor(docData.toolSettings.lastColor);
                if(docData.toolSettings.lastFocalLength) setLastFocalLength(docData.toolSettings.lastFocalLength);

                if(docData.toolSettings.lineWidth) setLineWidth(docData.toolSettings.lineWidth);
              }
    
              const selectionCanvas = document.createElement("canvas");
              selectionCanvas.width = docData.width;
              selectionCanvas.height = docData.height;
              selection.current = selectionCanvas;
              // const selectionCtx = selection.current.getContext('2d');
              // selectionCtx.fillRect(0,0,docData.width,docData.height);
    
              const oldSelection = React.createRef();

              const tempCanvas = document.createElement("canvas");
              tempCanvas.width = resolution.width;
              tempCanvas.height = resolution.height;
              oldSelection.current = tempCanvas;

              if(docData.newLayers && docData.newLayers.length > 0){
                const oldLayers = [];
                const tempLayers = [];
                for await (const layer of docData.newLayers){
                  switch(layer.type){
                    case "Canvas":
                      tempLayers.push({
                        type: "Canvas",
                        id: layer.id,
                        name: layer.name,
                        visible: layer.visible,
                        x: layer.x,
                        y: layer.y,
                        ref: React.createRef(),
                      });
                      const offscreenCanvas = document.createElement("canvas");
                      offscreenCanvas.width = layer.width;
                      offscreenCanvas.height = layer.height;
                      tempLayers[tempLayers.length - 1].ref.current = offscreenCanvas;
        
                      try{
                        await loadLayer(docId, tempLayers[tempLayers.length - 1]);
              
                        oldLayers.push({
                          type: "Canvas",
                          id: layer.id,
                          ref: React.createRef(),
                          visible: layer.visible,
                          name: layer.name,
                          x: layer.x,
                          y: layer.y
                        });
                        const tempCanvas = document.createElement("canvas");
                        tempCanvas.width = layer.width;
                        tempCanvas.height = layer.height;
                        oldLayers[oldLayers.length - 1].ref.current = tempCanvas;
                  
                        //draw temp canvas
                        const ctx = oldLayers[oldLayers.length - 1].ref.current.getContext("2d");
                        ctx.imageSmoothingEnabled = false;
                        ctx.drawImage(
                          tempLayers[tempLayers.length - 1].ref.current,
                          0,
                          0
                        );
                      } catch(error){
                        console.error(error);
                        window.alert("Error Loading Document.");
                      }
                      break;
                    case "Line":
                      tempLayers.push({
                        type: "Line",
                        id: layer.id,
                        name: layer.name,
                        visible: layer.visible,
                        color: layer.color,
                        startPoint: layer.startPoint,
                        endPoint: layer.endPoint,
                        extend: layer.extend,
                      });
                      break;
                    case "OnePoint":
                      tempLayers.push({
                        type: "OnePoint",
                        id: layer.id,
                        name: layer.name,
                        visible: layer.visible,
                        color: layer.color,
                        point: layer.point,
                      });
                      break;
                    case "TwoPoint":
                      tempLayers.push({
                        type: "TwoPoint",
                        id: layer.id,
                        name: layer.name,
                        visible: layer.visible,
                        color: layer.color,
                        center: layer.center,
                        angle: layer.angle,
                        focalLength: layer.focalLength,
                        tilt: layer.tilt,
                      });
                      break;
                    case "ThreePoint":
                      tempLayers.push({
                        type: "ThreePoint",
                        id: layer.id,
                        name: layer.name,
                        visible: layer.visible,
                        color: layer.color,
                        center: layer.center,
                        angle: layer.angle,
                        angle2: layer.angle2,
                        focalLength: layer.focalLength,
                        tilt: layer.tilt,
                      });
                      break;
                    case "Grid":
                      tempLayers.push({
                        type: "Grid",
                        id: layer.id,
                        name: layer.name,
                        visible: layer.visible,
                        color: layer.color,
                        corner: layer.corner,
                        width: layer.width,
                        height: layer.height,
                        rows: layer.rows,
                        columns: layer.columns,
                        angle: layer.angle,
                      });
                      break;
                    case "Isometric":
                      tempLayers.push({
                        type: "Isometric",
                        id: layer.id,
                        name: layer.name,
                        visible: layer.visible,
                        color: layer.color,
                        corner: layer.corner,
                        width: layer.width,
                        height: layer.height,
                        rows: layer.rows,
                        columns: layer.columns,
                        angle: layer.angle,
                        angle2: layer.angle2,
                      });
                      break;
                    case "Ellipse":
                      tempLayers.push({
                        type: "Ellipse",
                        id: layer.id,
                        name: layer.name,
                        visible: layer.visible,
                        color: layer.color,
                        center: layer.center,
                        width: layer.width,
                        height: layer.height,
                        angle: layer.angle,
                      });
                      break;
                  }
                }
        
                //set history 0      
                const tempHistory = [
                  {
                    palette: [...oldPalette],
                    layers: [...oldLayers],
                    selection: oldSelection,
                    selectedLayer: 0,
                    resolution: oldResolution.current,
                    offset: oldOffset.current,
                    version: 0
                  }
                ];
                setHistory([...tempHistory]);
                setLayers([...tempLayers]);
                if(docData.selectedLayer){
                  setSelectedLayer(docData.selectedLayer);
                  if(docData.selectedLayers.length > 0){
                    setSelectedLayers(docData.selectedLayers);
                  } else {
                    setSelectedLayers([docData.selectedLayer]);
                  }
                }
              } else if(docData.layers.length > 0){
                const oldLayers = [];
                const tempLayers = [];
                for await (const layer of docData.layers){
                  tempLayers.push({
                    type: "Canvas",
                    id: layer.id,
                    ref: React.createRef(),
                    visible: layer.visible,
                    name: layer.name,
                    x: layer.x,
                    y: layer.y
                  });
                  const offscreenCanvas = document.createElement("canvas");
                  offscreenCanvas.width = layer.width;
                  offscreenCanvas.height = layer.height;
                  tempLayers[tempLayers.length - 1].ref.current = offscreenCanvas;
        
                  try{
                    await loadLayer(docId, tempLayers[tempLayers.length - 1]);
          
                    oldLayers.push({
                      type: "Canvas",
                      id: layer.id,
                      ref: React.createRef(),
                      visible: layer.visible,
                      name: layer.name,
                      x: layer.x,
                      y: layer.y
                    });
                    const tempCanvas = document.createElement("canvas");
                    tempCanvas.width = layer.width;
                    tempCanvas.height = layer.height;
                    oldLayers[oldLayers.length - 1].ref.current = tempCanvas;
              
                    //draw temp canvas
                    const ctx = oldLayers[oldLayers.length - 1].ref.current.getContext("2d");
                    ctx.imageSmoothingEnabled = false;
                    ctx.drawImage(
                      tempLayers[tempLayers.length - 1].ref.current,
                      0,
                      0
                    );
                  } catch(error){
                    console.error(error);
                    window.alert("Error Loading Document.");
                  }
                }
        
                //set history 0      
                const tempHistory = [
                  {
                    palette: [...oldPalette],
                    layers: [...oldLayers],
                    selection: oldSelection,
                    selectedLayer: 0,
                    resolution: oldResolution.current,
                    offset: oldOffset.current,
                    version: 0
                  }
                ];
                setHistory([...tempHistory]);
                setLayers([...tempLayers]);
                if(docData.selectedLayer){
                  setSelectedLayer(docData.selectedLayer);
                  if(docData.selectedLayers.length > 0){
                    setSelectedLayers(docData.selectedLayers);
                  } else {
                    setSelectedLayers([docData.selectedLayer]);
                  }
                }
              } else {
                const tempLayers = [
                  {
                    type: "Canvas",
                    id: generateId(8),
                    ref: React.createRef(),
                    visible: true,
                    name: "Layer 1",
                    x: 0,
                    y: 0
                  }
                ];
                const firstCanvas = document.createElement("canvas");
                firstCanvas.width = docData.width;
                firstCanvas.height = docData.height;
                tempLayers[0].ref.current = firstCanvas;
        
                //set history 0      
                const tempHistory = [
                  {
                    palette: [...oldPalette],
                    layers: [...tempLayers],
                    selection: oldSelection,
                    selectedLayer: 0,
                    resolution: oldResolution.current,
                    offset: oldOffset.current,
                    version: 0
                  }
                ];
                setHistory([...tempHistory]);
                setLayers([...tempLayers]);
              }

              setIsDocumentLoaded(true);
            } catch (error) {  
              console.log(error);
              window.alert("Error Loading Document.");
            }
          };
          ditherImage.src = ditherFile;
        };
        previewBG.current.src = previewBGFile;
      };
      stencilImage.src = stencilFile;
    } catch (error) {    
      if (error.response){
        if(error.response.status === 404){
          navigate('/404');
        } else {
          navigate('/500');
        }
      } else {
        console.log(error);
        window.alert("Error Loading Document.");
      }
    }
  };

  const saveDocument = useCallback(async (newDoc, auto) => {
    try {
      // Retrieve the token from localStorage
      const documentDataStored = JSON.parse(localStorage.getItem('documentData')) || {};
      const token = documentDataStored[docId];

      const newLayers = [];
      for(const layer of layers){
        if(layer.type === "Canvas"){
          newLayers.push({
            ...layer,
            width: layer.ref.current.width,
            height: layer.ref.current.height
          });
        } else {
          newLayers.push(layer)
        }
      }
      
      const documentData = {
        newDoc,
        token,
        name: docName,
        starred,
        autoSave,
        showBorder,
        width: resolution.width,
        height: resolution.height,
        offset,
        lastEditDate:  Date.now(),
        newLayers,
        layoutSettings: {
          wrapperWidth,
          leftWindowHeight,
          rightWindowHeight,
          wrapperWidthPortrait,
          leftWindowHeightPortrait,
          rightWindowHeightPortrait,
          topLeftWindow,
          topRightWindow,
          bottomLeftWindow,
          bottomRightWindow
        },
        palette,
        selectedSwatch,
        secondarySwatch,
        selectedLayer,
        selectedLayers,
        magnetLayer,
        layersCreated,
        linesCreated, 
        isometricsCreated,  
        ellipsesCreated, 
        gridsCreated, 
        perspectivesCreated,
        vanishingPointsCreated,
        palettesCreated,
        toolSettings: {
          selectedTool,
          previousTool,
          brushDiameter,
          brushPixelPerfect,
          brushMode,
          brushPressure,
          eraseDiameter,
          erasePixelPerfect,
          eraseMode,
          erasePressure,
          dropperCurrent,
          autoSelect,
          invertZoom,
          brushSelect,
          eraseSelect,
          wandContinguous,
          bucketContinguous,
          brushPressureFactor,
          erasePressureFactor,
          ditherRatio,
          ditherOffsetX,
          ditherOffsetY,
          eraseDitherRatio,
          bucketMode,
          ditherPressureMode,
          eraseDitherPressureMode,
          invertEraseDither,
          lastColor,
          lastFocalLength,
          lineWidth,
        }
      };
  
      await axios.post(`${apiUrl}/api/saveDocument/${docId}`, documentData)
        .then(async response => {
          if (response.status === 200) {
            //save layers
            const imgData = new FormData();
            for (const layer of layers) {
              if(layer.type === "Canvas"){
                const blob = await canvasToBlob(layer.ref.current);
                imgData.append(layer.id, blob, `${layer.id}.png`);
              }
            }

            // Calculate the scaling factors for width and height
            const widthScale = 1200 / resolution.width;
            const heightScale = 627 / resolution.height;

            // Choose the minimum scaling factor to ensure the entire content fits within the target dimensions
            const scale = Math.min(widthScale, heightScale);

            // Calculate the new dimensions based on the scaling factor
            const width = resolution.width * scale;
            const height = resolution.height * scale;

            //get canvas and ctx for outpt
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.imageSmoothingEnabled = false;
            
            ctx.drawImage(previewBG.current,0,0);
            
            //merge upscaled layers
            for(const layer of layers){
              if(layer.visible && layer.type === "Canvas"){
                ctx.drawImage(
                  layer.ref.current,
                  layer.x * scale - offset.x * scale,
                  layer.y * scale - offset.y * scale,
                  layer.ref.current.width * scale,
                  layer.ref.current.height * scale
                );
              }
            }

            const blob = await canvasToBlob(canvas);
            imgData.append("preview", blob, "preview.png");

            // Append token to the FormData
            imgData.append('token', token);

            await axios.post(`${apiUrl}/api/saveLayers/${docId}`, imgData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if(auto){
              console.log('Document auto saved succesfully.')
            } else {
              window.alert('Document Saved Succesfully');
            }
            setUpToDate(true);
          } else if (response.status === 201) {
            const { newToken, newId, newName} = response.data;

            // Store the document ID in local storage for all users
            storeDocumentData(newId, newToken);

            //save layers
            const imgData = new FormData();
            for (const layer of layers) {
              if(layer.type !== "Canvas") continue;
              const blob = await canvasToBlob(layer.ref.current);
              imgData.append(layer.id, blob, `${layer.id}.png`);
            }

            // Append token to the FormData
            imgData.append('token', newToken);

            await axios.post(`${apiUrl}/api/saveLayers/${newId}`, imgData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            setUpToDate(true);

            if(newDoc) {
              window.open(`/editor/${newId}`, '_blank', 'noopener,noreferrer');
            } else {
              navigate(`/editor/${newId}`);
              setDocName(newName);
            }

            if(auto){
              console.log('Document auto saved succesfully.')
            } else {
              window.alert('Document Saved Succesfully');
            }
          } else {
            if(auto){
              console.log('Error auto saving document.')
            } else {
              window.alert('Error saving document');
            }
          }
      })
    } catch (error) {
      console.log(error);
      if(!auto) window.alert('Error saving document');
    } finally{
      setDocumentSaving(false);
    }
  }, [
    docName,
    starred,
    autoSave,
    showBorder,
    resolution,
    offset,
    layers,
    history,
    version,
    selectedSwatch,
    secondarySwatch,
    palette,
    selectedLayer,
    selectedLayers,
    magnetLayer,
    layersCreated,
    linesCreated, 
    isometricsCreated,  
    ellipsesCreated, 
    gridsCreated, 
    perspectivesCreated,
    vanishingPointsCreated,
    wrapperWidth,
    leftWindowHeight,
    rightWindowHeight,
    wrapperWidthPortrait,
    leftWindowHeightPortrait,
    rightWindowHeightPortrait,
    topLeftWindow,
    topRightWindow,
    bottomLeftWindow,
    bottomRightWindow,
    selectedTool,
    previousTool,
    brushDiameter,
    brushPixelPerfect,
    brushMode,
    brushPressure,
    eraseDiameter,
    erasePixelPerfect,
    eraseMode,
    erasePressure,
    ditherRatio,
    ditherOffsetX,
    ditherOffsetY,
    eraseDitherRatio,
    dropperCurrent,
    autoSelect,
    invertZoom,
    brushSelect,
    eraseSelect,
    wandContinguous,
    bucketContinguous,
    brushPressureFactor,
    erasePressureFactor,
    bucketMode,
    ditherPressureMode,
    eraseDitherPressureMode,
    invertEraseDither,
    lastColor, 
    lastFocalLength, 
    lineWidth,
  ]);

  useEffect(() => {
    if(saveNow > 0){
      saveDocument(false,false);
    }
  }, [saveNow]);

  useEffect(() => {
    if(autoSaveNow > 0){
      saveDocument(false,true);
    }
  }, [autoSaveNow]);

  useEffect(() => {
    if(saveNewNow > 0){
      saveDocument(true,false);
    }
  }, [saveNewNow]);

  useEffect(() => {
    const save = () => {
      console.log('Auto saving!');
      setAutoSaveNow(prevVale => prevVale + 1);
      intervalId.current = setTimeout(save, 120000);
    }
    if (autoSave) {
      if(!intervalId.current){
        console.log('Auto save started.');
        intervalId.current = setTimeout(save, 120000);
      }
    } else {
      console.log('Auto save turned off.')
      clearTimeout(intervalId.current);
      intervalId.current = null;
    }
  }, [autoSave]);

  const handleStar = async () => {
    // Retrieve the token from localStorage
    const documentDataStored = JSON.parse(localStorage.getItem('documentData')) || {};
    const token = documentDataStored[docId];

    try{
      const response = await axios.post(apiUrl + "/api/star/" + docId, {token});
      
      if(response.data){
        setStarred(response.data.starred);
      }
    } catch(error){
      if(error.response.status === 304){
        setStarred(prevValue => !prevValue);
      } else {
        console.log(error);
        if(error.response && error.response.data && error.response.data.message){
          window.alert(error.response.data.message);
        } else {
          window.alert('Error starring document');
        }
      }
    }
  }

  const handleAutoSave = async () => {
    // Retrieve the token from localStorage
    const documentDataStored = JSON.parse(localStorage.getItem('documentData')) || {};
    const token = documentDataStored[docId];

    try{
      const response = await axios.post(apiUrl + "/api/autoSave/" + docId, {token});
      if(response.data){
        setAutoSave(response.data.autoSave);
      }
    } catch(error){
      if(error.response.status === 304){
        setAutoSave(prevValue => !prevValue);
      } else {
        console.log(error);
        if(error.response && error.response.data && error.response.data.message){
          window.alert(error.response.data.message);
        } else {
          window.alert('Error setting auto save');
        }
      }
    }
  }

  const deleteDocument = async (newDoc) => {

    const confirmed = confirm("Are you sure you want to delete this document?");
    if(!confirmed) return;

    try {
      // Retrieve the token from localStorage
      const documentDataStored = JSON.parse(localStorage.getItem('documentData')) || {};
      const token = documentDataStored[docId];
  
      await axios.post(`${apiUrl}/api/deleteDocument/${docId}`, { token })
        .then(async response => {
          if (response.status === 200) {
            document.title = "Aliasing.Pro - Dashboard";
            navigate('/dashboard');
          } else {
            window.alert('Error deleting document');
          }
        });
    } catch (error) {
      console.log(error);
      if(error.response && error.response.data && error.response.data.message){
        window.alert(error.response.data.message);
      } else {
        window.alert('Error deleting document');
      }
    }
  };

  useEffect(() => {
    if(deleteDocNow > 0){
      deleteDocument();
    }
  }, [deleteDocNow]);

  const signOut = () => {
      axios.post(apiUrl + "/api/auth/signOut")
        .then(response => {
          if (response.status === 200) {
            window.alert('Signed out.');
            setUser(null);
          }
        })
        .catch(error => {
            console.error('Error signing out:', error);
            window.alert('Error signing out.');
        });
  };

  const exportCallback = useCallback(async () => {
    //choose a transparent color that isnt used in palette
    let transparentHex = '#ff00ff';
    while(palette.includes(transparentHex)){
      transparentHex = randomHex();
    }
    const transparentColor = chroma(transparentHex).rgb();

    //get canvas and ctx for outpt
    const canvas = document.createElement("canvas");
    canvas.width = resolution.width;
    canvas.height = resolution.height;
    const ctx = canvas.getContext("2d");
    ctx.imageSmoothingEnabled = false;
    
    //merge upscaled layers
    for(const layer of layers){
      if(layer.visible && layer.type === "Canvas"){
        ctx.drawImage(
          layer.ref.current,
          layer.x - offset.x,
          layer.y - offset.y,
          layer.ref.current.width,
          layer.ref.current.height
        );
      }
    }
    let hasTransparency = false;
    const imageData = ctx.getImageData(
      0,
      0,
      resolution.width,
      resolution.height
    );
    for (let i = 0; i < imageData.data.length; i+=4) {
      if ( imageData.data[i + 3] !== 255) {
        imageData.data[i] = transparentColor[0];
        imageData.data[i + 1] = transparentColor[1];
        imageData.data[i + 2] = transparentColor[1];
        imageData.data[i + 3] = 0;
        hasTransparency = true;
      }
    }
    ctx.putImageData(imageData, 0, 0);
    
    if(exportType === "gif" && hasTransparency){        
      //replace transparent parts of canvas with transparent hex color;
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = transparentHex;
      ctx.fillRect(0, 0, resolution.width, resolution.height);
    } else if(exportType === "jpg"){
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = palette[bgColor];
      ctx.fillRect(0, 0, resolution.width, resolution.height);
    }

    //get canvas and ctx for upscaling
    const canvas2 = document.createElement("canvas");
    canvas2.width = resolution.width * upscaling;
    canvas2.height = resolution.height * upscaling;
    const ctx2 = canvas2.getContext("2d");
    ctx2.imageSmoothingEnabled = false;

    //upscale image
    ctx2.drawImage(canvas, 0, 0, resolution.width * upscaling, resolution.height * upscaling);

    if(exportType === "gif"){
      let gif;

      if(hasTransparency){
        console.log('ad')
        //set up gif settings (with transparency)
        gif = new GIF({
          workers: 4,
          quality: 1,
          workerScript: '/gif.worker.js',
          transparent: transparentHex.toUpperCase().replace("#", "0x")
        });
      } else {
        //set up gif settings (with transparency)
        gif = new GIF({
          workers: 4,
          quality: 1,
          workerScript: '/gif.worker.js',
        });
      }
  
      gif.addFrame(canvas2);
      
      gif.on('finished', async function(blob) {
        // Open a file picker dialog to choose export location
        try {
          if(window.showSaveFilePicker){
            const fileHandle = await window.showSaveFilePicker({
              suggestedName: filename + ".gif",
              types: [
                {
                  description: 'GIF Files',
                  accept: {
                    'image/gif': ['.gif'],
                  },
                },
              ],
            });
  
            const writable = await fileHandle.createWritable();
            await writable.write(blob);
            await writable.close();
          } else {
            // Create a URL for the blob
            const url = URL.createObjectURL(blob);
            
            // Create a temporary anchor element
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            
            // Append the anchor to the body, trigger the download, then remove the anchor
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            
            // Revoke the blob URL to free up resources
            URL.revokeObjectURL(url);
          }
        } catch (error) {
          console.error('Error saving file:', error);
        }
      });
  
      gif.render();
    }else {
      let mimeType, fileExtension;
  
      if (exportType === "jpg") {
        mimeType = "image/jpeg";
        fileExtension = ".jpg";
      } else {
        mimeType = "image/png";
        fileExtension = ".png";
      }
  
      const blob = await new Promise(resolve => canvas2.toBlob(resolve, mimeType, 0.92)); // Adjust JPEG quality if needed
  
      // For non-GIF exports, you can similarly open a file picker dialog
      try {
        if (window.showSaveFilePicker) {
          const fileHandle = await window.showSaveFilePicker({
            suggestedName: filename + fileExtension,
            types: [
              {
                description: mimeType === "image/jpeg" ? 'JPEG Files' : 'PNG Files',
                accept: {
                  [mimeType]: [fileExtension],
                },
              },
            ],
          });
  
          const writable = await fileHandle.createWritable();
          await writable.write(blob);
          await writable.close();
        } else {
          // Create a URL for the blob
          const url = URL.createObjectURL(blob);
  
          // Create a temporary anchor element
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
  
          // Append the anchor to the body, trigger the download, then remove the anchor
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
  
          // Revoke the blob URL to free up resources
          URL.revokeObjectURL(url);
        }
      } catch (error) {
        console.error('Error saving file:', error);
      }
    }
  }, [resolution, palette, docName, layers, filename, exportType, upscaling, bgColor]);

  useEffect(() => {
    if(exportNow > 0){
      exportCallback();
    }
  }, [exportNow]); 

  useEffect(() => {
    setLoadNow(!loadNow);
  }, []);  

  useEffect(() => {
    if(loadNow && !isDocumentLoaded) loadDocument();
  }, [loadNow, isDocumentLoaded]); 

  useEffect(() => {
    setUpToDate(false);

    const handleKeyDown = (e) => {
      if(e.keyCode === 18){ //alt
        e.preventDefault();
        setAltKey(true);
      }

      if(e.keyCode === 16) setShiftKey(true);

      if (document.activeElement && (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA')){
        if(document.activeElement.className === "layer-name" && e.keyCode === 13) document.activeElement.blur();

        // Optionally, you can check for specific types of inputs like 'text', 'number', etc.
        if (document.activeElement.type === 'text' || document.activeElement.type === 'number' || document.activeElement.type === 'email'|| document.activeElement.type === 'password' ) {
          if (e.key.toLowerCase() === 'z' && (e.ctrlKey || e.metaKey)) { //undo redo
            e.preventDefault();
            if (e.shiftKey) {
              redo();
            } else {
              undo();
            }
          }
          return; // Do not execute hotkey action if a text input field is focused
        }
      }

      if (e.keyCode === 32){ //space
        e.preventDefault();
        setIsSpaceDown(true);
      }

      if(e.key.toLowerCase() === 'enter'){ //enter
        e.preventDefault();
      }

      if(
        !isDocumentLoaded ||
        documentSaving ||
        colorModal ||
        signInModal ||
        newDocumentModal ||
        savePaletteModal ||
        loadPaletteModal ||
        renameModal ||
        exportModal ||
        openModal ||
        resizeModal ||
        hotkeyModal
      ) return;


      
      if (e.key.toLowerCase() === 'z' && (e.ctrlKey || e.metaKey)) { //undo redo
        e.preventDefault();
        if (e.shiftKey) {
          redo();
        } else {
          undo();
        }
      } else if (e.key.toLowerCase() === 's' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        if(e.altKey){
          setExportModal(true);
        } else {
          saveDocument();
        }
      } else if (e.key.toLowerCase() === 'j' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        duplicateLayer();
      } else if (e.key.toLowerCase() === 'n') {
        e.preventDefault();
        addLayer("Canvas");
      } else if (e.key.toLowerCase() === 'tab') { //next layer
        e.preventDefault();
        if(shiftKey){
          if(selectedLayer < layers.length - 1){
            setSelectedLayer(selectedLayer + 1);
            setSelectedLayers([selectedLayer + 1]);
          } else {
            setSelectedLayer(0);
            setSelectedLayers([0]);
          }
        } else if(selectedLayer > 0){
          setSelectedLayer(selectedLayer - 1);
          setSelectedLayers([selectedLayer - 1]);
        } else {
          setSelectedLayer(layers.length - 1);
          setSelectedLayers([layers.length - 1]);
        }
      } else if (e.keyCode === 186) { //prev swatch (; key)
        if(e.ctrlKey || e.metaKey){
          shrink();
        } else{
          let i = selectedSwatch - 1;
          let newSelectedSwatch;
          while(typeof newSelectedSwatch === "undefined"){
            if(i < 0) i = 32;
            if(palette[i] || i === 32) newSelectedSwatch = i;
            i--;
          }
          setSelectedSwatch(newSelectedSwatch);
        }
      } else if (e.keyCode === 222) { //prev swatch (' key)
        if(e.ctrlKey || e.metaKey){
          grow();
        } else{
          let i = selectedSwatch + 1;
          let newSelectedSwatch;
          while(typeof newSelectedSwatch === "undefined"){
            if(i > 32) i = 0;
            if(palette[i] || i === 32) newSelectedSwatch = i;
            i++;
          }
          setSelectedSwatch(newSelectedSwatch);
        }
      } else if (e.keyCode === 221) { //increase brush diameter (] key)
        if(selectedTool === "pencil" && brushDiameter < maxCursorSize){
          setBrushDiameter(parseInt(brushDiameter) + 1);
        } else if(selectedTool === "erase" && eraseDiameter < maxCursorSize){
          setEraseDiameter(parseInt(eraseDiameter) + 1);
        } else if(selectedTool === "line" && lineWidth < maxCursorSize){
          setLineWidth(parseInt(lineWidth) + 1);
        }
      } else if (e.keyCode === 219) { //decreas brush diameter ( [ key)
        if(selectedTool === "pencil" && brushDiameter > 1){
          setBrushDiameter(parseInt(brushDiameter) - 1);
        } else if(selectedTool === "erase" && eraseDiameter > 1){
          setEraseDiameter(parseInt(eraseDiameter) - 1);
        } else if(selectedTool === "line" && lineWidth > 1){
          setLineWidth(parseInt(lineWidth) - 1);
        }
      } else if (e.key.toLowerCase() === 'c' && (e.ctrlKey || e.metaKey)){ //copy
        copy();
      } else if (e.key.toLowerCase() === 'x' && (e.ctrlKey || e.metaKey)){ //cut
        copy();
        deleteSelection();
      } else if (e.key.toLowerCase() === 'v' && (e.ctrlKey || e.metaKey)){ //paste
        if(shiftKey){ //paste in place
          pasteInPlace();
        } else { //paste center
          paste();
        }
      } else if (e.key.toLowerCase() === 'x'){ //swap swatches
        setSelectedSwatch(secondarySwatch);
        setSecondarySwatch(selectedSwatch);
      }else if (e.key.toLowerCase() === 'a' && (e.ctrlKey || e.metaKey)){
        e.preventDefault();
        selectAll();
      } else if (e.key.toLowerCase() === 'd'){
        e.preventDefault();
        if(e.ctrlKey || e.metaKey){
          deselect();
        } else {
          let i = 0;
          let newSelectedSwatch;
          while(typeof newSelectedSwatch === "undefined"){
            if(palette[i]) newSelectedSwatch = i;
            i++;
          }
          setSelectedSwatch(newSelectedSwatch);
          let newSecondarySwatch;
          while(typeof newSecondarySwatch === "undefined"){
            if(palette[i] || i === 32) newSecondarySwatch = i;
            i++;
          }
          setSecondarySwatch(newSecondarySwatch);
        }
      }  else if (e.key.toLowerCase() === 'i' && (e.ctrlKey || e.metaKey)){
        e.preventDefault();
        invert();
      } else if (e.key.toLowerCase() === 'p'){
        e.preventDefault();
        setSelectedTool("pencil");
      } else if (e.key.toLowerCase() === 'e') { //eraser or ellipse tool
        e.preventDefault();
        if(shiftKey){
          setSelectedTool("ellipse");
        } else if(e.ctrlKey || e.metaKey){
          mergeDown();
        } else{
          setSelectedTool("erase");
        }        
      }  else if (e.key.toLowerCase() === 'l'){
        e.preventDefault();
        setSelectedTool("line");
      } else if (e.key.toLowerCase() === 'i'){ //eyedropper tool
        setSelectedTool("eyedropper");
      } else if (e.key.toLowerCase() === 'm'){ //move tool
        setSelectedTool("move");
      } else if (e.key.toLowerCase() === 'h'){ //move tool
        setSelectedTool("hand");
      } else if (e.key.toLowerCase() === 'z') { //zoom too
        setSelectedTool("zoom");
      } else if (e.key.toLowerCase() === 'r') { //rectangle tool
        setSelectedTool("rectangle");
      } else if (e.key.toLowerCase() === 'w') { //wand Tool
        setSelectedTool("wand");
      } else if (e.key.toLowerCase() === 'b') { //bucket  tool
        setSelectedTool("bucket");
      } else if (e.key.toLowerCase() === 'g') { //gradient  tool
        setSelectedTool("gradient");
      }  else if (e.key.toLowerCase() === 'c') { //crop tool
        setSelectedTool("crop");
      } else if (e.keyCode === 46) { // delete layer
        if(!hasSelection){
          deleteLayer();
        } else {
          deleteSelection();
        }
      }
    };

    const handleKeyUp = (e) => {
      if(e.keyCode === 18) setAltKey(false);
      if (e.keyCode === 32) setIsSpaceDown(false);
      if(e.keyCode === 16) setShiftKey(false);
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [
    docName,
    starred,
    resolution,
    offset,
    isSpaceDown,
    layers,
    history,
    version,
    selectedSwatch,
    secondarySwatch,
    palette,
    selectedLayer,
    layersCreated,
    linesCreated, 
    isometricsCreated,  
    ellipsesCreated, 
    gridsCreated, 
    perspectivesCreated,
    vanishingPointsCreated,
    wrapperWidth,
    leftWindowHeight,
    rightWindowHeight,
    wrapperWidthPortrait,
    leftWindowHeightPortrait,
    rightWindowHeightPortrait,
    topLeftWindow,
    topRightWindow,
    bottomLeftWindow,
    bottomRightWindow,
    selectedTool,
    brushDiameter,
    brushPressure,
    eraseDiameter,
    erasePressure,
    dropperCurrent,
    autoSelect,
    invertZoom,
    isDocumentLoaded,
    documentSaving,
    colorModal,
    signInModal,
    newDocumentModal,
    savePaletteModal,
    loadPaletteModal,
    renameModal,
    exportModal,
    resizeModal,
    hotkeyModal,
    saveDocument,
    addLayer,
    hasSelection,
    transform,
    shiftKey,
    altKey,
    metaKey,
    lineWidth,
  ]);

  return (
    <MainContext.Provider
      value={{
        orientation,
        wrapperWidth,
        setWrapperWidth,
        leftWindowHeight,
        setLeftWindowHeight,
        rightWindowHeight,
        setRightWindowHeight,
        wrapperWidthPortrait,
        setWrapperWidthPortrait,
        leftWindowHeightPortrait,
        setLeftWindowHeightPortrait,
        rightWindowHeightPortrait,
        setRightWindowHeightPortrait,
        selectedTool,
        setSelectedTool,
        previousTool,
        setPreviousTool,
        topLeftWindow,
        setTopLeftWindow,
        topRightWindow,
        setTopRightWindow,
        bottomLeftWindow,
        setBottomLeftWindow,
        bottomRightWindow,
        setBottomRightWindow,
        docName,
        setDocName,
        starred,
        setStarred,
        autoSave,
        setAutoSave,
        resolution,
        setResolution,
        offset,
        setOffset,
        crop, 
        setCrop,
        transform,
        setTransform,
        transformStartCanvas,
        setTransformStartCanvas,
        transformStartSelection,
        setTransformStartSelection,
        transformCanvas,
        setTransformCanvas,
        transformSelection,
        setTransformSelection,
        transformStart,
        setTransformStart,
        palette,
        setPalette,
        palettes,
        setPalettes,
        selectedSwatch,
        setSelectedSwatch,
        secondarySwatch,
        setSecondarySwatch,
        layers,
        setLayers,
        selectedLayer,
        setSelectedLayer,
        selectedLayers,
        setSelectedLayers,
        magnetLayer, 
        setMagnetLayer,
        layersCreated,
        setLayersCreated,
        linesCreated, 
        setLinesCreated, 
        isometricsCreated, 
        setIsometricsCreated, 
        ellipsesCreated, 
        setEllipsesCreated, 
        gridsCreated, 
        setGridsCreated, 
        perspectivesCreated, 
        setPerspectivesCreated, 
        vanishingPointsCreated,
        setVanishingPointsCreated,
        palettesCreated,
        setPalettesCreated,
        brushDiameter,
        setBrushDiameter,
        brushPixelPerfect,
        setBrushPixelPerfect,
        brushMode,
        setBrushMode,
        brushPressure,
        setBrushPressure,
        brushPressureFactor,
        setBrushPressureFactor,
        ditherRatio,
        setDitherRatio,
        ditherOffsetX,
        setDitherOffsetX,
        ditherOffsetY,
        setDitherOffsetY,
        ditherPressureMode,
        setDitherPressureMode,
        eraseDiameter,
        setEraseDiameter,
        erasePixelPerfect,
        setErasePixelPerfect,
        eraseMode,
        setEraseMode,
        erasePressure,
        setErasePressure,
        erasePressureFactor,
        setErasePressureFactor,
        eraseDitherRatio,
        setEraseDitherRatio,
        eraseDitherPressureMode,
        setEraseDitherPressureMode,
        invertEraseDither,
        setInvertEraseDither,
        dropperCurrent,
        setDropperCurrent,
        dropperReplace,
        setDropperReplace,
        autoSelect,
        setAutoSelect,
        invertZoom,
        setInvertZoom,
        brushSelect,
        setBrushSelect,
        eraseSelect,
        setEraseSelect,
        wandContinguous,
        setWandContinguous,
        bucketContinguous,
        setBucketContinguous,
        bucketMode,
        setBucketMode,
        rectangleAngle,
        setRectangleAngle,
        ellipseAngle,
        setEllipseAngle,
        isDocumentLoaded,
        setIsDocumentLoaded,
        documentSaving,
        setDocumentSaving,
        colorModal,
        setColorModal,
        swatchModal,
        setSwatchModal,
        signInModal,
        setSignInModal,
        newDocumentModal,
        setNewDocumentModal,
        savePaletteModal,
        setSavePaletteModal,
        loadPaletteModal,
        setLoadPaletteModal,
        importModal,
        setImportModal,
        renameModal,
        setRenameModal,
        exportModal,
        setExportModal,
        openModal,
        setOpenModal,
        resizeModal,
        setResizeModal,
        hotkeyModal,
        setHotkeyModal,
        history,
        setHistory,
        version,
        setVersion,
        transformVersion,
        stencil,
        setStencil,
        dither,
        setDither,
        ditherLayer,
        setDitherLayer,
        altKey,
        shiftKey,
        shiftButton,
        setShiftButton,
        altButton,
        setAltButton,
        metaButton,
        setMetaButton,
        isSpaceDown,
        centerView,
        setCenterView,
        setSaveNow,
        setSaveNewNow,
        setExportNow,
        setAddLayerNow,
        setDuplicateNow,
        setMergeDownNow,
        setDeleteLayerNow,
        setMixColorsNow,
        setDeleteSwatchNow,
        setDeleteDocNow,
        setSelectAllNow,
        setDeslectNow, 
        setInvertNow,
        setGrowNow,
        setShrinkNow,
        setDeleteSelectionNow,
        setCopyNow,
        setCutNow,
        setPasteNow,
        setPasteInPlaceNow,
        transformNow, //important
        setTransformNow,
        applyTransformNow,
        setApplyTransformNow,
        resetTransformNow,
        setResetTransformNow,
        setUndoNow,
        setRedoNow,
        setFlipHorizontalNow,
        setFlipVerticalNow,
        setRotateCWNow,
        setRotateCCWNow,
        filename,
        setFilename,
        exportType,
        setExportType,
        upscaling,
        setUpscaling,
        setExportType,
        setUpscaling,
        updatePalette,
        deleteSwatch,
        newPalette,
        loadPalettes,
        updateLayer,
        applyTransform,
        moveLayers,
        addLayer,
        duplicateLayer,
        deleteLayer,
        reorderLayers,
        reorderSwatches,
        mergeDown,
        saveDocument,
        signOut,
        exportCallback,
        importImage,
        applyCrop,
        resize,
        layerSelect,
        layerSelectAdd,
        layerSelectRemove,
        layerSelectIn,
        swatchSelect,
        swatchSelectIn,
        swatchSelectAdd,
        swatchSelectRemove,
        handleStar,
        handleAutoSave,
        selection,
        hasSelection,
        setHasSelection,
        viewportCenter,
        setViewportCenter,
        selectedViewport,
        setSelectedViewport,
        contextMenu,
        setContextMenu,
        details,
        setDetails,
        showBorder,
        setShowBorder,
        loading,
        setLoading,
        updateGuide,
        lastColor, 
        setLastColor,
        lastFocalLength, 
        setLastFocalLength,
        maxUpscale,
        setMaxUpscale,
        bgColor,
        setBgColor,
        lineWidth,
        setLineWidth,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
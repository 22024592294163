import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";

function WandTool() {
  const { wandContinguous, setWandContinguous } = useMainContext();

  const handleWandContinguous = (checked) => {
    setWandContinguous(checked);
  };

  return (
    <>
      <h4>Wand Tool</h4>
      <label className="tool-label">
        Continguous:
        <input
          className="tool-checkbox"
          type="checkbox"
          onChange={(e) => handleWandContinguous(e.target.checked)}
          checked={wandContinguous}
        ></input>
      </label>
    </>
  );
}

export default WandTool;

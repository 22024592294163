export const windowLayouts = [
  {
    id: "toolOptions",
    name: "Tool Options"
  },
  {
    id: "viewport",
    name: "Viewport"
  },
  {
    id: "layers",
    name: "Layers"
  },
  {
    id: "palette",
    name: "Color Palette"
  },
];

export const defaultPalettes = [
  {
    name: "Dawnbringer",
    default: true,
    palette: [
      "#000000",
      "#ffffff",
      "#a09ca2",
      "#696a6a",
      "#595957",
      "#4a4845",
      "#eec39a",
      "#d9a066",
      "#8f563b",
      "#663931",
      "#ac3232",
      "#d95763",
      "#df7126",
      "#fbf236",
      "#8a6f30",
      "#524b24",
      "#99e550",
      "#6abe30",
      "#8f974a",
      "#4b692f",
      "#37946e",
      "#5fcde4",
      "#639bff",
      "#5b6ee1",
      "#306082",
      "#cbdbfc",
      "#8389b6",
      "#3f3f74",
      "#222034",
      "#d77bba",
      "#76428a",
      "#45283c"
  ]
  },
  {
    name: "Losing is Fun",
    default: true,
    palette: [
      "#1f0f0f",
      "#ffffff",
      "#4c4a47",
      "#9e978f",
      "#d1beae",
      "#c09469",
      "#905b46",
      "#663822",
      "#432b1d",
      "#d71818",
      "#C83939",
      "#a63737",
      "#c49088",
      "#a46256",
      "#82463c",
      "#68342e",
      "#c7653b",
      "#a46721",
      "#cc9629",
      "#cdaa65",
      "#6e733b",
      "#556735",
      "#39592e",
      "#334931",
      "#2d3835",
      "#77cbc9",
      "#457477",
      "#3f5170",
      "#2b3745",
      "#4c3268",
      "#6e4c93",
      "#845d69"
  ]
  },
  {
    name: "Portrait",
    default: true,
    palette: [
      "#0e0905",
      "#eae1db",
      "#b6a89b",
      "#d3b9a8",
      "#ae9a8e",
      "#605343",
      "#4e463d",
      "#39342e",
      "#f0ccb4",
      "#efbb98",
      "#edaa7d",
      "#ca8b60",
      "#b57b51",
      "#8b5d3b",
      "#724524",
      "#472e1b",
      "#d2381c",
      "#de6f55",
      "#d66216",
      "#7f3504",
      "#d29411",
      "#5c8e46",
      "#386f36",
      "#344e3b",
      "#a3aaaa",
      "#798383",
      "#455356",
      "#313b3f",
      "#1d252d",
      "#c779cd",
      "#886694",
      "#463c47"
  ]
  },
  {
    name: "Cyberpunk",
    default: true,
    palette: [
      "#000000",
      "#fcf8fa",
      "#9096a2",
      "#4c4c5e",
      "#eab58f",
      "#8f6448",
      "#453117",
      "#fe757f",
      "#ff3751",
      "#ba1d35",
      "#ec0c0c",
      "#f86e15",
      "#ffc503",
      "#01ea6a",
      "#1d7a46",
      "#41cdc9",
      "#329191",
      "#235a5c",
      "#9bc8df",
      "#5B89BC",
      "#435f95",
      "#303e68",
      "#161F32",
      "#9c9ccf",
      "#8561a0",
      "#4c306e",
      "#26124b",
      "#fea5fe",
      "#cc3ed3",
      "#AA24A3",
      "#ea0588",
      "#871057",
    ]
  },
  {
    name: "Terminal",
    default: true,
    palette: [
      "#000000", // Black
      "#000080", // Blue
      "#008000", // Green
      "#008080", // Cyan
      "#800000", // Red
      "#800080", // Magenta
      "#808000", // Brown
      "#C0C0C0", // Light Gray
      "#808080", // Dark Gray
      "#0000FF", // Light Blue
      "#00FF00", // Light Green
      "#00FFFF", // Light Cyan
      "#FF0000", // Light Red
      "#FF00FF", // Light Magenta
      "#FFFF00", // Yellow
      "#FFFFFF"  // White
    ]
  },
  {
    name: "PICO-8",
    default: true,
    palette: [
      "#000000",
      "#1D2B53",
      "#7E2553",
      "#008751",
      "#AB5236",
      "#5F574F",
      "#C2C3C7",
      "#FFF1E8",
      "#FF004D",
      "#FFA300",
      "#FFEC27",
      "#00E436",
      "#29ADFF",
      "#83769C",
      "#FF77A8",
      "#FFCCAA",
    ]
  },
  {
    name: "Game Boy",
    default: true,
    palette: [
      "#9bbc0f",
      "#668E29",
      "#306230",
      "#0f380f"
    ]
  },
  {
    name: "Black and White",
    default: true,
    palette: [
      "#000000",
      "#FFFFFF",
      "#a8a8a8",
      "#545454",
    ]
  },
];

export const fillerPalette = [
  "#000000",
  "#ffffff",
  "#0000ff",
  "#00ff00",
  "#00ffff",
  "#ff0000",
  "#ff00ff",
  "#ffff00",
  "#333333",
  "#666666",
  "#999999",
  "#cccccc",
  "#000033",
  "#000066",
  "#000099",
  "#0000cc",
  "#006600",
  "#009900",
  "#00cc00",
  "#003333",
  "#006666",
  "#009999",
  "#00cccc",
  "#330000",
  "#660000",
  "#990000",
  "#cc0000",
  "#330033",
  "#660066",
  "#990099",
  "#cc00cc",
  "#404040",
]

export const maxResolution = 1024;
export const maxLayerSize = 2048;
export const maxLayerCount = 32;
export const maxHistory = 32;
export const maxCursorSize = 64;

export const cropProximity = 8;


export const apiUrl = process.env.NODE_ENV === 'development' ? "http://localhost:3002" : "";

export const maxRetries = 3;

export const metaKey = navigator.platform.toUpperCase().indexOf('MAC') >= 0 ? "Cmd" : "Ctrl"